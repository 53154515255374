import React from "react";
import { useHistory } from "react-router";

import { Form, Spin, notification } from "antd";

import * as S from "./styles";

import { useServiceAreas } from "hooks/api/service_areas";
import { useCreateVehicle } from "hooks/api/vehicles";

import { CreateVehicleData } from "api/vehicles";

import { CreateEditVehicleForm } from "../components/CreateEditVehicleForm";
import { CreateEditVehicleHeader } from "../components/CreateEditVehicleHeader";
import { VehicleImagePicker } from "../components/VehicleImagePicker";

export const CreateVehiclePage: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { getActiveServiceAreaID } = useServiceAreas();

  const { isLoading: isCreatingVehicle, create: createVehicle } = useCreateVehicle();

  const handleConfirmCreate = () => {
    form
      .validateFields()
      .then(() => {
        const vehicleData = {
          ...form.getFieldsValue(true),
          service_area_id: getActiveServiceAreaID(),
        } as CreateVehicleData;

        createVehicle(vehicleData, {
          onSuccess: (vehicle) => {
            history.push("/vehicles");
            notification.success({
              message: `Successfully created ${vehicle.name}`,
            });
          },
        });
      })
      .catch(() => null);
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <S.PageContainer>
      <Spin spinning={isCreatingVehicle}>
        <CreateEditVehicleHeader mode="create" onConfirm={handleConfirmCreate} onCancel={handleCancel} />

        <S.BodyContainer>
          <CreateEditVehicleForm form={form} />
          <S.ImageUploadContainer>
            <VehicleImagePicker onChange={(image) => form.setFieldsValue({ photo: image })} />
          </S.ImageUploadContainer>
        </S.BodyContainer>
      </Spin>
    </S.PageContainer>
  );
};
