import React from "react";

import { InfoCircleOutlined } from "@ant-design/icons";
import { FormInstance, Form, Input, Popover, Select } from "antd";

import * as S from "./styles";

import { useVehicleFuelTypes, useVehicleOwnershipTypes, useVehicleSizes } from "hooks/api/vehicles";

import { getDefaultValidationRules, getVinValidationRules } from "./validation";

type DropdownItem = {
  id: string | number;
  value: string;
};

interface CreateEditVehicleFormProps {
  form: FormInstance;
}

export const CreateEditVehicleForm: React.FC<CreateEditVehicleFormProps> = ({ form }) => {
  const sizes = useVehicleSizes() ?? [];
  const fuelTypes = useVehicleFuelTypes() ?? [];
  const ownershipTypes = useVehicleOwnershipTypes() ?? [];

  const renderDropdown = (placeholder: string, items: DropdownItem[]) => (
    <Select placeholder={placeholder}>
      {items.map((item) => (
        <Select.Option key={item.id} value={item.id}>
          {item.value}
        </Select.Option>
      ))}
    </Select>
  );

  const renderLabelWithInfoIcon = (label: string, toolTipText: string) => {
    const content = <S.PopOverContainer>{toolTipText}</S.PopOverContainer>;
    return (
      <>
        <label>{label}</label>
        <Popover placement="rightBottom" trigger="hover" title={label} content={content}>
          <InfoCircleOutlined style={{ fontSize: "10px", margin: "0 0 0 6px" }} />
        </Popover>
      </>
    );
  };

  return (
    <S.Container>
      <Form form={form} name="vehicle-details" layout="vertical">
        <Form.Item name="name" label="Vehicle Name" rules={getDefaultValidationRules("Vehicle Name")}>
          <Input />
        </Form.Item>

        <Form.Item
          name="license_plate_number"
          label="License Plate Number"
          rules={getDefaultValidationRules("License Plate")}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="vin"
          label={renderLabelWithInfoIcon("VIN", "The VIN can often be found on the lower-left corner of the dashboard")}
          rules={getVinValidationRules()}
        >
          <Input />
        </Form.Item>

        <Form.Item name="vehicle_size_id" label="Vehicle Size" rules={getDefaultValidationRules("Vehicle Size")}>
          {renderDropdown(
            "Please select a vehicle size",
            sizes.map(({ id, size }) => ({ id, value: size }))
          )}
        </Form.Item>

        <Form.Item
          name="make"
          label={renderLabelWithInfoIcon("Make (Optional)", "For example: Hino, Benz Mercedes, Volvo...etc")}
        >
          <Input />
        </Form.Item>

        <Form.Item name="model" label="Model (Optional)">
          <Input />
        </Form.Item>

        <Form.Item name="ownership_type_id" label="Ownership Type" rules={getDefaultValidationRules("Ownership Type")}>
          {renderDropdown(
            "Please select an ownership type",
            ownershipTypes.map(({ id, name }) => ({ id, value: name }))
          )}
        </Form.Item>

        <Form.Item name="fuel_type_id" label="Fuel Type" rules={getDefaultValidationRules("Fuel Type")}>
          {renderDropdown(
            "Please select a fuel type",
            fuelTypes.map(({ id, name }) => ({ id, value: name }))
          )}
        </Form.Item>

        <Form.Item name="notes" label="Notes">
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </S.Container>
  );
};
