import React, { useEffect } from "react";

import { notification } from "antd";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";
import { bookingSelectedAppointmentIdsState, bookingSelectedDateState } from "recoil/booking/atoms";

import { useAppointments, useGetRangeOfDaysAppointments } from "hooks/api/appointments";
import { useActiveFlow } from "hooks/application/useActiveFlow";
import { useUrl } from "hooks/application/useUrl";

import { PageContainer } from "components/PageContainer";
import { Column, Row } from "components/styles";

import { AppointmentsTable } from "./AppointmentsTable";
import { BookingMap } from "./BookingMap";
import { DateSelector } from "./DateSelector";
import { Header } from "./Header";
import { SideBar } from "./SideBar";

export const BookingPage: React.FC = () => {
  const { WORK_FLOW_OPTIONS } = useActiveFlow();
  const bookingSelectedDate = useRecoilValue(bookingSelectedDateState);
  const activeServiceArea = useRecoilValue(activeServiceAreaState);
  const setBookingSelectedAppointmentIds = useSetRecoilState(bookingSelectedAppointmentIdsState);
  const { error: appointmentsFetchingError } = useAppointments();

  useGetRangeOfDaysAppointments();

  const { setUrlWithQueryValues } = useUrl();

  useEffect(() => {
    setUrlWithQueryValues({
      date: bookingSelectedDate,
      flowKey: WORK_FLOW_OPTIONS.IKEA.key,
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [bookingSelectedDate, activeServiceArea]);

  useEffect(() => {
    setBookingSelectedAppointmentIds([]);
  }, [activeServiceArea]);

  useEffect(() => {
    if (appointmentsFetchingError) {
      notification.error({ message: "Error", description: appointmentsFetchingError });
    }
  }, [appointmentsFetchingError]);

  return (
    <PageContainer withHeader withFooter>
      <Header />
      <Row key={activeServiceArea} height="calc(100vh - 60px - 20px - 69px)">
        <SideBar />
        <Row flex="1" width="calc(100vw - 230px)">
          <Column margin="0 16px" width="50%">
            <AppointmentsTable />
          </Column>
          <Column margin="0 16px 0 0" height="100%" minWidth="50%" flex="1">
            <Row margin="0 0 16px" justifyContent="center">
              <DateSelector />
            </Row>
            <BookingMap />
          </Column>
        </Row>
      </Row>
    </PageContainer>
  );
};
