import { HiOutlineEye } from "react-icons/hi";

import { Input } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

export const MainRow = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  gap: 4px;
  .route-header-popover .ant-popover-arrow {
    display: none;
  }
`;

export const LabelColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 100%;
  margin-top: 1px;
  user-select: none;
  word-break: break-word;
  max-width: 188px;
`;

export const RouteHeaderPopoverContent = styled.div`
  width: fit-content;
  font-size: 12px;
  margin: -8px;
  color: ${COLORS.WHITE};
`;

export const RouteNameInput = styled(Input)`
  .ant-input-clear-icon {
    color: ${COLORS.GREY_DARK};
  }
`;

export const RouteName = styled.div`
  color: ${COLORS.BLACK};
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const AppointmentsCount = styled.div`
  margin-top: 2px;
  color: ${COLORS.GREY};
  font-size: 12px;
`;

export const ActionColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: default;
  justify-content: end;
  flex-flow: row wrap;
  flex: 1;
  min-width: fit-content;
`;

export const ActionColumnElements = styled.div`
  display: flex;
  justify-content: end;
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  margin: 0 3px;
  padding: 3px;
  border: 0;
  background: transparent;
  cursor: pointer;
`;

export const Icon = styled.span`
  font-size: 22px;
  color: ${COLORS.PRIMARY_BLUE};
`;

interface VisibilityIconProps {
  visible?: boolean;
}
export const VisibilityIcon = styled(Icon).attrs<VisibilityIconProps>({
  as: HiOutlineEye,
})<VisibilityIconProps>`
  color: ${(props) => (props.visible ? COLORS.PRIMARY_BLUE : COLORS.GREY)};
`;

interface IconContainerProps {
  indicator?: boolean;
  right?: string;
}
export const IconContainer = styled.span<IconContainerProps>`
  display: flex;
  align-items: center;
  position: relative;
  line-height: 0;
  ${({ indicator, right }) =>
    indicator &&
    `
    &::after {
      position: absolute;
      display: block;
      top: 0;
      right: ${right || "-4px"};
      background: ${COLORS.RED};
      width: 8px;
      height: 8px;
      border-radius: 50%;
      content: '';
    }
  `}
`;
