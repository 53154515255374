import styled from "styled-components";

import { COLORS } from "styles";

interface DayContainerProps {
  color?: string;
}
export const Day = styled.div<DayContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 131px;
  padding: 8px;
  border-style: inset;
  border: 1px solid ${COLORS.ASH_BLUE};
  background-color: ${({ color }) => color ?? COLORS.WHITE};
  cursor: pointer;
`;

export const DayContainer = styled.div`
  .calendar-day-popover .ant-popover-inner {
    border-radius: 2px;
  }
`;

interface TextProps {
  isDefault?: boolean;
}

export const DayNumber = styled.div<TextProps>`
  align-self: flex-start;
  color: ${({ isDefault }) => (isDefault ? COLORS.BLACK : COLORS.WHITE)};
  font-weight: 400;
  font-size: 14px;
`;

export const PercentBooked = styled.div<TextProps>`
  color: ${({ isDefault }) => (isDefault ? COLORS.BLACK : COLORS.WHITE)};
  font-weight: 600;
  font-size: 34px;
  padding-top: 10px;
`;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: -8px;
  color: ${COLORS.WHITE};
`;
