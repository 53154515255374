import { HiX } from "react-icons/hi";

import { Drawer as AntdDrawer } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

import { AppointmentCard as AppointmentCardBase, AppointmentHoverShadow } from "../AppointmentCard";

export const Drawer = styled(AntdDrawer)`
  position: absolute;

  .ant-drawer-header {
    padding: 6px;
    padding-left: 24px;
    border-bottom: 1px solid ${COLORS.GREY_MID};
  }
  .ant-drawer-title {
    color: ${COLORS.PRIMARY_BLUE};
    width: 280px;
  }
  .ant-drawer-close {
    padding: 10px;
    margin: 10px;
  }
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

export const AppointmentCardsContainer = styled.div`
  overflow-y: scroll;
`;

interface AppointmentCardProps {
  hovered?: boolean;
}
export const AppointmentCard = styled(AppointmentCardBase)<AppointmentCardProps>`
  overflow: hidden;

  ${(props) => props.hovered && AppointmentHoverShadow};
  &:hover {
    ${AppointmentHoverShadow};
  }
`;

export const CloseButtonIcon = styled(HiX)`
  width: 20px;
  height: 20px;
  color: ${COLORS.CHARCOAL};
`;
