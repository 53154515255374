import React from "react";

import { message, Typography, Space, Button } from "antd";

const updateServiceWorker = (serviceWorker: ServiceWorker | null): void => {
  if (!serviceWorker) return window.location.reload();
  serviceWorker.postMessage({ type: "SKIP_WAITING" });
  serviceWorker.addEventListener("statechange", (e) => {
    const serviceWorker = e.target as ServiceWorker;
    if (serviceWorker?.state === "activated") {
      window.location.reload();
    }
  });
};

export const notifyNewVersion = (swr: ServiceWorkerRegistration): void => {
  message.info({
    key: "new-version",
    duration: 0,
    content: (
      <Space>
        <Typography.Text>There is a new version of BVR!</Typography.Text>
        <Button size="small" onClick={() => updateServiceWorker(swr.waiting)}>
          Update
        </Button>
      </Space>
    ),
  });
};
