import { HiChevronRight } from "react-icons/hi";

import styled from "styled-components";

import { COLORS } from "styles";

interface ExpandIconProps {
  expanded?: boolean;
}
export const ExpandIcon = styled(HiChevronRight)<ExpandIconProps>`
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  color: ${COLORS.PRIMARY_BLUE};
  transition: transform 0.2s;
  transform: ${(props) => (props.expanded ? "rotate(90deg)" : "")};
`;
