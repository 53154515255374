import { SwitchProps } from "antd";
import styled from "styled-components";

import { DEVICE, COLORS } from "styles";

export const Container = styled.div`
  display: flex;
  height: 100%;
  overflow: hidden;
`;

export const RouteMapWrap = styled.div`
  position: relative;
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @media ${DEVICE.md} {
    display: none;
  }
`;

interface SwitchWrapperProps extends SwitchProps {
  isChecked?: boolean;
}
export const SwitchWrapper = styled.div<SwitchWrapperProps>`
  display: flex;
  align-items: center;
  margin: 0 32px 0 0;
  cursor: pointer;

  .ant-switch {
    background-color: ${(props) => (props.isChecked ? COLORS.PRIMARY_BLUE : null)};
  }

  &:last-child {
    margin-right: 0;
  }

  @media ${DEVICE.md} {
    display: none;
  }
`;

export const SwitchLabel = styled.div`
  white-space: nowrap;
  margin-right: 8px;
  color: ${COLORS.GREY};
`;

export const SwitchIcon = styled.img`
  width: 15px;
`;
