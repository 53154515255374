import styled from "styled-components";

interface ColumnProps {
  flex?: string;
  alignItems?: string;
  padding?: string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  margin?: string;
}

export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  flex: ${({ flex }) => (flex !== undefined ? flex : "")};
  align-items: ${({ alignItems }) => (alignItems !== undefined ? alignItems : "")};
  padding: ${({ padding }) => (padding !== undefined ? padding : "")};
  width: ${({ width }) => (width ? width : "")};
  min-width: ${({ minWidth }) => (minWidth ? minWidth : "")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  height: ${({ height }) => (height ? height : "")};
  margin: ${({ margin }) => (margin !== undefined ? margin : "")};
`;

interface RowProps {
  flex?: string;
  justifyContent?: string;
  padding?: string;
  width?: string;
  maxWidth?: string;
  height?: string;
  margin?: string;
}

export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  flex: ${({ flex }) => (flex !== undefined ? flex : "")};
  justify-content: ${({ justifyContent }) => (justifyContent !== undefined ? justifyContent : "")};
  padding: ${({ padding }) => (padding !== undefined ? padding : "")};
  width: ${({ width }) => (width ? width : "")};
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "")};
  height: ${({ height }) => (height !== undefined ? height : "")};
  margin: ${({ margin }) => (margin !== undefined ? margin : "")};
`;

interface TextProps {
  color?: string;
  fontSize?: number;
  fontWeight?: number;
}

export const Text = styled.span<TextProps>`
  color: ${({ color }) => (color ? color : "")};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "")};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : "")};
`;
