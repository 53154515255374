import React, { useRef } from "react";

import { Switch } from "antd";

import { useCancelledAppointmentsVisible } from "hooks/application/useCancelledAppointmentsVisible";

import { SwitchLabel, SwitchWrapper } from "pages/RoutesPage/styles";

export const CancelledAppointmentsToggle: React.FC = () => {
  const { isCancelledAppointmentsVisible, setCancelledAppointmentsVisible } = useCancelledAppointmentsVisible();
  const showCancelledAppointmentsSwitchRef = useRef<HTMLButtonElement | null>(null);

  return (
    <SwitchWrapper
      isChecked={isCancelledAppointmentsVisible}
      onClick={() => showCancelledAppointmentsSwitchRef?.current?.click()}
    >
      <SwitchLabel>Cancelled Appointments</SwitchLabel>
      <Switch
        checked={isCancelledAppointmentsVisible}
        onChange={(checked) => {
          setCancelledAppointmentsVisible(checked);
        }}
      />
    </SwitchWrapper>
  );
};
