import styled, { css, keyframes } from "styled-components";

import { COLORS } from "styles";

const bounceAnimation = keyframes`
  from {
    transform: translate(-50%, -100%);
  }

  to {
    transform: translate(-50%, calc(-100% - 10px));
  }
`;

const pulseAnimation = (color: string) => keyframes`
  0% {
		transform: scale(0.5);
		box-shadow: 0 0 0 0 ${color + "b3"};
	}

	70% {
		transform: scale(3);
		box-shadow: 0 0 0 10px ${color + "00"};
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 ${color + "00"};
	}
`;

interface ContainerProps {
  scale?: boolean;
  bounce?: boolean;
}
export const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: flex-end;
  position: relative;
  width: 40px;
  height: 31px;
  transform: translate(-50%, -100%);
  transform-origin: center bottom;
  cursor: pointer;
  transition: transform 0.1s;

  ${(props) =>
    props.scale &&
    css`
      transform: translate(-50%, -100%) scale(1.3);
      z-index: 100;
    `}

  ${(props) =>
    props.bounce &&
    css`
      animation-duration: 0.5s;
      animation-name: ${bounceAnimation};
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      animation-iteration-count: infinite;
      animation-direction: alternate;
    `}
`;

export const PulseAnimationWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
`;

interface PulseAnimationProps {
  color: string;
}
export const PulseAnimation = styled.div<PulseAnimationProps>`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: ${(props) => pulseAnimation(props.color)} 2s infinite;
`;

export const AppointmentsCountIndicator = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 0 6px;
  border: 1px solid ${COLORS.WHITE};
  border-radius: 16px;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.GREY_DARK};
  transform: translate(30%, -40%);
  line-height: 16px;
`;
