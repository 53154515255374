import { Form } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

export const FormContainer = styled(Form)`
  max-width: 350px;
  width: 100%;
`;

export const LoginButton = styled.button`
  margin-bottom: 10px;
  border-radius: 6px;
  border: none;
  width: 100%;
  padding: 10px;
  background: ${COLORS.PRIMARY_BLUE};
  color: ${COLORS.WHITE};
  transition: all 0.2s ease-in;

  &:hover {
    background: ${COLORS.PRIMARY_MID};
    color: ${COLORS.GREY_DARK};
    cursor: pointer;
  }
`;
