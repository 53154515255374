import { useMemo } from "react";

import { pickBy, differenceBy } from "lodash";

import { useRecoilValue } from "recoil";
import { hiddenAppointmentMapState } from "recoil/appointments/atoms";

import { useCreateRouteState } from "hooks/application/createRoute";

import { useRoutes } from "../useRoutes";

import { useAppointments } from "./useAppointments";
import { useAssignedAppointments } from "./useAssignedAppointments";

export const useUnassignedAppointments = () => {
  const { routesLibrary } = useRoutes();
  const { routeID, routeItems } = useCreateRouteState();
  const { appointmentsLibrary } = useAppointments();
  const assignedAppointments = useAssignedAppointments();
  const hiddenAppointmentsMap = useRecoilValue(hiddenAppointmentMapState);

  const unassignedAppointments = useMemo(() => {
    const assignedAppointmentsIDs = assignedAppointments.map(({ id }) => id);

    const unassignedAppointmentsLibrary = pickBy(appointmentsLibrary, (_, appointmentID) => {
      const isAppointmentHidden = hiddenAppointmentsMap[appointmentID];
      const isAppointmentAssigned = assignedAppointmentsIDs.includes(appointmentID);
      return !isAppointmentHidden && !isAppointmentAssigned;
    });

    return Object.values(unassignedAppointmentsLibrary);
  }, [appointmentsLibrary, assignedAppointments, hiddenAppointmentsMap]);

  const editableRouteDeletedAppointments = useMemo(() => {
    const isEditableRoute = routeID && routeItems;
    if (!isEditableRoute) return [];

    const route = routesLibrary[routeID];
    const deletedItems = differenceBy(route?.route_items, routeItems, (routeItem) => routeItem.appointment_id);

    return deletedItems
      .map(({ appointment_id }) => appointmentsLibrary[appointment_id])
      .filter((appointment) => {
        return !hiddenAppointmentsMap[appointment.id];
      });
  }, [routeID, routeItems, appointmentsLibrary, routesLibrary, hiddenAppointmentsMap]);

  return useMemo(
    () => [...unassignedAppointments, ...editableRouteDeletedAppointments],
    [unassignedAppointments, editableRouteDeletedAppointments]
  );
};
