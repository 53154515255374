import React, { useRef } from "react";

import { Logistics } from "@secondcloset/types";

import { Popover } from "antd";
import moment from "moment";

import * as S from "./styles";
import { COLORS } from "styles";

import { getAvailabilityAppointmentsCount, getPercentage } from "pages/CapacityUtilizationPage/helpers";

import { getColorByPercentBooked } from "./helpers";

interface CalendarDayProps {
  date: string;
  availability?: Logistics.Availability;
  timeslots: string[];
  onClick: (date: string) => void;
}

export const CalendarDay: React.FC<CalendarDayProps> = ({ date, availability, timeslots, onClick }) => {
  const popoverContainerRef = useRef<HTMLDivElement>(null);
  const day = moment(date).format("D");

  const handleClick = () => {
    onClick(date);
  };

  if (!availability)
    return (
      <S.DayContainer onClick={handleClick} data-testid="calendarDay">
        <S.DayNumber isDefault>{day}</S.DayNumber>
      </S.DayContainer>
    );

  const dayTimeslots = availability.timeslots;
  const availabilitiesToDisplay = dayTimeslots.filter((av) => timeslots.includes(av.timeslot));

  const bookedAppointmentsCount = getAvailabilityAppointmentsCount(availabilitiesToDisplay, "booked");
  const maxAppointmentsCount = getAvailabilityAppointmentsCount(availabilitiesToDisplay, "max");
  const percentBooked = getPercentage(bookedAppointmentsCount, maxAppointmentsCount);

  const backgroundColor = getColorByPercentBooked(percentBooked);
  const isDefault = backgroundColor === COLORS.WHITE;

  const whiteGloveAppointmentsCount = getAvailabilityAppointmentsCount(availabilitiesToDisplay, "white_glove");
  const allOtherAppointmentsCount = getAvailabilityAppointmentsCount(availabilitiesToDisplay, "non_white_glove");
  const consumerAppointmentsCount = getAvailabilityAppointmentsCount(availabilitiesToDisplay, "consumer");

  const popoverContent = (
    <S.PopoverContent>
      <span>{whiteGloveAppointmentsCount} White Glove</span>
      <span>{allOtherAppointmentsCount} All Other</span>
      <span>{consumerAppointmentsCount} Consumer</span>
    </S.PopoverContent>
  );

  return (
    <S.DayContainer onClick={handleClick} data-testid="calendarDay">
      <Popover
        content={popoverContent}
        trigger="hover"
        color={COLORS.GREY_DARKER}
        placement="rightTop"
        overlayClassName={"calendar-day-popover"}
        getPopupContainer={(element) => {
          return popoverContainerRef.current ?? element;
        }}
      >
        <S.Day color={backgroundColor}>
          <S.DayNumber isDefault={isDefault}>{day}</S.DayNumber>
          <S.PercentBooked isDefault={isDefault} data-testid="percentBooked">
            {percentBooked}%
          </S.PercentBooked>
        </S.Day>
      </Popover>
    </S.DayContainer>
  );
};
