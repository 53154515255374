import React from "react";

import { AvailabilitySlot } from "@secondcloset/logistics-utils";

import { AxisLeft, AxisBottom } from "@visx/axis";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { BarGroup } from "@visx/shape";
import { Tooltip, useTooltip } from "@visx/tooltip";
import { sum } from "lodash";

import * as S from "./styles";
import { COLORS } from "styles";

import { getPercentage } from "pages/CapacityUtilizationPage/helpers";

import { TooltipContent, TooltipContainerStyle } from "./Tooltip";

const ServiceLevelsConfig = {
  nonWhiteGlove: {
    title: "All Other Service",
    color: COLORS.ORANGE,
  },
  whiteGlove: {
    title: "White Glove",
    color: COLORS.PRIMARY_BLUE,
  },
};

type ServiceLevelName = keyof typeof ServiceLevelsConfig;

const ServiceLevelNames = Object.keys(ServiceLevelsConfig) as ServiceLevelName[];
const ServiceLevelLimitKeys = ServiceLevelNames.map((name) => name + "Limit");

export type DataItem = {
  timeslot: string;
  values: {
    [key in ServiceLevelName]: [number, number];
  };
};

interface CapacityBarChartProps {
  data: DataItem[];
  width: number;
  height: number;
}

const MARGIN = { TOP: 70, LEFT: 60, RIGHT: 50, BOTTOM: 70 };

export const CapacityBarChart: React.FC<CapacityBarChartProps> = ({ data, width, height }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, showTooltip, hideTooltip } = useTooltip();

  if (data.length === 0) return null;

  const chartWidth = width - MARGIN.LEFT - MARGIN.RIGHT;
  const chartHeight = height - MARGIN.BOTTOM;

  const getMaxAppointmentsCount = () => {
    const maxValuesPerTimeslot = data.map((dataItem) => {
      const dataItemAllValues = Object.values(dataItem.values).flat();
      return Math.max(...dataItemAllValues);
    });

    return Math.max(...maxValuesPerTimeslot);
  };
  const maxAppointmentsCount = getMaxAppointmentsCount();

  const getTimeslotTotalBookedPercentage = (dataItem: DataItem) => {
    const sumValues = sum(ServiceLevelNames.map((name) => dataItem.values[name][0]));
    const sumLimits = sum(ServiceLevelNames.map((name) => dataItem.values[name][1]));

    return getPercentage(sumValues, sumLimits);
  };

  const timeslotScale = scaleBand({
    domain: data.map((dataItem) => AvailabilitySlot.getDisplaySlot(dataItem.timeslot)),
    range: [0, chartWidth],
    paddingInner: 0.4,
    paddingOuter: 0.1,
  });

  const serviceLevelScale = scaleBand({
    domain: ServiceLevelLimitKeys,
    paddingInner: 0.1,
    range: [0, timeslotScale.bandwidth()],
  });

  const appointmentsCountScale = scaleLinear({
    domain: [0, maxAppointmentsCount],
    range: [chartHeight, MARGIN.TOP],
  });

  const serviceLevelColorScale = scaleOrdinal({
    domain: ServiceLevelNames as string[],
    range: ServiceLevelNames.map((name) => ServiceLevelsConfig[name].color),
  });

  const bookedPercentScale = scaleBand({
    domain: data.map((_, index) => index),
    range: [0, chartWidth],
    paddingInner: 0.4,
    paddingOuter: 0.1,
  });

  const chartData = data.map(({ timeslot, values }) => {
    const serviceLevelData = ServiceLevelNames.reduce(
      (result, serviceLevelName) => ({
        ...result,
        [serviceLevelName]: values[serviceLevelName][0],
        [serviceLevelName + "Limit"]: values[serviceLevelName][1],
      }),
      {}
    );

    return { timeslot, ...serviceLevelData };
  });

  return (
    <S.Container style={{ width, height }}>
      <svg width="100%" height="100%">
        <Group left={MARGIN.LEFT}>
          <BarGroup
            data={chartData}
            height={chartHeight}
            keys={ServiceLevelLimitKeys}
            x0={(d) => AvailabilitySlot.getDisplaySlot(d.timeslot)}
            x0Scale={timeslotScale}
            x1Scale={serviceLevelScale}
            yScale={appointmentsCountScale}
            color={serviceLevelColorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => {
                const dataItem = data[barGroup.index];

                return (
                  <Group key={`bar-group-${barGroup.index}`} left={barGroup.x0}>
                    {barGroup.bars.map((bar) => {
                      const serviceLevelName = bar.key.replace("Limit", "") as ServiceLevelName;
                      const [value, limit] = dataItem.values[serviceLevelName];

                      const limitBarHeight = limit === 0 ? 0 : bar.height;
                      const valueBarHeight = chartHeight - appointmentsCountScale(value);

                      const limitBarY = bar.y;
                      const valueBarY = bar.y + bar.height - valueBarHeight;
                      const tooltipTopPosition = Math.min(limitBarY, valueBarY);

                      return (
                        <Group
                          key={`bar-group-bar-${bar.key}`}
                          onMouseMove={() => {
                            const serviceLevelBookedPercentage = getPercentage(value, limit);
                            const serviceLevelTitle = ServiceLevelsConfig[serviceLevelName].title;

                            showTooltip({
                              tooltipTop: tooltipTopPosition,
                              tooltipLeft: MARGIN.LEFT + barGroup.x0 + bar.x + bar.width,
                              tooltipData: `${serviceLevelBookedPercentage}% ${serviceLevelTitle}`,
                            });
                          }}
                          onMouseLeave={() => hideTooltip()}
                        >
                          <rect
                            x={bar.x}
                            y={limitBarY}
                            width={bar.width}
                            height={limitBarHeight}
                            fill={COLORS.GREY_MID}
                          />
                          <rect x={bar.x} y={valueBarY} width={bar.width} height={valueBarHeight} fill={bar.color} />
                        </Group>
                      );
                    })}
                  </Group>
                );
              })
            }
          </BarGroup>
        </Group>

        <AxisLeft
          scale={appointmentsCountScale}
          left={MARGIN.LEFT}
          hideZero
          hideTicks
          tickFormat={appointmentsCountScale.tickFormat(1, "d")}
          numTicks={maxAppointmentsCount < 10 ? maxAppointmentsCount : 10}
          tickLabelProps={() => ({
            fill: COLORS.GREY_DARKER,
            fontSize: 14,
            textAnchor: "end",
          })}
        />

        <AxisBottom
          top={chartHeight}
          left={MARGIN.LEFT}
          scale={timeslotScale}
          hideTicks
          tickLabelProps={() => ({
            fill: COLORS.GREY_DARKER,
            fontSize: 16,
            textAnchor: "middle",
          })}
        />

        <AxisBottom
          top={chartHeight + 30}
          left={MARGIN.LEFT}
          scale={bookedPercentScale}
          hideAxisLine
          hideTicks
          tickFormat={(index) => `${getTimeslotTotalBookedPercentage(data[index])}% booked`}
          tickLabelProps={() => ({
            fill: COLORS.GREY_DARK,
            fontSize: 12,
            fontWeight: 600,
            textAnchor: "middle",
          })}
        />
      </svg>

      <S.Legend
        scale={serviceLevelColorScale.copy()}
        direction="row"
        labelFormat={(serviceLevelName: ServiceLevelName) => ServiceLevelsConfig[serviceLevelName].title}
      />

      {tooltipOpen && (
        <Tooltip top={tooltipTop} left={tooltipLeft} style={TooltipContainerStyle}>
          <TooltipContent>{tooltipData} Booked</TooltipContent>
        </Tooltip>
      )}
    </S.Container>
  );
};
