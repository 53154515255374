import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import { RecoilRoot } from "recoil";

import { App } from "./App";
import { notifyNewVersion } from "./lib/notifyNewVersion";
import { sentryInit } from "./lib/sentry";
import { ErrorBoundary } from "./lib/sentry/ErrorBoundary";
import * as serviceWorker from "./serviceWorker";

import "./styles/normalize.css";
import "antd/dist/antd.less";

// initializing sentry before react
sentryInit();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <CookiesProvider>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </QueryClientProvider>
    </RecoilRoot>
  </CookiesProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: notifyNewVersion,
});
