import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 230px;
  height: 100%;
  border-right: solid 1px ${COLORS.GREY_LIGHT};

  .ant-menu-item {
    padding-left: 16px;
  }
`;

export const MenuItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AddressContainer = styled.div`
  overflow-x: hidden;
  text-overflow: ellipsis;
  min-width: 0;
`;

export const EmptyContainer = styled(Container)`
  padding: 0 36px;
`;
