import { useQuery } from "react-query";

import { fetchRouteAutomationRunTimeConfig } from "api/routeAutomationRunTimeConfig";

export const useRouteAutomationConfig = () => {
  const { data: runTime = 0, isLoading } = useQuery<number>(
    ["routeAutomationRunTimeConfig"],
    () => fetchRouteAutomationRunTimeConfig(),
    {
      cacheTime: Infinity,
      staleTime: 300000,
    }
  );

  return { runTime, isLoading };
};
