import React from "react";

import { EnvironmentOutlined } from "@ant-design/icons";
import { Empty, Menu } from "antd";

import * as S from "./styles";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";
import { bookingSelectedAppointmentIdsState, bookingSelectedWarehouseIdState } from "recoil/booking/atoms";

import { useWarehouses } from "hooks/api/warehouses";

import { Appointment as AppointmentLib } from "lib/appointment";

import { RedDot } from "./RedDot";

export const SideBar: React.FC = () => {
  const { warehouses: ikeaWarehouseList, warehouseAppointmentsLibrary } = useWarehouses();

  const activeServiceArea = useRecoilValue(activeServiceAreaState);
  const [selectedWarehouseId, dispatchSelectedWarehouseId] = useRecoilState(bookingSelectedWarehouseIdState);
  const dispatchBookingSelectedAppointmentIds = useSetRecoilState(bookingSelectedAppointmentIdsState);

  const getFilteredWarehouseList = () => {
    return ikeaWarehouseList.filter((warehouse) => warehouse.service_area === activeServiceArea);
  };

  const getAppointmentsCountLessThanOneDay = (warehouseId: string) => {
    const minInOneDay = 24 * 60;
    const apptList = warehouseAppointmentsLibrary[warehouseId];

    return apptList.reduce((count, appointment) => {
      const { difference } = AppointmentLib.getTimeLeftToBook(appointment);
      if (difference < minInOneDay) count++;
      return count;
    }, 0);
  };

  if (!getFilteredWarehouseList() || getFilteredWarehouseList().length === 0) {
    const description = "All Range of Days orders are assigned! Please check again later";
    return (
      <S.EmptyContainer>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={description} />
      </S.EmptyContainer>
    );
  }

  return (
    <S.Container>
      <Menu
        onClick={({ key }) => {
          // warehouse.id is the key
          dispatchSelectedWarehouseId(key.toString());
          dispatchBookingSelectedAppointmentIds([]);
        }}
        selectedKeys={[selectedWarehouseId]}
        mode="inline"
      >
        {getFilteredWarehouseList().map((warehouse) => {
          return (
            <Menu.Item key={warehouse.id}>
              <S.MenuItemContainer>
                <S.AddressContainer>
                  <EnvironmentOutlined />
                  {warehouse.address}
                </S.AddressContainer>
                <RedDot dueTodayCount={getAppointmentsCountLessThanOneDay(warehouse.id)} />
              </S.MenuItemContainer>
            </Menu.Item>
          );
        })}
      </Menu>
    </S.Container>
  );
};
