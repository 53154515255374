import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  h1 {
    margin-top: 20px;
    font-weight: 100;
  }

  .grey-icon {
    color: ${COLORS.GREY};
  }
`;

export const AppName = styled.div`
  max-width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 35px;
  color: ${COLORS.PRIMARY_BLUE};
  margin: 10px 0;
  font-weight: 100;
`;

export const LogoWrap = styled.div`
  max-width: 300px;
`;

export const BackgroundContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    width: 100vw;
    height: 100vh;
  }

  z-index: -1;
`;
