import React, { useState, useEffect } from "react";

import { Button } from "antd";
import { startCase } from "lodash";

import * as S from "./styles";

import { useVehicles } from "hooks/api/vehicles";
import { useCreateRouteAutomation } from "hooks/application/createRouteAutomation";
import { useFuzzySearch } from "hooks/application/useFuzzySearch";

import { SelectToggle } from "./types";

import { ServiceArea } from "interface/serviceArea";
import { Vehicle } from "interface/vehicle";

interface VehicleModalProps {
  visible: boolean;
  selectedVehicleIDs: string[];
  onSelect: (vehicle: Vehicle) => void;
  onDeselect?: (vehicle: Vehicle) => void;
  selectToggle?: SelectToggle;
  footer: React.ReactNode[];
  onClose: () => void;
}

const DEFAULT_TABLE_PAGE_SIZE = 10;

export const VehicleModal: React.FC<VehicleModalProps> = ({
  visible,
  selectedVehicleIDs,
  onSelect,
  onDeselect,
  selectToggle,
  footer,
  onClose,
}) => {
  const [pageSize, setPageSize] = useState(DEFAULT_TABLE_PAGE_SIZE);

  const { isLoading: isVehiclesLoading, getVehiclesAvailableToRoute } = useVehicles();
  const { areVehiclesAvailable } = useCreateRouteAutomation();

  const vehiclesAvailableToRoute = getVehiclesAvailableToRoute();

  useEffect(() => {
    if (!visible) {
      setSearchString("");
    }

    if (visible) {
      adjustTablePageSizeToFitModal();
    }
  }, [visible]);

  const adjustTablePageSizeToFitModal = () => {
    if (window.innerHeight < 700) setPageSize(4);
    else if (window.innerHeight < 800) setPageSize(6);
    else if (window.innerHeight < 900) setPageSize(8);
    else setPageSize(DEFAULT_TABLE_PAGE_SIZE);
  };

  const renderTableActionColumn = (vehicle: Vehicle) => {
    if (!onDeselect && selectedVehicleIDs.includes(vehicle.id)) {
      return <S.CheckIcon />;
    }

    if (onDeselect && selectedVehicleIDs.includes(vehicle.id)) {
      return (
        <>
          <S.CheckIcon />
          <Button className="action-button" onClick={() => onDeselect(vehicle)}>
            Deselect Vehicle
          </Button>
        </>
      );
    }

    const isVehicleAvailable = areVehiclesAvailable([vehicle]);
    if (isVehicleAvailable) {
      return (
        <Button className="action-button" onClick={() => onSelect(vehicle)}>
          Select Vehicle
        </Button>
      );
    }

    return "Vehicle is unavailable";
  };

  const getTableRowClassname = (record: unknown) => {
    const vehicle = record as Vehicle;

    return selectedVehicleIDs.includes(vehicle.id) ? "selected" : "";
  };

  const getTableColumns = () => {
    return [
      {
        key: "service_area",
        title: "Service Area",
        dataIndex: "service_area",
        render: (serviceArea: ServiceArea) => serviceArea.name.toUpperCase(),
      },
      {
        key: "name",
        title: "Name",
        dataIndex: "name",
      },
      {
        key: "license_plate_number",
        title: "License Plate",
        dataIndex: "license_plate_number",
      },
      {
        key: "make",
        title: "Make",
        dataIndex: "make",
        render: (make: string) => startCase(make),
      },
      {
        key: "model",
        title: "Model",
        dataIndex: "model",
        render: (model: string) => startCase(model),
      },
      {
        key: "action",
        title: "Action",
        className: "action",
        render: renderTableActionColumn,
      },
    ];
  };

  const [searchString, setSearchString] = useState("");
  const searchVehicles = useFuzzySearch(vehiclesAvailableToRoute, {
    keys: ["name", "license_plate_number", "make", "model"],
  });
  const filteredVehiclesList = searchVehicles(searchString);

  return (
    <S.Modal
      visible={visible}
      title="Select Vehicle"
      closeIcon={<S.ModalCloseButton />}
      footer={footer}
      onCancel={onClose}
      destroyOnClose
      width="100%"
      style={S.ModalStyle}
    >
      <S.TopBar>
        <S.SearchInput
          autoFocus
          placeholder="Search vehicle"
          suffix={<S.SearchInputIcon />}
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />

        {selectToggle && (
          <S.ToggleContainer>
            <S.ToggleButton
              active={selectToggle.canSelectAll}
              disabled={!selectToggle.canSelectAll}
              onClick={selectToggle.onSelectAll}
            >
              Select All
            </S.ToggleButton>
            <S.Divider type="vertical" />
            <S.ToggleButton
              active={selectToggle.canDeselectAll}
              disabled={!selectToggle.canDeselectAll}
              onClick={selectToggle.onDeselectAll}
            >
              Deselect All
            </S.ToggleButton>
          </S.ToggleContainer>
        )}
      </S.TopBar>

      <S.VehiclesTable
        rowKey="id"
        loading={isVehiclesLoading}
        columns={getTableColumns()}
        dataSource={filteredVehiclesList}
        rowClassName={getTableRowClassname}
        hasDeselect={!!onDeselect}
        pagination={{
          pageSize,
          hideOnSinglePage: true,
        }}
      />
    </S.Modal>
  );
};
