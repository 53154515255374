import { useEffect, useState } from "react";

import moment from "moment";

import * as S from "./styles";

import { useRouteAutomationConfig } from "hooks/api/useRouteAutomationConfig";

import { computeEtaSeconds } from "./utilities";

import { RouteAutomationJob } from "interface/routeAutomationJob";

interface RouteAutomationJobPopoverProps {
  routeAutomationJob: RouteAutomationJob;
}

export const RouteAutomationJobPopover: React.FC<RouteAutomationJobPopoverProps> = ({ routeAutomationJob }) => {
  const { runTime } = useRouteAutomationConfig();

  const etaInSeconds = computeEtaSeconds(runTime, routeAutomationJob);

  const [eta, setEta] = useState<string | undefined>();

  useEffect(() => {
    const interval = setInterval(() => {
      const start = moment(routeAutomationJob.created_at);
      const end = start.add(etaInSeconds, "seconds");
      const diff = end.diff(moment());

      if (diff > 0) {
        setEta(moment(diff).format("mm:ss"));
      } else {
        setEta("00:00");
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [etaInSeconds, routeAutomationJob, runTime]);

  return (
    <S.RouteAutomationPopoverContainer>
      <span>Automation In Progress</span> <br />
      {eta && <span>{eta} Remaining</span>}
    </S.RouteAutomationPopoverContainer>
  );
};
