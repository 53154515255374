import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { Table, Button } from "antd";
import { startCase } from "lodash";

import * as S from "./styles";

import { useServiceAreas } from "hooks/api/service_areas";
import { useVehicles } from "hooks/api/vehicles";
import { useActiveDate } from "hooks/application/useActiveDate";

import { PageContainer } from "components/PageContainer";

import { StatusTag } from "./components/StatusTag";
import { VehicleSearchInput } from "./VehicleSearchInput";
import { VehicleStatusSelector } from "./VehicleStatusSelector";

import {
  FuelType as VehicleFuelType,
  OwnershipType as VehicleOwnershipType,
  Size as VehicleSize,
  Vehicle,
} from "interface/vehicle";

const DEFAULT_STATUS = "in_service";

export const VehiclesPage: React.FC = () => {
  const { activeDate } = useActiveDate();
  const { getActiveServiceAreaID } = useServiceAreas();

  const activeServiceAreaID = getActiveServiceAreaID();

  const [query, setQuery] = useState({
    search: "",
    status: DEFAULT_STATUS,
    page: 1,
    per_page: 10,
    date: activeDate,
  });

  useEffect(() => {
    setQuery((query) => ({ ...query, page: 1, date: activeDate }));
  }, [activeDate]);

  const {
    isLoading: isLoadingVehicles,
    getActiveServiceAreaVehicles,
    statistics,
    pagination,
  } = useVehicles(
    { ...query, service_area_id: activeServiceAreaID ?? "" },
    {
      enabled: Boolean(activeServiceAreaID),
    }
  );

  const activeServiceAreaVehicles = getActiveServiceAreaVehicles();

  const getTableColumns = () => {
    return [
      {
        title: "Vehicle Name",
        dataIndex: "name",
        key: "name",
        // eslint-disable-next-line react/display-name
        render: (name: string, vehicle: Vehicle) => <Link to={`/vehicles/${vehicle.id}`}>{name}</Link>,
      },
      {
        title: "Vehicle Status",
        dataIndex: "status",
        key: "status",
        // eslint-disable-next-line react/display-name
        render: (status: string, vehicle: Vehicle) => <StatusTag status={status} inUse={vehicle.in_use} />,
      },
      {
        title: "Vehicle Size",
        dataIndex: "vehicle_size",
        key: "vehicle_size",
        render: (size: VehicleSize) => size.size,
      },
      {
        title: "VIN",
        dataIndex: "vin",
        key: "vin",
      },
      {
        title: "Make",
        dataIndex: "make",
        key: "make",
        render: (make: string) => startCase(make),
      },
      {
        title: "Ownership Type",
        dataIndex: "ownership_type",
        key: "ownership_type",
        render: (ownershipType: VehicleOwnershipType) => startCase(ownershipType.name),
      },
      {
        title: "Fuel Type",
        dataIndex: "fuel_type",
        key: "fuel_type",
        render: (fuelType: VehicleFuelType) => startCase(fuelType.name),
      },
    ];
  };

  return (
    <PageContainer withHeader withFooter withPadding>
      <S.PageHeader>
        <S.PageTitle>Vehicles</S.PageTitle>

        <Button type="primary">
          <Link to="/vehicles/create">Create Vehicle</Link>
        </Button>
      </S.PageHeader>

      <S.VehiclesStatistics isLoading={isLoadingVehicles} statistics={statistics} />

      <S.SearchAndFilterBar>
        <VehicleSearchInput
          value={query.search}
          onChange={(search) => setQuery((query) => ({ ...query, page: 1, search }))}
        />

        <VehicleStatusSelector
          value={query.status}
          onChange={(status) => setQuery((query) => ({ ...query, page: 1, status }))}
        />
      </S.SearchAndFilterBar>

      <S.VehicleTableWrap>
        <Table
          rowKey="id"
          loading={isLoadingVehicles}
          dataSource={activeServiceAreaVehicles}
          columns={getTableColumns()}
          pagination={{
            total: pagination?.count,
            pageSize: query.per_page,
            current: query.page,
            onChange: (pageNumber) => {
              setQuery((query) => ({ ...query, page: pageNumber }));
            },
          }}
        />
      </S.VehicleTableWrap>
    </PageContainer>
  );
};
