import { useEffect, useState } from "react";

import { useActiveServiceAreaTimeslots } from "hooks/application/capacityUtilization";

export const useSelectedTimeslots = () => {
  const activeServiceAreaTimeslots = useActiveServiceAreaTimeslots();

  const [selectedTimeslots, setSelectedTimeslots] = useState([] as string[]);

  useEffect(() => {
    setSelectedTimeslots(activeServiceAreaTimeslots);
  }, [activeServiceAreaTimeslots]);

  const updateSelectedTimeslots = (timeslots: string[]) => {
    if (timeslots.length === 0) {
      setSelectedTimeslots(activeServiceAreaTimeslots);
      return;
    }

    setSelectedTimeslots(timeslots);
  };

  return {
    selectedTimeslots,
    updateSelectedTimeslots,
  };
};
