import { Fulfillment, Storage } from "@secondcloset/types";

import { DefaultValue, selector } from "recoil";

import { Markers } from "lib/markers";

import * as IkeaHelpers from "../_helpers/IkeaHelpers";

import {
  bookingAppointmentsMarkersState,
  BookingAppointmentsMarkersState,
  bookingAppointmentsState,
  bookingPickupDeliveryPairState,
} from "./atoms";

type CommonAppointment = Fulfillment.Appointment | Storage.Appointment;

type Appointment = Fulfillment.Appointment;

type AppointmentsState = {
  [appointmentID: string]: Appointment;
};

export const setBookingAppointmentIndex = selector<CommonAppointment[]>({
  key: "setBookingAppointmentIndex",
  get: () => [],
  set: ({ set }, commonAppointments) => {
    if (commonAppointments instanceof DefaultValue) return;

    const appointments = commonAppointments as Appointment[];
    const appointmentsState = appointments.reduce((acc: AppointmentsState, appointment: Appointment) => {
      acc[appointment.id] = appointment;
      return acc;
    }, {});

    set(bookingAppointmentsState, appointmentsState);
  },
});

export const setBookingAppointmentsMarkersIndex = selector<CommonAppointment[]>({
  key: "setBookingAppointmentsMarkersIndex",
  get: () => [],
  set: ({ set }, commonAppointments) => {
    if (commonAppointments instanceof DefaultValue) return;

    const appointments = commonAppointments as Appointment[];
    const appointmentsMarkerState = appointments.reduce(
      (acc: BookingAppointmentsMarkersState, appointment: Appointment) => {
        const markerKey = Markers.getMarkerKeyFromAppointment(appointment);
        if (!acc[markerKey]) {
          acc[markerKey] = [];
        }
        acc[markerKey].push(appointment.id);
        return acc;
      },
      {}
    );

    set(bookingAppointmentsMarkersState, appointmentsMarkerState);
  },
});

export const setBookingPickupDeliveryPair = selector<CommonAppointment[]>({
  key: "setBookingPickupDeliveryPair",
  get: () => [],
  set: ({ set }, commonAppointments) => {
    if (commonAppointments instanceof DefaultValue) return;

    const appointments = commonAppointments as Appointment[];
    const bookingPickupDeliveryPair = appointments.reduce(
      (acc: { [appointmentID: string]: string }, appointment: Appointment) => {
        if (!acc[appointment.id]) {
          const matchingInventoryPickupOrCrossDockDelivery = IkeaHelpers.getIkeaMatchingAppointmentPair(
            appointment as Fulfillment.Appointment,
            ["cross_dock_delivery", "inventory_pick_up"],
            commonAppointments as Fulfillment.Appointment[]
          );

          if (matchingInventoryPickupOrCrossDockDelivery) {
            acc[appointment.id] = matchingInventoryPickupOrCrossDockDelivery.id;

            acc[matchingInventoryPickupOrCrossDockDelivery.id] = appointment.id;
          } // else: it is an inventory on hand appointment, no extra logic needed
        }

        return acc;
      },
      {}
    );
    set(bookingPickupDeliveryPairState, bookingPickupDeliveryPair);
  },
});
