import { serialize } from "object-to-formdata";

import { API } from "lib/API";

import { Vehicle } from "interface/vehicle";

export interface CreateVehicleData {
  service_area_id: number;
  name: string;
  license_plate_number: string;
  vin: string;
  vehicle_size_id: number;
  make?: string;
  model?: string;
  ownership_type_id: number;
  fuel_type_id: number;
  notes?: string;
  photo?: File;
  status?: string;
}

const getPhotoAttributes = (photo?: File) => {
  return photo ? { file: photo } : null;
};

const getVehicleRequestData = (data: CreateVehicleData) => {
  const result = {
    ...data,
    photo_attributes: getPhotoAttributes(data?.photo),
  };

  delete result.photo;

  return result;
};

export const createVehicle = async (data: CreateVehicleData) => {
  const api = new API();

  const formData = serialize({ vehicle: getVehicleRequestData(data) });

  const response = await api.post("/vehicles", formData as unknown as Record<string, unknown>);
  return response.data as Vehicle;
};
