import { useMemo } from "react";

import { useGetRouteAppointments } from "hooks/application/useGetRouteAppointments";

import { Appointment as AppointmentLib } from "lib/appointment";

import { useAppointments } from "../appointments";

import { useRoutes } from "./useRoutes";

import { AppointmentOrShipment } from "interface/appointment";

export const useRoutesWithAppointments = () => {
  const { routesLibrary } = useRoutes();
  const { appointmentsLibrary } = useAppointments();
  const getRouteAppointments = useGetRouteAppointments();

  const routesWithAppointments = useMemo(() => {
    const getGroupedRouteAppointments = (routeID: string) => {
      const appointments = getRouteAppointments(routeID);

      const hasReverseLogistics = appointments.find((a) => a?.job_type === "reverse_logistics");
      const result = hasReverseLogistics
        ? AppointmentLib.groupAppointmentsByReturnToSender(appointments)
        : AppointmentLib.groupAppointmentsByInventoryPickup(appointments);

      return result as AppointmentOrShipment[];
    };

    return Object.values(routesLibrary)
      .map((route) => ({
        ...route,
        appointments: getGroupedRouteAppointments(route.id),
      }))
      .filter(({ appointments }) => appointments.length > 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routesLibrary, appointmentsLibrary]);

  return routesWithAppointments;
};
