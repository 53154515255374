import { AppointmentSlot } from "@secondcloset/types/src/storage";

import { MARKER_COLORS } from "styles";

class Slot {
  static getSlotColor = (slot?: AppointmentSlot): string => {
    switch (slot) {
      case "10-13":
      case "09-13":
        return MARKER_COLORS.GREEN_DARKER;
      case "13-16":
      case "13-17":
        return MARKER_COLORS.BLUE_DARKER;
      case "16-19":
      case "17-21":
        return MARKER_COLORS.ORANGE;
      default:
        return MARKER_COLORS.PURPLE;
    }
  };
}

export { Slot };
