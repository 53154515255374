import styled, { css } from "styled-components";

import { COLORS } from "styles";

import { AppointmentCard as AppointmentCardBase, AppointmentHoverShadow } from "../AppointmentCard";

interface AppointmentCardProps {
  hovered?: boolean;
  selected?: boolean;
}
export const AppointmentCard = styled(AppointmentCardBase)<AppointmentCardProps>`
  cursor: pointer;
  ${(props) => props.hovered && AppointmentHoverShadow};

  ${(props) =>
    props.selected &&
    css`
      background-color: ${COLORS.PRIMARY_LIGHT};
    `}
`;
