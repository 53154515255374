import { useActiveMarker } from "hooks/application/useActiveMarker";

import { AppointmentOrShipment } from "interface/appointment";

export const useClickAppointmentCard = () => {
  const { setActiveMarker, getMarkerKeyByAppointmentID } = useActiveMarker();

  const handleAppointmentCardClick = (appointment: AppointmentOrShipment) => {
    const markerKey = getMarkerKeyByAppointmentID(appointment.id);
    if (markerKey) setActiveMarker({ id: markerKey, shouldCenter: true, selectedAppointmentId: appointment.id });
  };

  return handleAppointmentCardClick;
};
