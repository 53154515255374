import { useEffect } from "react";
import { useQueryClient } from "react-query";

import { CheckCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";
import moment from "moment";

import { COLORS } from "styles";

import { useRouteAutomationJobs } from "hooks/api/useRouteAutomationJobs";
import { useLocalStorage } from "hooks/application/useLocalStorage";

import { JobStatus, RouteAutomationJob } from "interface/routeAutomationJob";

export const useCompletedRouteAutomationJobEffects = () => {
  const queryClient = useQueryClient();
  const { getValue, setValue } = useLocalStorage();
  const { routeAutomationJobs } = useRouteAutomationJobs();
  const lastChecked = getValue("routeAutomationLastChecked");

  const checkForCompletedRouteAutomationJobs = (routeAutomationJobs: RouteAutomationJob[]) => {
    const newlyCompletedJobs = routeAutomationJobs.filter(
      (job) =>
        job.status === JobStatus.COMPLETED &&
        !!job.completed_at &&
        moment(job.completed_at).valueOf() > Number(lastChecked)
    );

    return newlyCompletedJobs.length > 0;
  };

  const checkForFailedJobs = (routeAutomationJobs: RouteAutomationJob[]) => {
    const newFailedJobs = routeAutomationJobs.filter(
      (job) => job.status === JobStatus.FAILED && moment(job.updated_at).valueOf() > Number(lastChecked)
    );

    return newFailedJobs.length > 0;
  };

  useEffect(() => {
    if (!routeAutomationJobs) return;

    const haveNewlyCompletedJobs = checkForCompletedRouteAutomationJobs(routeAutomationJobs);
    const haveFailedJobs = checkForFailedJobs(routeAutomationJobs);

    if (!haveFailedJobs && !haveNewlyCompletedJobs) return;

    if (haveNewlyCompletedJobs) {
      queryClient.invalidateQueries(["routes"]);
      notification.success({
        message: "Route Automation Completed!",
        duration: 0,
        style: {
          backgroundColor: COLORS.GREEN_LIGHT,
        },
        icon: <CheckCircleOutlined style={{ color: COLORS.PRIMARY_BLUE }} />,
      });
    }

    if (haveFailedJobs) {
      notification.error({
        message: "Route Automation Failed!",
        duration: 0,
        style: {
          backgroundColor: COLORS.RED_LIGHT,
        },
        icon: <CheckCircleOutlined style={{ color: COLORS.RED }} />,
      });
    }

    setValue("routeAutomationLastChecked", `${Date.now()}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeAutomationJobs]);
};
