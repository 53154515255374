import { Collapse, CollapsePanelProps as AntdCollapsePanelProps } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

interface CollapsePanelProps extends AntdCollapsePanelProps {
  highlight?: boolean;
}

export const CollapsePanel = styled(Collapse.Panel)<CollapsePanelProps>`
  .ant-collapse-content-box {
    padding: 0;
  }

  && .ant-collapse-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 4px;
    padding: 12px;
    color: ${(props) => (props.highlight ? COLORS.PRIMARY_BLUE : COLORS.GREY_DARK)};
    font-size: 16px;
    font-weight: 500;

    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }

  && .ant-collapse-header > .ant-collapse-arrow {
    position: initial;
    margin: 0;
    padding: 0;
  }
`;

export const RouteCollapsePanel = styled(CollapsePanel)`
  && .ant-collapse-header {
    padding: 6px 12px;
  }
`;
