import moment from "moment";

export class Dates {
  // Check if 2 dates overlap
  static dateRangeOverlaps(aStart: string, aEnd: string, bStart: string, bEnd: string) {
    const aStartUtc = moment(aStart).utc();
    const aEndUtc = moment(aEnd).utc();
    const bStartUtc = moment(bStart).utc();
    const bEndUtc = moment(bEnd).utc();
    if (aEndUtc < bStartUtc || bEndUtc < aStartUtc) return false;
    return true;
  }

  // Check if any date ranges in a list overlap
  static multipleDateRangeOverlaps(dateEntries: { dateTo: string; dateFrom: string }[]) {
    const dateIntervals = dateEntries.filter((entry) => !!entry.dateFrom && !!entry.dateTo);

    if (!!dateIntervals && dateIntervals.length > 1)
      for (let i = 0; i < dateIntervals.length - 1; i += 1) {
        for (let j = i + 1; j < dateIntervals.length; j += 1) {
          if (
            this.dateRangeOverlaps(
              dateIntervals[i].dateFrom,
              dateIntervals[i].dateTo,
              dateIntervals[j].dateFrom,
              dateIntervals[j].dateTo
            )
          )
            return true;
        }
      }
    return false;
  }
}
