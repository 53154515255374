import { useCallback, useMemo } from "react";

import { difference, uniq, without } from "lodash";

import { useRecoilState } from "recoil";
import { hiddenRoutesIDsState, isUnassignedAppointmentsHiddenState } from "recoil/routeMapControls/atoms";

import { useAppointmentsRoutesMap, useRoutesWithAppointments } from "hooks/api/routes";

import { AppointmentOrShipment } from "interface/appointment";
import { Route } from "interface/route";

type RouteID = Route["id"];
type AppointmentID = AppointmentOrShipment["id"];

export const useRoutesMapVisibility = () => {
  const [hiddenRoutesIDs, setHiddenRoutesIDs] = useRecoilState(hiddenRoutesIDsState);
  const appointmentsRoutesMap = useAppointmentsRoutesMap();

  const getIsRouteVisible = useCallback((routeID: RouteID) => !hiddenRoutesIDs.includes(routeID), [hiddenRoutesIDs]);

  const getIsRouteAppointmentVisible = useCallback(
    (appointmentID: AppointmentID) => {
      const routeID = appointmentsRoutesMap[appointmentID];

      const isAppointmentBelongsToRoute = routeID;
      if (!isAppointmentBelongsToRoute) return true;

      return getIsRouteVisible(routeID);
    },
    [appointmentsRoutesMap, getIsRouteVisible]
  );

  const toggleRouteVisibility = useCallback(
    (routeID: RouteID) => {
      if (hiddenRoutesIDs.includes(routeID)) {
        setHiddenRoutesIDs(without(hiddenRoutesIDs, routeID));
      } else {
        setHiddenRoutesIDs([...hiddenRoutesIDs, routeID]);
      }
    },
    [hiddenRoutesIDs, setHiddenRoutesIDs]
  );

  const showRoutes = useCallback(
    (routesIDs: string[]) => {
      setHiddenRoutesIDs(difference(hiddenRoutesIDs, routesIDs));
    },
    [hiddenRoutesIDs, setHiddenRoutesIDs]
  );

  const hideRoutes = useCallback(
    (routesIDs: string[]) => {
      setHiddenRoutesIDs(uniq([...hiddenRoutesIDs, ...routesIDs]));
    },
    [hiddenRoutesIDs, setHiddenRoutesIDs]
  );

  return {
    getIsRouteVisible,
    getIsRouteAppointmentVisible,
    showRoutes,
    hideRoutes,
    toggleRouteVisibility,
  };
};

export const useUnassignedAppointmentsMapVisibility = () => {
  const [isUnassignedAppointmentsHidden, setIsUnassignedAppointmentsHidden] = useRecoilState(
    isUnassignedAppointmentsHiddenState
  );

  const toggleVisibility = useCallback(() => {
    setIsUnassignedAppointmentsHidden(!isUnassignedAppointmentsHidden);
  }, [isUnassignedAppointmentsHidden, setIsUnassignedAppointmentsHidden]);

  return {
    isVisible: !isUnassignedAppointmentsHidden,
    toggleVisibility,
  };
};

export const useAssignedAppointmentsMapVisibility = () => {
  const { showRoutes, hideRoutes, getIsRouteVisible } = useRoutesMapVisibility();
  const routesWithAppointments = useRoutesWithAppointments();

  const isAllRoutesHidden = useMemo(
    () => routesWithAppointments.every((route) => !getIsRouteVisible(route.id)),
    [routesWithAppointments, getIsRouteVisible]
  );

  const isVisible = useMemo(() => !isAllRoutesHidden, [isAllRoutesHidden]);

  const toggleVisibility = useCallback(() => {
    const routesIDs = routesWithAppointments.map((route) => route.id);

    if (isVisible) {
      hideRoutes(routesIDs);
    } else {
      showRoutes(routesIDs);
    }
  }, [isVisible, routesWithAppointments, showRoutes, hideRoutes]);

  return {
    isVisible,
    toggleVisibility,
  };
};
