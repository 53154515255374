import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import { Form, Spin, notification } from "antd";

import * as S from "./styles";

import { useServiceAreas } from "hooks/api/service_areas";
import { useUpdateVehicle, useVehicleDetails } from "hooks/api/vehicles";
import { useActiveDate } from "hooks/application/useActiveDate";

import { UpdateVehicleData } from "api/vehicles";

import { CreateEditVehicleForm } from "../components/CreateEditVehicleForm";
import { CreateEditVehicleHeader } from "../components/CreateEditVehicleHeader";
import { VehicleImagePicker } from "../components/VehicleImagePicker";

export const EditVehiclePage: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { activeDate } = useActiveDate();

  const { getActiveServiceAreaID } = useServiceAreas();

  const { vehicleID } = useParams() as { vehicleID: string };
  const { vehicle, isLoading: isFetchingVehicleDetails } = useVehicleDetails(vehicleID, activeDate);
  const { update, isLoading: isUpdatingVehicle } = useUpdateVehicle();
  const [vehicleStatus, setVehicleStatus] = useState<string>();

  useEffect(() => {
    if (!vehicle) return;

    const { id, name, license_plate_number, vin, vehicle_size, make, model, ownership_type, fuel_type, notes, status } =
      vehicle;
    form.setFieldsValue({
      id,
      name,
      license_plate_number,
      vin,
      vehicle_size_id: vehicle_size.id,
      make,
      model,
      ownership_type_id: ownership_type.id,
      fuel_type_id: fuel_type.id,
      notes,
    });
    setVehicleStatus(status);
  }, [vehicle, form]);

  const handleConfirmEdit = () => {
    if (!vehicle) return;

    form
      .validateFields()
      .then(() => {
        const vehicleData = {
          ...form.getFieldsValue(true),
          current_photo: vehicle.photo,
          service_area_id: getActiveServiceAreaID(),
          status: vehicleStatus,
        } as UpdateVehicleData;

        update(vehicleData, {
          onSuccess: (vehicle) => {
            history.push("/vehicles");
            notification.success({
              message: `Successfully edited ${vehicle.name}`,
            });
          },
        });
      })
      .catch(() => null);
  };

  const handleCancel = () => {
    history.goBack();
  };

  return (
    <S.PageContainer>
      <Spin spinning={isFetchingVehicleDetails || isUpdatingVehicle}>
        <CreateEditVehicleHeader
          mode="edit"
          status={vehicleStatus}
          inUse={vehicle?.in_use}
          onConfirm={handleConfirmEdit}
          onCancel={handleCancel}
          onChangeVehicleStatus={setVehicleStatus}
        />

        <S.BodyContainer>
          <CreateEditVehicleForm form={form} />
          <S.ImageUploadContainer>
            <VehicleImagePicker url={vehicle?.photo?.url} onChange={(image) => form.setFieldsValue({ photo: image })} />
          </S.ImageUploadContainer>
        </S.BodyContainer>
      </Spin>
    </S.PageContainer>
  );
};
