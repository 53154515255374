import React from "react";

import * as S from "./styles";

import { Markers } from "lib/markers";

interface IkeaMarkerProps {
  lat: number;
  lng: number;
}

export const IkeaMarker: React.FC<IkeaMarkerProps> = () => {
  const icon = Markers.getIkeaMarkerIcon();

  return <S.Container markerIcon={icon} />;
};
