import { Divider, Table } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0;
`;

export const DetailsContainer = styled.div`
  padding: 24px;

  .ant-form-item-control-input {
    align-items: flex-start;
  }
`;

export const DetailsFormLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${COLORS.GREY_DARK};
  font-weight: 500;
`;

interface DetailsFormValueProps {
  empty?: boolean;
}
export const DetailsFormValue = styled.div<DetailsFormValueProps>`
  padding-top: 5px;
  max-height: 100px;
  color: ${(props) => (props.empty ? COLORS.GREY_MID : COLORS.CHARCOAL)};
  font-weight: 500;
  overflow-y: auto;
  white-space: pre-wrap;

  > div:not(:first-child) {
    margin-top: 7px;
  }
`;

export const DetailsFormIcon = styled.div`
  width: 22px;
  height: 22px;
  color: ${COLORS.GREY_MID};
`;

export const SierraOrderDetailsLink = styled.a`
  display: flex;
  align-items: center;
`;

export const SierraOrderDetailsIcon = styled(DetailsFormIcon)`
  margin-right: 6px;
  color: ${COLORS.PRIMARY_BLUE};
`;

export const ModalBodyStyle = { padding: "0" };

export const ProductsTable = styled(Table)`
  background: #f0f2ff;
  max-height: 600px;
  overflow-y: auto;

  .ant-table {
    background: #f0f2ff;
  }

  .ant-table-title {
    padding-bottom: 0;
  }

  .ant-table-tbody > tr.ant-table-row > td {
    background: unset;
  }
`;

export const ProductsTableHeaderDivider = styled(Divider)`
  margin-top: 10px;
  margin-bottom: 0;
`;

export const ProductsTableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ProductsTableHeaderTitle = styled.span`
  color: ${COLORS.CHARCOAL};
  font-weight: 500;
`;

export const ProductsTableHeaderSummary = styled.span`
  font-size: 12px;
  color: #7d7d7d;
`;
