import { BarLoader as BarLoaderBase } from "react-spinners";

import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  position: absolute;
  top: 140px;
  left: 10px;
`;

export const BarLoader = styled(BarLoaderBase).attrs({
  width: 100,
  height: 3,
  color: COLORS.PRIMARY_BLUE,
})``;
