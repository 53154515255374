import { useUrl } from "./useUrl";

export type FlowKeyType = "BOLT_LOGISTICS" | "IKEA";

interface WorkFlowOption {
  key: FlowKeyType;
  name: string;
}

export const WORK_FLOW_OPTIONS: {
  BOLT_LOGISTICS: WorkFlowOption;
  IKEA: WorkFlowOption;
} = {
  BOLT_LOGISTICS: {
    key: "BOLT_LOGISTICS",
    name: "Bolt Logistics",
  },
  IKEA: { key: "IKEA", name: "IKEA Inc" },
};

const isFlowValid = (flowKey: FlowKeyType) => {
  return !!Object.values(WORK_FLOW_OPTIONS).find((option) => option.key === flowKey);
};

export const useActiveFlow = () => {
  const defaultFlowKey = WORK_FLOW_OPTIONS.BOLT_LOGISTICS.key;

  const { getQueryValues, setUrlWithQueryValues } = useUrl();

  const queryValues = getQueryValues() as { flowKey: FlowKeyType };
  const flowKeyOnUrl = queryValues.flowKey;
  const activeFlowKey = isFlowValid(flowKeyOnUrl) ? flowKeyOnUrl : defaultFlowKey;

  const isBoltLogistics = activeFlowKey === WORK_FLOW_OPTIONS.BOLT_LOGISTICS.key;
  const isIkea = activeFlowKey === WORK_FLOW_OPTIONS.IKEA.key;

  const setActiveFlowKey = (newFlowKey: FlowKeyType) => {
    const flowKey = isFlowValid(newFlowKey) ? newFlowKey : defaultFlowKey;
    setUrlWithQueryValues({ flowKey });
  };

  return {
    activeFlowKey,
    setActiveFlowKey,
    WORK_FLOW_OPTIONS,
    isBoltLogistics,
    isIkea,
  };
};
