import { useMemo } from "react";

import useSupercluster from "use-supercluster";

import { useRecoilValue } from "recoil";
import { clusterModeControl } from "recoil/_common/clusterModeControl";

import { VisibleMarker } from "./useVisibleMarkers";

import { ClusterLeaf } from "interface/map";

interface Params {
  bounds: number[];
  zoom: number;
  visibleMarkers: VisibleMarker[];
}

export interface Clusters {
  clusters: {
    id: number;
    geometry: {
      coordinates: [number, number];
      type: "Point";
    };
    properties: {
      cluster: boolean;
      cluster_id: number;
      point_count: number;
      point_count_abbreviated: number;
      markerKey?: string;
      appointmentIDs?: string[];
    };
    type: "Feature";
  }[];
  supercluster: {
    getClusterExpansionZoom: (clusterID: number) => number;
    getLeaves: (clusterID: number, v?: string) => ClusterLeaf[];
  };
}

export interface ClusterMap {
  [appointmentID: string]: {
    clusterID: number;
    coordinates: { lat: number; lng: number };
  };
}

const buildClusterMap = (clusters: Clusters): ClusterMap => {
  const superCluster = clusters.supercluster;
  if (!clusters.clusters || clusters.clusters.length === 0) return {};
  return clusters.clusters.reduce((acc, cluster) => {
    const clusterID = cluster.id;
    if (!clusterID) return acc;
    const [lng, lat] = cluster.geometry.coordinates;
    const leaves = superCluster.getLeaves(clusterID, "Infinity");
    const appointmentIDs = leaves.map((l) => l?.properties?.appointmentIDs?.[0]);
    appointmentIDs.map((apptID: string) => (acc[apptID] = { clusterID, coordinates: { lat, lng } }));
    return acc;
  }, {} as ClusterMap);
};

export const useClusters = ({ bounds, zoom, visibleMarkers }: Params) => {
  const isCluster = useRecoilValue(clusterModeControl);

  const points = useMemo(() => {
    if (!isCluster) return [];
    return visibleMarkers.map((marker) => {
      const { markerKey, appointmentIDs, lng, lat } = marker;
      return {
        type: "Feature",
        properties: {
          cluster: false,
          appointmentIDs,
          markerKey,
        },
        geometry: {
          type: "Point",
          coordinates: [lng, lat],
        },
      };
    });
  }, [visibleMarkers, isCluster]);

  const clusters = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 100, maxZoom: 20 },
  });

  const clusterMap = buildClusterMap(clusters);

  return { clusters, clusterMap };
};
