import React, { useRef } from "react";

import { Switch } from "antd";

import { useRecoilState } from "recoil";
import { darkModeControlState } from "recoil/_common/darkModeControl";

import * as Cookie from "lib/cookie";
import { SwitchIcon, SwitchLabel, SwitchWrapper } from "pages/RoutesPage/styles";

import moon from "./images/moon_white.png";
import sun from "./images/sun_white.png";

export const DarkModeToggle: React.FC = () => {
  const [isDarkModeActive, dispatchDarkModeActive] = useRecoilState(darkModeControlState);
  const darkModeToggleRef = useRef<HTMLButtonElement | null>(null);

  return (
    <SwitchWrapper isChecked={isDarkModeActive} onClick={() => darkModeToggleRef?.current?.click()}>
      <SwitchLabel>Dark Mode</SwitchLabel>
      <Switch
        ref={darkModeToggleRef}
        checked={isDarkModeActive}
        onChange={(isActive: boolean) => {
          dispatchDarkModeActive(isActive);
          const appSettings = Cookie.getAppSettings();
          const newAppSettings = appSettings ? { ...appSettings, darkMode: isActive } : { darkMode: isActive };
          Cookie.setAppSettings(newAppSettings);
        }}
        checkedChildren={<SwitchIcon src={moon} alt="moon" />}
        unCheckedChildren={<SwitchIcon src={sun} alt="sun" />}
      />
    </SwitchWrapper>
  );
};
