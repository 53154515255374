import React, { useEffect, useMemo } from "react";

import { Fulfillment, Storage } from "@secondcloset/types";

import { TreeSelect } from "antd";

import * as S from "../styles";

import { useRecoilState, useRecoilValue } from "recoil";
import { createRouteState } from "recoil/routes/atoms";
import { activeAppointmentTypesState } from "recoil/topFilterControls/atoms";

import { useActiveFlow } from "hooks/application/useActiveFlow";

import { getSelectPlaceHolder } from "../helpers";

import { IkeaAppointmentDropdown } from "./IkeaAppointmentDropdown";

import { AppointmentOrShipment } from "interface/appointment";

type AppointmentType = Storage.AppointmentType | Fulfillment.AppointmentType;
export const AppointmentFilter: React.FC<{
  appointments: AppointmentOrShipment[];
}> = ({ appointments }) => {
  const getOptionCount = (key: string) => appointments.filter((appt) => appt?.job_type === key).length;

  const APPOINTMENT_OPTIONS = [
    { id: "fulfillment", pId: null, value: "fulfillment", title: "Fulfillment" },
    { id: "delivery", pId: "fulfillment", value: "delivery", title: `Delivery (${getOptionCount("delivery")})` },
    {
      id: "reverse_logistics",
      pId: "fulfillment",
      value: "reverse_logistics",
      title: `Reverse Logistics (${getOptionCount("reverse_logistics")})`,
    },
    { id: "storage", pId: null, value: "storage", title: "Storage" },
    { id: "pick_up", pId: "storage", value: "pick_up", title: `Pick Up (${getOptionCount("pick_up")})` },
    { id: "drop_off", pId: "storage", value: "drop_off", title: `Drop Off (${getOptionCount("drop_off")})` },
    {
      id: "immediate_pick_up",
      pId: "storage",
      value: "immediate_pick_up",
      title: `Immediate Pick Up (${getOptionCount("immediate_pick_up")})`,
    },
    { id: "return", pId: "storage", value: "return", title: `Return (${getOptionCount("return")})` },
    {
      id: "immediate_retrieve",
      pId: "storage",
      value: "immediate_retrieve",
      title: `Immediate Retrieve (${getOptionCount("immediate_retrieve")})`,
    },
    { id: "retrieve", pId: "storage", value: "retrieve", title: `Retrieve (${getOptionCount("retrieve")})` },
    { id: "custom", pId: "storage", value: "custom", title: `Custom (${getOptionCount("custom")})` },
  ];

  const { isIkea } = useActiveFlow();
  const [activeAppointmentTypes, dispatchActiveAppointmentTypes] = useRecoilState(activeAppointmentTypesState);
  const initialSelectedOptions = APPOINTMENT_OPTIONS.map((opt) => opt.id) as AppointmentType[];
  const { enabled: isCreateRouteModeEnabled } = useRecoilValue(createRouteState);

  // eslint-disable-next-line
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isIkea) dispatchActiveAppointmentTypes(initialSelectedOptions);
  }, [isIkea]);

  const renderAppointmentFilter = () => {
    const numberOfCDD = getOptionCount("cross_dock_delivery");
    const numberOfDelivery = getOptionCount("delivery");
    const numberOfReverseLogistics = getOptionCount("reverse_logistics");

    const renderSelectedPlaceholder = () => {
      const numberOfOptions = APPOINTMENT_OPTIONS.filter((o) => o.pId !== null).length;
      const numberOfNodes = APPOINTMENT_OPTIONS.length;
      const numberOfAllOptions = [numberOfOptions, numberOfNodes];

      return (
        <S.SelectedText>
          {getSelectPlaceHolder(numberOfAllOptions, activeAppointmentTypes, "Appointments", null, APPOINTMENT_OPTIONS)}
        </S.SelectedText>
      );
    };
    return (
      <S.FilterContainer>
        {isIkea ? (
          <IkeaAppointmentDropdown
            numberOfCDD={numberOfCDD}
            numberOfDelivery={numberOfDelivery}
            numberOfReverseLogistics={numberOfReverseLogistics}
          />
        ) : (
          <TreeSelect
            treeCheckable
            treeData={APPOINTMENT_OPTIONS}
            treeDefaultExpandAll
            treeDataSimpleMode
            placeholder={"None Selected"}
            value={activeAppointmentTypes}
            onChange={dispatchActiveAppointmentTypes}
            style={{ width: 160 }}
            maxTagCount={0}
            maxTagPlaceholder={renderSelectedPlaceholder}
            dropdownStyle={{ minWidth: 244 }}
            showArrow
            showSearch={false}
          />
        )}
      </S.FilterContainer>
    );
  };

  return useMemo(renderAppointmentFilter, [activeAppointmentTypes, isCreateRouteModeEnabled, appointments]);
};
