import { useLocation, useHistory } from "react-router";

import { parse, stringify } from "query-string";

export const useUrl = () => {
  const location = useLocation();
  const history = useHistory();
  const url = location.pathname;

  const getQueryValues = () => {
    return parse(location.search);
  };

  const setUrlWithQueryValues = (newValues: Record<string, unknown>) => {
    const queryValues = { ...getQueryValues(), ...newValues };
    const query = stringify(queryValues);
    history.push(`${location.pathname}?${query}`);
  };

  return { url, setUrlWithQueryValues, getQueryValues };
};
