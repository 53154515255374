import { useCallback, useState } from "react";

export const useCollapseExpandedPanels = (initialExpandedPanels: string[] = []) => {
  const [expandedPanels, setExpandedPanels] = useState(initialExpandedPanels);

  const expandPanel = useCallback(
    (panelKey: string) => {
      setExpandedPanels((panels) => (panels.includes(panelKey) ? panels : [...panels, panelKey]));
    },
    [setExpandedPanels]
  );

  const collapsePanel = useCallback(
    (panelKey: string) => {
      setExpandedPanels((panels) => panels.filter((panel) => panel !== panelKey));
    },
    [setExpandedPanels]
  );

  const handleChangeExpandedPanels = (panels: string[] | string) => {
    setExpandedPanels(typeof panels === "string" ? [panels] : panels);
  };

  return {
    expandedPanels,
    setExpandedPanels,
    handleChangeExpandedPanels,
    expandPanel,
    collapsePanel,
  };
};
