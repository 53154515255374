import { useQuery } from "react-query";

import { Logistics } from "@secondcloset/types";

import { useRecoilValue } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";

import { fetchAvailabilities } from "api/availabilities";

import { getQuery } from "./queries";

type Availability = Logistics.Availability;

export const useAvailabilities = (dateFrom: string, dateTo: string) => {
  const activeServiceArea = useRecoilValue(activeServiceAreaState);

  const query = getQuery(dateFrom, dateTo, activeServiceArea || "yyz");

  const {
    isLoading,
    isRefetching,
    error,
    data: availabilities,
  } = useQuery<Availability[], string>(query, () =>
    fetchAvailabilities({
      dateFrom: dateFrom,
      dateTo: dateTo,
      serviceArea: activeServiceArea,
    })
  );
  return {
    isLoading,
    isRefetching,
    error,
    availabilities,
  };
};
