import { Fulfillment } from "@secondcloset/types";

import { atom } from "recoil";
type Appointment = Fulfillment.Appointment;

export interface BookingAppointmentsState {
  [appointmentID: string]: Appointment;
}

export const bookingAppointmentsState = atom<BookingAppointmentsState>({
  key: "bookingAppointmentsState",
  default: {},
});

export interface BookingAppointmentsMarkersState {
  [stringifiedMarkerKey: string]: string[];
}

export const bookingAppointmentsMarkersState = atom<BookingAppointmentsMarkersState>({
  key: "bookingAppointmentsMarkersState",
  default: {},
});

export const bookingPickupDeliveryPairState = atom<{
  [appointmentID: string]: string;
}>({
  key: "bookingPickupDeliveryPairState",
  default: {},
});

export const bookingSelectedAppointmentIdsState = atom<string[]>({
  key: "bookingSelectedAppointmentIdsState",
  default: [],
});

export const bookingSelectedWarehouseIdState = atom<string>({
  key: "bookingSelectedWarehouseIdState",
  default: "",
});

export const bookingSelectedDateState = atom<string | null>({
  key: "bookingSelectedDateState",
  default: null,
});
