import React, { useState, useEffect } from "react";

import { Button } from "antd";

import { useVehicles } from "hooks/api/vehicles";

import { VehicleModal } from "./VehicleModal";

import { Vehicle } from "interface/vehicle";

interface SelectVehicleModalProps {
  visible: boolean;
  selectedVehicleID?: string;
  onClose: () => unknown;
  onSave: (vehicleID: string) => unknown;
}

export const SelectVehicleModal: React.FC<SelectVehicleModalProps> = ({
  visible,
  selectedVehicleID,
  onClose,
  onSave,
}) => {
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);

  const { isFetched: isVehiclesFetched, getVehiclesAvailableToRoute } = useVehicles();
  const vehiclesAvailableToRoute = getVehiclesAvailableToRoute();

  useEffect(() => {
    if (visible && isVehiclesFetched) {
      setSelectedVehicle(vehiclesAvailableToRoute.find((vehicle) => vehicle.id === selectedVehicleID) || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, isVehiclesFetched]);

  const renderCancelButton = () => (
    <Button key="cancel" onClick={onClose}>
      Cancel
    </Button>
  );

  const renderSaveButton = () => (
    <Button
      key="save"
      type="primary"
      disabled={!selectedVehicle}
      onClick={() => selectedVehicle && onSave(selectedVehicle.id)}
    >
      Save
    </Button>
  );

  const onSelect = (vehicle: Vehicle) => {
    setSelectedVehicle(vehicle);
  };

  return (
    <VehicleModal
      selectedVehicleIDs={selectedVehicle?.id ? [selectedVehicle.id] : []}
      visible={visible}
      onClose={onClose}
      onSelect={onSelect}
      footer={[renderCancelButton(), renderSaveButton()]}
    />
  );
};
