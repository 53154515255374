import { css, keyframes } from "styled-components";

const pulseAnimationKeyframes = keyframes`
  50% {
    opacity: 0.5;
  }
`;
export const pulseAnimation = css`
  animation: ${pulseAnimationKeyframes} 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
`;
