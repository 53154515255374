import { Tag as TagBase } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

interface ContainerProps {
  visible?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  z-index: 0 !important;
  bottom: ${(props) => (props.visible ? "64px" : "-800px")};
  left: 50%;
  height: 180px;
  display: flex;
  background: white;
  transform: translateX(-50%);
  transition: bottom ease-in 0.3s;
  box-shadow: 0 0px 6px 4px #78787838;
`;

export const MissingPackagesWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: red;
  color: white;
  font-weight: 500;
`;

export const InfoBoxContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 0 20px;
  width: 600px;
  height: 100%;
`;

export const Address = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  font-weight: 700;
  background: ${COLORS.GREY_LIGHT};
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background: ${COLORS.GREY_MID};
  }
`;

export const ContentWrap = styled.div`
  height: 100%;
  display: flex;
  overflow: hidden;
`;

export const ContentInfo = styled.div`
  width: 210px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  border-right: 1px solid ${COLORS.GREY_LIGHT};

  .ant-tag {
    margin-bottom: 5px;
  }
`;

export const SmallText = styled.div`
  display: inline-block;
  font-size: 13px;
  font-weight: 300;
  margin-left: 10px;
`;

export const OrgTag = styled.div`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  .anticon {
    margin-right: 5px;
  }
`;

export const Tag = styled(TagBase)`
  max-width: 192px;
  white-space: normal;
`;
