import React from "react";

import * as S from "./styles";

export interface PageContainerProps {
  withHeader?: boolean;
  withFooter?: boolean;
  withPadding?: boolean;
  title?: string;
  children?: React.ReactNode;
  rightButton?: JSX.Element;
}

export const PageContainer: React.FC<PageContainerProps> = (props) => {
  return (
    <S.Container {...props}>
      {props.title && <S.PageTitle>{props.title}</S.PageTitle>}
      {props.children}
    </S.Container>
  );
};
