import { Cookies as ReactCookie } from "react-cookie";

import { Common } from "@secondcloset/types";

import { credentialCookieName, appSettingsCookieName } from "config/cookieName";

type ServiceArea = Common.ServiceArea;
type FacilityCode = Common.FacilityCode;
type Credential = Common.Credential;

interface AppSettings {
  darkMode?: boolean;
  clusterMode?: boolean;
  facility?: FacilityCode;
  serviceArea?: ServiceArea;
}

export const getCredential = (): Credential | undefined => {
  const cookie = new ReactCookie();
  return cookie.get(credentialCookieName);
};

const setCookie = (name: string, data: Credential | AppSettings) => {
  const cookie = new ReactCookie();
  const days = 1;
  const expires = new Date();
  expires.setDate(Date.now() + 1000 * 60 * 60 * 24 * days);
  cookie.set(name, data, { expires });
};

export const setCredential = (credential: Credential, rememberMe = false) => {
  setCookie(credentialCookieName, { ...credential, rememberMe });
};

export const removeCredential = () => {
  const cookie = new ReactCookie();
  cookie.remove(credentialCookieName);
};

export const getUserToken = (): string => {
  try {
    const cookie = new ReactCookie();
    const credential = cookie.get(credentialCookieName);
    return credential.token.token;
  } catch (_) {
    return "";
  }
};

export const getUserAppSettingsCookieName = (): string | undefined => {
  const userToken = getUserToken();
  if (!userToken) return;
  return `${userToken}-${appSettingsCookieName}`;
};

export const setAppSettings = (appSettings: AppSettings) => {
  const cookieName = getUserAppSettingsCookieName();
  if (!cookieName) return;
  setCookie(cookieName, appSettings);
};

export const getAppSettings = (): AppSettings | undefined => {
  const cookieName = getUserAppSettingsCookieName();
  if (!cookieName) return;
  const cookie = new ReactCookie();
  return cookie.get(cookieName) || {};
};
