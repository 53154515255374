import uniq from "lodash/uniq";

import { Appointment } from "lib/appointment";
import { report } from "lib/sentry";

import { AppointmentOrShipment } from "interface/appointment";
import { AppointmentsState } from "interface/appointmentsState";

const regularDeliveryLogic = (appointmentID: string, newRouteAppointmentIDs: string[]) => {
  const routeAppointmentIDs = [...newRouteAppointmentIDs];
  const isExisted = routeAppointmentIDs.includes(appointmentID);

  if (isExisted) {
    return routeAppointmentIDs.filter((id) => id !== appointmentID);
  }

  return [...routeAppointmentIDs, appointmentID];
};

export const addMissingInventoryPickupOrReturnToSender = (
  appointmentIDs: string[],
  appointmentsLibrary: AppointmentsState["appointmentsLibrary"],
  pairLibrary:
    | AppointmentsState["inventoryPickupCrossDockDeliveryPairs"]
    | AppointmentsState["reverseLogisticsReturnToSenderPairs"],
  pairChecker: (a: AppointmentOrShipment) => boolean
) => {
  const remainingAppointments = appointmentIDs.map((id) => appointmentsLibrary[id]);
  const pairIDs = remainingAppointments.reduce((acc, a) => {
    if (pairChecker(a)) return [...acc, a.id];

    const pairID = pairLibrary[a.id];
    return pairID ? [...acc, pairID] : acc;
  }, [] as string[]);

  return [...appointmentIDs, ...pairIDs];
};

const ikeaOutboundLogic = (
  appointmentID: string,
  newRouteAppointmentIDs: string[],
  appointmentsState: AppointmentsState
) => {
  let routeAppointmentIDs = [...newRouteAppointmentIDs];
  const { inventoryPickupCrossDockDeliveryPairs, appointmentsLibrary } = appointmentsState;
  const isExisted = routeAppointmentIDs.includes(appointmentID);
  const inventoryPickupAppointmentID = inventoryPickupCrossDockDeliveryPairs[appointmentID];
  const crossDockDeliveryAppointmentID = appointmentID;

  if (!isExisted) {
    if (inventoryPickupAppointmentID) routeAppointmentIDs.push(inventoryPickupAppointmentID);
    routeAppointmentIDs.push(crossDockDeliveryAppointmentID);

    return routeAppointmentIDs;
  }

  routeAppointmentIDs = routeAppointmentIDs.filter(
    (id) => id !== crossDockDeliveryAppointmentID && id !== inventoryPickupAppointmentID
  );
  if (routeAppointmentIDs.length === 0) return routeAppointmentIDs;

  return addMissingInventoryPickupOrReturnToSender(
    routeAppointmentIDs,
    appointmentsLibrary,
    inventoryPickupCrossDockDeliveryPairs,
    Appointment.isInventoryPickup
  );
};

const ikeaInboundLogic = (
  appointmentID: string,
  newRouteAppointmentIDs: string[],
  appointmentsState: AppointmentsState
) => {
  let routeAppointmentIDs = [...newRouteAppointmentIDs];
  const { reverseLogisticsReturnToSenderPairs, appointmentsLibrary } = appointmentsState;
  const returnToSenderAppointmentID = reverseLogisticsReturnToSenderPairs[appointmentID];
  const reverseLogisticsAppointmentID = appointmentID;
  const isExisted = routeAppointmentIDs.includes(appointmentID);

  if (!isExisted) {
    if (returnToSenderAppointmentID) routeAppointmentIDs.push(returnToSenderAppointmentID);
    routeAppointmentIDs.push(reverseLogisticsAppointmentID);

    return routeAppointmentIDs;
  }

  routeAppointmentIDs = routeAppointmentIDs.filter(
    (id) => id !== reverseLogisticsAppointmentID && id !== returnToSenderAppointmentID
  );
  if (routeAppointmentIDs.length === 0) return routeAppointmentIDs;

  return addMissingInventoryPickupOrReturnToSender(
    routeAppointmentIDs,
    appointmentsLibrary,
    reverseLogisticsReturnToSenderPairs,
    Appointment.isInventoryPickup
  );
};

export const addRemoveAppointmentRouteID = (
  appointmentID: string,
  currentRouteAppointmentIDs: string[],
  appointmentsState: AppointmentsState,
  isCreateRouteView?: boolean
) => {
  const { appointmentsLibrary } = appointmentsState;

  const appointment = appointmentsLibrary[appointmentID];
  if (!appointment) {
    report(`Appointment ${appointmentID} not found`, { extra: { appointmentsState: appointmentsState } });
    return currentRouteAppointmentIDs;
  }

  const newRouteAppointmentIDs = [...currentRouteAppointmentIDs];
  const isInventoryPickup = Appointment.isInventoryPickup(appointment);
  const isCrossDockDelivery = Appointment.isCrossDockDelivery(appointment);
  const isReverseLogistics = Appointment.isReverseLogistics(appointment);
  const isReturnToSender = Appointment.isReturnToSender(appointment);

  if (isInventoryPickup || isReturnToSender || isCreateRouteView) return newRouteAppointmentIDs;

  if (isCrossDockDelivery) {
    return uniq(ikeaOutboundLogic(appointmentID, newRouteAppointmentIDs, appointmentsState));
  }

  if (isReverseLogistics) {
    return uniq(ikeaInboundLogic(appointmentID, newRouteAppointmentIDs, appointmentsState));
  }

  return regularDeliveryLogic(appointmentID, newRouteAppointmentIDs);
};
