import { Route } from "interface/route";

interface Params {
  routesLibrary: { [routeID: string]: Route };
  routeID: string | null;
}
export const getRouteAppointmentIDs = (params: Params) => {
  const { routesLibrary, routeID } = params;
  if (!routeID) return [];
  const foundRoute = routesLibrary[routeID];
  if (!foundRoute) return [];
  const foundItems = [...foundRoute.route_items];
  const sortedAppointments = foundItems.sort((a1, a2) => a1.sequence - a2.sequence);
  return sortedAppointments.map((a) => a.appointment_id);
};
