import { chain } from "lodash";

import { useRecoilState } from "recoil";
import { createRouteState as createRouteStateAtom } from "recoil/routes/atoms";

type AppointmentsSelectionMap = { [appointmentID: string]: boolean };

export const useUpdateSelectedAppointments = () => {
  const [createRouteState, setCreateRouteState] = useRecoilState(createRouteStateAtom);

  const updateSelectedAppointments = (appointmentsSelectionMap: AppointmentsSelectionMap) => {
    const currentSelectedAppointmentsSelectionMap = chain(createRouteState.selectedAppointmentsIDs)
      .keyBy((appointmentID) => appointmentID)
      .mapValues(() => true)
      .value();

    const newSelectedAppointmentsSelectionMap = Object.assign(
      currentSelectedAppointmentsSelectionMap,
      appointmentsSelectionMap
    );

    const selectedAppointmentsIDs = Object.entries(newSelectedAppointmentsSelectionMap)
      .filter(([_, selected]) => selected)
      .map(([appointmentID]) => appointmentID);

    setCreateRouteState((state) => ({ ...state, selectedAppointmentsIDs }));
  };

  return updateSelectedAppointments;
};
