import React, { FC, useMemo } from "react";

import { Fulfillment } from "@secondcloset/types";

import { Table } from "antd";
import moment from "moment";

import * as S from "./styles";
import { COLORS } from "styles";

import { useRecoilState, useRecoilValue } from "recoil";
import { bookingPickupDeliveryPairState, bookingSelectedAppointmentIdsState } from "recoil/booking/atoms";

import { useWarehouses } from "hooks/api/warehouses";

import { Text } from "components/styles";
import { Appointment } from "lib/appointment";

export const AppointmentsTable: FC = () => {
  const { warehouseFilteredAppointments } = useWarehouses();
  const [bookingSelectedAppointmentIds, setBookingSelectedAppointmentIds] = useRecoilState(
    bookingSelectedAppointmentIdsState
  );
  const pickupDeliveryPair = useRecoilValue(bookingPickupDeliveryPairState);

  const getColor = (daysLeft: number) => {
    if (daysLeft < 1) return COLORS.RED;
    if (daysLeft < 2) return COLORS.ORANGE;
    return COLORS.GREEN;
  };

  const renderExternalOrderNumberColumn = (_: unknown, appointment: Fulfillment.Appointment) => (
    <S.ExternalOrderNumber
      href={`${process.env.REACT_APP_SIERRA_DOMAIN}/fulfillment/orders/${appointment.source.id}`}
      target="_blank"
      rel="noreferrer"
    >
      {appointment.source.external_order_number}
    </S.ExternalOrderNumber>
  );

  const renderRangeOfDaysColumn = (_: unknown, appointment: Fulfillment.Appointment) => (
    <div>
      {`${moment(appointment.range_of_days_start_date).format("MMM DD")} - ${moment(
        appointment.range_of_days_end_date
      ).format("MMM DD")}`}
    </div>
  );

  const renderTimeLeftToBookColumn = (_: unknown, appointment: Fulfillment.Appointment) => {
    const { daysLeft, timeLeft } = Appointment.getTimeLeftToBook(appointment);
    return <Text color={getColor(daysLeft)}>{timeLeft}</Text>;
  };

  const columns = [
    {
      title: "External Order #",
      dataIndex: ["source", "external_order_number"],
      render: renderExternalOrderNumberColumn,
    },
    {
      title: "Range of Days",
      render: renderRangeOfDaysColumn,
    },
    {
      title: "Time Left to Book",
      render: renderTimeLeftToBookColumn,
    },
  ];

  const rowSelection = {
    selectedRowKeys: bookingSelectedAppointmentIds,
    onChange: (selectedRowKeys: React.Key[]) => {
      const selectedAppointments = selectedRowKeys.reduce((acc, deliveryApptId) => {
        acc.push(deliveryApptId as string);
        const inventoryPickupId = pickupDeliveryPair[deliveryApptId];
        if (inventoryPickupId) acc.push(inventoryPickupId);
        return acc;
      }, [] as string[]);

      setBookingSelectedAppointmentIds(selectedAppointments as string[]);
    },
  };

  const dataSource = useMemo(() => {
    const appointments = warehouseFilteredAppointments ? [...warehouseFilteredAppointments] : [];
    return appointments.sort((a: Fulfillment.Appointment, b: Fulfillment.Appointment) => {
      const aDeadlineToBook = Appointment.getNowToDeadlineToBookDifference(a);
      const bDeadlineToBook = Appointment.getNowToDeadlineToBookDifference(b);

      if (aDeadlineToBook < bDeadlineToBook) {
        return -1;
      }
      if (aDeadlineToBook > bDeadlineToBook) {
        return 1;
      }
      return 0;
    });
  }, [warehouseFilteredAppointments]);

  return (
    <S.Wrapper>
      <Table
        rowKey="id"
        dataSource={dataSource}
        columns={columns}
        pagination={{
          defaultPageSize: 10,
        }}
        rowSelection={rowSelection}
      />
    </S.Wrapper>
  );
};
