import { Common } from "@secondcloset/types";
type ServiceArea = Common.ServiceArea;

type CenterPoints = {
  [key in ServiceArea]: { lat: number; lng: number; zoom: number };
};

export const centerPoints: CenterPoints = {
  yyz: {
    lat: 43.67553,
    lng: -79.394823,
    zoom: 11,
  },
  yvr: {
    lat: 49.254998,
    lng: -123.094043,
    zoom: 11,
  },
  yow: {
    lat: 45.41064,
    lng: -75.70373,
    zoom: 10,
  },
  yul: {
    lat: 45.54918,
    lng: -73.61919,
    zoom: 10,
  },
};
