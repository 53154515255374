import * as S from "./styles";
import { LAYOUT } from "styles";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { activeRouteOverviewState, appointmentDetailsModalState } from "recoil/sideBarControls/atoms";

import { useRoutesWithAppointments } from "hooks/api/routes";
import { useActiveMarker } from "hooks/application/useActiveMarker";
import { useActiveRouteOverview } from "hooks/application/useActiveRouteOverview";
import { useEditRoute } from "hooks/application/useEditRoute";

import { useClickAppointmentCard, useHoverAppointmentCard } from "../AppointmentCard";
import { OverviewRouteHeader } from "../RouteHeader";

import { Route } from "interface/route";

export const ActiveRouteOverview = () => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { visible, routeID } = useRecoilValue(activeRouteOverviewState);

  const { closeRouteOverview } = useActiveRouteOverview();

  const routesWithAppointments = useRoutesWithAppointments();
  const route = routesWithAppointments.find((route) => route.id === routeID);

  const { getIsAppointmentActive } = useActiveMarker();
  const { getIsAppointmentHovered, hoverAppointmentCard, unhoverAppointmentCard } = useHoverAppointmentCard();
  const setAppointmentDetailsModal = useSetRecoilState(appointmentDetailsModalState);
  const { handleEditRouteClick } = useEditRoute();
  const handleAppointmentCardClick = useClickAppointmentCard();

  const onEditRoute = (route: Route) => {
    handleEditRouteClick(route);
    closeRouteOverview();
  };

  return (
    <S.Drawer
      title={!!route && <OverviewRouteHeader route={route} appointments={route.appointments} onEdit={onEditRoute} />}
      visible={visible}
      onClose={() => closeRouteOverview()}
      getContainer={false}
      width={LAYOUT.SIDEBAR_WIDTH}
      closeIcon={<S.CloseButtonIcon />}
    >
      {!!route && (
        <S.AppointmentCardsContainer>
          {route.appointments.map((appointment) => (
            <S.AppointmentCard
              key={appointment.id}
              appointment={appointment}
              selected={getIsAppointmentActive(appointment.id)}
              hovered={getIsAppointmentHovered(appointment.id)}
              onClick={handleAppointmentCardClick}
              onShowDetails={(appointmentID) => setAppointmentDetailsModal({ visible: true, appointmentID })}
              onMouseEnterCard={hoverAppointmentCard}
              onMouseLeaveCard={unhoverAppointmentCard}
            />
          ))}
        </S.AppointmentCardsContainer>
      )}
    </S.Drawer>
  );
};
