import { useEffect } from "react";

import { initializeApp } from "firebase/app";

import { GlobalStyle } from "./styles/global";

import { useSetRecoilState } from "recoil";

import { useRouteAutomationConfig } from "hooks/api/useRouteAutomationConfig";

import * as Cookie from "./lib/cookie";
import { notifyNewVersion } from "./lib/notifyNewVersion";
import { withProfiler } from "./lib/sentry";
import { activeServiceAreaState } from "./recoil/_common/activeServiceArea";
import { clusterModeControl } from "./recoil/_common/clusterModeControl";
import { darkModeControlState } from "./recoil/_common/darkModeControl";
import { Router } from "./router";

// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAyKsuaHajw77d-0rpmFDygfX41cirDTgg",
  authDomain: "sc-prod-341803.firebaseapp.com",
  projectId: "sc-prod-341803",
  storageBucket: "sc-prod-341803.appspot.com",
  messagingSenderId: "648939706253",
  appId: "1:648939706253:web:1acab308412ab85854cfc4",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const AppBase = () => {
  useRouteAutomationConfig();
  const dispatchDarkMode = useSetRecoilState(darkModeControlState);
  const dispatchClusterMode = useSetRecoilState(clusterModeControl);
  const dispatchServiceArea = useSetRecoilState(activeServiceAreaState);

  const loadAppSettingsFromCookie = () => {
    const appSettings = Cookie.getAppSettings();
    const { darkMode: isDarkMode, clusterMode: isClusterMode, serviceArea } = appSettings || {};
    dispatchDarkMode(!!isDarkMode);
    dispatchClusterMode(!!isClusterMode);
    dispatchServiceArea(serviceArea || "yyz");
  };

  useEffect(() => {
    const checkForUpdate = async () => {
      const registration = await navigator?.serviceWorker?.getRegistration();
      if (!registration) return;
      // no update pending; check swr for updates
      else if (!registration.waiting) return await registration.update();
      // update is pending for installation; show notification
      else return notifyNewVersion(registration);
    };

    // persistent notification when update is available
    checkForUpdate();

    window.addEventListener("focus", checkForUpdate);
    loadAppSettingsFromCookie();

    return () => window.removeEventListener("focus", checkForUpdate);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <div className="App">
      <GlobalStyle />
      <Router />
    </div>
  );
};

export const App = withProfiler(AppBase);
