import { ServiceArea } from "@secondcloset/types/src/_common";

import { stringify } from "query-string";

import { API } from "lib/API";

import { RouteAutomationJob, JobStatus } from "interface/routeAutomationJob";

export interface FetchRouteAutomationJobsQuery {
  date: string;
  status?: JobStatus;
  serviceArea?: ServiceArea;
}

export const fetchRouteAutomationJobs = async (
  queryParams: FetchRouteAutomationJobsQuery
): Promise<RouteAutomationJob[]> => {
  const api = new API("silver");
  const { date, status, serviceArea } = queryParams;
  const query = stringify({
    date,
    status,
    service_area: serviceArea,
  });
  const url = `/routing/get-jobs?${query}`;
  const response = await api.get(url);
  return response.data;
};
