import React, { useRef } from "react";
import { HiOutlineInformationCircle, HiOutlinePencilAlt } from "react-icons/hi";

import { Popover } from "antd";

import * as S from "./styles";
import { COLORS } from "styles";

import { useInventoryOnHandAppointment } from "hooks/application/appointments";

import { Route as RouteLib } from "lib/route";

import * as ApptS from "../AppointmentCard/styles";

import { RouteInformationPopover } from "./RouteInformation";

import { AppointmentOrShipment } from "interface/appointment";
import { Route } from "interface/route";

interface OverviewRouteHeaderProps {
  route: Route;
  appointments: AppointmentOrShipment[];
  onEdit?: (route: Route) => void;
}

export const OverviewRouteHeader: React.FC<OverviewRouteHeaderProps> = ({ route, appointments, onEdit }) => {
  const { getIsIkeaInventoryOnHandAppointment } = useInventoryOnHandAppointment();

  const popoverContainerRef = useRef<HTMLDivElement>(null);
  const popoverContent = <S.RouteHeaderPopoverContent>{route.route_name || "No Nickname"}</S.RouteHeaderPopoverContent>;

  const hasInventoryOnHandAppointments = appointments.some(getIsIkeaInventoryOnHandAppointment);

  const canEdit = !!onEdit && !RouteLib.isRouteCompleted(route);

  return (
    <S.MainRow ref={popoverContainerRef}>
      <S.LabelColumn>
        <Popover
          content={popoverContent}
          trigger="hover"
          color={`${COLORS.CHARCOAL}`}
          overlayClassName={"route-header-popover"}
          getPopupContainer={(element) => {
            if (popoverContainerRef.current) return popoverContainerRef.current;
            else return element;
          }}
        >
          <S.RouteName>
            R#{route.route_number} - {route.route_name || "No Nickname"}
          </S.RouteName>
        </Popover>
        <S.AppointmentsCount>{appointments.length} Appointments</S.AppointmentsCount>
      </S.LabelColumn>

      <S.ActionColumn onClick={(e) => e.stopPropagation()}>
        {hasInventoryOnHandAppointments && (
          <S.ActionColumnElements>
            <ApptS.InventoryOnHandLabel>IOH</ApptS.InventoryOnHandLabel>
          </S.ActionColumnElements>
        )}
        <S.ActionColumnElements>
          <RouteInformationPopover route={route} appointments={appointments}>
            <S.IconButton>
              <S.Icon as={HiOutlineInformationCircle} />
            </S.IconButton>
          </RouteInformationPopover>
          {canEdit && (
            <S.IconButton
              onClick={() => {
                onEdit(route);
              }}
            >
              <S.Icon as={HiOutlinePencilAlt} />
            </S.IconButton>
          )}
        </S.ActionColumnElements>
      </S.ActionColumn>
    </S.MainRow>
  );
};
