import { useMemo, useRef } from "react";
import { useMutation, useQueryClient } from "react-query";

import { Logistics } from "@secondcloset/types";

import { notification } from "antd";
import { debounce, isEqual, isEmpty, findIndex } from "lodash";

import { BASE_QUERY as AVAILABILITIES_BASE_QUERY, getQuery } from "hooks/api/availabilities/queries";

import { updateAvailabilitySlot, UpdateAvailabilitySlotQuery } from "api/availabilities";

const UPDATE_TIMESLOT_LIMITS_DEBOUNCE_TIMEOUT = 500;

type Availability = Logistics.Availability;
type AvailabilityTimeSlot = Logistics.AvailabilityTimeSlot;

export const useUpdateAvailabilityLimits = () => {
  const queryClient = useQueryClient();
  const timeslotsToUpdate = useRef<AvailabilityTimeSlot[]>([]);

  const { mutate: updateTimeslotAvailabilityLimit } = useMutation(updateAvailabilitySlot, {
    onError: (message: string) => {
      notification.error({ message });
      queryClient.invalidateQueries(AVAILABILITIES_BASE_QUERY);
    },
  });

  const updateTimeslotAvailabilityLimitDebounced = useMemo(
    () =>
      debounce((query: UpdateAvailabilitySlotQuery) => {
        updateTimeslotAvailabilityLimit(query);
        timeslotsToUpdate.current = [];
      }, UPDATE_TIMESLOT_LIMITS_DEBOUNCE_TIMEOUT),
    [updateTimeslotAvailabilityLimit]
  );

  const setTimeslotsToUpdate = (newUpdate: AvailabilityTimeSlot) => {
    const newUpdateIdentifiers = {
      timeslot: newUpdate.timeslot,
      delivery_service_level: newUpdate.delivery_service_level,
    };

    if (isEmpty(timeslotsToUpdate.current)) {
      timeslotsToUpdate.current = [newUpdate];
    }

    const prevTimeslots = timeslotsToUpdate.current.map((slot) => {
      return { timeslot: slot.timeslot, delivery_service_level: slot.delivery_service_level };
    });

    if (!prevTimeslots.some((slot) => isEqual(slot, newUpdateIdentifiers))) {
      timeslotsToUpdate.current.push(newUpdate);
    } else {
      const index = findIndex(prevTimeslots, (i) => isEqual(i, newUpdateIdentifiers));
      timeslotsToUpdate.current[index].maximum_appointments_number = newUpdate.maximum_appointments_number;
    }
  };

  const changeTimeslotAvailabilityLimitLocal = async (newDate: string, timeslotAvailability: AvailabilityTimeSlot) => {
    setTimeslotsToUpdate(timeslotAvailability);

    await queryClient.cancelQueries(AVAILABILITIES_BASE_QUERY);

    const query = getQuery(newDate, newDate, timeslotAvailability.service_area);

    queryClient.setQueryData(query, (currentAvailability: Availability[] = []) => {
      const dayAvailability = currentAvailability.find(({ date }) => date === newDate);
      if (!dayAvailability) return currentAvailability;

      const timeslotToUpdate = dayAvailability.timeslots.find(({ timeslot, delivery_service_level }) => {
        return (
          timeslot === timeslotAvailability.timeslot &&
          delivery_service_level === timeslotAvailability.delivery_service_level
        );
      });

      if (timeslotToUpdate) {
        timeslotToUpdate.maximum_appointments_number = timeslotAvailability.maximum_appointments_number;
      }
      return currentAvailability;
    });

    const newQuery = {
      date: newDate,
      timeslotAvailabilities: timeslotsToUpdate.current,
    };

    updateTimeslotAvailabilityLimitDebounced(newQuery);
  };

  return { updateTimeslotAvailabilityLimit: changeTimeslotAvailabilityLimitLocal };
};
