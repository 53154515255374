import React from "react";

import styled, { css } from "styled-components";

interface ContainerProps {
  markerType: "single" | "overlapping";
}
const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 7px;
  width: 100%;
  height: 22px;
  font-size: 11px;
  font-weight: 500;

  ${(props) =>
    props.markerType === "overlapping" &&
    css`
      bottom: 5px;
      left: -1px;
    `}
`;

interface LabelProps {
  children: React.ReactNode;
  color: string;
  markerType: "single" | "overlapping";
}

export const Label: React.FC<LabelProps> = ({ children, color, markerType }) => (
  <Container style={{ color }} markerType={markerType}>
    {children}
  </Container>
);
