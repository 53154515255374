import React from "react";
import { RouteComponentProps } from "react-router";

import { PageNotFound } from "@secondcloset/web-components";

import { BookingPage } from "pages/BookingPage";
import { CapacityUtilizationPage } from "pages/CapacityUtilizationPage";
import { LoginPage } from "pages/LoginPage";
import { RoutesPage } from "pages/RoutesPage";
import { VehiclesPage } from "pages/VehiclesPage";
import { CreateVehiclePage } from "pages/VehiclesPage/CreateVehiclePage";
import { EditVehiclePage } from "pages/VehiclesPage/EditVehiclePage";
import { VehicleDetailsPage } from "pages/VehiclesPage/VehicleDetailsPage";

export interface RouteSetting {
  key: string;
  name: string;
  path: string;
  url?: string;
  component: React.FC<RouteComponentProps>;
  isTab: boolean;
  isPrivate: boolean;
  showNavBar: boolean;
  persistDateFrom: RouteSetting["key"][];
}

export const routes: RouteSetting[] = [
  {
    key: "login",
    name: "Login",
    path: "/login",
    component: LoginPage,
    isTab: false,
    isPrivate: false,
    showNavBar: false,
    persistDateFrom: [],
  },
  {
    key: "routes",
    name: "Routes",
    path: "/routes",
    component: RoutesPage,
    isTab: true,
    isPrivate: true,
    showNavBar: true,
    persistDateFrom: ["capacity-utilization", "vehicles"],
  },
  {
    key: "booking",
    name: "Booking",
    path: "/booking",
    component: BookingPage,
    isTab: true,
    isPrivate: true,
    showNavBar: true,
    persistDateFrom: [],
  },
  {
    key: "vehicles",
    name: "Vehicles",
    path: "/vehicles",
    component: VehiclesPage,
    isTab: true,
    isPrivate: true,
    showNavBar: true,
    persistDateFrom: ["routes", "capacity-utilization"],
  },
  {
    key: "create-vehicle",
    name: "Create Vehicle",
    path: "/vehicles/create",
    component: CreateVehiclePage,
    isTab: false,
    isPrivate: true,
    showNavBar: true,
    persistDateFrom: [],
  },
  {
    key: "vehicle-details",
    name: "Vehicle Details",
    path: "/vehicles/:vehicleID",
    component: VehicleDetailsPage,
    isTab: false,
    isPrivate: true,
    showNavBar: true,
    persistDateFrom: [],
  },
  {
    key: "edit-vehicle",
    name: "Edit Vehicle",
    path: "/vehicles/:vehicleID/edit",
    component: EditVehiclePage,
    isTab: false,
    isPrivate: true,
    showNavBar: true,
    persistDateFrom: [],
  },
  {
    key: "capacity-utilization",
    name: "Capacity Utilization",
    url: "/capacity-utilization",
    path: "/capacity-utilization/:view?",
    component: CapacityUtilizationPage,
    isTab: true,
    isPrivate: true,
    showNavBar: true,
    persistDateFrom: ["routes", "vehicles"],
  },
  {
    key: "PageNotFound",
    name: "page not found",
    path: "*",
    component: PageNotFound,
    isPrivate: false,
    showNavBar: false,
    isTab: false,
    persistDateFrom: [],
  },
];
