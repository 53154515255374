import React, { useEffect, useRef, useState } from "react";
import { HiOutlinePlus, HiOutlineEye, HiOutlineTrash } from "react-icons/hi";

import { Modal, message } from "antd";

import * as S from "./styles";

const MAX_IMAGE_SIZE = 10 * 1024 * 1024;

interface VehicleImagePickerProps {
  url?: string;
  onChange: (image: File | null) => void;
}
export const VehicleImagePicker: React.FC<VehicleImagePickerProps> = ({ url = "", onChange }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [imageURL, setImageURL] = useState(url);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);

  useEffect(() => {
    setImageURL(url);
  }, [url]);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(imageURL);
    };
  }, [imageURL]);

  const handlePreviewButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsPreviewModalVisible(true);
  };

  const handleDeleteButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setImageURL("");
    onChange(null);
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return;

    const imageFile = event.target.files[0];
    if (!imageFile) return;

    if (imageFile.size >= MAX_IMAGE_SIZE) {
      event.target.value = "";
      message.error("Maximum supported image size is 10MB");
      return;
    }

    setImageURL(URL.createObjectURL(imageFile));
    onChange(imageFile);
  };

  return (
    <>
      <S.Container>
        {imageURL ? (
          <>
            <S.Image src={imageURL} data-testid="vehicleImage" />

            <S.ActionButtons>
              <S.ActionButton onClick={handlePreviewButtonClick} data-testid="vehicleImagePreviewButton">
                <HiOutlineEye />
              </S.ActionButton>
              <S.ActionButton onClick={handleDeleteButtonClick} data-testid="vehicleImageDeleteButton">
                <HiOutlineTrash />
              </S.ActionButton>
            </S.ActionButtons>
          </>
        ) : (
          <S.UploadContainer onClick={() => fileInputRef?.current?.click()}>
            <S.HiddenInput
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleFileInputChange}
              data-testid="vehicleImagePickInput"
            />

            <S.InnerContainer>
              <HiOutlinePlus />
              Upload
            </S.InnerContainer>
          </S.UploadContainer>
        )}
      </S.Container>

      <Modal
        visible={isPreviewModalVisible}
        title="Preview"
        footer={null}
        onCancel={() => setIsPreviewModalVisible(false)}
      >
        <S.ImagePreview src={imageURL} data-testid="vehicleImagePreview" />
      </Modal>
    </>
  );
};
