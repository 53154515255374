import { Fulfillment, Storage } from "@secondcloset/types";

import { atom } from "recoil";

type AppointmentType = Storage.AppointmentType | Fulfillment.AppointmentType;
type ScServiceCode = Fulfillment.ScServiceCode;
type Slot = Storage.AppointmentSlot;

export const activeTimeSlotsState = atom<Slot[]>({
  key: "activeTimeSlotsState",
  default: [],
});

export const activeAppointmentTypesState = atom<AppointmentType[]>({
  key: "activeAppointmentTypesState",
  default: [],
});

export const selectedIkeaAppointmentsFilterValuesState = atom<string[]>({
  key: "selectedIkeaAppointmentsFilterValuesState",
  default: ["cross_dock_delivery", "delivery", "outbound"],
});

export const activeServiceLevelsState = atom<ScServiceCode[]>({
  key: "activeServiceLevelsState",
  default: [],
});

export const activeIkeaWarehouseAddressIDState = atom<string>({
  key: "activeIkeaWarehouseAddressIDState",
  default: "",
});

export const inventoryOnHandAppointmentsVisible = atom<boolean>({
  key: "inventoryOnHandAppointmentsVisible",
  default: true,
});

export const activeOrganizationsState = atom<string[]>({
  key: "activeOrganizations",
  default: [],
});
