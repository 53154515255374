import styled from "styled-components";

import { COLORS } from "styles";

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  padding: 20px 0;
  gap: 16px;
`;

export const IconStyle = {
  fontSize: "24px",
  color: COLORS.PRIMARY_BLUE,
};

export const PrimaryButtonStyle = {
  color: COLORS.PRIMARY_BLUE,
  display: "flex",
  alignItem: "center",
};

export const ButtonGroup = styled.div`
  display: flex;
  gap: 16px;
`;

export const ButtonStyle = {
  borderRadius: "4px",
  padding: "0px 6px",
};
