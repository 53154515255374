import { Spin } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  display: grid;
  grid-template-areas:
    "info-cards info-cards info-cards"
    "bar-chart bar-chart table";
  grid-template-columns: repeat(2, 1fr) 2fr;
  gap: 14px;
`;

export const InfoCardContainer = styled.div`
  grid-area: info-cards;
  display: flex;
  gap: 16px;
`;

export const ChartContainer = styled.div`
  grid-area: bar-chart;
  height: 510px;
  border-radius: 6px;
  background-color: ${COLORS.CARD_BACKGROUND};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
`;

export const TableContainer = styled.div`
  grid-area: table;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  border-radius: 6px;
  background-color: ${COLORS.CARD_BACKGROUND};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
`;

export const LoadingSpinner = styled(Spin)`
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
`;
