import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  border-radius: 6px;
  background-color: white;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  user-select: none;
  overflow: hidden;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 15px;
  border-bottom: 1px solid ${COLORS.GREY_MID};
`;

interface MenuItemProps {
  disabled?: boolean;
}

export const MenuItem = styled.div<MenuItemProps>`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 15px;
  cursor: pointer;
  background-color: ${({ disabled }) => (disabled ? COLORS.GREY_LIGHT : "initial")};

  &:hover {
    background-color: ${({ disabled }) => (disabled ? "" : COLORS.GREY_LIGHT)};
  }
`;

interface MenuItemIconProps {
  disabled?: boolean;
}

export const MenuItemIcon = styled.span<MenuItemIconProps>`
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 10px;
  color: ${({ disabled }) => (disabled ? COLORS.GREY : COLORS.PRIMARY_BLUE)};
`;
