import React from "react";

import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Form, Input } from "antd";

import { LoginButton, FormContainer } from "./styles";

import { AlertBanner } from "components/AlertBanner";

interface FormValues {
  remember: boolean;
  email: string;
  password: string;
}

interface LoginFormProps {
  onFormSubmit: (value: FormValues) => void;
  error: string;
  isLoading: boolean;
}

export const LoginForm: React.FC<LoginFormProps> = ({ onFormSubmit, error }) => {
  return (
    <FormContainer
      name="normal_login"
      onFinish={(values) => onFormSubmit(values as FormValues)} //typecast unkown to FormValues
      size="middle"
    >
      {error && <AlertBanner errorMsg="You have entered an invalid username or password" />}
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "Please input your account email!" },
          { type: "email", message: "Please input a valid email address." },
        ]}
      >
        <Input prefix={<UserOutlined />} placeholder="Account email" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: "Please input your password!" }]}>
        <Input.Password prefix={<LockOutlined />} type="password" placeholder="Password" />
      </Form.Item>

      <Form.Item>
        <LoginButton>Log in</LoginButton>
      </Form.Item>
    </FormContainer>
  );
};
