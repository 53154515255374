import React from "react";

import * as S from "./styles";

import { version } from "../../../package.json";

import { ClusterModeToggle } from "./ClusterModeToggle";
import { DarkModeToggle } from "./DarkModeToggle";

export const FooterBar: React.FC = () => {
  return (
    <S.Container>
      <ClusterModeToggle />
      <DarkModeToggle />
      <S.VersionText>Version {version} </S.VersionText>
    </S.Container>
  );
};
