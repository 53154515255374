import { useRecoilValue } from "recoil";
import { userState } from "recoil/_common/user";

export const useUser = () => {
  const user = useRecoilValue(userState);

  const isUserSuperAdmin = user?.role === "is_admin" && user.subrole === "super";

  return { user, isUserSuperAdmin };
};
