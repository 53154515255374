import { useMemo } from "react";
import { useQuery } from "react-query";

import { keyBy, chain, sortBy } from "lodash";

import { useActiveDate } from "hooks/application/useActiveDate";

import { fetchRouteIndex } from "api/routes";

import { Route } from "interface/route";

type RoutesLibrary = { [routeID: string]: Route };
type VehiclesRoutesMap = { [vehicleID: string]: string[] };

const ONE_MINUTE = 60 * 1000;

export const useRoutes = () => {
  const { activeDate } = useActiveDate();

  const {
    isLoading,
    isFetched,
    error,
    data: routes,
  } = useQuery<Route[], string>(["routes", activeDate], () => fetchRouteIndex(activeDate), {
    staleTime: ONE_MINUTE,
  });

  routes?.forEach((route) => {
    route.route_items = sortBy(route?.route_items, "sequence");
  });

  const routesLibrary = useMemo(() => {
    if (!routes) return {};

    return keyBy(routes, (route) => route.id) as RoutesLibrary;
  }, [routes]);

  const routesVehicles = useMemo(() => {
    if (!routes) return {};

    return chain(routes)
      .groupBy((route) => route.vehicle_id)
      .mapValues((routes) => routes.map((route) => route.id))
      .value() as VehiclesRoutesMap;
  }, [routes]);

  const startingAppointmentIDs = useMemo(() => {
    if (!routes) return [];

    return routes.map((route) => {
      const sortedAppointments = sortBy(route.route_items, "sequence");
      return sortedAppointments[0]?.appointment_id;
    });
  }, [routes]);

  const endingAppointmentIDs = useMemo(() => {
    if (!routes) return [];

    return routes.map((route) => {
      const sortedAppointments = sortBy(route.route_items, "sequence");
      return sortedAppointments[sortedAppointments.length - 1]?.appointment_id;
    });
  }, [routes]);

  const appointmentIDsWithExistingRoutes = useMemo(() => {
    if (!routes) return [];

    return routes.flatMap((route) => route.route_items.map((routeItem) => routeItem.appointment_id));
  }, [routes]);

  return {
    isLoading,
    isFetched,
    error,
    routes,
    routesLibrary,
    routesVehicles,
    startingAppointmentIDs,
    endingAppointmentIDs,
    appointmentIDsWithExistingRoutes,
  };
};
