import React, { useMemo, useRef } from "react";
import { HiOutlineInformationCircle, HiOutlinePencilAlt } from "react-icons/hi";

import { Popover } from "antd";
import { isEmpty } from "lodash";

import * as S from "./styles";
import { COLORS } from "styles";

import { useInventoryOnHandAppointment } from "hooks/application/appointments";
import { usePluralRules } from "hooks/application/usePluralRules";

import { Route as RouteLib } from "lib/route";

import * as ApptS from "../AppointmentCard/styles";
import { routeAppointmentCount } from "../utils";

import { RouteInformationPopover } from "./RouteInformation";

import { AppointmentOrShipment } from "interface/appointment";
import { Route } from "interface/route";

interface RouteHeaderProps {
  route: Route;
  appointments: AppointmentOrShipment[];
  visible: boolean;
  onToggleDisplay: (route: Route, visible: boolean) => void;
  onEdit: (route: Route) => void;
}

export const RouteHeader: React.FC<RouteHeaderProps> = ({ route, appointments, visible, onToggleDisplay, onEdit }) => {
  const canShowEditIndicator = isEmpty(route.route_users) || isEmpty(route.vehicle_id);
  const { getIsIkeaInventoryOnHandAppointment } = useInventoryOnHandAppointment();
  const hasInventoryOnHandAppointments = appointments.some(getIsIkeaInventoryOnHandAppointment);

  const popoverContainerRef = useRef<HTMLDivElement>(null);
  const popoverContent = <S.RouteHeaderPopoverContent>{route.route_name || "No Nickname"}</S.RouteHeaderPopoverContent>;
  const appointmentsCount = useMemo(() => routeAppointmentCount(appointments), [appointments]);
  const pluralRules = usePluralRules();
  const pluralRule = pluralRules.select(appointmentsCount);

  return (
    <S.MainRow ref={popoverContainerRef}>
      <S.LabelColumn>
        <Popover
          content={popoverContent}
          trigger="hover"
          color={`${COLORS.CHARCOAL}`}
          overlayClassName={"route-header-popover"}
          getPopupContainer={(element) => {
            if (popoverContainerRef.current) return popoverContainerRef.current;
            else return element;
          }}
        >
          <S.RouteName>
            R#{route.route_number} - {route.route_name || "No Nickname"}
          </S.RouteName>
        </Popover>
        <S.AppointmentsCount>
          {appointmentsCount} {pluralRule === "other" ? "appointments" : "appointment"}
        </S.AppointmentsCount>
      </S.LabelColumn>

      <S.ActionColumn onClick={(e) => e.stopPropagation()}>
        {hasInventoryOnHandAppointments && (
          <S.ActionColumnElements>
            <ApptS.InventoryOnHandLabel>IOH</ApptS.InventoryOnHandLabel>
          </S.ActionColumnElements>
        )}
        <S.ActionColumnElements>
          <RouteInformationPopover route={route} appointments={appointments}>
            <S.IconButton>
              <S.Icon as={HiOutlineInformationCircle} />
            </S.IconButton>
          </RouteInformationPopover>

          {!RouteLib.isRouteCompleted(route) && (
            <S.IconContainer indicator={canShowEditIndicator} right="0px">
              <S.IconButton onClick={() => onEdit(route)}>
                <S.Icon as={HiOutlinePencilAlt} />
              </S.IconButton>
            </S.IconContainer>
          )}

          {visible ? (
            <S.IconButton onClick={() => onToggleDisplay(route, false)}>
              <S.VisibilityIcon visible />
            </S.IconButton>
          ) : (
            <S.IconButton onClick={() => onToggleDisplay(route, true)}>
              <S.VisibilityIcon />
            </S.IconButton>
          )}
        </S.ActionColumnElements>
      </S.ActionColumn>
    </S.MainRow>
  );
};
