import { useMemo } from "react";

import * as S from "./styles";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { activeMarkerAppointmentsOverviewState, appointmentDetailsModalState } from "recoil/sideBarControls/atoms";

import { useUnassignedAppointments } from "hooks/api/appointments";
import { useActiveMarker } from "hooks/application/useActiveMarker";

import { useClickAppointmentCard, useHoverAppointmentCard } from "../AppointmentCard";
import { CollapsePanel } from "../Collapse";

export const useRenderUnassignedAppointments = () => {
  const setAppointmentDetailsModal = useSetRecoilState(appointmentDetailsModalState);

  const unassignedAppointments = useUnassignedAppointments();
  const { appointmentsIDs: activeMarkerAppointmentsIDs } = useRecoilValue(activeMarkerAppointmentsOverviewState);

  const activeMarkerUnassignedAppointments = useMemo(
    () => unassignedAppointments.filter((appointment) => activeMarkerAppointmentsIDs.includes(appointment.id)),
    [activeMarkerAppointmentsIDs, unassignedAppointments]
  );

  const { getIsAppointmentActive } = useActiveMarker();
  const { getIsAppointmentHovered, hoverAppointmentCard, unhoverAppointmentCard } = useHoverAppointmentCard();
  const handleAppointmentCardClick = useClickAppointmentCard();

  const renderUnassignedAppointments = () => {
    if (activeMarkerUnassignedAppointments.length === 0) return null;

    return (
      <CollapsePanel
        key="unassigned"
        header={<S.CollapseHeader>Unassigned Shipments</S.CollapseHeader>}
        highlight={true}
      >
        {activeMarkerUnassignedAppointments.map((appointment) => (
          <S.AppointmentCard
            key={appointment.id}
            appointment={appointment}
            selected={getIsAppointmentActive(appointment.id)}
            hovered={getIsAppointmentHovered(appointment.id)}
            onShowDetails={(appointmentID) => setAppointmentDetailsModal({ visible: true, appointmentID })}
            onClick={handleAppointmentCardClick}
            onMouseEnterCard={hoverAppointmentCard}
            onMouseLeaveCard={unhoverAppointmentCard}
          />
        ))}
      </CollapsePanel>
    );
  };

  return renderUnassignedAppointments;
};
