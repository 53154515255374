import React, { useState, useEffect, FC } from "react";
import { HiOutlineXCircle } from "react-icons/hi";
import { useQuery } from "react-query";

import { Common } from "@secondcloset/types";
import { SearchInput } from "@secondcloset/web-components";

import { notification, Avatar, Spin, Typography } from "antd";
import { startCase } from "lodash";

import * as S from "./styles";
import { COLORS } from "styles";

import { adminUserLookup } from "api/user";
import * as User from "lib/user";

import { RouteUser } from "interface/route";

type UserInterface = Common.User;

interface SelectFieldOpsModalProps {
  onClose: () => void;
  visible: boolean;
  onSave: (routeUsers: RouteUser[]) => void;
  routeUsers: RouteUser[];
}
export const SelectFieldOpsModal: FC<SelectFieldOpsModalProps> = ({ onClose, visible, routeUsers, onSave }) => {
  const [search, setSearch] = useState("");
  const [constructingRouteUsers, setConstructingRouteUsers] = useState<RouteUser[]>([]);
  const isMaxUserSelected = constructingRouteUsers.length === 4;
  const {
    data: users,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    "adminUserLookup",
    () => {
      return adminUserLookup({ q: search, per_page: 10 });
    },
    {
      enabled: false,
      onError: (e: string) => {
        notification.error({ message: e });
      },
    }
  );

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setConstructingRouteUsers(routeUsers || []);
  }, [routeUsers]);

  const handleSearch = (value: string) => setSearch(value);

  const renderSearchBar = () => {
    return <SearchInput onSearch={handleSearch} onClear={() => setSearch("")} placeholder="Search Field Ops" />;
  };

  const onRemoveStaff = (user: RouteUser) => {
    const routeUsers = constructingRouteUsers.filter((u) => u.user_id !== user.user_id);
    setConstructingRouteUsers(routeUsers);
  };

  const onSelectStaff = (user: UserInterface) => {
    if (isMaxUserSelected) return;
    const routeUser = {
      assigned_role: user.subrole,
      user_id: user.id,
      first_name: user.first_name,
      last_name: user.last_name,
    } as RouteUser;
    const routeUsers = [...constructingRouteUsers];
    routeUsers.push(routeUser);
    setConstructingRouteUsers(routeUsers);
  };

  const getFilteredUsers = () => {
    if (!users) return [];
    return users.filter((user) => {
      const initials = User.getInitials(user);
      const name = User.getUserName(user);
      const matchString = `${initials} ${name}`.toLowerCase();
      const currentSearch = search.toLowerCase();
      return matchString.includes(currentSearch);
    });
  };

  const isUserSelected = (user: UserInterface) => {
    return !!constructingRouteUsers.find((u) => u.user_id === user.id);
  };

  const renderList = () => {
    if (isLoading || isFetching) {
      return (
        <S.Loading>
          <Spin tip="getting users.." />
        </S.Loading>
      );
    }

    const users = getFilteredUsers();
    const userRows = users.map((u) => {
      const initials = User.getInitials(u);
      const name = User.getUserName(u);
      const isSelected = isUserSelected(u);
      return (
        <S.UserRow
          key={u.id}
          isSelected={isSelected}
          onClick={() => !isSelected && onSelectStaff(u)}
          isMaxUserSelected={isMaxUserSelected}
        >
          <S.UserListAvatar size={44} isSelected={isSelected}>
            {initials}
          </S.UserListAvatar>
          <S.UserName isSelected={isSelected} title={name}>
            {startCase(name)}
          </S.UserName>
          <S.UserRole>{startCase(u.subrole)}</S.UserRole>
        </S.UserRow>
      );
    });
    return userRows;
  };

  const renderSelectedUsers = () => {
    const selectedUsers = constructingRouteUsers.map((u) => {
      const initials = User.getInitials(u);
      return (
        <S.SelectedAvatarWrap key={u.user_id}>
          <Avatar size={44} style={{ backgroundColor: `${COLORS.PRIMARY_BLUE}` }}>
            {initials}
          </Avatar>
          <HiOutlineXCircle onClick={() => onRemoveStaff(u)} />
        </S.SelectedAvatarWrap>
      );
    });
    const isSelectedUsersEmpty = selectedUsers.length === 0;
    return (
      <div>
        {selectedUsers}
        <S.SelectedUserDefaultTextWrap>
          {isSelectedUsersEmpty && <Typography.Text type="secondary">No User Selected</Typography.Text>}
        </S.SelectedUserDefaultTextWrap>
      </div>
    );
  };

  const onConfirmFieldOpsSelection = () => {
    onSave(constructingRouteUsers);
    onClose();
  };

  const onCancelFieldOpsSelection = () => {
    setConstructingRouteUsers(routeUsers);
    onClose();
  };

  return (
    <S.SelectFieldOpsModal
      visible={visible}
      okText="Confirm"
      onOk={onConfirmFieldOpsSelection}
      onCancel={onCancelFieldOpsSelection}
      title="Select Field Ops"
      width={411}
      maskClosable={false}
      closeIcon={<S.ModalCloseButton />}
    >
      <S.Container>
        <S.SelectedDriversContainer>{renderSelectedUsers()}</S.SelectedDriversContainer>
        <S.SearchBarWrap>{renderSearchBar()}</S.SearchBarWrap>
        <S.UserListWrap>{renderList()}</S.UserListWrap>
      </S.Container>
    </S.SelectFieldOpsModal>
  );
};
