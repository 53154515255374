import { Common, Logistics } from "@secondcloset/types";

import { stringify } from "query-string";

import { API } from "lib/API";

type AvailabilityTimeSlot = Logistics.AvailabilityTimeSlot;
type ServiceArea = Common.ServiceArea;

interface FetchAvailabilitiesQuery {
  dateFrom: string;
  dateTo: string;
  serviceArea?: ServiceArea;
}
export const fetchAvailabilities = async (query: FetchAvailabilitiesQuery) => {
  const api = new API("logistics");

  const { dateFrom, dateTo, serviceArea } = query;
  const options = {
    date_from: dateFrom,
    date_to: dateTo,
    service_area: serviceArea || "yyz",
  };

  const response = await api.get(`/availabilities?${stringify(options)}`);
  return response.data;
};

export interface UpdateAvailabilitySlotQuery {
  date: string;
  timeslotAvailabilities: AvailabilityTimeSlot[];
}
export const updateAvailabilitySlot = async (query: UpdateAvailabilitySlotQuery) => {
  const api = new API("logistics");

  const { date, timeslotAvailabilities } = query;

  const response = await api.put(`/availabilities/${date}`, {
    date,
    timeslots: timeslotAvailabilities,
  });

  return response.data;
};
