import React from "react";

import { ToolOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";

export const DevToolButton: React.FC<ButtonProps> = (p) => (
  <Button
    type="primary"
    shape="circle"
    icon={<ToolOutlined />}
    size="large"
    title="Open dev tools"
    style={{
      position: "fixed",
      bottom: 10,
      right: 416,
      zIndex: 1,
    }}
    {...p}
  />
);
