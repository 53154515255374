import React from "react";

import { Container, Image } from "./styles";

import LogoImage from "assets/bolt-logo.png";

export const Logo: React.FC = () => {
  return (
    <Container>
      <Image src={LogoImage} alt="logo" />
    </Container>
  );
};
