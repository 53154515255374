import { FC } from "react";

import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import * as S from "./styles";
import { COLORS } from "styles";

import { useRouteAutomationJobs } from "hooks/api/useRouteAutomationJobs";

export const ActiveRouteAutomationJobIndicator: FC = () => {
  const { activeRouteAutomationJobs } = useRouteAutomationJobs();

  const LoadingIcon = <LoadingOutlined style={{ fontSize: 14, color: COLORS.PRIMARY_BLUE }} spin />;

  return (
    <S.Wrapper visible={!!activeRouteAutomationJobs?.length} data-testid="routeAutomationJobIndicatorWrapper">
      <Spin indicator={LoadingIcon} /> <S.TextWrapper>Route Automation In Progress</S.TextWrapper>
    </S.Wrapper>
  );
};
