import * as S from "./styles";

export const RouteHeaderPreloader = () => {
  return (
    <S.RouteHeaderPreloader>
      <S.RouteHeaderTitle />

      <S.RouteHeaderButtons>
        <S.RouteHeaderButton />
        <S.RouteHeaderButton />
        <S.RouteHeaderButton />
      </S.RouteHeaderButtons>
    </S.RouteHeaderPreloader>
  );
};
