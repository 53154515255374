import { Route as RouteInterface } from "interface/route";

const colors = {
  yellow: "#ffc400",
  green: "#0bd800",
  red: "#ff3a52",
};
export class Route {
  static getRouteStatusColor = (route: RouteInterface): string => {
    const status = route?.route_status || "";
    switch (status) {
      case "scheduled":
      case "ready":
      case "confirmed":
        return colors.yellow;
      case "fulfilled":
      case "on_the_way":
      case "started":
      case "in_progress":
      case "active":
      case "done":
      case "staging":
      case "completed":
        return colors.green;
      case "incompleted":
        return colors.red;
      default:
        return colors.yellow;
    }
  };

  static isRouteStarted = (route: RouteInterface) => {
    const status = route.route_status;
    return status === "in_progress" || status === "started";
  };
  static isRouteCompleted = (route: RouteInterface) => {
    const status = route.route_status;
    return status === "completed" || status === "done";
  };
}
