import { RouteAutomationJob } from "interface/routeAutomationJob";

export const INIT_TIME_PER_JOB = 90;

export const computeEtaSeconds = (runTime: number, automationJob: RouteAutomationJob) => {
  const runtimePerJob = runTime + INIT_TIME_PER_JOB;
  const { queue } = automationJob;

  if (queue?.count) {
    return queue.count * runtimePerJob;
  }

  return runtimePerJob;
};
