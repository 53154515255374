import React from "react";
import { GrDrag } from "react-icons/gr";
import { HiOutlineLocationMarker, HiOutlineHome, HiTrash, HiInformationCircle, HiOutlineClock } from "react-icons/hi";

import { Tag, Popconfirm, Popover } from "antd";
import { startCase } from "lodash";

import * as S from "./styles";

import { useInventoryOnHandAppointment } from "hooks/application/appointments";

import { Appointment } from "lib/appointment";

import { RouteAutomationJobPopover } from "./RouteAutomationJobPopover";

import { AppointmentOrShipment } from "interface/appointment";
import { JobStatus, RouteAutomationJob } from "interface/routeAutomationJob";

interface AppointmentCardProps {
  appointment?: AppointmentOrShipment;
  onClick?: (appointment: AppointmentOrShipment) => void;
  onSelect?: (appointment: AppointmentOrShipment) => void;
  onDelete?: (appointmentID: string) => void;
  onShowDetails: (appointmentID: string) => void;
  onMouseEnterCard?: (appointmentID: string) => void;
  onMouseLeaveCard?: (appointmentID: string) => void;
  selected?: boolean;
  dragIcon?: boolean;
  isConstructing?: boolean;
  routeAutomationJob?: RouteAutomationJob;
  className?: string;
}

export const AppointmentCard: React.FC<AppointmentCardProps> = ({
  appointment,
  selected,
  dragIcon,
  onClick,
  className,
  routeAutomationJob,
  isConstructing,
  onDelete,
  onShowDetails,
  onMouseEnterCard,
  onMouseLeaveCard,
}) => {
  const { getIsIkeaInventoryOnHandAppointment } = useInventoryOnHandAppointment();

  if (!appointment) return null;

  const customerName = Appointment.getCustomerName(appointment) ?? "";
  const formattedTimerange = Appointment.getFormattedTimerange(appointment);
  const isInventoryPickUp = Appointment.isInventoryPickup(appointment);
  const isReturnToSender = Appointment.isReturnToSender(appointment);
  const canDelete = onDelete && !isInventoryPickUp && !isReturnToSender;
  //Grouped Appointments are appointments to and from IKEA warehouses (Home Markers)
  const isGroupAppointments = isInventoryPickUp || isReturnToSender;

  const isIkeaInventoryOnHandAppointment = getIsIkeaInventoryOnHandAppointment(appointment);

  const renderJobTypeAndSubtype = () => {
    const subtypeText = appointment.job_subtype ? `, ${startCase(appointment.job_subtype)}` : "";
    return startCase(appointment.job_type) + subtypeText;
  };

  return (
    <S.AppointmentCard
      selected={selected}
      onClick={() => onClick?.(appointment)}
      onMouseEnter={() => onMouseEnterCard?.(appointment.id)}
      onMouseLeave={() => onMouseLeaveCard?.(appointment.id)}
      className={className}
      data-appointment-card={`a-${appointment.id}`}
    >
      <S.MainContainer>
        {dragIcon && (
          <S.DragContainer>
            <GrDrag />
          </S.DragContainer>
        )}
        <S.Container>
          <S.Details>
            {isGroupAppointments ? (
              <HiOutlineHome style={S.IconStyle(selected)} />
            ) : (
              <HiOutlineLocationMarker style={S.IconStyle(selected)} />
            )}
            <S.DetailContent>
              <S.DetailLine>
                <strong>{customerName}</strong>
              </S.DetailLine>
              <S.DetailLine>
                {renderJobTypeAndSubtype()}
                {isIkeaInventoryOnHandAppointment && <S.InventoryOnHandLabel>On Hand</S.InventoryOnHandLabel>}
              </S.DetailLine>
              <S.DetailLine>{formattedTimerange}</S.DetailLine>
              <Tag color={Appointment.getAppointmentStatusColor(appointment)}>{startCase(appointment.status)}</Tag>
            </S.DetailContent>
          </S.Details>

          <S.Buttons>
            {isConstructing && canDelete && (
              <Popconfirm
                title="Are you sure to delete this task?"
                onConfirm={() => onDelete(appointment.id)}
                okText="Yes"
                cancelText="No"
                placement="right"
              >
                <S.IconButton>
                  <HiTrash style={S.IconStyle(true)} />
                </S.IconButton>
              </Popconfirm>
            )}

            {!!routeAutomationJob && routeAutomationJob.status === JobStatus.ACTIVE && (
              <Popover
                content={<RouteAutomationJobPopover routeAutomationJob={routeAutomationJob} />}
                trigger="hover"
                placement="left"
              >
                <S.InfoButton alwaysVisible>
                  <HiOutlineClock style={S.TimerIconStyle} />
                </S.InfoButton>
              </Popover>
            )}

            {!isGroupAppointments && (
              <S.InfoButton
                onClick={(e) => {
                  e.stopPropagation();
                  onShowDetails(appointment.id);
                }}
                alwaysVisible={isConstructing}
              >
                <HiInformationCircle style={S.IconStyle(true)} />
              </S.InfoButton>
            )}
          </S.Buttons>
        </S.Container>
      </S.MainContainer>
    </S.AppointmentCard>
  );
};
