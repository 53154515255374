import React from "react";
import { IconType } from "react-icons";
import {
  HiOutlineChatAlt,
  HiOutlineCheck,
  HiOutlineDocumentText,
  HiOutlineExternalLink,
  HiOutlineLocationMarker,
  HiOutlineUser,
} from "react-icons/hi";

import { Form } from "antd";

import * as S from "./styles";

import { Details } from "./types";

interface ShipmentDetailsProps {
  details: Details;
}

export const ShipmentDetails: React.FC<ShipmentDetailsProps> = ({ details }) => {
  const renderFormLabel = (text: string, icon: IconType) => (
    <S.DetailsFormLabel>
      <S.DetailsFormIcon as={icon} />
      {text}
    </S.DetailsFormLabel>
  );

  const renderFulfillmentNotes = () => {
    return (
      <>
        <Form.Item label={renderFormLabel("Internal Note", HiOutlineChatAlt)}>
          <S.DetailsFormValue empty={!details.internalNote}>{details.internalNote || "Empty"}</S.DetailsFormValue>
        </Form.Item>
        <Form.Item label={renderFormLabel("Marketplace Note", HiOutlineChatAlt)}>
          <S.DetailsFormValue empty={!details.marketplaceNote}>{details.marketplaceNote || "Empty"}</S.DetailsFormValue>
        </Form.Item>
        <Form.Item label={renderFormLabel("Shipment Note", HiOutlineChatAlt)}>
          <S.DetailsFormValue empty={!details.shipmentNote}>{details.shipmentNote || "Empty"}</S.DetailsFormValue>
        </Form.Item>
      </>
    );
  };

  const renderStorageNotesAndTasks = () => {
    // also include custom appt
    return (
      <>
        <Form.Item label={renderFormLabel("Notes", HiOutlineChatAlt)}>
          <S.DetailsFormValue empty={!details.storageNotes}>{details.storageNotes || "Empty"}</S.DetailsFormValue>
        </Form.Item>
        <Form.Item label={renderFormLabel("Tasks", HiOutlineChatAlt)}>
          <S.DetailsFormValue empty={!details.storageTasks}>{details.storageTasks || "Empty"}</S.DetailsFormValue>
        </Form.Item>
      </>
    );
  };

  return (
    <S.DetailsContainer>
      <Form layout="horizontal" labelCol={{ span: 10 }} wrapperCol={{ span: 16 }} labelAlign="left">
        <Form.Item label={renderFormLabel("Recipient", HiOutlineUser)}>
          <S.DetailsFormValue>
            <div>{details.customer.name}</div>
            <div>{details.customer.phone}</div>
          </S.DetailsFormValue>
        </Form.Item>

        <Form.Item label={renderFormLabel("Destination", HiOutlineLocationMarker)}>
          <S.DetailsFormValue>{details.customer.address}</S.DetailsFormValue>
        </Form.Item>
        {details.isFulfillment ? renderFulfillmentNotes() : renderStorageNotesAndTasks()}
        <Form.Item label={renderFormLabel("Appt Type & Service", HiOutlineCheck)}>
          <S.DetailsFormValue>
            <div> {details.appointmentServiceType}</div>
            <div> {details.appointmentSubType}</div>
          </S.DetailsFormValue>
        </Form.Item>

        <Form.Item label={renderFormLabel("Timeslot", HiOutlineDocumentText)}>
          <S.DetailsFormValue>{details.timeslot}</S.DetailsFormValue>
        </Form.Item>

        <Form.Item label={renderFormLabel("Inventory Location", HiOutlineDocumentText)}>
          <S.DetailsFormValue>{details.inventoryLocation}</S.DetailsFormValue>
        </Form.Item>
      </Form>

      <S.SierraOrderDetailsLink href={details.sierraLink} target="_blank">
        <S.SierraOrderDetailsIcon as={HiOutlineExternalLink} />
        Sierra Order Details
      </S.SierraOrderDetailsLink>
    </S.DetailsContainer>
  );
};
