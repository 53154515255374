import React, { useState, Fragment } from "react";

import { DevToolButton, DevToolDrawer } from "./components";

interface DevToolProps {
  visible: boolean;
}

const DevTool: React.FC<DevToolProps> = ({ visible }) => {
  const [enabled, setEnabled] = useState(false);

  return visible ? (
    <Fragment>
      <DevToolButton onClick={() => setEnabled(true)} />
      <DevToolDrawer visible={enabled} onClose={() => setEnabled(false)} />
    </Fragment>
  ) : null;
};

export * from "./utils";

const DevToolMemoized = React.memo(DevTool);

export { DevToolMemoized as DevTool };
