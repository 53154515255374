import { useCallback, useEffect, useMemo, useState } from "react";

import { Button } from "antd";

import * as S from "./styles";
import { LAYOUT } from "styles";

import { useSetRecoilState } from "recoil";
import { CreateRouteView } from "recoil/routes/atoms";
import { appointmentDetailsModalState } from "recoil/sideBarControls/atoms";

import { useRouteAutomationJobs } from "hooks/api/useRouteAutomationJobs";
import { useCreateRouteState, useNotAddedToRouteUnassignedAppointments } from "hooks/application/createRoute";
import { useSidebarSearch } from "hooks/application/useSidebarSearch";

import { useHoverAppointmentCard, useClickAppointmentCard } from "../../AppointmentCard";
import { Search } from "../../Search";

import { useSelectedAppointments } from "./useSelectedAppointments";

import { AppointmentOrShipment } from "interface/appointment";

export const RemainingAppointmentsSelector = () => {
  const unassignedAppointments = useNotAddedToRouteUnassignedAppointments();
  const [remainingAppointments, setRemainingAppointments] = useState<AppointmentOrShipment[]>([]);
  const { isSearchEnabled, getIsAppointmentSearched } = useSidebarSearch();
  const { getIsAppointmentHovered, hoverAppointmentCard, unhoverAppointmentCard } = useHoverAppointmentCard();
  const handleAppointmentCardClick = useClickAppointmentCard();

  const { currentView: createRouteStateCurrentView, setCurrentView: setCreateRouteStateView } = useCreateRouteState();
  const isVisible = createRouteStateCurrentView === CreateRouteView.ConstructRouteAddMoreAppointments;

  const setAppointmentDetailsModal = useSetRecoilState(appointmentDetailsModalState);
  const { appointmentIsInActiveJob } = useRouteAutomationJobs();

  useEffect(() => {
    if (isVisible) {
      setRemainingAppointments(unassignedAppointments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const { getIsAppointmentSelected, toggleAppointmentSelected, confirmSelection, cancelSelection } =
    useSelectedAppointments();

  const handleCancel = () => {
    cancelSelection();
    setCreateRouteStateView(CreateRouteView.ConstructRoute);
  };

  const handleConfirm = () => {
    confirmSelection();
    setCreateRouteStateView(CreateRouteView.ConstructRoute);
  };

  const validateAppointment = useCallback(
    (appointment: { id: string }) => {
      const isAppointmentValid = !appointmentIsInActiveJob(appointment);
      return isAppointmentValid && getIsAppointmentSearched(appointment);
    },
    [getIsAppointmentSearched, appointmentIsInActiveJob]
  );

  const remainingAppointmentsToRender = useMemo(() => {
    return isSearchEnabled
      ? remainingAppointments.filter(validateAppointment)
      : remainingAppointments.filter((appointment) => !appointmentIsInActiveJob(appointment));
  }, [isSearchEnabled, remainingAppointments, validateAppointment, appointmentIsInActiveJob]);

  const renderTitle = () => <S.Title>Unassigned Shipments</S.Title>;

  const onClickAppointmentCard = (appointment: AppointmentOrShipment) => {
    handleAppointmentCardClick(appointment);
    toggleAppointmentSelected(appointment.id);
  };

  return (
    <S.Drawer
      title={renderTitle()}
      closable={false}
      visible={isVisible}
      getContainer={false}
      width={LAYOUT.SIDEBAR_WIDTH}
    >
      <Search />

      <S.AppointmentsList>
        {remainingAppointmentsToRender.map((appointment) => (
          <S.AppointmentCard
            key={appointment.id}
            appointment={appointment}
            selected={getIsAppointmentSelected(appointment)}
            hovered={getIsAppointmentHovered(appointment.id)}
            onClick={onClickAppointmentCard}
            onShowDetails={(appointmentID) => setAppointmentDetailsModal({ visible: true, appointmentID })}
            onMouseEnterCard={hoverAppointmentCard}
            onMouseLeaveCard={unhoverAppointmentCard}
          />
        ))}
      </S.AppointmentsList>

      <S.ActionButtons>
        <Button onClick={handleCancel}>Cancel</Button>

        <Button type="primary" onClick={handleConfirm}>
          Add to Route
        </Button>
      </S.ActionButtons>
    </S.Drawer>
  );
};
