import { useUrl } from "./useUrl";

export const useCancelledAppointmentsVisible = () => {
  const { getQueryValues, setUrlWithQueryValues } = useUrl();
  const queryValues = getQueryValues() as {
    isCancelledAppointmentsVisible: string | boolean;
  };
  const isCancelledAppointmentsVisible = queryValues.isCancelledAppointmentsVisible === "true";

  const setCancelledAppointmentsVisible = (isCancelledAppointmentsVisible: boolean) => {
    setUrlWithQueryValues({ isCancelledAppointmentsVisible });
  };

  return { isCancelledAppointmentsVisible, setCancelledAppointmentsVisible };
};
