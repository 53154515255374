import styled from "styled-components";

import { COLORS } from "styles";

interface Props {
  isDarkMode: boolean;
}

export const Container = styled.div<Props>`
  color: ${({ isDarkMode }) => (isDarkMode ? COLORS.CHARCOAL : "white")};
  background: ${({ isDarkMode }) => (isDarkMode ? COLORS.WHITE : COLORS.CHARCOAL)};
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  transition: background ease-in 0.3s;
  transition: color ease-in 0.3s;
  z-index: 500;
  transform: translate(-50%, -50%);
  position: absolute;

  :hover {
    background: ${COLORS.YELLOW};
    color: ${COLORS.CHARCOAL};
  }
`;
