import React from "react";
import { useMutation } from "react-query";

import { notification } from "antd";
import moment from "moment";

import * as S from "./styles";

import { useRecoilValue } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";

import { useActiveDate } from "hooks/application/useActiveDate";
import { useActiveFlow } from "hooks/application/useActiveFlow";

import { downloadRouteCsv } from "api/routes";
import { Organization } from "lib/organization";

export const CSVDownloadButton: React.FC = () => {
  const { isIkea } = useActiveFlow();
  const { activeDate } = useActiveDate();
  const activeServiceArea = useRecoilValue(activeServiceAreaState);

  const { mutate: getRouteCsv, isLoading } = useMutation(downloadRouteCsv, {
    onError: (e: string) => {
      notification.error({ message: "Error", description: e });
    },
    onSuccess: (file) => {
      const fileName = moment().format();
      const hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURIComponent(file);
      hiddenElement.target = "_blank";
      hiddenElement.download = `${fileName}.csv`;
      hiddenElement.click();
    },
  });

  const onDownloadCSVClick = () => {
    if (!activeServiceArea) {
      return null;
    }

    const ikeaOrganizationID = Organization.getIkeaOrgID();
    getRouteCsv({
      date: activeDate,
      organizationID: isIkea ? ikeaOrganizationID : undefined,
      excludedOrganizationId: isIkea ? undefined : ikeaOrganizationID,
      serviceArea: activeServiceArea,
    });
  };

  return (
    <S.Button onClick={onDownloadCSVClick} loading={isLoading}>
      <S.Icon />
      CSV
    </S.Button>
  );
};
