import * as Sentry from "@sentry/browser";

import { Appointment } from "lib/appointment";

import { AppointmentOrShipment } from "interface/appointment";

export const groupAppointments = (
  appointmentIds: string[],
  appointmentsLibrary: { [appointmentID: string]: AppointmentOrShipment }
) => {
  const appts = appointmentIds.map((id) => appointmentsLibrary[id]).filter((a) => a);
  const hasInventoryPickup = appts.find((appt) => Appointment.isInventoryPickup(appt));
  const hasReturnToSender = appts.find((appt) => Appointment.isReturnToSender(appt));

  Sentry.setContext("groupAppointments params", {
    appointmentIds,
    appointmentsLibraryIds: Object.keys(appointmentsLibrary),
  });

  if (hasInventoryPickup) {
    const groupedAppointments = Appointment.groupAppointmentsByInventoryPickup(appts);
    Sentry.setContext("hasInventoryPickup grouped appointments", {
      inventoryPickup: hasInventoryPickup?.id,
      groupedAppointmentIds: groupedAppointments.map((appt) => appt?.id),
    });

    return groupedAppointments;
  } else if (hasReturnToSender) {
    const groupedAppointments = Appointment.groupAppointmentsByReturnToSender(appts);
    Sentry.setContext("hasReturnToSender grouped appointments", {
      inventoryPickup: hasReturnToSender?.id,
      groupedAppointmentIds: groupedAppointments.map((appt) => appt?.id),
    });

    return groupedAppointments;
  }

  return appts;
};
