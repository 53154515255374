import { Logistics } from "@secondcloset/types";

import { sum } from "lodash";

type AvailabilityTimeSlot = Logistics.AvailabilityTimeSlot;
type CountType = "max" | "booked" | "white_glove" | "non_white_glove" | "consumer";

export const getAvailabilityAppointmentsCount = (
  availabilities: AvailabilityTimeSlot[],
  typeOfCount: CountType
): number => {
  let appointmentsCounts, filteredAvailabilities;
  switch (typeOfCount) {
    case "max":
      appointmentsCounts = availabilities.map((av) => av.maximum_appointments_number);
      break;
    case "booked":
      appointmentsCounts = availabilities.map((av) => av.booked_appointments_count);
      break;
    case "white_glove":
      filteredAvailabilities = availabilities.filter((av) => av.delivery_service_level === "white_glove");
      return getAvailabilityAppointmentsCount(filteredAvailabilities, "booked");
    case "non_white_glove":
      filteredAvailabilities = availabilities.filter((av) => av.delivery_service_level === "non_white_glove");
      return getAvailabilityAppointmentsCount(filteredAvailabilities, "booked");
    case "consumer":
      filteredAvailabilities = availabilities.filter((av) => av.customer_segment === "consumer");
      return getAvailabilityAppointmentsCount(filteredAvailabilities, "booked");
  }
  return sum(appointmentsCounts);
};
