import React, { useRef } from "react";

import { Switch } from "antd";

import { useRecoilState } from "recoil";
import { inventoryOnHandAppointmentsVisible } from "recoil/topFilterControls/atoms";

import { SwitchLabel, SwitchWrapper } from "pages/RoutesPage/styles";

export const InventoryOnHandToggle: React.FC = () => {
  const [isInventoryOnHandAppointmentsVisible, setIsInventoryOnHandAppointmentsVisible] = useRecoilState(
    inventoryOnHandAppointmentsVisible
  );
  const showInventoryOnHandAppointmentsSwitchRef = useRef<HTMLButtonElement | null>(null);

  return (
    <SwitchWrapper
      isChecked={isInventoryOnHandAppointmentsVisible}
      onClick={() => showInventoryOnHandAppointmentsSwitchRef?.current?.click()}
    >
      <SwitchLabel>Inventory on Hand</SwitchLabel>
      <Switch
        checked={isInventoryOnHandAppointmentsVisible}
        onChange={(checked) => {
          setIsInventoryOnHandAppointmentsVisible(checked);
        }}
      />
    </SwitchWrapper>
  );
};
