import styled from "styled-components";

import { COLORS } from "styles";

export const IconButton = styled.button`
  padding: 3px;
  border: 0;
  border-radius: 5px;
  background-color: transparent;
  color: ${COLORS.GREY_DARK};
  font-size: 0;
  cursor: pointer;

  &:hover {
    background-color: ${COLORS.GREY_LIGHT};
  }

  &:active {
    background-color: ${COLORS.GREY_MID};
  }
`;

export const Icon = styled.svg`
  width: 26px;
  height: 26px;
`;
