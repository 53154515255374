import styled from "styled-components";

import { LAYOUT } from "styles";

export const Container = styled.div`
  flex-shrink: 0;
  position: relative;
  width: ${LAYOUT.SIDEBAR_WIDTH};
  z-index: 1;
  overflow: hidden;
`;

interface InnerContainerProps {
  isSideBarShowing?: boolean;
}
export const InnerContainer = styled.div<InnerContainerProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  opacity: ${(props) => (props.isSideBarShowing ? "100%" : "0%")};
  transition: max-width 0.4s ease-in-out, opacity 0.4s ease-in-out;
  height: 100%;
  overflow-y: auto;
`;
