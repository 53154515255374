import { useRecoilValue } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";
import { darkModeControlState } from "recoil/_common/darkModeControl";

import { centerPoints } from "./centerPoints";
import { mapStylesDarkMode, mapStylesLightMode } from "./mapStyles";
import { removedMapStyles } from "./removedMapStyles";

export const useMapUISettings = () => {
  const activeServiceArea = useRecoilValue(activeServiceAreaState);
  const isDarkModeActive = useRecoilValue(darkModeControlState);
  const defaultCenter = centerPoints[activeServiceArea || "yyz"];
  const defaultZoom = defaultCenter.zoom;
  const mapStyles = isDarkModeActive ? mapStylesDarkMode : mapStylesLightMode;

  return {
    bootstrapURLKeys: { key: process.env.REACT_APP_GOOGLE_API_KEY as string },
    defaultCenter,
    defaultZoom,
    options: {
      styles: [...mapStyles, ...removedMapStyles],
      disableDefaultUI: true,
      clickableIcons: false,
    },
  };
};
