import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 60px;
  padding: 0 16px;
  border-bottom: 1px solid ${COLORS.GREY_LIGHT};
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 5%);
`;

export const TabsContainer = styled.div`
  margin-left: auto;
`;
