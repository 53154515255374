import { useMemo } from "react";

import { intersection } from "lodash";

import { useRecoilValue } from "recoil";
import {
  bookingAppointmentsMarkersState,
  bookingSelectedAppointmentIdsState,
  bookingAppointmentsState,
} from "recoil/booking/atoms";

import { Appointment as AppointmentLib } from "lib/appointment";

export interface VisibleMarker {
  markerKey: string;
  appointmentIDs: string[];
  lat: number;
  lng: number;
}

export const useVisibleBookingMarkers = () => {
  const bookingAppointmentsMarkers = useRecoilValue(bookingAppointmentsMarkersState);
  const selectedAppointmentsIds = useRecoilValue(bookingSelectedAppointmentIdsState);
  const bookingAppointmentsLibrary = useRecoilValue(bookingAppointmentsState);

  const visibleBookingMarkers = useMemo(() => {
    return Object.entries(bookingAppointmentsMarkers).reduce((acc, cv) => {
      const [markerKey, appointmentIDs] = cv;

      const filteredAppointmentsIds = appointmentIDs.filter((appointmentIDs: string) => {
        const appointment = bookingAppointmentsLibrary[appointmentIDs];
        return !AppointmentLib.isInventoryPickup(appointment) && !AppointmentLib.isReturnToSender(appointment);
      });

      const selectedVisibleAppointmentsIds = intersection(filteredAppointmentsIds, selectedAppointmentsIds);

      if (selectedVisibleAppointmentsIds.length === 0) return acc;
      const { lat, lng } = JSON.parse(markerKey);
      acc.push({
        markerKey,
        appointmentIDs: selectedVisibleAppointmentsIds,
        lat,
        lng,
      });
      return acc;
    }, [] as VisibleMarker[]);
  }, [selectedAppointmentsIds, bookingAppointmentsMarkers, bookingAppointmentsLibrary]);

  return visibleBookingMarkers;
};
