import moment from "moment";

import { useUrl } from "./useUrl";

const isDateValid = (date: string) => {
  return date && moment(date, "YYYY-MM-DD", true).isValid();
};

export const useActiveDate = () => {
  const { getQueryValues, setUrlWithQueryValues } = useUrl();
  const today = moment().format("YYYY-MM-DD");
  const queryValues = getQueryValues() as { date: string };
  const dateOnUrl = queryValues.date;
  const activeDate = isDateValid(dateOnUrl) ? dateOnUrl : today;

  const setActiveDate = (newDate: string) => {
    const date = isDateValid(newDate) ? newDate : today;
    setUrlWithQueryValues({ date });
  };

  return { activeDate, setActiveDate };
};
