import React, { useEffect, useMemo, useRef, useState } from "react";

import keyBy from "lodash/keyBy";

import * as S from "./styles";

import { useSetRecoilState } from "recoil";
import { appointmentDetailsModalState } from "recoil/sideBarControls/atoms";

import { useUnassignedAppointments } from "hooks/api/appointments";
import { useRouteAutomationJobs } from "hooks/api/useRouteAutomationJobs";
import { useCreateRouteState } from "hooks/application/createRoute";
import { useActiveMarker } from "hooks/application/useActiveMarker";

import { useClickAppointmentCard, useHoverAppointmentCard } from "../AppointmentCard";

import { CreateRouteMenu } from "./CreateRouteMenu";
import { useAppointmentsToRender } from "./useAppointmentsToRender";

import { AppointmentOrShipment } from "interface/appointment";
import { JobStatus } from "interface/routeAutomationJob";

interface UnassignedShipmentsListProps {
  onConstruction: () => void;
  onCreateRouteAutomation: () => void;
}

export const UnassignedShipmentsList: React.FC<UnassignedShipmentsListProps> = ({
  onConstruction,
  onCreateRouteAutomation,
}) => {
  const { activeMarkerAppointments } = useActiveMarker();
  const {
    enabled: isCreateRouteStateEnabled,
    selectedAppointmentsIDs,
    toggleAppointmentSelected,
  } = useCreateRouteState();
  const { routeAutomationAppointmentLibrary } = useRouteAutomationJobs();

  const unassignedAppointments = useUnassignedAppointments();
  const { getIsAppointmentHovered, hoverAppointmentCard, unhoverAppointmentCard } = useHoverAppointmentCard();
  const appointmentsToRender = useAppointmentsToRender();

  const [popupSettings, setPopupSettings] = useState({ visible: false, x: 0, y: 0 });
  const popupRef = useRef<HTMLDivElement>(null);

  const setAppointmentDetailsModal = useSetRecoilState(appointmentDetailsModalState);
  const handleAppointmentCardClick = useClickAppointmentCard();

  const onAppointmentCardClick = (appointment: AppointmentOrShipment) => {
    handleAppointmentCardClick(appointment);
    if (!isCreateRouteStateEnabled) return;
    toggleAppointmentSelected(appointment.id);
  };

  const highlightedAppointmentsCount = useMemo(() => {
    const mappedUnassignedAppointments = keyBy(unassignedAppointments, (appointment) => appointment.id);
    return selectedAppointmentsIDs.filter((id) => mappedUnassignedAppointments[id]).length;
  }, [selectedAppointmentsIDs, unassignedAppointments]);

  useEffect(() => {
    if (popupSettings.visible) {
      const handleClickOutside = (event: MouseEvent) => {
        if (!popupRef.current?.contains(event.target as Node)) {
          setPopupSettings((state) => ({ ...state, visible: false }));
        }
      };

      document.addEventListener("click", handleClickOutside);
      return () => {
        document.removeEventListener("click", handleClickOutside);
      };
    }
  }, [popupSettings.visible]);

  const onRightClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (selectedAppointmentsIDs.length === 0) return;

    e.preventDefault();
    setPopupSettings({ visible: true, x: e.clientX, y: e.clientY });
  };

  const getIsAppointmentSelected = (appointment: AppointmentOrShipment) => {
    return (
      selectedAppointmentsIDs.includes(appointment.id) ||
      activeMarkerAppointments?.map((appointment) => appointment.id).includes(appointment.id)
    );
  };

  const renderAppointments = () => {
    return appointmentsToRender.map((appointment) => {
      const routeAutomationJobs = routeAutomationAppointmentLibrary[appointment.id];
      const routeAutomationJob = routeAutomationJobs?.find((job) => job.status === JobStatus.ACTIVE);

      return (
        <S.AppointmentCard
          key={appointment.id}
          appointment={appointment}
          selected={getIsAppointmentSelected(appointment)}
          hovered={getIsAppointmentHovered(appointment.id)}
          allowSelection={isCreateRouteStateEnabled}
          onShowDetails={(appointmentID) => setAppointmentDetailsModal({ visible: true, appointmentID })}
          onClick={onAppointmentCardClick}
          onMouseEnterCard={hoverAppointmentCard}
          onMouseLeaveCard={unhoverAppointmentCard}
          routeAutomationJob={routeAutomationJob}
        />
      );
    });
  };

  return (
    <div onContextMenu={onRightClick}>
      {renderAppointments()}

      {popupSettings.visible && (
        <div style={S.CreateRouteMenuContainerStyle(popupSettings.x, popupSettings.y)} ref={popupRef}>
          <CreateRouteMenu
            onCreateRouteManual={onConstruction}
            onCreateRouteAutomation={onCreateRouteAutomation}
            selectedCount={highlightedAppointmentsCount}
          />
        </div>
      )}
    </div>
  );
};
