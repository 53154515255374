import React from "react";

import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 16px;
  padding: 16px 24px;
  border-radius: 6px;
  background-color: ${COLORS.CARD_BACKGROUND};
`;

export const Title = styled.div`
  font-size: 48px;
  font-weight: bold;
  line-height: 58px;
`;

export const Description = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

interface CardProps {
  title: string;
  titleColor?: string;
  description: string;
}

export const Card: React.FC<CardProps> = ({ title, titleColor = COLORS.BLACK, description }) => (
  <Container>
    <Title style={{ color: titleColor }}>{title}</Title>
    <Description>{description}</Description>
  </Container>
);
