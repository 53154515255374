import React from "react";

import { range } from "lodash";
import moment from "moment";

import * as S from "./styles";

interface CalendarProps {
  month: number;
  year: number;
  render: (day: number) => JSX.Element;
  className?: string;
}

export const Calendar: React.FC<CalendarProps> = ({ month, year, render, className }) => {
  const firstDay = moment({ year, month });
  const daysCount = moment({ year, month }).daysInMonth();

  return (
    <S.Container className={className}>
      <S.WeekDaysHeader>
        {moment.weekdays().map((weekDayName) => (
          <div key={weekDayName}>{weekDayName}</div>
        ))}
      </S.WeekDaysHeader>

      <S.MonthView firstDay={firstDay.day() + 1}>
        {range(0, daysCount).map((dayNumber) => (
          <S.Day key={dayNumber}>{render(dayNumber + 1)}</S.Day>
        ))}
      </S.MonthView>
    </S.Container>
  );
};
