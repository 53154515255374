import styled from "styled-components";

import { COLORS } from "styles";

export const RedDotContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  width: 16px;
`;

export const RedDot = styled.div`
  height: 5px;
  width: 5px;
  background-color: ${COLORS.RED};
  border-radius: 50%;
`;
