import styled from "styled-components";

interface ContainerProps {
  markerIcon: string;
}
export const Container = styled.div<ContainerProps>`
  width: 40px;
  height: 29px;
  background-image: url(${(props) => props.markerIcon});
  background-size: contain;
  background-repeat: no-repeat;
  transform: translate(-50%, -100%);
`;
