import React from "react";

import { Button } from "antd";

import * as S from "./styles";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { bookingSelectedAppointmentIdsState, bookingSelectedDateState } from "recoil/booking/atoms";

import { useUpdateBookingDate } from "hooks/api/appointments";

export const AssignDateButton = () => {
  const { updateBookingDate } = useUpdateBookingDate();
  const bookingSelectedAppointmentIds = useRecoilValue(bookingSelectedAppointmentIdsState);
  const bookingSelectedDate = useRecoilValue(bookingSelectedDateState);
  const setBookingSelectedAppointmentIds = useSetRecoilState(bookingSelectedAppointmentIdsState);

  return (
    <S.ButtonWrapper>
      <Button
        type="primary"
        onClick={() => {
          if (bookingSelectedDate) {
            updateBookingDate.mutate(
              {
                appointmentIds: bookingSelectedAppointmentIds,
                date: bookingSelectedDate,
              },
              {
                onSettled: () => setBookingSelectedAppointmentIds([]),
              }
            );
          }
        }}
        loading={updateBookingDate.isLoading}
        disabled={!bookingSelectedDate || bookingSelectedAppointmentIds?.length === 0}
      >
        Assign Date
      </Button>
    </S.ButtonWrapper>
  );
};
