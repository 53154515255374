import { Common, Fulfillment } from "@secondcloset/types";
type AddressType = Common.BaseAddress;
type LocationType = Fulfillment.Location;

export class Address {
  static getCoordinates = (address?: AddressType | LocationType) => {
    const lat = address?.coordinates?.lat || 0;
    const lng = address?.coordinates?.lng || 0;
    return { lat, lng };
  };
}
