import { useMutation, useQueryClient } from "react-query";

import { notification } from "antd";

import { useRecoilValue } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";

import { batchUpdateAppointments } from "api/appointment";

export const useUpdateBookingDate = () => {
  const queryClient = useQueryClient();
  const activeServiceArea = useRecoilValue(activeServiceAreaState);

  const updateBookingDate = useMutation(batchUpdateAppointments, {
    onSuccess: () => {
      if (activeServiceArea) {
        queryClient.refetchQueries(["fetchBookingAppointmentIndex"]);
      }
    },
    onError: (error: string) => {
      notification.error({ message: "Error", description: error });
    },
  });

  return { updateBookingDate };
};
