import React from "react";

import { CheckboxDropdown } from "@secondcloset/web-components";

import { Space, Typography } from "antd";

import { Route } from "interface/route";

interface RouteNumberFilterProps {
  data: Route[];
  value: string[];
  isLoading: boolean;
  onChange: (routeIDs: string[]) => void;
}

export const RouteNumberFilter: React.FC<RouteNumberFilterProps> = ({ data, value, isLoading, onChange }) => {
  const options = data
    .sort((a, b) => +a.route_number - +b.route_number)
    .map((r) => ({
      key: r.id,
      value: r.id,
      label: `Route ${r.route_number}`,
    }));

  return (
    <Space align="center">
      <Typography.Text>Filter by route #:</Typography.Text>
      <CheckboxDropdown
        options={options}
        defaultText={options.length ? "None Selected" : "All"}
        disabled={isLoading}
        loading={isLoading}
        selectedOptionKeys={value}
        setSelectedOptionKeys={onChange}
      />
    </Space>
  );
};
