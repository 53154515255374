import styled from "styled-components";

export const HeaderWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px;
`;

export const HeaderText = styled.div`
  font-size: 24px;
  font-weight: 300;
`;
