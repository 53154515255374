import React from "react";

import { ServiceLevel } from "@secondcloset/fulfillment-utils";
import { Fulfillment } from "@secondcloset/types";

import { GlobalOutlined } from "@ant-design/icons";
import { startCase } from "lodash";

import * as S from "./styles";

import { useAppointments } from "hooks/api/appointments";
import { useActiveFlow } from "hooks/application/useActiveFlow";
import { useActiveMarker } from "hooks/application/useActiveMarker";

import { Appointment } from "lib/appointment";
import { Slot } from "lib/slot";

import { ItemsTable } from "./ItemsTable";
import { NotesAndTasks } from "./NotesAndTasks";

type ServiceLevelInterface = ServiceLevel.ServiceLevelInterface;

export const InfoBox: React.FC = () => {
  const { isIkea } = useActiveFlow();
  const { activeMarker, activeMarkerAppointments } = useActiveMarker();
  const { inventoryOnHandAppointmentIDMap } = useAppointments();

  const getSelectedAppointment = () => {
    if (activeMarker?.selectedAppointmentId) {
      return activeMarkerAppointments.find(({ id }) => activeMarker.selectedAppointmentId === id);
    }
    return activeMarkerAppointments.length === 1 ? activeMarkerAppointments[0] : null;
  };
  const selectedAppointment = getSelectedAppointment();

  const openAppointmentDetails = (id: string) => {
    const url = `${process.env.REACT_APP_SIERRA_DOMAIN}/appointments/${id}`;
    const win = window.open(url, "_blank");
    win?.focus();
  };

  const renderCardHeader = () => {
    if (!selectedAppointment) return null;
    const { timerange, id } = selectedAppointment;
    const name = Appointment.getCustomerName(selectedAppointment);
    const phone = Appointment.getPhoneNumber(selectedAppointment);
    const address = Appointment.getOneLineAddress(selectedAppointment);
    const slotColor = Slot.getSlotColor(timerange);
    return (
      <S.Address onClick={() => openAppointmentDetails(id)} role="button">
        <div>
          {address}
          <S.SmallText>
            {name} {phone}
          </S.SmallText>
        </div>
        <div style={{ color: slotColor }}>{timerange}</div>
      </S.Address>
    );
  };

  const renderOrganizationName = () => {
    if (!selectedAppointment) return null;
    const isFulfillment = Appointment.isFulfillmentAppointment(selectedAppointment);
    if (!isFulfillment) return null;
    const organizationName = (selectedAppointment as Fulfillment.Appointment)?.shipment?.organization?.name;
    if (!organizationName) return null;
    return (
      <S.Tag>
        <S.OrgTag>
          <GlobalOutlined />
          {organizationName}
        </S.OrgTag>
      </S.Tag>
    );
  };

  const renderInventoryOnHandTag = () => {
    if (!selectedAppointment) return null;
    const shouldShow = !!inventoryOnHandAppointmentIDMap[selectedAppointment.id];
    if (!shouldShow || !isIkea) return null;
    return (
      <div>
        <S.Tag color="blue">Inventory On Hand</S.Tag>
      </div>
    );
  };

  const renderMissingPackagesBanner = () => {
    const isMissing = Appointment.isMissingPackages(isIkea, selectedAppointment as Fulfillment.Appointment);
    if (!isMissing) return null;
    return <S.MissingPackagesWrap>Missing Packing Details</S.MissingPackagesWrap>;
  };

  const renderAppointmentInfo = () => {
    if (!selectedAppointment) return null;
    const { job_type, status, delivery_service_level } = selectedAppointment;
    const jobType = startCase(job_type);
    const jobTypeColor = Appointment.getJobTypeColor(selectedAppointment);
    const isCancelled = status === "cancelled";

    const serviceLevelLabel =
      ServiceLevel.getServiceLevelList().find((sl: ServiceLevelInterface) => sl.key === delivery_service_level)
        ?.label || "-";

    return (
      <S.ContentInfo>
        {renderOrganizationName()}
        <S.Tag color={jobTypeColor} style={{ maxWidth: "192px", whiteSpace: "normal" }}>
          {jobType}
        </S.Tag>
        {isCancelled && <S.Tag color="red">Cancelled</S.Tag>}
        <S.Tag color="purple">Service Level: {serviceLevelLabel}</S.Tag>
        {renderInventoryOnHandTag()}
      </S.ContentInfo>
    );
  };

  return (
    <S.Container visible={!!selectedAppointment} data-testid="infoBox">
      <S.InfoBoxContent>
        {renderCardHeader()}
        {renderMissingPackagesBanner()}
        <S.ContentWrap>
          {renderAppointmentInfo()}
          <ItemsTable infoBoxVisible appointment={selectedAppointment} />
        </S.ContentWrap>
      </S.InfoBoxContent>
      <NotesAndTasks appointment={selectedAppointment} />
    </S.Container>
  );
};
