import { useMemo } from "react";

import { getRouteAppointmentIDs } from "recoil/routes/helpers";

import { useRoutes } from "hooks/api/routes";

import { useAppointments } from "./useAppointments";

import { AppointmentOrShipment } from "interface/appointment";

export const useAssignedAppointments = () => {
  const { routesLibrary } = useRoutes();
  const { appointmentsLibrary } = useAppointments();

  const assignedAppointments = useMemo(() => {
    const getRouteAppointments = (routeID: string) => {
      const appointmentIDs = getRouteAppointmentIDs({ routeID, routesLibrary });
      const appointments = appointmentIDs.map((id) => appointmentsLibrary[id]).filter(Boolean);
      return appointments;
    };

    const assignedAppointments: AppointmentOrShipment[] = [];
    Object.values(routesLibrary).forEach((route) => {
      const routeAppointments = getRouteAppointments(route.id);
      if (routeAppointments.length > 0) {
        assignedAppointments.push(...routeAppointments);
      }
    });

    return assignedAppointments;
  }, [routesLibrary, appointmentsLibrary]);

  return assignedAppointments;
};
