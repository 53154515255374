import React from "react";

import { Alert } from "antd";

import { Container } from "./styles";

interface Props {
  errorMsg?: string;
}

export const AlertBanner: React.FC<Props> = (props) => {
  if (!props.errorMsg) return null;
  return (
    <Container>
      <Alert type="error" message={props.errorMsg} banner closable={true} />
    </Container>
  );
};
