import { useState, useEffect } from "react";

import { Input } from "antd";
import isEmpty from "lodash/isEmpty";

import * as S from "./styles";

import { useSidebarSearch } from "hooks/application/useSidebarSearch";

import { CSVDownloadButton } from "../CSVDownloadButton";

interface SearchProps {
  showCSV?: boolean;
}

export const Search: React.FC<SearchProps> = ({ showCSV }) => {
  const { searchKey, setSearchKey } = useSidebarSearch();
  const [searchString, setSearchString] = useState(searchKey);

  useEffect(() => {
    if (isEmpty(searchKey)) setSearchString("");
  }, [searchKey]);

  return (
    <S.TopBar>
      <Input.Search
        placeholder="Search"
        value={searchString}
        onChange={(e) => setSearchString(e.target.value)}
        onKeyPress={(e) => {
          e.key === "Enter" && setSearchKey(searchString);
        }}
        onSearch={() => setSearchKey(searchString)}
      />
      {showCSV && <CSVDownloadButton />}
    </S.TopBar>
  );
};
