import { useMemo, useCallback } from "react";

import { intersection } from "lodash";

import { useRecoilValue } from "recoil";
import { hiddenAppointmentMapState } from "recoil/appointments/atoms";
import { createRouteState } from "recoil/routes/atoms";

import { useAppointments, useUnassignedAppointments } from "hooks/api/appointments";
import { useAppointmentsRoutesMap } from "hooks/api/routes";
import { useRouteAutomationJobs } from "hooks/api/useRouteAutomationJobs";
import {
  useRoutesMapVisibility,
  useUnassignedAppointmentsMapVisibility,
} from "hooks/application/useRoutesMapVisibility";

import { Markers } from "lib/markers";

export interface VisibleMarker {
  markerKey: string;
  appointmentIDs: string[];
  lat: number;
  lng: number;
}

export const useVisibleMarkers = () => {
  const { enabled: isCreateRouteStateEnabled } = useRecoilValue(createRouteState);
  const { appointmentsMarkers } = useAppointments();
  const hiddenAppointmentsMap = useRecoilValue(hiddenAppointmentMapState);
  const appointmentsRoutesMap = useAppointmentsRoutesMap();
  const { getIsRouteAppointmentVisible } = useRoutesMapVisibility();
  const { isVisible: isUnassignedAppointmentsVisible } = useUnassignedAppointmentsMapVisibility();

  const getIsAppointmentVisible = useCallback(
    (appointmentID: string) => {
      if (isCreateRouteStateEnabled) return true;

      const isAppointmentBelongsToRoute = appointmentsRoutesMap[appointmentID];
      if (isAppointmentBelongsToRoute) return getIsRouteAppointmentVisible(appointmentID);

      return isUnassignedAppointmentsVisible;
    },
    [isCreateRouteStateEnabled, appointmentsRoutesMap, getIsRouteAppointmentVisible, isUnassignedAppointmentsVisible]
  );

  const visibleMarkers = useMemo(() => {
    const noLocationMarkerKey = Markers.getNoLocationMarkerKey();

    return Object.entries(appointmentsMarkers).reduce((result, [markerKey, appointmentIDs]) => {
      if (markerKey === noLocationMarkerKey) return result;

      const visibleAppointmentIDs = appointmentIDs
        .filter((id) => !hiddenAppointmentsMap[id])
        .filter(getIsAppointmentVisible);
      if (visibleAppointmentIDs.length === 0) return result;

      const { lat, lng } = JSON.parse(markerKey);
      result.push({ markerKey, appointmentIDs: visibleAppointmentIDs, lat, lng });

      return result;
    }, [] as VisibleMarker[]);
  }, [hiddenAppointmentsMap, appointmentsMarkers, getIsAppointmentVisible]);

  return visibleMarkers;
};

export const useVisibleUnassignedAppointmentsMarkers = () => {
  const visibleMarkers = useVisibleMarkers();
  const unassignedAppointments = useUnassignedAppointments();

  const unassignedAppointmentsMarkers = useMemo(() => {
    const unassignedAppointmentsIDs = unassignedAppointments.map((appointment) => appointment.id);

    return visibleMarkers
      .map(({ appointmentIDs, ...marker }) => ({
        ...marker,
        appointmentIDs: intersection(appointmentIDs, unassignedAppointmentsIDs),
      }))
      .filter(({ appointmentIDs }) => appointmentIDs.length > 0);
  }, [visibleMarkers, unassignedAppointments]);

  return unassignedAppointmentsMarkers;
};

export const useVisibleEditingRouteAppointmentsMarkers = () => {
  const { routeItems = [] } = useRecoilValue(createRouteState);
  const { appointmentIsInActiveJob } = useRouteAutomationJobs();

  const visibleMarkers = useVisibleMarkers();
  const editingRouteAppointmentsIDs = routeItems.map(({ appointment_id }) => appointment_id);

  const editingRouteAppointmentsMarkers = useMemo(() => {
    return visibleMarkers
      .map(({ appointmentIDs, ...marker }) => ({
        ...marker,
        appointmentIDs: intersection(appointmentIDs, editingRouteAppointmentsIDs),
      }))
      .filter(({ appointmentIDs }) => {
        const areAppointmentsAvailable = appointmentIDs.every((id) => !appointmentIsInActiveJob({ id }));
        if (appointmentIDs.length > 0 && areAppointmentsAvailable) return true;
        return false;
      });
  }, [visibleMarkers, editingRouteAppointmentsIDs, appointmentIsInActiveJob]);

  return editingRouteAppointmentsMarkers;
};
