import { useRecoilValue } from "recoil";
import { CreateRouteView } from "recoil/routes/atoms";
import { isIkeaOutboundState } from "recoil/sideBarControls/atoms";

import { useGetDoesRouteHaveReverseLogistics } from "hooks/api/routes/useGetDoesRouteHaveReverseLogistics";

import { useCreateRouteState } from "./createRoute";
import { useActiveFlow } from "./useActiveFlow";
import { useIKEAInOutboundToggle } from "./useToggleIKEAInOutbound";

import { Route } from "interface/route";

export const useEditRoute = () => {
  const { isIkea } = useActiveFlow();
  const isIkeaOutbound = useRecoilValue(isIkeaOutboundState);
  const handleIkeaInOutToggle = useIKEAInOutboundToggle();
  const getDoesRouteHaveReverseLogistics = useGetDoesRouteHaveReverseLogistics();
  const {
    setRouteDetails,
    enable: enableCreateRouteState,
    setCurrentView: setCreateRouteStateView,
  } = useCreateRouteState();

  const handleEditRouteClick = (route: Route) => {
    if (isIkea) {
      const isRouteInbound = getDoesRouteHaveReverseLogistics(route.id);
      if (isIkeaOutbound && isRouteInbound) {
        handleIkeaInOutToggle(["reverse_logistics", "inbound"]);
      }
      if (!isIkeaOutbound && !isRouteInbound) {
        handleIkeaInOutToggle(["delivery", "cross_dock_delivery", "outbound"]);
      }
    }
    setRouteDetails({
      routeID: route.id,
      routeName: route.route_name,
      routeItems: route.route_items,
      users: route.route_users,
      vehicleID: route.vehicle_id,
      selectedAppointmentsIDs: route.route_items.map((routeItem) => routeItem.appointment_id),
    });
    enableCreateRouteState();
    setCreateRouteStateView(CreateRouteView.ConstructRoute);
  };

  return { handleEditRouteClick };
};
