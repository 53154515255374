import React from "react";
import { HiOutlineUserCircle, HiOutlineTruck, HiTrash } from "react-icons/hi";

import { Popconfirm } from "antd";
import { debounce } from "lodash";

import * as S from "./styles";

interface ConstructionRouteHeaderProps {
  onClickTruck: () => void;
  onClickDriver: () => void;
  onDeleteRoute: () => void;
  onInputRouteName: (routeName: string) => void;
  routeID?: string;
  prevRouteName?: string;
  hasVehicle?: boolean;
  hasFieldOps?: boolean;
  canDelete: boolean;
}

export const ConstructionRouteHeader: React.FC<ConstructionRouteHeaderProps> = ({
  onClickDriver,
  onClickTruck,
  hasVehicle,
  hasFieldOps,
  onDeleteRoute,
  prevRouteName,
  onInputRouteName,
  canDelete,
}) => {
  return (
    <S.MainRow>
      <S.LabelColumn>
        <S.RouteNameInput
          defaultValue={prevRouteName}
          placeholder={"Route Nickname Optional"}
          allowClear
          onChange={debounce((e) => onInputRouteName(e.target.value), 500)}
        />
      </S.LabelColumn>

      <S.ActionColumn onClick={(e) => e.stopPropagation()}>
        <S.ActionColumnElements>
          <S.IconButton onClick={onClickTruck}>
            <S.IconContainer indicator={!hasVehicle}>
              <S.Icon as={HiOutlineTruck} />
            </S.IconContainer>
          </S.IconButton>
          <S.IconButton onClick={onClickDriver}>
            <S.IconContainer indicator={!hasFieldOps}>
              <S.Icon as={HiOutlineUserCircle} />
            </S.IconContainer>
          </S.IconButton>

          {canDelete && (
            <Popconfirm
              title="Are you sure delete this route?"
              onConfirm={onDeleteRoute}
              okText="Yes"
              cancelText="No"
              placement="right"
            >
              <S.IconButton>
                <S.IconContainer>
                  <S.Icon as={HiTrash} />
                </S.IconContainer>
              </S.IconButton>
            </Popconfirm>
          )}
        </S.ActionColumnElements>
      </S.ActionColumn>
    </S.MainRow>
  );
};
