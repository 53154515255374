import React, { useEffect, useMemo } from "react";

import { ServiceLevel } from "@secondcloset/fulfillment-utils";

import * as S from "./styles";

import { useSetRecoilState } from "recoil";
import { activeServiceLevelsState, activeTimeSlotsState } from "recoil/topFilterControls/atoms";

import { useAppointments } from "hooks/api/appointments";
import { useActiveFlow } from "hooks/application/useActiveFlow";

import { AppointmentFilter } from "./AppointmentFilter";
import { CancelledAppointmentsToggle } from "./CancelledAppointmentsToggle";
import { InventoryOnHandToggle } from "./InventoryOnHandToggle/InventoryOnHandToggle";
import { OrganizationFilter } from "./OrganizationFilter";
import { ServiceLevelFilter } from "./ServiceLevelFilter";
import { SlotFilter } from "./SlotFilter";

export const TopFilterBar: React.FC = () => {
  const { isIkea } = useActiveFlow();
  const { appointmentsLibrary, appointmentTimeslots } = useAppointments();
  const appointments = useMemo(() => Object.values(appointmentsLibrary), [appointmentsLibrary]);
  const dispatchActiveTimeSlots = useSetRecoilState(activeTimeSlotsState);
  const flowKey = isIkea ? "ikea" : "second_closet";
  const initialActiveServiceLevels = ServiceLevel.getServiceLevelList(flowKey).map((sl) => sl.key);
  const dispatchActiveServiceLevels = useSetRecoilState(activeServiceLevelsState);

  useEffect(() => {
    dispatchActiveTimeSlots(appointmentTimeslots);
    dispatchActiveServiceLevels(initialActiveServiceLevels);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowKey]);
  return (
    <S.Container>
      <AppointmentFilter appointments={appointments} key={isIkea ? "ikea" : "not-ikea"} />
      <ServiceLevelFilter appointments={appointments} flowKey={flowKey} />
      <SlotFilter appointments={appointments} />
      {!isIkea && <OrganizationFilter appointments={appointments} />}
      <CancelledAppointmentsToggle />
      {isIkea && <InventoryOnHandToggle />}
    </S.Container>
  );
};
