import { useQuery } from "react-query";

import { notification } from "antd";

import { fetchAppointmentTasks } from "api/appointment";

import { AppointmentOrShipment } from "interface/appointment";

export const useTasks = (appointment?: AppointmentOrShipment | null) => {
  const {
    data: tasks,
    isLoading: isTasksLoading,
    refetch: refetchTasks,
  } = useQuery(
    ["fetchAppointmentTasks", appointment?.id],
    () => {
      return appointment?.id ? fetchAppointmentTasks(appointment.id) : [];
    },
    {
      enabled: !!appointment && appointment.type === "storage",
      onError: (e: string) => notification.error({ message: e }),
    }
  );

  return { tasks, isTasksLoading, refetchTasks };
};
