import { useHistory } from "react-router";

import { Logistics } from "@secondcloset/types";

import { ParentSize } from "@visx/responsive";
import moment from "moment";

import * as S from "./styles";
import { COLORS } from "styles";

import { useRecoilValue } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";

import { useAvailabilities } from "hooks/api/availabilities";
import { useActiveServiceAreaTimeslots } from "hooks/application/capacityUtilization";
import { useActiveDate } from "hooks/application/useActiveDate";
import { useUser } from "hooks/user/useUser";

import { getAvailabilityAppointmentsCount, getPercentage } from "pages/CapacityUtilizationPage/helpers";

import { Content as PageContent } from "../PageLayout/Content";
import { Header as PageHeader, Date as PageHeaderDate, Controls as PageHeaderControls } from "../PageLayout/Header";
import { ViewSelector } from "../ViewSelector";

import { CapacityBarChart, DataItem } from "./CapacityBarChart";
import { CapacityByTimeSlotTable } from "./CapacityByTimeSlotTable";
import { getCapacityBarChartData } from "./helpers/getCapacityBarChartData";
import { InfoCard } from "./InfoCard";
import { useUpdateAvailabilityLimits } from "./useUpdateAvailabilityLimits";

type AvailabilityTimeSlot = Logistics.AvailabilityTimeSlot;

export const DayView = () => {
  const history = useHistory();
  const { activeDate } = useActiveDate();
  const activeServiceArea = useRecoilValue(activeServiceAreaState);
  const { availabilities } = useAvailabilities(activeDate, activeDate);
  const { isUserSuperAdmin } = useUser();
  const { updateTimeslotAvailabilityLimit } = useUpdateAvailabilityLimits();
  const timeslots = useActiveServiceAreaTimeslots();

  if (!availabilities) {
    return (
      <S.Container>
        <S.LoadingSpinner size="large" />
      </S.Container>
    );
  }

  const dayAvailabilities = availabilities[0].timeslots;
  const availabilitiesToDisplay = dayAvailabilities.filter((av) => timeslots.includes(av.timeslot));

  const maxAppointmentsCount = getAvailabilityAppointmentsCount(availabilitiesToDisplay, "max");
  const bookedAppointmentsCount = getAvailabilityAppointmentsCount(availabilitiesToDisplay, "booked");
  const availableAppointmentsCount = maxAppointmentsCount - bookedAppointmentsCount;

  const handleChangeTimeslotLimit = (timeslotAvailability: AvailabilityTimeSlot) => {
    if (!activeServiceArea) return;

    updateTimeslotAvailabilityLimit(activeDate, timeslotAvailability);
  };

  const percentBooked = getPercentage(bookedAppointmentsCount, maxAppointmentsCount);

  return (
    <>
      <PageHeader>
        <PageHeaderDate>{moment(activeDate).format("MMMM Do, YYYY")}</PageHeaderDate>

        <PageHeaderControls>
          <ViewSelector
            value="day"
            onChange={(value) => {
              history.push(`/capacity-utilization/${value}?date=${activeDate}`);
            }}
          />
        </PageHeaderControls>
      </PageHeader>

      <PageContent>
        <S.Container>
          <S.InfoCardContainer>
            <InfoCard
              numberAppt={bookedAppointmentsCount}
              numberColor={`${COLORS.PRIMARY_GREEN}`}
              additionalInfo={`${percentBooked}% Booked`}
            />

            <InfoCard
              numberAppt={availableAppointmentsCount}
              numberColor={`${COLORS.BLACK}`}
              additionalInfo="Available"
            />

            <InfoCard numberAppt={maxAppointmentsCount} numberColor={`${COLORS.BLACK}`} additionalInfo="Max Capacity" />
          </S.InfoCardContainer>

          <S.ChartContainer>
            <ParentSize>
              {({ width, height }) => {
                if (width === 0 || height === 0) {
                  return null;
                }

                return (
                  <CapacityBarChart
                    width={width}
                    height={height}
                    data={getCapacityBarChartData(availabilitiesToDisplay) as DataItem[]}
                  />
                );
              }}
            </ParentSize>
          </S.ChartContainer>

          <S.TableContainer>
            <CapacityByTimeSlotTable
              allowEdit={isUserSuperAdmin}
              slotAvailabilitiesData={availabilitiesToDisplay}
              onChangeTimeslotLimit={handleChangeTimeslotLimit}
            />
          </S.TableContainer>
        </S.Container>
      </PageContent>
    </>
  );
};
