import { getRouteAppointmentIDs } from "recoil/routes/helpers";

import { useAppointments } from "hooks/api/appointments";
import { useRoutes } from "hooks/api/routes";

export const useGetRouteAppointments = () => {
  const { appointmentsLibrary } = useAppointments();
  const { routesLibrary } = useRoutes();

  return (routeID: string) => {
    const appointmentIDs = getRouteAppointmentIDs({ routeID, routesLibrary });
    return appointmentIDs.map((id) => appointmentsLibrary[id]).filter(Boolean);
  };
};
