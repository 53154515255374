import { API } from "lib/API";

import { OrganizationName } from "interface/organization";

export const fetchOrganizationNames = async (organizationIDs: string[]) => {
  const api = new API("logistics");
  const url = `/organizations/bulk_get`;
  const body = { ids: organizationIDs };
  const response = await api.post(url, body);

  return response.data as OrganizationName[];
};
