import styled from "styled-components";

import { COLORS } from "styles";

export const InfoCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100px;
  width: 100%;
  padding: 24px;
  border-radius: 6px;
  background-color: ${COLORS.CARD_BACKGROUND};
`;

export const NumberAppointmentText = styled.div`
  font-size: 36px;
  font-weight: 700;
  align-self: flex-end;
  justify-self: center;
`;

export const AdditionalInfoText = styled.div`
  order: 2;
  font-size: 16px;
  font-weight: 500px;
  align-self: flex-end;
  justify-self: flex-end;
`;
