import { Typography } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 64px;
  padding: 16px;
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: $border;
  align-items: center;
  padding-right: 16px;

  .ant-select-selection-item {
    background-color: white;
    border: none;
  }

  .ant-select-selector {
    height: 32px;
  }
`;

export const SelectedText = styled(Typography.Text)`
  color: ${COLORS.GREY_DARK};
`;
