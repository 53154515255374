import styled from "styled-components";

export const Container = styled.div``;

export const ImageWrap = styled.div`
  text-align: center;
  transform: translate(-50%, -100%);
  position: absolute;
  z-index: 10;
`;

export const Image = styled.img``;

export const LabelWrap = styled.div`
  border: 1px solid transparent;
  width: 100%;
  height: 100%;
`;

export const Label = styled.div<{ color?: string }>`
  width: 50px;
  margin-top: -36px;
  color: ${({ color }) => (color ? color : "white")};
  font-size: 14px;
`;
