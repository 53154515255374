import { useState, useEffect } from "react";

import { chain } from "lodash";

import { useCreateRouteState, useCreateRouteView } from "hooks/application/createRoute";

import { AppointmentOrShipment } from "interface/appointment";

type AppointmentsSelectionMap = { [appointmentID: string]: boolean };

export const useOverlappingAppointments = () => {
  const { isOverlappingAppointmentsSelectorVisible } = useCreateRouteView();

  const {
    selectedAppointmentsIDs,
    overlappingAppointments: appointments,
    resetOverlappingAppointmentsState,
  } = useCreateRouteState();

  const [appointmentsSelectionMap, setAppointmentsSelectionMap] = useState<AppointmentsSelectionMap>({});

  useEffect(() => {
    if (isOverlappingAppointmentsSelectorVisible) {
      const appointmentsSelectionMap = chain(appointments)
        .keyBy((appointment) => appointment.id)
        .mapValues((appointment) => selectedAppointmentsIDs.includes(appointment.id))
        .value();
      setAppointmentsSelectionMap(appointmentsSelectionMap);

      return () => {
        setAppointmentsSelectionMap({});
      };
    }
  }, [isOverlappingAppointmentsSelectorVisible, appointments, selectedAppointmentsIDs]);

  const toggleAppointmentSelected = (appointment: AppointmentOrShipment) => {
    setAppointmentsSelectionMap((state) => ({
      ...state,
      [appointment.id]: !state[appointment.id],
    }));
  };

  const getIsAppointmentSelected = (appointment: AppointmentOrShipment) => appointmentsSelectionMap[appointment.id];

  return {
    appointments,
    appointmentsSelectionMap,
    getIsAppointmentSelected,
    toggleAppointmentSelected,
    resetOverlappingAppointmentsState,
  };
};
