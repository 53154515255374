import React, { useMemo } from "react";

import { startCase } from "lodash";
import moment from "moment";

import * as S from "./styles";

import { Appointment as AppointmentLib } from "lib/appointment";
import { Route as RouteLib } from "lib/route";

import { AppointmentOrShipment } from "interface/appointment";
import { Route } from "interface/route";
import { Vehicle } from "interface/vehicle";

interface RouteInformationProps {
  route: Route;
  appointments: AppointmentOrShipment[];
  vehicle: Vehicle;
  isLoadingItems?: boolean;
}

export const RouteInformation: React.FC<RouteInformationProps> = ({
  route,
  appointments,
  vehicle,
  isLoadingItems = false,
}) => {
  const routeStatusColor = RouteLib.getRouteStatusColor(route);

  const items = useMemo(() => {
    return appointments.flatMap((appointment) => AppointmentLib.getCompressedItems(appointment));
  }, [appointments]);

  const itemsTotalWeight = useMemo(() => {
    return items.reduce((acc, item) => (item.weight ? acc + parseFloat(item.weight.value) : acc), 0);
  }, [items]);

  const getItemsUnit = () => {
    return items[0]?.weight?.unit;
  };

  const itemsQuantity = useMemo(() => {
    return items.reduce((acc, item) => acc + item.quantity, 0);
  }, [items]);

  const getFormattedTravelTime = () => {
    const travelTimeSeconds = route?.estimated_travel_time_in_seconds;
    if (!travelTimeSeconds) return "N/A";
    const travelTime = moment.utc(travelTimeSeconds * 1000);
    return `${travelTime.format("H")} hr ${travelTime.format("mm")} min`;
  };

  const getFormattedTravelDistance = () => {
    const distance = route?.estimated_travel_distance_in_meters;
    if (!distance) return "N/A";
    return `${(distance / 1000).toFixed(0)} km`;
  };

  return (
    <S.Container>
      <S.Section>
        <S.SectionTitle>Status</S.SectionTitle>
        <S.SectionBody>
          <span style={{ color: routeStatusColor }}>{startCase(route.route_status)}</span>
        </S.SectionBody>
      </S.Section>

      <S.Section>
        <S.SectionTitle>Total Items</S.SectionTitle>
        <S.SectionBody>
          {isLoadingItems ? (
            "Loading..."
          ) : (
            <span>
              {itemsQuantity} Items ({Math.round(itemsTotalWeight)} {getItemsUnit()})
            </span>
          )}
        </S.SectionBody>
      </S.Section>

      <S.Section>
        <S.SectionTitle>Staff</S.SectionTitle>
        <S.SectionBody>
          {!!route.route_users.length
            ? route.route_users.map((user) => (
                <div key={user.user_id}>
                  {user.first_name} {user.last_name} ({startCase(user.assigned_role)})
                </div>
              ))
            : "None"}
        </S.SectionBody>
      </S.Section>

      <S.Section>
        <S.SectionTitle>Truck</S.SectionTitle>
        <S.SectionBody>
          <div>{vehicle.name}</div>
          <div>{vehicle.model}</div>
          <div>{vehicle.license_plate_number}</div>
        </S.SectionBody>
      </S.Section>

      <S.Section>
        <S.SectionTitle>Total Travel Time</S.SectionTitle>
        <S.SectionBody>
          <div>{getFormattedTravelTime()}</div>
        </S.SectionBody>
      </S.Section>

      <S.Section>
        <S.SectionTitle>Total Distance</S.SectionTitle>
        <S.SectionBody>
          <div>{getFormattedTravelDistance()}</div>
        </S.SectionBody>
      </S.Section>
    </S.Container>
  );
};
