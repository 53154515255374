import React, { useState, useEffect } from "react";

import { Button, Spin } from "antd";

import { useVehicles } from "hooks/api/vehicles";

import { LoadingIcon } from "./LoadingIcon";
import { SelectToggle } from "./types";
import { VehicleModal } from "./VehicleModal";

import { Vehicle } from "interface/vehicle";

interface SelectMultipleVehicleModalProps {
  visible: boolean;
  isLoading?: boolean;
  selectedVehicleIDs: string[];
  onClose: () => unknown;
  onSave: (selectedVehicles: Vehicle[]) => unknown;
}

export const SelectMultipleVehicleModal: React.FC<SelectMultipleVehicleModalProps> = ({
  visible,
  isLoading,
  selectedVehicleIDs,
  onClose,
  onSave,
}) => {
  const [selectedVehicles, setSelectedVehicles] = useState<Vehicle[]>([]);

  const { isFetched: isVehiclesFetched, getVehiclesAvailableToRoute } = useVehicles();

  const vehiclesAvailableToRoute = getVehiclesAvailableToRoute();

  useEffect(() => {
    if (visible && isVehiclesFetched) {
      const initialVehicles = selectedVehicleIDs.map(
        (vehicleID) => vehiclesAvailableToRoute.find((vehicle) => vehicle.id === vehicleID) as Vehicle
      );
      setSelectedVehicles(initialVehicles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, isVehiclesFetched]);

  const renderCancelButton = () => (
    <Button key="cancel" onClick={() => !isLoading && onClose()} disabled={isLoading}>
      Cancel
    </Button>
  );

  const renderSaveButton = () => (
    <Button key="save" type="primary" onClick={() => !isLoading && onSave(selectedVehicles)} disabled={isLoading}>
      {isLoading && <Spin indicator={LoadingIcon} />}
      Save ({selectedVehicles.length} selected)
    </Button>
  );

  const onSelect = (vehicle: Vehicle) => {
    setSelectedVehicles((state) => [...state, vehicle]);
  };

  const onDeselect = (vehicle: Vehicle) => {
    setSelectedVehicles(selectedVehicles.filter((selectedVehicle) => selectedVehicle.id !== vehicle.id));
  };

  const onSelectAll = () => {
    setSelectedVehicles(vehiclesAvailableToRoute);
  };

  const onDeselectAll = () => {
    setSelectedVehicles([]);
  };

  const canSelectAll = selectedVehicles.length < vehiclesAvailableToRoute.length;

  const canDeselectAll = selectedVehicles.length > 0;

  const selectToggle: SelectToggle = {
    onSelectAll,
    onDeselectAll,
    canSelectAll,
    canDeselectAll,
  };

  return (
    <VehicleModal
      selectedVehicleIDs={selectedVehicles.map((vehicle) => vehicle.id)}
      visible={visible}
      onClose={onClose}
      onSelect={onSelect}
      onDeselect={onDeselect}
      selectToggle={selectToggle}
      footer={[renderCancelButton(), renderSaveButton()]}
    />
  );
};
