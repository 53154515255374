import React from "react";

import { Container } from "./styles";

export const AnimatedBackground: React.FC = () => {
  return (
    <Container>
      <ul className="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </Container>
  );
};
