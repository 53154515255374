import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  min-width: 220px;
`;

export const Section = styled.div`
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const SectionTitle = styled.div`
  margin-bottom: 3px;
  color: ${COLORS.GREY_MID};
  font-size: 12px;
  font-weight: bold;
`;

export const SectionBody = styled.div`
  font-size: 14px;
`;

export const PopoverTitle = styled.span`
  padding: 10px;
  color: ${COLORS.GREY_DARKER};
  font-weight: 600;
  line-height: 17px;
`;
