import { useRecoilValue } from "recoil";
import { hiddenAppointmentMapState } from "recoil/appointments/atoms";
import { isConstructingModeState } from "recoil/sideBarControls/atoms";

import { useAppointments } from "hooks/api/appointments";
import { useActiveFlow } from "hooks/application/useActiveFlow";
import { useActiveRouteOverview } from "hooks/application/useActiveRouteOverview";

import { Appointment } from "lib/appointment";
import { Markers } from "lib/markers";
import { Polylines } from "lib/polylines";

import { ClusterMap } from "./useClusters";

import { AppointmentOrShipment } from "interface/appointment";
import { Map, Maps, Polyline } from "interface/map";

interface DrawPolylineProps {
  map: Map;
  maps: Maps;
  appointmentIDs: string[];
  routeID: string;
  clusterMap: ClusterMap;
  dashed?: boolean;
}

export const useDrawPolyline = () => {
  const { isIkea: isIkeaActiveFlow } = useActiveFlow();
  const { appointmentsMarkers, appointmentsLibrary } = useAppointments();
  const isConstructionMode = useRecoilValue(isConstructingModeState);
  const hiddenAppointmentsMap = useRecoilValue(hiddenAppointmentMapState);
  const { openRouteOverview } = useActiveRouteOverview();

  const getNoLocationAppointmentIDs = () => {
    const noLocationMarkerKey = Markers.getNoLocationMarkerKey();
    return noLocationMarkerKey ? appointmentsMarkers[noLocationMarkerKey] : [];
  };

  const getAppointmentsByIDs = (ids: string[]) => {
    return ids.map((id) => appointmentsLibrary[id]).filter(Boolean);
  };

  const getIsAppointmentVisible = (appointment: AppointmentOrShipment) => {
    const isInventoryPickUpOrReturnToSender =
      appointment.job_type === "inventory_pick_up" || appointment.job_type === "return_to_sender";
    const isIkeaWarehouseAppointment = isIkeaActiveFlow && isInventoryPickUpOrReturnToSender;
    if (isIkeaWarehouseAppointment) {
      return true;
    }

    return !hiddenAppointmentsMap[appointment.id];
  };

  return ({ map, maps, appointmentIDs, routeID, clusterMap, dashed = false }: DrawPolylineProps) => {
    const noLocationAppointmentIDs = getNoLocationAppointmentIDs();

    const appointments = getAppointmentsByIDs(appointmentIDs).filter(
      (appointment) => getIsAppointmentVisible(appointment) && !noLocationAppointmentIDs?.includes(appointment.id)
    );

    const positions = appointments.map((appt) => {
      let lat, lng;
      const { clusterID, coordinates } = clusterMap[appt.id] || {};

      if (clusterID) {
        lat = coordinates.lat;
        lng = coordinates.lng;
      } else {
        const p = Appointment.getPosition(appt);
        lat = p.lat;
        lng = p.lng;
      }

      return { lat, lng };
    });

    const intermediatePathsLines = getIntermediatePathsLines();
    intermediatePathsLines.forEach((line) => line.setMap(map));

    const bottomLinePath = getBottomLinePath();
    bottomLinePath.setMap(map);

    if (!isConstructionMode) {
      bottomLinePath.addListener("dblclick", (e: { stop: () => void }) => {
        e.stop();
        openRouteOverview(routeID);
      });
    }

    const destroyPolylines = () => {
      intermediatePathsLines.forEach((line) => line.setMap(null));
      bottomLinePath.setMap(null);
    };

    return { lines: intermediatePathsLines, bottomLine: bottomLinePath, destroyPolylines };

    function getIntermediatePathsLines() {
      const result: Polyline[] = [];

      for (let i = 0; i < positions.length - 1; i++) {
        const startPosition = positions[i];
        const endPosition = positions[i + 1];
        const isSameLocation = startPosition.lat === endPosition.lat && startPosition.lng === endPosition.lng;

        const path = Polylines.getPolylinePath([startPosition, endPosition], maps);

        const polylineOptions = Polylines.getPolylineOptions(routeID, maps, {
          isUnder: false,
          dashed,
          arrow: !isSameLocation,
        });

        const intermediatePathLine = new maps.Polyline({ path, ...polylineOptions });
        result.push(intermediatePathLine);
      }

      return result;
    }

    function getBottomLinePath() {
      const path = Polylines.getPolylinePath(positions, maps);
      const polylineOptions = Polylines.getPolylineOptions(`${routeID}-wide`, maps, { isUnder: true, dashed });
      return new maps.Polyline({ path, ...polylineOptions });
    }
  };
};
