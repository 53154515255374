import { useCallback } from "react";

import { useRecoilState, useResetRecoilState } from "recoil";
import { activeMarkerState } from "recoil/routeMapControls/atoms";
import { activeMarkerAppointmentsOverviewState } from "recoil/sideBarControls/atoms";

export const useClickMarkerInOverviewMode = () => {
  const [activeMarker, setActiveMarker] = useRecoilState(activeMarkerState);
  const resetActiveMarker = useResetRecoilState(activeMarkerState);
  const [activeMarkerAppointmentsOverview, setActiveMarkerAppointmentsOverview] = useRecoilState(
    activeMarkerAppointmentsOverviewState
  );
  const resetActiveMarkerAppointmentOverview = useResetRecoilState(activeMarkerAppointmentsOverviewState);

  const handleMarkerClickInOverviewMode = useCallback(
    (appointmentIDs: string[], markerKey: string) => {
      if (activeMarker.id !== markerKey) {
        setActiveMarker({
          id: markerKey,
          shouldCenter: true,
          selectedAppointmentId: appointmentIDs.length === 1 ? appointmentIDs[0] : "",
        });
      } else resetActiveMarker();

      if (activeMarkerAppointmentsOverview.visible) resetActiveMarkerAppointmentOverview();

      if (appointmentIDs.length !== 1)
        setActiveMarkerAppointmentsOverview({ visible: true, appointmentsIDs: appointmentIDs });
    },
    [
      activeMarker.id,
      resetActiveMarker,
      setActiveMarker,
      activeMarkerAppointmentsOverview.visible,
      resetActiveMarkerAppointmentOverview,
      setActiveMarkerAppointmentsOverview,
    ]
  );
  return handleMarkerClickInOverviewMode;
};
