import * as S from "./styles";

export const Legend = () => (
  <div>
    <S.Title>Legend</S.Title>

    <S.Content>
      <div style={{ color: "#00b176" }}>0% - 19%</div>
      <div>Low capacity</div>

      <div style={{ color: "#be52f2" }}>20% - 39%</div>
      <div>Low-mid capacity</div>

      <div style={{ color: "#0084f4" }}>40% - 69%</div>
      <div>Mid capacity</div>

      <div style={{ color: "#ff904e" }}>70% - 89%</div>
      <div>Mid-high capacity</div>

      <div style={{ color: "#ff3353" }}>90% - 100%</div>
      <div>High/full capacity</div>
    </S.Content>
  </div>
);
