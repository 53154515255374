import React, { useEffect } from "react";

import { notification } from "antd";

import * as S from "./styles";

import { useRecoilValue, useResetRecoilState } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";
import { createRouteState } from "recoil/routes/atoms";
import { activeRouteOverviewState } from "recoil/sideBarControls/atoms";

import { useAppointments } from "hooks/api/appointments";
import { useRoutes } from "hooks/api/routes";
import { useCreateRouteState } from "hooks/application/createRoute";
import { useActiveDate } from "hooks/application/useActiveDate";
import { useActiveFlow } from "hooks/application/useActiveFlow";
import { useActiveMarker } from "hooks/application/useActiveMarker";
import { useUrl } from "hooks/application/useUrl";

import { ActiveRouteAutomationJobIndicator } from "components/ActiveRouteAutomationJobIndicator";
import { FooterBar } from "components/FooterBar";
import { PageContainer } from "components/PageContainer";
import { DevTool, useDevtoolAuth } from "devtool";

import { InfoBox } from "./InfoBox";
import { MapLoadingIndicator } from "./MapLoadingIndicator";
import { RoutesMap } from "./RoutesMap";
import { SideBar } from "./SideBar";
import { TopFilterBar } from "./TopFilterBar";

export const RoutesPage: React.FC = () => {
  const activeServiceArea = useRecoilValue(activeServiceAreaState);
  const { activeDate } = useActiveDate();
  const { error: routesFetchingError } = useRoutes();
  const { error: appointmentsFetchingError } = useAppointments();
  const { activeFlowKey } = useActiveFlow();
  const { setUrlWithQueryValues } = useUrl();

  const isStaging =
    process.env.REACT_APP_API?.includes("staging") || process.env.REACT_APP_API?.includes("preprod") || false;
  const isDevToolEnabled = useDevtoolAuth() || isStaging;

  // eslint-disable-next-line
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setUrlWithQueryValues({
      date: activeDate,
      flowKey: activeFlowKey,
    });
  }, []);

  useEffect(() => {
    const message = routesFetchingError ? "get routes error" : "get appointments error";
    const description = routesFetchingError || appointmentsFetchingError;
    if (description) notification.error({ message, description });
  }, [routesFetchingError, appointmentsFetchingError]);

  const { resetState: resetCreateRouteState } = useCreateRouteState();
  const resetActiveRouteOverviewState = useResetRecoilState(activeRouteOverviewState);
  const { resetState: resetActiveMarkerState } = useActiveMarker();
  const { enabled: isCreateRouteEnabled } = useRecoilValue(createRouteState);

  useEffect(() => {
    resetCreateRouteState();
    resetActiveMarkerState();
    resetActiveRouteOverviewState();
  }, [activeFlowKey, activeDate, activeServiceArea]);

  useEffect(() => {
    if (isCreateRouteEnabled) resetActiveMarkerState();
  }, [isCreateRouteEnabled]);

  return (
    <PageContainer withHeader>
      <S.Container>
        <S.RouteMapWrap key={activeServiceArea}>
          <TopFilterBar />
          <RoutesMap />
          <InfoBox />
          <MapLoadingIndicator />
          <FooterBar />
          <ActiveRouteAutomationJobIndicator />
        </S.RouteMapWrap>

        <SideBar />
        <DevTool visible={isDevToolEnabled} />
      </S.Container>
    </PageContainer>
  );
};
