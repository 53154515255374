import { Button as AntdButton } from "antd";
import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TitleAndStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const Title = styled.div`
  font-size: 32px;
  font-weight: 600;
  margin-right: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Button = styled(AntdButton)`
  margin-left: 16px;
`;
