import styled from "styled-components";

export const WarningTextContainer = styled.div`
  margin-bottom: 16px;
`;

export const WarningText = styled.div`
  font-size: 14px;
`;

export const NoWrap = styled.div`
  white-space: nowrap;
`;

export const ViewDetailsLink = styled(NoWrap)`
  text-align: right;
`;
