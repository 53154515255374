import { useCallback } from "react";

import { useRecoilState } from "recoil";
import { hoverAppointmentsState } from "recoil/routeMapControls/atoms";

export const useHoverMarker = () => {
  const [hoveredAppointments, setHoveredAppointments] = useRecoilState(hoverAppointmentsState);

  const hoverMarkers = useCallback(
    (appointmentsIDs: string[]) => {
      setHoveredAppointments(appointmentsIDs);
    },
    [setHoveredAppointments]
  );

  const unhoverMarker = useCallback(() => {
    setHoveredAppointments(null);
  }, [setHoveredAppointments]);

  const getIsMarkerHovered = (markerAppointmentsIDs: string[]) => {
    if (!hoveredAppointments) return false;

    return hoveredAppointments.some((appt) => markerAppointmentsIDs.includes(appt));
  };

  return { hoverMarkers, unhoverMarker, getIsMarkerHovered };
};
