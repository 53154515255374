import * as S from "./styles";

import { AppointmentPreloader } from "./AppointmentPreloader";
import { RouteHeaderPreloader } from "./RouteHeaderPreloader";

export const Preloader = () => {
  return (
    <S.Container>
      <RouteHeaderPreloader />

      <AppointmentPreloader />
      <AppointmentPreloader />
      <AppointmentPreloader />
      <AppointmentPreloader />

      <RouteHeaderPreloader />
      <RouteHeaderPreloader />
      <RouteHeaderPreloader />
    </S.Container>
  );
};
