import React, { useEffect, useMemo, useState } from "react";

import { TreeSelect } from "antd";
import { uniq } from "lodash";

import * as S from "../styles";

import { useRecoilState } from "recoil";
import { activeOrganizationsState } from "recoil/topFilterControls/atoms";

import { Appointment } from "lib/appointment";

import { getSelectPlaceHolder, getAppointmentOrganizationID, ALL_ORGANIZATIONS_KEY } from "../helpers";

import { useToggleOptions, useGetFilterOptions } from "./hooks";

import { AppointmentOrShipment } from "interface/appointment";

interface OrganizationFilterProps {
  appointments: AppointmentOrShipment[];
}

export const OrganizationFilter: React.FC<OrganizationFilterProps> = ({ appointments }) => {
  const [activeOrganizations, dispatchActiveOrganizations] = useRecoilState(activeOrganizationsState);

  const [searchKey, setSearchKey] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const organizationIDs = useMemo(() => {
    const activeAppointments = appointments.filter((appt) => !Appointment.isCancelled(appt));
    return activeAppointments.map((apt) => getAppointmentOrganizationID(apt));
  }, [appointments]);

  const getFilterOptions = useGetFilterOptions(organizationIDs, appointments);

  const { onSelect, onDeselect } = useToggleOptions();

  useEffect(() => {
    const activeOrgs = uniq([...organizationIDs, ALL_ORGANIZATIONS_KEY]);
    dispatchActiveOrganizations(activeOrgs);
  }, [dispatchActiveOrganizations, organizationIDs]);

  const filterOptions = getFilterOptions(searchKey);

  const handleOnSelect = (selectedOrganization: string) => {
    onSelect(selectedOrganization, filterOptions);
  };

  const handleOnDeselect = (deselectedOrganization: string) => {
    onDeselect(deselectedOrganization, filterOptions);
  };

  const renderPlaceHolder = () => {
    if (isDropdownOpen && searchKey !== "") return undefined;
    return (
      <S.SelectedText>
        {!!filterOptions &&
          getSelectPlaceHolder([filterOptions.length], activeOrganizations, "Organizations", filterOptions, null)}
      </S.SelectedText>
    );
  };

  return (
    <S.FilterContainer>
      <TreeSelect
        treeCheckable
        placeholder="None Selected"
        treeData={filterOptions}
        value={activeOrganizations}
        onSelect={handleOnSelect}
        onDeselect={handleOnDeselect}
        onSearch={setSearchKey}
        onDropdownVisibleChange={setIsDropdownOpen}
        maxTagCount={0}
        maxTagPlaceholder={() => renderPlaceHolder()}
        showArrow
        showSearch
        style={{ width: 170 }}
        dropdownStyle={{ minWidth: "fit-content", maxHeight: 400, overflowY: "auto" }}
        filterTreeNode={false}
        autoClearSearchValue={false}
      />
    </S.FilterContainer>
  );
};
