import { Fulfillment } from "@secondcloset/types";

import { BookingAppointmentsState } from "./atoms";

type Appointment = Fulfillment.Appointment;

export const getFilteredAppointmentsByWarehouse = (
  bookingAppointments: BookingAppointmentsState,
  bookingPickupDeliveryPair: {
    [appointmentID: string]: string;
  },
  warehouseId: string
): Appointment[] => {
  return (
    Object.values(bookingAppointments).filter((appointment: Appointment) => {
      if (
        appointment.status === "confirmed" &&
        (appointment.job_type === "cross_dock_delivery" || appointment.job_type === "delivery")
      ) {
        const matchingAppointmentId = bookingPickupDeliveryPair[appointment.id];
        // The warehouse that a cross dock delivery appointment came from is on the corresponding inventory pick up appointment
        // Find corresponding IPU
        const matchingAppointment = bookingAppointments[matchingAppointmentId];
        // If there isn't a matching appointment, then it's a warehouse receiving appointment which should show
        if (matchingAppointment === undefined) return true;
        // If there's a matching IPU, filter by the active warehouse
        if (matchingAppointment.job_type === "inventory_pick_up") {
          return warehouseId === matchingAppointment.location.id;
        }
      }
      // Filter out any appointments that aren't deliveries
      return false;
    }) || []
  );
};
