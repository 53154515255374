import React from "react";

import { startCase } from "lodash";

import * as S from "./styles";

const getColor = (status: string, inUse: boolean) => {
  if (status === "in_service") {
    return inUse ? "blue" : "green";
  }

  if (status === "temporarily_out_of_service") return "orange";
  if (status === "deactivated") return "red";
};

const getText = (status: string, inUse: boolean) => {
  if (status === "in_service") {
    return inUse ? "In Use" : "Available";
  }
  return startCase(status);
};

interface StatusTagProps {
  status: string;
  inUse?: boolean;
  style?: React.CSSProperties;
}

export const StatusTag: React.FC<StatusTagProps> = ({ status, inUse = false, style }) => (
  <S.Container color={getColor(status, inUse)} style={style}>
    {getText(status, inUse)}
  </S.Container>
);
