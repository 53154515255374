import { Fulfillment } from "@secondcloset/types";

import { Appointment as AppointmentLib } from "lib/appointment";

import { AppointmentOrShipment } from "interface/appointment";

type Location = Fulfillment.Location;
type Appointment = Fulfillment.Appointment;

export const getIkeaMatchingAppointmentPair = (
  appointment: Fulfillment.Appointment,
  pairTypes: [string, string],
  appointments: Fulfillment.Appointment[]
): AppointmentOrShipment | null => {
  const isAppointmentCancelled = appointment?.status === "cancelled";
  if (isAppointmentCancelled) return null;
  const canPair = pairTypes.includes(appointment?.job_type);
  if (!canPair) return null;
  const orderID = appointment?.source?.id;
  if (!orderID) return null;
  const isToBeMatchedType = appointment?.job_type === pairTypes[0];
  const matchingType = isToBeMatchedType ? pairTypes[1] : pairTypes[0];
  const matchingAppointment = appointments.find((a) => {
    const isSameOrder = a?.source?.id === orderID;
    const isMatchingType = a?.job_type === matchingType;
    const isNotCancelled = a?.status !== "cancelled";
    return isSameOrder && isMatchingType && isNotCancelled;
  });
  return matchingAppointment || null;
};

export const getIkeaWarehouseList = (appointments: Appointment[]): Location[] => {
  if (!appointments || appointments.length === 0) return [];
  return appointments.reduce((acc: Location[], appointment: Appointment) => {
    const isInventoryPickup = AppointmentLib.isInventoryPickup(appointment);
    const isReturnToSender = AppointmentLib.isReturnToSender(appointment);

    if (!isInventoryPickup && !isReturnToSender) {
      return acc;
    }
    const newLocation = appointment?.location;
    if (!newLocation?.id) return acc;
    const isExist = !!acc.find((location) => location.id === newLocation?.id);
    if (!isExist) acc.push(newLocation);
    return acc;
  }, []);
};
