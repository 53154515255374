import { useMemo } from "react";

import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { activeMarkerState } from "recoil/routeMapControls/atoms";
import { activeMarkerAppointmentsOverviewState } from "recoil/sideBarControls/atoms";

import { useAppointments } from "hooks/api/appointments";

export const useActiveMarker = () => {
  const { appointmentsLibrary, appointmentsMarkers } = useAppointments();

  const [activeMarker, setActiveMarker] = useRecoilState(activeMarkerState);
  const activeMarkerOverview = useRecoilValue(activeMarkerAppointmentsOverviewState);

  const activeMarkerAppointments = useMemo(() => {
    if (!activeMarker?.id) return [];
    if (!appointmentsMarkers[activeMarker.id]) return [];
    return appointmentsMarkers[activeMarker.id].map((appointmentID) => appointmentsLibrary[appointmentID]);
  }, [activeMarker.id, appointmentsMarkers, appointmentsLibrary]);

  const resetActiveMarker = useResetRecoilState(activeMarkerState);
  const resetActiveMarkerAppointmentsOverviewState = useResetRecoilState(activeMarkerAppointmentsOverviewState);

  const resetState = () => {
    resetActiveMarker();
    resetActiveMarkerAppointmentsOverviewState();
  };

  const getMarkerKeyByAppointmentID = (appointmentID: string) => {
    const result = Object.entries(appointmentsMarkers).find(([, appointmentsIDs]) =>
      appointmentsIDs.includes(appointmentID)
    );
    return result?.[0];
  };

  const getIsAppointmentActive = (appointmentID: string) => {
    return !!activeMarkerAppointments.find((appointment) => appointment.id === appointmentID);
  };

  return {
    activeMarker,
    setActiveMarker,
    activeMarkerOverview,
    activeMarkerAppointments,
    resetState,
    getMarkerKeyByAppointmentID,
    getIsAppointmentActive,
  };
};
