import * as S from "./styles";
import { COLORS } from "styles";

import { Card } from "./Card";
import { CardPreloader } from "./CardPreloader";

import { Statistics } from "interface/vehicle";

interface VehiclesStatisticsProps {
  statistics?: Statistics;
  isLoading?: boolean;
  className?: string;
}

export const VehiclesStatistics: React.FC<VehiclesStatisticsProps> = ({ statistics, isLoading = false, className }) => {
  if (isLoading)
    return (
      <S.Container className={className}>
        <CardPreloader />
        <CardPreloader />
        <CardPreloader />
      </S.Container>
    );

  if (!statistics) return null;

  return (
    <S.Container className={className}>
      <Card
        title={`${statistics.in_use} Vehicles`}
        titleColor={COLORS.PRIMARY_GREEN}
        description={`In Use (Utilizing ${statistics.utilizing}%)`}
      />
      <Card title={`${statistics.available} Vehicles`} description="Total Available" />
      <Card title={`${statistics.temporarily_out_of_service} Vehicles`} description="Temporarily Out Of Service" />
    </S.Container>
  );
};
