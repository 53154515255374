import React from "react";

import * as S from "./styles";

import { Tab } from "./Tab";

export interface TabItem {
  key: string;
  title: string;
  render?: () => React.ReactNode;
}

interface TabsProps {
  tabs: TabItem[];
  selectedTab?: string;
  onChange: (tab: TabItem) => void;
}

export const Tabs: React.FC<TabsProps> = ({ tabs, selectedTab, onChange }) => {
  return (
    <S.Container>
      {tabs.map((tab) => {
        const { key, title, render } = tab;
        return (
          <Tab key={key} active={key === selectedTab} onClick={() => onChange(tab)}>
            {render ? render() : title}
          </Tab>
        );
      })}
    </S.Container>
  );
};
