import styled, { css } from "styled-components";

import { COLORS } from "styles";

import { AppointmentCard as AppointmentCardBase, AppointmentHoverShadow } from "../AppointmentCard";

export const DraggableListContainer = styled.div``;

export const DraggableAppointmentContainer = styled.div``;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px;
  border: 1px solid #d9d9d9;
  color: ${COLORS.PRIMARY_BLUE};
  font-size: 16px;
  font-weight: 500;
`;

export const AppointmentsList = styled.div`
  flex: 1;
  overflow-y: auto;
`;

interface AppointmentCardProps {
  hovered?: boolean;
  selected?: boolean;
}
export const AppointmentCard = styled(AppointmentCardBase)<AppointmentCardProps>`
  ${(props) => props.hovered && AppointmentHoverShadow};

  ${(props) =>
    props.selected &&
    css`
      background-color: ${COLORS.PRIMARY_LIGHT};
    `}
`;
