import React from "react";

import { Select } from "antd";
import { concat } from "lodash";

import { useRecoilState, useRecoilValue } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";
import { isConstructingModeState } from "recoil/sideBarControls/atoms";

import { useServiceAreas } from "hooks/api/service_areas";

import * as Cookie from "lib/cookie";

import { useIsDisabledForCertainPages } from "./useIsDisabledForCertainPages";

interface Props {
  style?: React.CSSProperties;
}

export const ServiceAreaSelector: React.FC<Props> = ({ style }) => {
  const [activeServiceArea, setActiveServiceArea] = useRecoilState(activeServiceAreaState);
  const isConstructingMode = useRecoilValue(isConstructingModeState);
  const { getServiceAreaOptions } = useServiceAreas();
  const serviceAreas = getServiceAreaOptions();
  const isDisabledForCertainPages = useIsDisabledForCertainPages();

  const options = serviceAreas?.map((serviceArea) => {
    return (
      <Select.Option value={serviceArea.name} key={serviceArea.name}>
        {concat(`${serviceArea.label}`, ` (${serviceArea.name})`)}
      </Select.Option>
    );
  });

  return (
    <Select
      style={{ ...style, width: 150 }}
      disabled={isConstructingMode || isDisabledForCertainPages}
      value={activeServiceArea || undefined}
      onChange={(serviceArea) => {
        setActiveServiceArea(serviceArea);
        const appSettings = Cookie.getAppSettings();
        const newAppSettings = appSettings
          ? { ...appSettings, serviceArea: serviceArea }
          : { serviceArea: serviceArea };
        Cookie.setAppSettings(newAppSettings);
      }}
    >
      {options}
    </Select>
  );
};
