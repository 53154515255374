import { useState } from "react";

import { random } from "lodash";
import styled from "styled-components";

import { pulseAnimation } from "styles/animations";

import { Container as CardContainer, Title as CardTitle, Description as CardDescription } from "./Card";

const Container = styled(CardContainer)`
  background-color: rgba(209, 213, 219, 0.8);
  ${pulseAnimation};
`;

const Title = styled(CardTitle)`
  border-radius: 4px;
  background-color: rgba(156, 163, 175, 0.5);
  ${pulseAnimation};
`;

const Description = styled(CardDescription)`
  border-radius: 4px;
  background-color: rgba(156, 163, 175, 0.5);
  ${pulseAnimation};
`;

export const CardPreloader = () => {
  const [titleWidth] = useState(random(50, 80));
  const [descriptionWidth] = useState(random(50, 80));

  return (
    <Container>
      <Title style={{ width: `${titleWidth}%` }}>&nbsp;</Title>
      <Description style={{ width: `${descriptionWidth}%` }}>&nbsp;</Description>
    </Container>
  );
};
