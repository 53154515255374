import React from "react";

import { TreeSelect } from "antd";

import * as S from "./styles";

interface TimeslotSelectorProps {
  timeslots: string[];
  selectedTimeslots: string[];
  onChange: (timeslots: string[]) => void;
}

export const TimeslotSelector: React.FC<TimeslotSelectorProps> = ({ timeslots, selectedTimeslots, onChange }) => {
  const data = timeslots.map((timeslot) => ({
    key: timeslot,
    value: timeslot,
    label: timeslot,
  }));

  const getPlaceholderText = () => {
    if (selectedTimeslots.length === 1) {
      return selectedTimeslots[0];
    }

    if (selectedTimeslots.length === timeslots.length) {
      return "All Timeslots";
    }

    return "Multiple";
  };

  return (
    <S.Container>
      <TreeSelect
        treeData={data}
        value={selectedTimeslots}
        treeCheckable
        showArrow
        style={{ width: 144 }}
        onChange={onChange}
        showSearch={false}
        maxTagCount={0}
        maxTagPlaceholder={() => <S.PlaceholderText>{getPlaceholderText()}</S.PlaceholderText>}
      />
    </S.Container>
  );
};
