import React, { useEffect, useMemo, useRef } from "react";

import { Appointment } from "@secondcloset/types/src/fulfillment";

import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import moment from "moment";

import * as S from "./styles";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  bookingAppointmentsState,
  bookingSelectedAppointmentIdsState,
  bookingSelectedDateState,
} from "recoil/booking/atoms";

import { useWarehouses } from "hooks/api/warehouses";

import { Dates } from "lib/dates";

import { ScrollWrapper } from "./ScrollWrapper";

export const DateSelector = () => {
  const { warehouseFilteredAppointments } = useWarehouses();
  const bookingAppointments = useRecoilValue(bookingAppointmentsState);
  const bookingSelectedAppointmentIds = useRecoilValue(bookingSelectedAppointmentIdsState);
  const [bookingSelectedDate, setBookingSelectedDateState] = useRecoilState(bookingSelectedDateState);
  const scrollWrapperRef = useRef<HTMLDivElement | null>(null);

  const getSelectedAppointmentsDateRange = () => {
    const hasSelectedAppointments = bookingSelectedAppointmentIds.length !== 0;
    const appointments = hasSelectedAppointments
      ? Object.values(bookingAppointments).filter((appointment) => {
          return (
            ["cross_dock_delivery", "delivery"].includes(appointment.job_type) &&
            bookingSelectedAppointmentIds.includes(appointment.id)
          );
        })
      : warehouseFilteredAppointments || [];

    // If there are more than 1 appointments selected, check if any of the dates overlap
    if (hasSelectedAppointments && appointments.length > 1) {
      const dateRanges = appointments.map((appointment: Appointment) => ({
        dateFrom: appointment.range_of_days_start_date as string,
        dateTo: appointment.range_of_days_end_date as string,
      }));
      const didDateRangesOverlap = Dates.multipleDateRangeOverlaps(dateRanges);
      if (!didDateRangesOverlap) {
        return {
          dateFrom: null,
          dateTo: null,
        };
      }
    }

    // If there are overlapping dates, return the overlapping date window
    return appointments.reduce(
      (
        acc: {
          dateFrom: string | null;
          dateTo: string | null;
        },
        appointment: Appointment
      ) => {
        if (!acc.dateFrom) {
          acc.dateFrom = moment(appointment.range_of_days_start_date).format("YYYY-MM-DD");
        }
        if (!acc.dateTo) {
          acc.dateTo = moment(appointment.range_of_days_end_date).format("YYYY-MM-DD");
        }
        const isDateFromLessRangeOfDays = bookingSelectedAppointmentIds.length
          ? moment(acc.dateFrom).utc() < moment(appointment.range_of_days_start_date).utc()
          : moment(acc.dateFrom).utc() > moment(appointment.range_of_days_start_date).utc();
        const isDateToGreaterRangeOfDays = bookingSelectedAppointmentIds.length
          ? moment(acc.dateTo).utc() > moment(appointment.range_of_days_end_date).utc()
          : moment(acc.dateTo).utc() < moment(appointment.range_of_days_end_date).utc();
        if (isDateFromLessRangeOfDays) {
          acc.dateFrom = moment(appointment.range_of_days_start_date).format("YYYY-MM-DD");
        }
        if (isDateToGreaterRangeOfDays) {
          acc.dateTo = moment(appointment.range_of_days_end_date).format("YYYY-MM-DD");
        }
        return acc;
      },
      {
        dateFrom: null,
        dateTo: null,
      }
    );
  };

  const dates = useMemo(() => {
    const { dateFrom, dateTo } = getSelectedAppointmentsDateRange();
    const differenceInDays = moment(dateTo).diff(dateFrom, "days");
    const dates = [moment(dateFrom).format("YYYY-MM-DD")];
    for (let i = 0; i < differenceInDays; i++) {
      dates.push(
        moment(dateFrom)
          .add(i + 1, "day")
          .format("YYYY-MM-DD")
      );
    }
    return dates;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingAppointments, bookingSelectedAppointmentIds]);

  useEffect(() => {
    if (dates[0] !== "Invalid date" && !dates.includes(bookingSelectedDate || "")) {
      setBookingSelectedDateState(moment(dates[0]).format("YYYY-MM-DD"));
    }
    // eslint-disable-next-line
  }, [dates]);

  const handleOnArrowPress = (direction: "back" | "forward") => {
    const comparisonDate = direction === "back" ? dates[0] : dates[dates.length - 1];
    if (bookingSelectedDate === comparisonDate) return;
    const currentIndex = dates.findIndex((date) => date === bookingSelectedDate);
    const nextIndex = direction === "back" ? currentIndex - 1 : currentIndex + 1;
    setBookingSelectedDateState(dates[nextIndex]);
    if (scrollWrapperRef?.current?.scrollLeft !== undefined) {
      const offsetWidth = (document.getElementById("booking-date-button-0")?.offsetWidth || 120) - 20;
      const newScrollPosition =
        direction === "back"
          ? (scrollWrapperRef.current.scrollLeft || 0) - offsetWidth
          : (scrollWrapperRef.current.scrollLeft || 0) + offsetWidth;
      scrollWrapperRef.current.scrollLeft = newScrollPosition;
    }
  };

  const scrollIntoView = (index: number) => {
    const element = document.getElementById(`booking-date-button-${index}`);
    if (element && scrollWrapperRef?.current?.scrollLeft !== undefined) {
      const scrollLeft = scrollWrapperRef.current.scrollLeft;
      const boundingClientRect = scrollWrapperRef.current.getBoundingClientRect();
      if (
        element.offsetLeft < // position of left edge of button
        boundingClientRect.left + scrollLeft // position of left edge of scroll wrapper
      ) {
        const offsetWidth = (element.offsetWidth || 120) + 20;
        const newScrollPosition = (scrollWrapperRef.current.scrollLeft || 0) - offsetWidth;

        scrollWrapperRef.current.scrollLeft = newScrollPosition;
      }

      if (
        element.offsetLeft + element.offsetWidth > // position of right edge of button
        boundingClientRect.left + boundingClientRect.width + scrollWrapperRef.current.scrollLeft // position of right edge of scroll wrapper
      ) {
        const offsetWidth = (element.offsetWidth || 120) + 20;
        const newScrollPosition = (scrollWrapperRef.current.scrollLeft || 0) + offsetWidth;
        scrollWrapperRef.current.scrollLeft = newScrollPosition;
      }
    }
  };

  return (
    <S.Wrapper>
      <S.IconButton
        disabled={bookingSelectedDate === dates[0] || dates[0] === "Invalid date"}
        onClick={() => {
          if (bookingSelectedDate === dates[0] || dates[0] === "Invalid date") {
            return;
          }
          handleOnArrowPress("back");
        }}
      >
        <LeftOutlined />
      </S.IconButton>

      <ScrollWrapper ref={scrollWrapperRef}>
        {dates.map((date, index) => {
          const isDisabled = moment(date).format("MMM DD, YYYY") === "Invalid date";
          return (
            <S.ButtonWrapper active={date === bookingSelectedDate} key={date} disabled={isDisabled}>
              <Button
                id={`booking-date-button-${index}`}
                onClick={() => {
                  setBookingSelectedDateState(date);
                  scrollIntoView(index);
                }}
                disabled={isDisabled}
              >
                {isDisabled ? "No overlapping dates" : moment(date).format("MMM DD, YYYY")}
              </Button>
            </S.ButtonWrapper>
          );
        })}
      </ScrollWrapper>

      <S.IconButton
        disabled={bookingSelectedDate === dates[dates.length - 1] || dates[0] === "Invalid date"}
        onClick={() => {
          if (bookingSelectedDate === dates[dates.length - 1] || dates[0] === "Invalid date") {
            return;
          }
          handleOnArrowPress("forward");
        }}
      >
        <RightOutlined />
      </S.IconButton>
    </S.Wrapper>
  );
};
