import React, { useEffect } from "react";

import { AppointmentType } from "@secondcloset/types/src/fulfillment";

import { uniq, difference } from "lodash";

import * as S from "./styles";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { createRouteState } from "recoil/routes/atoms";
import { isIkeaOutboundState } from "recoil/sideBarControls/atoms";
import { activeAppointmentTypesState, selectedIkeaAppointmentsFilterValuesState } from "recoil/topFilterControls/atoms";

import { useActiveFlow } from "hooks/application/useActiveFlow";
import { useIKEAInOutboundToggle } from "hooks/application/useToggleIKEAInOutbound";

import { SelectedText } from "../../styles";

interface IkeaAppointmentDropdownProps {
  numberOfCDD: number;
  numberOfDelivery: number;
  numberOfReverseLogistics: number;
}

export const IkeaAppointmentDropdown: React.FC<IkeaAppointmentDropdownProps> = ({
  numberOfCDD,
  numberOfDelivery,
  numberOfReverseLogistics,
}) => {
  const { isIkea } = useActiveFlow();
  const [isIkeaOutbound, dispatchIsIkeaOutbound] = useRecoilState(isIkeaOutboundState);
  const dispatchActiveAppointmentTypes = useSetRecoilState(activeAppointmentTypesState);
  const selectedIkeaAppointmentsFilterValues = useRecoilValue(selectedIkeaAppointmentsFilterValuesState);
  const { enabled: isCreateRouteViewEnabled } = useRecoilValue(createRouteState);
  const handleIkeaInOutToggle = useIKEAInOutboundToggle();

  const IKEA_APPOINTMENTS = [
    { id: "outbound", pId: null, value: "outbound", title: "Outbound" },
    { id: "delivery", pId: "outbound", value: "delivery", title: `Delivery (${numberOfDelivery})` },
    {
      id: "cross_dock_delivery",
      pId: "outbound",
      value: "cross_dock_delivery",
      title: `Cross Dock Delivery (${numberOfCDD})`,
    },
    { id: "inbound", pId: null, value: "inbound", title: "Inbound" },
    {
      id: "reverse_logistics",
      pId: "inbound",
      value: "reverse_logistics",
      title: `Reverse Logistics (${numberOfReverseLogistics})`,
    },
  ];

  useEffect(() => {
    dispatchActiveAppointmentTypes(selectedIkeaAppointmentsFilterValues as AppointmentType[]);
    dispatchIsIkeaOutbound(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIkea]);

  const renderSelectedPlaceholder = () => {
    let label = "Inbound";
    if (isIkeaOutbound) label = "Outbound";
    return <SelectedText>{label}</SelectedText>;
  };

  const getNodeByValue = (value: string) => {
    const node = IKEA_APPOINTMENTS.find((node) => node.value === value);
    return node;
  };

  const getChildNodeValues = (parentID: string) => {
    const children = IKEA_APPOINTMENTS.filter((n) => n.pId === parentID);
    return children.map((c) => c.value);
  };

  const findParentNode = (value: string) => {
    const node = getNodeByValue(value);
    const parentNode = IKEA_APPOINTMENTS.find(({ id }) => id === node?.pId);
    return parentNode;
  };

  const onSelect = (value: string) => {
    let newSelectedValues = [] as string[];

    const previouslySelectedValues = selectedIkeaAppointmentsFilterValues;
    if (previouslySelectedValues) {
      newSelectedValues = [...previouslySelectedValues];
    }

    newSelectedValues.push(value);

    const node = getNodeByValue(value);
    if (!node) return;

    const isParentNode = node.pId === null;
    if (isParentNode) {
      newSelectedValues = [...newSelectedValues, ...getChildNodeValues(node.id)];
    } else {
      const parentNode = findParentNode(node.value);
      if (parentNode) {
        newSelectedValues = [...newSelectedValues, parentNode.value];
      }
    }

    const parentNode = isParentNode ? node : findParentNode(value);
    if (parentNode) {
      const branchNodes = [parentNode.value, ...getChildNodeValues(parentNode.id)];

      const restNodes = difference(
        IKEA_APPOINTMENTS.map((a) => a.value),
        branchNodes
      );
      newSelectedValues = difference(newSelectedValues, restNodes);
    }

    handleIkeaInOutToggle(uniq(newSelectedValues));
  };

  const onDeselect = (value: string) => {
    const previouslySelectedValues = selectedIkeaAppointmentsFilterValues;
    let newSelectedValues = [...previouslySelectedValues] as string[];

    const node = getNodeByValue(value);
    if (!node) return;

    const isParentNode = node.pId === null;
    if (isParentNode) {
      newSelectedValues = difference(newSelectedValues, getChildNodeValues(node.id));
    }

    newSelectedValues = difference(newSelectedValues, [value]);
    handleIkeaInOutToggle(newSelectedValues);
  };

  return (
    <S.IKEAAppointmentFilter
      treeCheckable
      treeCheckStrictly
      treeExpandedKeys={selectedIkeaAppointmentsFilterValues}
      treeData={IKEA_APPOINTMENTS}
      treeDataSimpleMode
      value={selectedIkeaAppointmentsFilterValues}
      onSelect={(value) => onSelect(value as string)}
      onDeselect={(value) => onDeselect(value as string)}
      style={{ width: 142 }}
      dropdownStyle={{ minWidth: 244 }}
      placeholder="No Appointments Selected"
      showArrow
      showSearch={false}
      maxTagCount={0}
      maxTagPlaceholder={() => renderSelectedPlaceholder()}
      disabled={isCreateRouteViewEnabled}
    />
  );
};
