import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";

import { Fulfillment } from "@secondcloset/types";

import { isEmpty, startCase } from "lodash";

import * as S from "./styles";

import { useTasks } from "hooks/api/appointments";
import { useNotes } from "hooks/api/appointments/useNotes";

import { Appointment as AppointmentLib } from "lib/appointment";

import { AppointmentOrShipment } from "interface/appointment";

interface NotesAndTasksProps {
  appointment?: AppointmentOrShipment | null;
}

export const NotesAndTasks: React.FC<NotesAndTasksProps> = ({ appointment }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { notes, isNotesLoading, refetchNotes } = useNotes(appointment);
  const { tasks, isTasksLoading, refetchTasks } = useTasks(appointment);

  const isFulfillmentAppointment = AppointmentLib.isFulfillmentAppointment(appointment);

  useEffect(() => {
    setIsExpanded(false);
  }, [appointment]);

  const onNotesTaskButtonClick = () => {
    if (!isExpanded && !isFulfillmentAppointment && (!notes || !tasks)) {
      refetchNotes();
      refetchTasks();
    }

    setIsExpanded((isExpanded) => !isExpanded);
  };

  const renderListSection = (title: string, data: string[] = []) => {
    return (
      <S.InfoSection>
        <label>{startCase(title)}</label>
        {isEmpty(data) || data.every((value) => isEmpty(value)) ? (
          <div className="text">-</div>
        ) : (
          data.map((text, index) => (
            <div className="text" key={index}>
              {text}
            </div>
          ))
        )}
      </S.InfoSection>
    );
  };

  const renderNotesAndTasks = (isFulfillment: boolean) => {
    const shipmentNotes = (appointment as Fulfillment.Appointment)?.shipment?.notes;
    if (isFulfillment) {
      const fulfillmentNotes = [AppointmentLib.getFulfillmentNotes(appointment)] || [];

      const shipmentNoteTexts = AppointmentLib.getAppointmentNoteTexts(shipmentNotes || []) || [];

      return (
        <S.NoteTaskContent>
          {renderListSection("notes", fulfillmentNotes)}
          {renderListSection("shipment notes", shipmentNoteTexts)}
        </S.NoteTaskContent>
      );
    }

    if (isNotesLoading || isTasksLoading) return <ClipLoader />;

    const taskTexts = AppointmentLib.getAppointmentTaskTexts(tasks) || [];
    const orderNoteTexts = AppointmentLib.getAppointmentNoteTexts(notes) || [];

    return (
      <S.NoteTaskContent>
        {renderListSection("notes", orderNoteTexts)}
        {renderListSection("tasks", taskTexts)}
      </S.NoteTaskContent>
    );
  };

  return (
    <S.Container>
      <S.ExpandedSection expanded={isExpanded}>{renderNotesAndTasks(isFulfillmentAppointment)}</S.ExpandedSection>
      <S.ExpandedButton onClick={onNotesTaskButtonClick} role="button">
        <div className="text">Notes and Tasks</div>
      </S.ExpandedButton>
    </S.Container>
  );
};
