type FirstLastName = { first_name: string; last_name: string };
type RoleSubrole = { subrole: string; role: string };

export const isAdmin = <T extends RoleSubrole | null>(user?: T): boolean => {
  return user?.role === "is_admin";
};

export const isSuper = <T extends RoleSubrole | null>(user?: T): boolean => {
  if (!user) return false;
  const subrole = user?.subrole || "";
  const superRoles = ["super", "god"];
  return isAdmin(user) && superRoles.includes(subrole);
};

export const getInitials = <T extends FirstLastName | null>(user?: T): string => {
  if (!user) return "";
  const firstName = user?.first_name || "";
  const lastName = user?.last_name || "";
  return `${firstName[0]}${lastName[0]}`.toUpperCase();
};

export const getUserName = <T extends FirstLastName | null>(user?: T): string => {
  if (!user) return "";
  const firstName = user?.first_name || "";
  const lastName = user?.last_name || "";
  return `${firstName} ${lastName}`;
};
