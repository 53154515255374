import { serialize } from "object-to-formdata";

import { API } from "lib/API";

import { Vehicle, Photo as VehiclePhoto } from "interface/vehicle";

export interface UpdateVehicleData {
  id: string;
  service_area_id: number;
  name: string;
  license_plate_number: string;
  vin: string;
  vehicle_size_id: number;
  make?: string;
  model?: string;
  ownership_type_id: number;
  fuel_type_id: number;
  notes?: string;
  photo?: File | null;
  current_photo?: VehiclePhoto;
  status?: string;
}

const getPhotoAttributes = (data: UpdateVehicleData) => {
  const { photo, current_photo } = data;

  if (photo) {
    return {
      photo_attributes: { file: photo },
    };
  }

  if (!current_photo) {
    return {};
  } else {
    return photo === null ? { photo_attributes: { id: current_photo.id, _destroy: true } } : {};
  }
};

const getVehicleRequestData = (data: UpdateVehicleData) => {
  const result = {
    ...data,
    ...getPhotoAttributes(data),
  };

  delete result.photo;
  delete result.current_photo;

  return result;
};

export const updateVehicle = async (data: UpdateVehicleData) => {
  const api = new API();

  const formData = serialize({ vehicle: getVehicleRequestData(data) });

  const response = await api.put(`/vehicles/${data.id}`, formData as unknown as Record<string, unknown>);
  return response.data as Vehicle;
};
