import { useLocation, matchPath } from "react-router-dom";

import * as S from "./styles";

import { Tabs, TabItem } from "components/Tabs";

import { useLogout } from "./useLogout";

export type TabNavigationItem = {
  key: string;
  name: string;
  path: string;
  url?: string;
};

interface NavigationTabsProps {
  items: TabNavigationItem[];
  onTabClick: (tabItem: TabNavigationItem) => void;
}

export const NavigationTabs: React.FC<NavigationTabsProps> = ({ items, onTabClick }) => {
  const location = useLocation();
  const logout = useLogout();

  const handleTabChange = (tab: TabItem) => {
    const selectedItem = items.find((item) => item.key === tab.key);
    if (selectedItem) {
      onTabClick(selectedItem);
    }
  };

  const getTabs = () => {
    const tabs = items.map((route) => ({ key: route.key, title: route.name })) as TabItem[];
    tabs.push({
      key: "logout",
      title: "logout",
      // eslint-disable-next-line react/display-name
      render: () => <S.LogoutButton onClick={logout}>Logout</S.LogoutButton>,
    });

    return tabs;
  };

  const getSelectedTab = () => {
    const selectedItem = items.find(({ path }) => matchPath(location.pathname, { path }));
    return tabs.find((tab) => tab.key === selectedItem?.key);
  };

  const tabs = getTabs();
  const selectedTab = getSelectedTab();

  return <Tabs tabs={tabs} selectedTab={selectedTab?.key} onChange={handleTabChange} />;
};
