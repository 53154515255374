import { useEffect, useState } from "react";

import { Common, Storage } from "@secondcloset/types";

import { uniqBy } from "lodash";

import { useActiveFlow } from "hooks/application/useActiveFlow";

import { Appointment as AppointmentLib } from "lib/appointment";

import { useAppointments } from "../appointments";

import { AppointmentsState } from "interface/appointmentsState";

type Address = Common.BaseAddress;
type AppointmentsLibrary = AppointmentsState["appointmentsLibrary"];

export const useIkeaWarehouseAddressList = () => {
  const { isIkea: isIkeaFlow } = useActiveFlow();
  const { appointmentsLibrary } = useAppointments();

  const [warehousesAddresses, setWarehousesAddresses] = useState<Address[]>([]);

  const getWarehousesAddresses = (appointmentsLibrary: AppointmentsLibrary) => {
    const appointments = Object.values(appointmentsLibrary) as Storage.Appointment[];

    const addresses: Address[] = [];

    for (let i = 0; i < appointments.length; i++) {
      const appointment = appointments[i];

      const isInventoryPickup = AppointmentLib.isInventoryPickup(appointment);
      const isReturnToSender = AppointmentLib.isReturnToSender(appointment);
      if (!isInventoryPickup && !isReturnToSender) continue;

      const appointmentLocation = appointment?.address || appointment?.location;
      if (!appointmentLocation?.id) continue;

      const isAddressExists = !!addresses.find((address) => address.id === appointmentLocation?.id);
      if (!isAddressExists) addresses.push(appointmentLocation);
    }

    return addresses;
  };

  useEffect(() => {
    if (!isIkeaFlow) return;

    const warehousesAddresses = getWarehousesAddresses(appointmentsLibrary);
    setWarehousesAddresses((existingWarehousesAddresses) =>
      uniqBy([...existingWarehousesAddresses, ...warehousesAddresses], (address) => address.id)
    );
  }, [isIkeaFlow, appointmentsLibrary]);

  return warehousesAddresses;
};
