import React from "react";

import * as S from "./styles";

interface InfoCardProps {
  numberAppt: number;
  numberColor: string;
  additionalInfo?: string;
}

export const InfoCard: React.FC<InfoCardProps> = ({ numberAppt, numberColor, additionalInfo }) => {
  return (
    <S.InfoCardContainer>
      <S.NumberAppointmentText style={{ color: numberColor }}>{numberAppt} appt</S.NumberAppointmentText>
      {!!additionalInfo && <S.AdditionalInfoText>{additionalInfo}</S.AdditionalInfoText>}
    </S.InfoCardContainer>
  );
};
