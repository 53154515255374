import { Fulfillment } from "@secondcloset/types";

import * as IkeaHelpers from "recoil/_helpers/IkeaHelpers";

import { Markers as MarkersLib } from "lib/markers";

import { AppointmentOrShipment } from "interface/appointment";
import { AppointmentsState } from "interface/appointmentsState";

const sortAppointmentsByTimeSlot = (a1: AppointmentOrShipment, a2: AppointmentOrShipment) => {
  const time1 = +a1.timerange.split("-")[0];
  const time2 = +a2.timerange.split("-")[0];
  return time1 - time2;
};

export const setupAppointments = (appointments: AppointmentOrShipment[]) => {
  const sortedAppointments = appointments.sort(sortAppointmentsByTimeSlot);

  const initialValue = {
    appointmentsLibrary: {},
    appointmentsMarkers: {},
    inventoryPickupCrossDockDeliveryPairs: {},
    reverseLogisticsReturnToSenderPairs: {},
    inventoryOnHandAppointmentIDMap: {},
    appointmentTimeslots: [],
  };

  return sortedAppointments.reduce((acc: AppointmentsState, appt) => {
    if (!appt || acc.appointmentsLibrary[appt.id]) return acc;

    // setup appointmentsLibrary
    acc.appointmentsLibrary[appt.id] = appt;

    // setup appointmentsMarker
    const markerKey = MarkersLib.getMarkerKeyFromAppointment(appt);
    if (!acc.appointmentsMarkers[markerKey]) {
      acc.appointmentsMarkers[markerKey] = [];
    }
    acc.appointmentsMarkers[markerKey].push(appt.id);

    // setup inventoryPickupCrossDockDeliveryPairs
    if (!acc.inventoryPickupCrossDockDeliveryPairs[appt.id]) {
      const matchingInventoryPickupOrCrossDockDelivery = IkeaHelpers.getIkeaMatchingAppointmentPair(
        appt as Fulfillment.Appointment,
        ["cross_dock_delivery", "inventory_pick_up"],
        sortedAppointments as Fulfillment.Appointment[]
      );

      if (matchingInventoryPickupOrCrossDockDelivery) {
        acc.inventoryPickupCrossDockDeliveryPairs[appt.id] = matchingInventoryPickupOrCrossDockDelivery.id;

        acc.inventoryPickupCrossDockDeliveryPairs[matchingInventoryPickupOrCrossDockDelivery.id] = appt.id;
      } else {
        if (appt.job_type === "cross_dock_delivery" || appt.job_type === "delivery") {
          acc.inventoryOnHandAppointmentIDMap[appt.id] = appt.id;
        }
      }
    }

    // setup reverseLogisticsReturnToSenderPairs
    if (!acc.reverseLogisticsReturnToSenderPairs[appt.id]) {
      const matchingReturnToSenderOrReverseLogistics = IkeaHelpers.getIkeaMatchingAppointmentPair(
        appt as Fulfillment.Appointment,
        ["return_to_sender", "reverse_logistics"],
        sortedAppointments as Fulfillment.Appointment[]
      );

      if (matchingReturnToSenderOrReverseLogistics) {
        acc.reverseLogisticsReturnToSenderPairs[appt.id] = matchingReturnToSenderOrReverseLogistics.id;

        acc.reverseLogisticsReturnToSenderPairs[matchingReturnToSenderOrReverseLogistics.id] = appt.id;
      }
    }

    // setup timeslots
    const timerange = appt?.timerange;
    if (timerange && !acc.appointmentTimeslots.find((t) => t === timerange)) {
      acc.appointmentTimeslots.push(timerange);
    }

    return acc;
  }, initialValue);
};
