import React from "react";

import { Button, Col, Modal, Row, Spin } from "antd";

import * as S from "./styles";

import { ProductTable } from "./ProductTable";
import { ShipmentDetails } from "./ShipmentDetails";
import { useAppointmentDetails } from "./useAppointmentDetails";

interface AppointmentDetailsModalProps {
  visible: boolean;
  appointmentID?: string;
  onClose: () => unknown;
}

export const AppointmentDetailsModal: React.FC<AppointmentDetailsModalProps> = ({
  appointmentID = "",
  visible,
  onClose,
}) => {
  const {
    appointmentDetails,
    isLoading: isLoadingAppointmentDetails,
    getFormattedAppointmentDetails,
  } = useAppointmentDetails(appointmentID);

  const getShipmentDetails = () => {
    if (!appointmentDetails) return null;
    return getFormattedAppointmentDetails(appointmentDetails);
  };
  const shipmentDetails = getShipmentDetails();

  const renderTitle = () => {
    const { orderId, apptNumber, externalOrderNumber, organizationName } = shipmentDetails ?? {};

    return (
      <S.ModalHeader>
        <span>Shipment Details (Order ID {externalOrderNumber || orderId || apptNumber || "N/A"})</span>
        <span>{organizationName || "N/A"}</span>
      </S.ModalHeader>
    );
  };

  const renderPreloader = () => (
    <S.LoadingContainer>
      <Spin tip="Loading shipment details" />
    </S.LoadingContainer>
  );

  const renderContent = () => {
    if (!shipmentDetails) return null;

    return (
      <Row>
        <Col sm={24} md={12}>
          <ShipmentDetails details={shipmentDetails} />
        </Col>
        <Col sm={0} md={1} />
        <Col sm={24} md={11}>
          <ProductTable details={shipmentDetails} />
        </Col>
      </Row>
    );
  };

  return (
    <Modal
      title={renderTitle()}
      visible={visible}
      width={1000}
      closable={false}
      bodyStyle={S.ModalBodyStyle}
      footer={[
        <Button key="done" type="primary" onClick={onClose}>
          Done
        </Button>,
      ]}
    >
      {isLoadingAppointmentDetails ? renderPreloader() : renderContent()}
    </Modal>
  );
};
