import { useQuery } from "react-query";

import { ServiceArea as ServiceAreaName } from "@secondcloset/types/src/_common";

import { useRecoilValue } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";

import { fetchServiceAreas } from "api/serviceAreas";

import { ServiceArea } from "interface/serviceArea";

const DEFAULT_SERVICE_AREAS = [{ humanized_name: "Toronto", name: "yyz", id: 1 }];

export const useServiceAreas = () => {
  const activeServiceArea = useRecoilValue(activeServiceAreaState) || "yyz";

  const { data, isError } = useQuery(["service_areas"], () => fetchServiceAreas());
  const getServiceAreaOptions = () => {
    const serviceAreas = isError ? DEFAULT_SERVICE_AREAS : data;
    return serviceAreas?.map((sa) => ({ label: sa.humanized_name, name: sa.name, id: sa.id })) as ServiceArea[];
  };

  const getServiceAreaID = (serviceAreaName: ServiceAreaName) => {
    const serviceAreas = getServiceAreaOptions();
    const serviceAreaID = serviceAreas?.find((sa) => sa.name === serviceAreaName)?.id;
    return serviceAreaID;
  };

  const getActiveServiceAreaID = () => {
    return getServiceAreaID(activeServiceArea);
  };

  return { getServiceAreaOptions, getServiceAreaID, getActiveServiceAreaID };
};
