import { HiX } from "react-icons/hi";

import { Modal, Avatar } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

export const SelectFieldOpsModal = styled(Modal)`
  position: fixed;
  top: 100px;
  right: 360px;
  .ant-modal-body {
    padding: 24px 0 0;
    max-height: 550px;
  }
  .ant-modal-title {
    color: ${COLORS.CHARCOAL};
  }
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Container = styled.div`
  height: 500px;
`;

export const SelectedDriversContainer = styled.div`
  display: flex;
  height: 44px;
  padding: 0 24px;
  margin-bottom: 24px;
`;

export const ModalCloseButton = styled(HiX)`
  width: 20px;
  height: 20px;
  color: ${COLORS.CHARCOAL};
`;

export const Loading = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectedAvatarWrap = styled.div`
  display: inline-flex;
  margin-right: 11px;
`;

export const SelectedUserDefaultTextWrap = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SearchBarWrap = styled.div`
  margin-top: 10px;
  padding: 0 24px;
`;

export const UserListWrap = styled.div`
  margin-top: 10px;
  max-height: 390px;
  overflow-y: auto;
`;

interface UserProps {
  isSelected: boolean;
  isMaxUserSelected?: boolean;
}

export const UserListAvatar = styled(Avatar)<UserProps>`
  background: ${(props) => (props.isSelected ? COLORS.PRIMARY_BLUE : "")};
`;

export const UserRow = styled.div<UserProps>`
  height: 60px;
  display: flex;
  align-items: center;
  background: ${(props) => (props.isSelected ? COLORS.PRIMARY_LIGHT : "white")};
  padding: 0 24px;
  border: 1px solid transparent;
  cursor: ${(props) => (props.isMaxUserSelected || props.isSelected ? "no-drop" : "pointer")};

  &:hover {
    border: 1px solid ${(props) => (props.isSelected ? "transparent" : COLORS.PRIMARY_BLUE)};
    color: ${(props) => (props.isSelected ? null : COLORS.PRIMARY_BLUE)};
    .ant-avatar {
      background: ${(props) => (props.isSelected ? null : COLORS.PRIMARY_MID)};
    }
  }
`;

export const UserName = styled.div<UserProps>`
  display: inline-block;
  width: 170px;
  height: 22px;
  margin-left: 20px;
  cursor: ${(props) => (props.isSelected ? "no-drop" : null)};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const UserRole = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin-left: auto;
`;
