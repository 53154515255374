import { useMemo } from "react";

import { useRoutes } from "./useRoutes";

type AppointmentsRoutesMap = { [appointmentID: string]: string };

export const useAppointmentsRoutesMap = () => {
  const { routes = [] } = useRoutes();

  const appointmentsRoutesMap = useMemo(() => {
    const result: AppointmentsRoutesMap = {};

    routes.forEach(({ id, route_items }) => {
      const appointmentsIDs = route_items.map((routeItem) => routeItem.appointment_id);
      appointmentsIDs.forEach((appointmentID) => {
        result[appointmentID] = id;
      });
    });

    return result;
  }, [routes]);

  return appointmentsRoutesMap;
};
