import { Logistics } from "@secondcloset/types";

import { groupBy } from "lodash";

type AvailabilityTimeSlot = Logistics.AvailabilityTimeSlot;
type DeliveryServiceLevel = Logistics.DeliveryServiceLevel;

const getTimeslotData = (timeslotAvailability: AvailabilityTimeSlot[], deliveryServiceLevel: DeliveryServiceLevel) => {
  const result = timeslotAvailability.find(
    ({ delivery_service_level }) => delivery_service_level === deliveryServiceLevel
  );

  return result ? [result.booked_appointments_count, result.maximum_appointments_number] : [0, 0];
};

export const getCapacityBarChartData = (dayAvailabilities: AvailabilityTimeSlot[]) => {
  const groupedByTimeslot = groupBy(dayAvailabilities, (av) => av.timeslot);

  const result = Object.entries(groupedByTimeslot).map(([timeslot, timeslotAvailabilities]) => {
    const whiteGlove = getTimeslotData(timeslotAvailabilities, "white_glove");
    const nonWhiteGlove = getTimeslotData(timeslotAvailabilities, "non_white_glove");

    return {
      timeslot,
      values: {
        whiteGlove,
        nonWhiteGlove,
      },
    };
  });

  return result;
};
