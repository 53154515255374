import React from "react";

import styled from "styled-components";

import { COLORS } from "styles";

export const TooltipContainerStyle = {
  position: "absolute",
  whiteSpace: "nowrap",
} as React.CSSProperties;

const Container = styled.div`
  padding: 5px 8px;
  border-radius: 3px;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.GREY_DARKER};
`;

const Arrow = styled.div`
  position: absolute;
  top: 6px;
  left: -6px;
  width: 0;
  height: 0;
  border-width: 5px 6px 5px 0;
  border-color: transparent ${COLORS.GREY_DARKER} transparent;
  border-style: solid;
`;

export const TooltipContent: React.FC = ({ children }) => {
  return (
    <Container>
      {children}
      <Arrow />
    </Container>
  );
};
