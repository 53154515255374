import { useGetRouteAppointments } from "hooks/application/useGetRouteAppointments";

export const useGetDoesRouteHaveReverseLogistics = () => {
  const getRouteAppointments = useGetRouteAppointments();

  return (routeID: string) => {
    const appointments = getRouteAppointments(routeID);
    const appointmentsTypes = appointments.map((appt) => appt.job_type);
    return appointmentsTypes.includes("reverse_logistics");
  };
};
