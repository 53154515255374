import React, { useEffect } from "react";

import * as S from "./styles";

import { useSetRecoilState } from "recoil";
import { appointmentDetailsModalState } from "recoil/sideBarControls/atoms";

import { useAppointmentsRoutesMap } from "hooks/api/routes";
import { useActiveMarker } from "hooks/application/useActiveMarker";
import { useEditRoute } from "hooks/application/useEditRoute";
import { useRoutesMapVisibility } from "hooks/application/useRoutesMapVisibility";
import { useCollapseExpandedPanels } from "hooks/ui/useCollapseExpandedPanels";

import { useHoverAppointmentCard, useClickAppointmentCard } from "../AppointmentCard";
import { Collapse, RouteCollapsePanel } from "../Collapse";
import { RouteHeader } from "../RouteHeader";

import { useAppointmentsToRender } from "./useAppointmentsToRender";

export const AssignedShipmentsList: React.FC = () => {
  const { activeMarker, getIsAppointmentActive } = useActiveMarker();
  const { handleEditRouteClick } = useEditRoute();
  const { getIsRouteVisible, toggleRouteVisibility } = useRoutesMapVisibility();
  const setAppointmentDetailsModal = useSetRecoilState(appointmentDetailsModalState);
  const appointmentsRoutesMap = useAppointmentsRoutesMap();
  const handleAppointmentCardClick = useClickAppointmentCard();

  const { getIsAppointmentHovered, hoverAppointmentCard, unhoverAppointmentCard } = useHoverAppointmentCard();

  const { routesWithAppointmentsToRender } = useAppointmentsToRender();

  const {
    expandedPanels: routesExpandedPanels,
    expandPanel: expandRoutePanel,
    handleChangeExpandedPanels,
  } = useCollapseExpandedPanels();

  useEffect(() => {
    if (!activeMarker.selectedAppointmentId) return;
    const routeID = appointmentsRoutesMap[activeMarker.selectedAppointmentId];
    if (routeID) {
      const routePanelKey = routeID;
      expandRoutePanel(routePanelKey);
    }
  }, [activeMarker.selectedAppointmentId, appointmentsRoutesMap, expandRoutePanel]);

  return (
    <>
      {routesWithAppointmentsToRender.map((route) => (
        <Collapse key={route.id} activeKey={routesExpandedPanels} onChange={handleChangeExpandedPanels}>
          <RouteCollapsePanel
            key={route.id}
            header={
              <RouteHeader
                route={route}
                appointments={route.appointments}
                visible={getIsRouteVisible(route.id)}
                onToggleDisplay={(route) => toggleRouteVisibility(route.id)}
                onEdit={handleEditRouteClick}
              />
            }
          >
            {route.appointments.map((appointment) => (
              <S.AppointmentCard
                key={appointment.id}
                appointment={appointment}
                selected={getIsAppointmentActive(appointment.id)}
                hovered={getIsAppointmentHovered(appointment.id)}
                onShowDetails={(appointmentID) => setAppointmentDetailsModal({ visible: true, appointmentID })}
                onClick={handleAppointmentCardClick}
                onMouseEnterCard={hoverAppointmentCard}
                onMouseLeaveCard={unhoverAppointmentCard}
              />
            ))}
          </RouteCollapsePanel>
        </Collapse>
      ))}
    </>
  );
};
