import styled from "styled-components";

import { COLORS } from "styles";

export const NoItems = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  flex: 1;
  width: 100%;
  padding: 10px;
  overflow: auto;

  table {
    width: 100%;
    th,
    td {
      font-size: 12px;
      width: 20%;
      &:first-child {
        width: 80%;
      }
    }

    tr {
      &:first-child {
        border-bottom: 1px solid ${COLORS.GREY_LIGHT};
        th {
          padding-bottom: 5px;
        }
      }
    }
  }
`;

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.tr`
  display: flex;
  flex-direction: row;
`;

interface ColumnProps {
  flex: number;
}

export const Column = styled.td<ColumnProps>`
  display: flex;
  flex: ${(props) => (props.flex ? props.flex : 1)};

  &:not(:last-child) {
    margin-right: 8px;
  }
`;
