import React from "react";

import { COLORS } from "styles";

interface SingleIconProps {
  type: "solid" | "outlined";
  color: string;
}

export const SingleIcon: React.FC<SingleIconProps> = ({ type, color }) => {
  const fillColor = type === "solid" ? color : COLORS.WHITE;
  const borderColor = color;

  return (
    <svg width="40" height="29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill={fillColor}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1a3 3 0 0 0-3 3v15a3 3 0 0 0 3 3h12.67l2.4 5.78a1 1 0 0 0 1.85 0L23.33 22H36a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3H4Z"
      />
      <path
        fill={borderColor}
        d="m16.67 22 .46-.2-.13-.3h-.33v.5Zm2.4 5.78-.45.2.46-.2Zm1.85 0 .46.2-.46-.2ZM23.33 22v-.5H23l-.13.3.46.2ZM1.5 4A2.5 2.5 0 0 1 4 1.5v-1A3.5 3.5 0 0 0 .5 4h1Zm0 15V4h-1v15h1ZM4 21.5A2.5 2.5 0 0 1 1.5 19h-1A3.5 3.5 0 0 0 4 22.5v-1Zm12.67 0H4v1h12.67v-1Zm2.87 6.1-2.41-5.8-.92.4 2.4 5.78.93-.39Zm.92 0a.5.5 0 0 1-.92 0l-.92.38a1.5 1.5 0 0 0 2.76 0l-.92-.39Zm2.41-5.8-2.4 5.8.91.38 2.41-5.79-.92-.38ZM36 21.5H23.33v1H36v-1Zm2.5-2.5a2.5 2.5 0 0 1-2.5 2.5v1a3.5 3.5 0 0 0 3.5-3.5h-1Zm0-15v15h1V4h-1ZM36 1.5A2.5 2.5 0 0 1 38.5 4h1A3.5 3.5 0 0 0 36 .5v1Zm-32 0h32v-1H4v1Z"
      />
    </svg>
  );
};
