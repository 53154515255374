import { useMemo } from "react";

import { useRecoilValue } from "recoil";
import { createRouteState } from "recoil/routes/atoms";

import { useUnassignedAppointments } from "hooks/api/appointments";

import { AppointmentOrShipment } from "interface/appointment";

export const useNotAddedToRouteUnassignedAppointments = () => {
  const unassignedAppointments = useUnassignedAppointments();
  const { selectedAppointmentsIDs = [] } = useRecoilValue(createRouteState);

  return useMemo(() => {
    const isAppointmentNotAddedToConstructingRoute = (appointment: AppointmentOrShipment) => {
      return !selectedAppointmentsIDs.includes(appointment.id);
    };
    return unassignedAppointments.filter(isAppointmentNotAddedToConstructingRoute);
  }, [unassignedAppointments, selectedAppointmentsIDs]);
};
