import React from "react";

import * as S from "./styles";

import { useRecoilValue } from "recoil";
import { darkModeControlState } from "recoil/_common/darkModeControl";

import { Clusters } from "../../hooks/useClusters";

import { Map } from "interface/map";

interface ClusterMarkerProps {
  lng: number;
  lat: number;
  points: number;
  supercluster: Clusters["supercluster"];
  map: Map;
  clusterID: number;
}

export const ClusterMarker: React.FC<ClusterMarkerProps> = ({ points, supercluster, map, clusterID, lat, lng }) => {
  const isDarkMode = useRecoilValue(darkModeControlState);
  const onClick = () => {
    const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(clusterID), 20);
    map.setZoom(expansionZoom);
    map.panTo({ lat, lng });
  };

  return (
    <S.Container onClick={onClick} isDarkMode={isDarkMode}>
      {points}
    </S.Container>
  );
};
