import { useCallback } from "react";

import { useSetRecoilState } from "recoil";
import { activeMarkerState } from "recoil/routeMapControls/atoms";

import { useCreateRouteState, useCreateRouteView } from "hooks/application/createRoute";

import { useGetCanToggleAppointmentSelected } from "./useGetCanToggleAppointmentSelected";

import { AppointmentOrShipment } from "interface/appointment";

export const useClickMakerInCreateRouteMode = () => {
  const { setOverlappingAppointments, toggleAppointmentSelected } = useCreateRouteState();
  const { shouldAllowSelectingMapMarkers } = useCreateRouteView();
  const setActiveMarker = useSetRecoilState(activeMarkerState);
  const getCanToggleAppointmentSelected = useGetCanToggleAppointmentSelected();

  const handleMarkerInCreateRouteModeClick = useCallback(
    (appointments: AppointmentOrShipment[], markerKey: string) => {
      if (!!appointments.length && appointments.length !== 1) return setOverlappingAppointments(appointments);

      if (getCanToggleAppointmentSelected(appointments[0].id) || shouldAllowSelectingMapMarkers) {
        toggleAppointmentSelected(appointments[0].id);
        setActiveMarker({ id: markerKey, shouldCenter: true, selectedAppointmentId: appointments[0].id });
      }
    },
    [
      setOverlappingAppointments,
      getCanToggleAppointmentSelected,
      shouldAllowSelectingMapMarkers,
      toggleAppointmentSelected,
      setActiveMarker,
    ]
  );

  return handleMarkerInCreateRouteModeClick;
};
