import React, { useEffect, useMemo, useState } from "react";

import { debounce } from "lodash";

import * as S from "./styles";

const CHANGE_SEARCH_STRING_DEBOUNCE_TIMEOUT = 1000;

interface VehicleSearchInputProps {
  value: string;
  onChange: (searchString: string) => void;
}

export const VehicleSearchInput: React.FC<VehicleSearchInputProps> = ({ value, onChange }) => {
  const [searchString, setSearchString] = useState(value);

  useEffect(() => {
    setSearchString(value);
  }, [value]);

  const handleChange = useMemo(
    () => debounce((searchString: string) => onChange(searchString), CHANGE_SEARCH_STRING_DEBOUNCE_TIMEOUT),
    [onChange]
  );

  return (
    <S.SearchInput
      value={searchString}
      placeholder="Search Vehicle"
      suffix={<S.SearchInputIcon />}
      onChange={(event) => {
        setSearchString(event.target.value);
        handleChange(event.target.value);
      }}
    />
  );
};
