import styled from "styled-components";

export const Title = styled.p`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: fit-content(100px) auto;
  grid-column-gap: 10px;
  grid-row-gap: 5px;
  font-size: 14px;
  line-height: 20px;
`;
