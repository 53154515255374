import React, { useMemo } from "react";

import { Select } from "antd";

import { Container } from "./styles";

import { useRecoilState, useRecoilValue } from "recoil";
import { createRouteState } from "recoil/routes/atoms";
import { activeIkeaWarehouseAddressIDState } from "recoil/topFilterControls/atoms";

import { useIkeaWarehouseAddressList } from "hooks/api/warehouses";
import { useActiveFlow } from "hooks/application/useActiveFlow";

export const WarehouseFilter: React.FC = () => {
  const { isIkea } = useActiveFlow();
  // TODO: move this to global state so we have access in MainView for createRouteAutomationJobMutate
  const [activeIkeaWarehouseAddressID, dispatchActiveIkeaWarehouseAddressID] = useRecoilState(
    activeIkeaWarehouseAddressIDState
  );
  const ikeaWarehouseAddressList = useIkeaWarehouseAddressList();
  const { enabled: isCreateRouteModeEnabled } = useRecoilValue(createRouteState);

  const renderWarehouseFilter = () => {
    const isAddressListEmpty = ikeaWarehouseAddressList.length === 0;
    if (isAddressListEmpty || !isIkea) return null;

    const renderSelectOptions = () => {
      return ikeaWarehouseAddressList.map((address) => {
        return (
          <Select.Option key={address.id} value={address.id}>
            {address.address}
          </Select.Option>
        );
      });
    };

    const onSelect = (value: string) => {
      dispatchActiveIkeaWarehouseAddressID(value === "show_all" ? "" : value);
    };

    const renderSelect = () => {
      return (
        <Select
          value={!!activeIkeaWarehouseAddressID ? activeIkeaWarehouseAddressID : "All"}
          onChange={onSelect}
          dropdownMatchSelectWidth={false}
          disabled={isCreateRouteModeEnabled}
          className="warehouse-select-button"
          style={{ width: "184px" }}
        >
          <Select.Option key="show_all" value="show_all">
            All Warehouses
          </Select.Option>
          {renderSelectOptions()}
        </Select>
      );
    };

    return <Container>{renderSelect()}</Container>;
  };

  // eslint-disable-next-line
  return useMemo(renderWarehouseFilter, [
    isCreateRouteModeEnabled,
    ikeaWarehouseAddressList,
    activeIkeaWarehouseAddressID,
  ]);
};
