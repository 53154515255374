import { without } from "lodash";

import { useRecoilState, useResetRecoilState } from "recoil";
import { hiddenRoutesIDsState } from "recoil/routeMapControls/atoms";
import { activeRouteOverviewState } from "recoil/sideBarControls/atoms";

import { useRoutesWithAppointments } from "hooks/api/routes";
import { useRoutesMapVisibility } from "hooks/application/useRoutesMapVisibility";

export const useActiveRouteOverview = () => {
  const { hideRoutes } = useRoutesMapVisibility();
  const routesWithAppointments = useRoutesWithAppointments();
  const routesWithAppointmentIDs = routesWithAppointments.map((route) => route.id);
  const [{ previouslyHiddenRouteIDs }, setActiveRouteOverview] = useRecoilState(activeRouteOverviewState);
  const resetRouteOverviewState = useResetRecoilState(activeRouteOverviewState);
  const [hiddenRoutesIDs, setHiddenRoutesIDs] = useRecoilState(hiddenRoutesIDsState);

  const openRouteOverview = (routeID: string) => {
    const routeIDsToHide = without(routesWithAppointmentIDs, routeID);
    hideRoutes(routeIDsToHide);
    setActiveRouteOverview({ visible: true, routeID, previouslyHiddenRouteIDs: hiddenRoutesIDs });
  };

  const closeRouteOverview = () => {
    setHiddenRoutesIDs(previouslyHiddenRouteIDs);
    resetRouteOverviewState();
  };

  return { openRouteOverview, closeRouteOverview };
};
