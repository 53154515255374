import React from "react";

import { DatePicker as AntDatePicker } from "antd";
import moment, { Moment } from "moment";

import { useSetRecoilState } from "recoil";
import { activeIkeaWarehouseAddressIDState } from "recoil/topFilterControls/atoms";

import { useActiveDate } from "hooks/application/useActiveDate";

export const DatePicker: React.FC = () => {
  const { activeDate, setActiveDate } = useActiveDate();
  const dispatchActiveIkeaWarehouseAddressID = useSetRecoilState(activeIkeaWarehouseAddressIDState);

  const dateFormat = "YYYY-MM-DD";

  const onChange = (date: Moment | null) => {
    if (!date) return;
    if (date && date.isSame(moment(activeDate, dateFormat), "day")) return;

    const newDate = date ? date.format(dateFormat) : moment().format(dateFormat);
    setActiveDate(newDate);
    dispatchActiveIkeaWarehouseAddressID("");
  };

  return <AntDatePicker value={moment(activeDate, dateFormat)} onChange={onChange} allowClear={false} />;
};
