import React from "react";

import { Tooltip } from "antd";

import * as S from "./styles";

interface RedDotProps {
  dueTodayCount: number;
}

export const RedDot: React.FC<RedDotProps> = ({ dueTodayCount }) => {
  if (dueTodayCount < 1) return null;
  const tooltipText = dueTodayCount === 1 ? `1 appointment due today` : `${dueTodayCount} appointments due today`;

  return (
    <Tooltip placement="right" title={tooltipText}>
      <S.RedDotContainer>
        <S.RedDot />
      </S.RedDotContainer>
    </Tooltip>
  );
};
