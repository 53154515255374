import { difference } from "lodash";

import { Appointment } from "lib/appointment";

import { AppointmentsState } from "interface/appointmentsState";

const getSortedIPUAppointmentsIDs = (
  deliveryAppointmentsIDs: string[],
  ipuAppointmentsIDs: string[],
  appointmentsState: AppointmentsState
) => {
  const { inventoryPickupCrossDockDeliveryPairs } = appointmentsState;

  const result = ipuAppointmentsIDs.sort((a, b) => {
    const cddA = inventoryPickupCrossDockDeliveryPairs[a];
    const cddB = inventoryPickupCrossDockDeliveryPairs[b];

    const cddAIndex = deliveryAppointmentsIDs.indexOf(cddA);
    const cddBIndex = deliveryAppointmentsIDs.indexOf(cddB);

    return cddAIndex - cddBIndex;
  });

  return result.reverse();
};

export const sortIKEAAppointmentIDs = (appointmentsIDs: string[], appointmentsState: AppointmentsState) => {
  const { appointmentsLibrary } = appointmentsState;

  const appointments = appointmentsIDs.map((id) => appointmentsLibrary[id]);

  const ipuAppointments = appointments.filter((appt) => Appointment.isInventoryPickup(appt));
  const ipuAppointmentsIDs = ipuAppointments.map((appt) => appt.id);

  const rtsAppointments = appointments.filter((appt) => Appointment.isReturnToSender(appt));
  const rtsAppointmentsIDs = rtsAppointments.map((appt) => appt.id);

  const deliveryAppointmentsIDs = difference(appointmentsIDs, [...ipuAppointmentsIDs, ...rtsAppointmentsIDs]);

  const sortedIPUAppointmentsIDs = getSortedIPUAppointmentsIDs(
    deliveryAppointmentsIDs,
    ipuAppointmentsIDs,
    appointmentsState
  );

  return [...sortedIPUAppointmentsIDs, ...deliveryAppointmentsIDs, ...rtsAppointmentsIDs];
};
