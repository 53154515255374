import { Card } from "antd";
import { CardProps } from "antd/lib/card";
import styled, { css } from "styled-components";

import { COLORS } from "styles";

export const HoverShadow = css`
  .ant-card-body {
    box-shadow: inset 2px 2px 0px ${COLORS.PRIMARY_BLUE}, inset -2px -2px 0px ${COLORS.PRIMARY_BLUE};
  }
`;

interface AppointmentCardProps extends CardProps {
  selected?: boolean;
}
export const AppointmentCard = styled(Card)<AppointmentCardProps>`
  .ant-card-body {
    padding: 0px;
    background: ${(props) => (props.selected ? COLORS.ASH_BLUE : COLORS.WHITE)};
  }
`;

export const MainContainer = styled.div`
  display: flex;
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
  padding: 16px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const DetailContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

export const DetailLine = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.CHARCOAL};
  font-weight: 400;
  font-size: 12px;
  > strong {
    color: ${COLORS.GREY_DARKER};
    font-weight: 600;
    font-size: 14px;
  }
`;

export const InventoryOnHandLabel = styled.div`
  margin-left: 8px;
  padding: 0 8px;
  border-radius: 16px;
  color: ${COLORS.WHITE};
  background-color: ${COLORS.BLUE_LIGHT};
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  text-transform: uppercase;
`;

export const IconStyle = (selected?: boolean) => {
  return {
    flexShrink: 0,
    fontSize: "22px",
    color: selected ? COLORS.PRIMARY_BLUE : COLORS.GREY_MID,
  };
};

export const TimerIconStyle = { ...IconStyle(), color: COLORS.ORANGE };

export const DragContainer = styled.div`
  box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.15);
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLORS.WHITE};
`;

export const Buttons = styled.div`
  display: flex;
  gap: 8px;
`;

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  padding: 3px;
  border: 0;
  background: transparent;
  cursor: pointer;
`;

export const InfoButton = styled(IconButton)<{ alwaysVisible?: boolean }>`
  display: ${(props) => (props.alwaysVisible ? "flex" : "none")};

  ${AppointmentCard}:hover & {
    display: flex;
  }
`;

export const RouteAutomationPopoverContainer = styled.p`
  margin: 0;
`;
