import React from "react";

import { Spin, SpinProps } from "antd";

import { Container } from "./styles";

interface PageLoaderProps extends SpinProps {
  text?: string;
}

export const PageLoader: React.FC<PageLoaderProps> = (props) => {
  return (
    <Container>
      <Spin tip={props.text || "loading..."} />
    </Container>
  );
};
