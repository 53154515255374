import * as S from "./styles";

import { useAppointments } from "hooks/api/appointments";
import { useRoutes } from "hooks/api/routes";

export const MapLoadingIndicator = () => {
  const { isLoading: isLoadingRoutes } = useRoutes();
  const { isLoading: isLoadingAppointments } = useAppointments();

  if (!isLoadingRoutes && !isLoadingAppointments) return null;

  return (
    <S.Container>
      <S.BarLoader loading={true} />
    </S.Container>
  );
};
