import React from "react";
import { HiPlus } from "react-icons/hi";

import { Button, Spin } from "antd";

import * as S from "./styles";

import { useNotAddedToRouteUnassignedAppointments } from "hooks/application/createRoute";

interface FooterProps {
  onCancel: () => void;
  onSave: () => void;
  isValid?: boolean;
  isLoading?: boolean;
  isEdit?: boolean;
  onAddMoreAppointmentsRequest: () => void;
}

export const Footer: React.FC<FooterProps> = ({
  onCancel,
  onSave,
  isValid,
  isLoading,
  isEdit,
  onAddMoreAppointmentsRequest,
}) => {
  const freeUnassignedAppointments = useNotAddedToRouteUnassignedAppointments();

  return (
    <S.FooterWrapper>
      {freeUnassignedAppointments.length > 0 && (
        <Button type="text" style={S.PrimaryButtonStyle} onClick={onAddMoreAppointmentsRequest}>
          <HiPlus style={S.IconStyle} />
          Add More Appointments
        </Button>
      )}

      <S.ButtonGroup>
        <Button type="default" style={S.ButtonStyle} onClick={onCancel} disabled={isLoading}>
          Cancel
        </Button>
        <Button type="default" style={S.ButtonStyle} onClick={onSave} disabled={!isValid || isLoading}>
          {isEdit ? "Update Route" : "Create Route"}
          {isLoading && <Spin size="small" style={{ marginLeft: "8px" }} />}
        </Button>
      </S.ButtonGroup>
    </S.FooterWrapper>
  );
};
