import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    font-family: Inter, sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  .ant-picker-cell .ant-picker-cell-inner {
    border-radius: 2px;
  }

  // NOTE:
  // We use 6px base border radius overriding default Antd Less variables
  // @border-radius-base": 6px - see craco.config.js
  // For checkbox component we overrided it's border radius:
  // @checkbox-border-radius: 2px
  // Unfortunately in ^16.* version of Antd checkbox component
  // has a bug - @border-radius-base value is used instead of
  // @checkbox-border-radius
  .ant-checkbox-inner,
  .ant-select-tree-checkbox-inner {
    border-radius: 2px;
  }

  .ant-select-tree-switcher-noop {
    width: 10px;
  }
`;
