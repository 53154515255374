import { useHistory } from "react-router";

import { useSetRecoilState } from "recoil";
import { darkModeControlState } from "recoil/_common/darkModeControl";
import { userState } from "recoil/_common/user";

import { removeCredential } from "lib/cookie";

export const useLogout = () => {
  const history = useHistory();
  const setUser = useSetRecoilState(userState);
  const setDarkModeActive = useSetRecoilState(darkModeControlState);

  return () => {
    setUser(undefined);
    setDarkModeActive(false);

    removeCredential();
    history.push("/login");
  };
};
