import { useState } from "react";
import { useQuery } from "react-query";

import { useRecoilValue } from "recoil";
import { userState } from "recoil/_common/user";

import { fetchFeatures } from "api/bfapi";

export const useDevtoolAuth = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const user = useRecoilValue(userState);
  useQuery(["fetchFeatures", user], fetchFeatures, {
    onSuccess: (features) => {
      const permissions = features?.[0]?.permissions || [];
      const isEmailFound = !!permissions.find((p) => p.email.toLowerCase() === user?.email.toLowerCase());
      setIsEnabled(isEmailFound);
    },
  });

  return isEnabled;
};
