import { useEffect } from "react";

import { useSetRecoilState } from "recoil";
import { HiddenAppointmentMapState, hiddenAppointmentMapState } from "recoil/appointments/atoms";

import { useAppointments } from "hooks/api/appointments";
import { useCancelledAppointmentsVisible } from "hooks/application/useCancelledAppointmentsVisible";

import { AppointmentOrShipment } from "interface/appointment";

export const useHiddenAppointmentsListener = () => {
  const { isCancelledAppointmentsVisible } = useCancelledAppointmentsVisible();

  const { appointmentsLibrary } = useAppointments();

  const dispatchHiddenAppointmentMap = useSetRecoilState(hiddenAppointmentMapState);

  useEffect(() => {
    const shouldAppointmentBeHidden = (appointment: AppointmentOrShipment) => {
      if (!appointment) return true;

      //hide all cancelled appointments
      if (!isCancelledAppointmentsVisible && appointment.status === "cancelled") {
        return true;
      }

      //hide all inventory_pick_appointments (IKEA flow shows warehouse marker instead)
      const isInventoryPickup = appointment.job_type === "inventory_pick_up";
      if (isInventoryPickup) return true;

      // only display delivery or cross dock delivery
      const deliveryJobTypeList = ["delivery", "cross_dock_delivery"];
      const isJobTypeDelivery = deliveryJobTypeList.includes(appointment?.job_type);

      if (!isJobTypeDelivery) return true;

      return false;
    };

    const hiddenAppointmentsMap = Object.values(appointmentsLibrary).reduce((acc, cv) => {
      const appointmentID = cv.id;
      const shouldBeHidden = shouldAppointmentBeHidden(cv);
      acc[appointmentID] = shouldBeHidden;
      return acc;
    }, {} as HiddenAppointmentMapState);

    dispatchHiddenAppointmentMap(hiddenAppointmentsMap);
  }, [isCancelledAppointmentsVisible, appointmentsLibrary, dispatchHiddenAppointmentMap]);
};
