import { useMemo } from "react";

import difference from "lodash/difference";

import * as S from "./styles";

import { useUnassignedAppointments } from "hooks/api/appointments";
import { useCreateRouteState } from "hooks/application/createRoute";
import { useSidebarSearch } from "hooks/application/useSidebarSearch";

export const SelectAllToggle = () => {
  const unassignedAppointments = useUnassignedAppointments();
  const { isSearchEnabled, searchedAppointmentsMap: unassignedAppointmentsSearchResult } = useSidebarSearch();
  const { setSelectedAppointments, selectedAppointmentsIDs } = useCreateRouteState();

  const canSelectAll = useMemo(() => {
    return isSearchEnabled
      ? difference(Object.keys(unassignedAppointmentsSearchResult), selectedAppointmentsIDs).length !== 0
      : difference(
          unassignedAppointments.map(({ id }) => id),
          selectedAppointmentsIDs
        ).length !== 0;
  }, [isSearchEnabled, selectedAppointmentsIDs, unassignedAppointments, unassignedAppointmentsSearchResult]);

  const canDeselectAll = selectedAppointmentsIDs.length > 0;

  const handleSelectAll = () => {
    return isSearchEnabled
      ? setSelectedAppointments(Object.keys(unassignedAppointmentsSearchResult))
      : setSelectedAppointments(unassignedAppointments.map(({ id }) => id));
  };

  const handleDeselectAll = () => {
    setSelectedAppointments([]);
  };

  return (
    <S.Container>
      <S.ToggleButton active={canSelectAll} disabled={!canSelectAll} onClick={handleSelectAll}>
        Select All
      </S.ToggleButton>
      <S.Divider type="vertical" />
      <S.ToggleButton active={canDeselectAll} disabled={!canDeselectAll} onClick={handleDeselectAll}>
        Deselect All
      </S.ToggleButton>
    </S.Container>
  );
};
