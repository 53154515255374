import { useEffect } from "react";

import { isEmpty } from "lodash";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { HiddenAppointmentMapState, hiddenAppointmentMapState } from "recoil/appointments/atoms";
import { getRouteAppointmentIDs } from "recoil/routes/helpers";
import { constructingRouteIDState, isConstructingModeState } from "recoil/sideBarControls/atoms";
import {
  activeAppointmentTypesState,
  activeServiceLevelsState,
  activeTimeSlotsState,
  inventoryOnHandAppointmentsVisible,
  activeOrganizationsState,
} from "recoil/topFilterControls/atoms";

import { useAppointments } from "hooks/api/appointments";
import { useRoutes } from "hooks/api/routes";
import { useActiveFlow } from "hooks/application/useActiveFlow";
import { useCancelledAppointmentsVisible } from "hooks/application/useCancelledAppointmentsVisible";

import { getAppointmentOrganizationID } from "pages/RoutesPage/TopFilterBar/helpers";

import { AppointmentOrShipment } from "interface/appointment";

export const useHiddenAppointmentsListener = () => {
  const { isCancelledAppointmentsVisible } = useCancelledAppointmentsVisible();
  const isInventoryOnHandAppointmentsVisible = useRecoilValue(inventoryOnHandAppointmentsVisible);
  const { appointmentIDsWithExistingRoutes, routesLibrary } = useRoutes();
  const { appointmentsLibrary, inventoryOnHandAppointmentIDMap } = useAppointments();
  const { isIkea } = useActiveFlow();

  const constructingRouteID = useRecoilValue(constructingRouteIDState);
  const isConstructingMode = useRecoilValue(isConstructingModeState);
  const activeTimeslots = useRecoilValue(activeTimeSlotsState);
  const activeAppointmentTypes = useRecoilValue(activeAppointmentTypesState);
  const activeServiceLevels = useRecoilValue(activeServiceLevelsState);
  const activeOrganizations = useRecoilValue(activeOrganizationsState);
  const dispatchHiddenAppointmentMap = useSetRecoilState(hiddenAppointmentMapState);

  useEffect(() => {
    const shouldAppointmentBeHidden = (appointment: AppointmentOrShipment) => {
      if (!appointment) return true;

      //hide all cancelled appointments
      if (!isCancelledAppointmentsVisible && appointment.status === "cancelled") {
        return true;
      }

      //hide all inventory on hand appointments
      if (isIkea && !isInventoryOnHandAppointmentsVisible && !!inventoryOnHandAppointmentIDMap[appointment.id]) {
        return true;
      }

      //hide all inventory_pick_appointments (IKEA flow shows warehouse marker instead)
      const isInventoryPickup = appointment.job_type === "inventory_pick_up";
      if (isInventoryPickup) return true;

      //filtered by timeslot filter
      const isInActiveTimeslots = isEmpty(activeTimeslots) || activeTimeslots.includes(appointment?.timerange);
      if (!isInActiveTimeslots) return true;

      //filter by appointment types filter
      const isInActiveAppointmentTypes = activeAppointmentTypes.includes(appointment?.job_type);
      if (!isInActiveAppointmentTypes) return true;

      //filter by service types filter
      const isInActiveServiceLevels = activeServiceLevels.some((asl) => appointment?.delivery_service_level === asl);
      if (!isInActiveServiceLevels) return true;

      //filter by organizations filter
      const isInActiveOrganizations = activeOrganizations.includes(getAppointmentOrganizationID(appointment));
      if (!isInActiveOrganizations && !isIkea) return true;

      //hide all existing route markers when creating new route or editing
      if (isConstructingMode) {
        const isInARoute = appointmentIDsWithExistingRoutes.includes(appointment.id);
        const isInConstructingRoute = getRouteAppointmentIDs({
          routeID: constructingRouteID,
          routesLibrary,
        }).includes(appointment.id);
        return isInARoute && !isInConstructingRoute;
      }

      return false;
    };

    const hiddenAppointmentsMap = Object.values(appointmentsLibrary).reduce((acc, cv) => {
      const appointmentID = cv.id;
      const shouldBeHidden = shouldAppointmentBeHidden(cv);
      acc[appointmentID] = shouldBeHidden;
      return acc;
    }, {} as HiddenAppointmentMapState);

    dispatchHiddenAppointmentMap(hiddenAppointmentsMap);
  }, [
    isIkea,
    isCancelledAppointmentsVisible,
    appointmentIDsWithExistingRoutes,
    routesLibrary,
    appointmentsLibrary,
    constructingRouteID,
    isConstructingMode,
    activeTimeslots,
    activeAppointmentTypes,
    activeServiceLevels,
    dispatchHiddenAppointmentMap,
    inventoryOnHandAppointmentIDMap,
    isInventoryOnHandAppointmentsVisible,
    activeOrganizations,
  ]);
};
