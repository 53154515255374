import React, { useState } from "react";

import { Select, Button } from "antd";
import { SelectValue } from "antd/lib/select";

import * as S from "./styles";

import { useSetRecoilState } from "recoil";
import { activeIkeaWarehouseAddressIDState } from "recoil/topFilterControls/atoms";

import { useIkeaWarehouseAddressList } from "hooks/api/warehouses";

interface SelectIkeaWarehouseModalProps {
  visible: boolean;
  onClose: (warehouseID?: string) => void;
}
export const SelectIkeaWarehouseModal: React.FC<SelectIkeaWarehouseModalProps> = ({ visible, onClose }) => {
  const ikeaWarehouseAddressList = useIkeaWarehouseAddressList();
  const setActiveIkeaWarehouseID = useSetRecoilState(activeIkeaWarehouseAddressIDState);

  const [selectedWarehouseID, setSelectedWarehouseID] = useState("");

  const handleClickDone = () => {
    setActiveIkeaWarehouseID(selectedWarehouseID);
    onClose(selectedWarehouseID);
  };

  return (
    <S.Modal
      visible={visible}
      title="Please select IKEA warehouse"
      maskClosable={false}
      closeIcon={<S.ModalCloseIcon />}
      onCancel={() => onClose()}
      footer={[
        <Button key="done" type="primary" onClick={handleClickDone}>
          Done
        </Button>,
      ]}
    >
      <S.WarehouseSelect
        placeholder="Select location"
        suffixIcon={<S.WarehouseSelectArrowIcon />}
        onChange={(value: SelectValue) => {
          const warehouseID = value as string;
          setSelectedWarehouseID(warehouseID);
        }}
      >
        {ikeaWarehouseAddressList.map(({ id, address }) => (
          <Select.Option key={id} value={id}>
            {address}
          </Select.Option>
        ))}
      </S.WarehouseSelect>
    </S.Modal>
  );
};
