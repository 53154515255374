import { Common, Storage } from "@secondcloset/types";
import { AppointmentType } from "@secondcloset/types/src/fulfillment";

import { stringify } from "query-string";

import { API } from "lib/API";
import { Organization } from "lib/organization";

import { AppointmentOrShipment } from "interface/appointment";

type ServiceArea = Common.ServiceArea;
type AppointmentNote = Storage.AppointmentNote;
type AppointmentTask = Storage.AppointmentTask;

export interface AppointmentIndexQuery {
  date?: string;
  serviceArea?: ServiceArea;
  isIkea?: boolean;
  ikeaWarehouseAddressID?: string;
  isRangeOfDays?: boolean;
  job_types?: AppointmentType[];
}

export const fetchAppointmentIndex = async (query: AppointmentIndexQuery): Promise<AppointmentOrShipment[]> => {
  const api = new API("bvr");
  const { isIkea = false, ikeaWarehouseAddressID, date, serviceArea, isRangeOfDays, job_types } = query;
  const options = {
    date_from: date,
    date_to: date,
    per_page: "ALL",
    service_area: serviceArea || "yyz",
    organization_id: isIkea ? Organization.getIkeaOrgID() : undefined,
    excluded_organization_id: !isIkea ? Organization.getIkeaOrgID() : undefined,
    location_id: !!ikeaWarehouseAddressID ? ikeaWarehouseAddressID : undefined,
    show_related: !!ikeaWarehouseAddressID ? true : undefined,
    range_of_days: isRangeOfDays,
    job_types,
  };
  const url = `/appointments?${stringify(options || {}, {
    arrayFormat: "bracket",
  })}`;
  const respond = await api.get(url);
  return respond.data as AppointmentOrShipment[];
};

export interface InventoryOnHandAppointmentIndexQuery {
  date: string;
  serviceArea?: ServiceArea;
}

export const fetchInventoryOnHandAppointmentIndex = async (
  query: AppointmentIndexQuery
): Promise<AppointmentOrShipment[]> => {
  const api = new API("bvr");
  const { date, serviceArea, isRangeOfDays } = query;
  const options = {
    date,
    per_page: "ALL",
    service_area: serviceArea || "yyz",
    organization_id: Organization.getIkeaOrgID(),
    job_types: ["delivery", "cross_dock_delivery"],
    standalone_appointments_only: true,
    range_of_days: isRangeOfDays,
  };
  const url = `/appointments?${stringify(options || {}, {
    arrayFormat: "bracket",
  })}`;
  const respond = await api.get(url);
  return respond.data as AppointmentOrShipment[];
};

export const fetchAppointmentDetails = async (appointmentID: string) => {
  const api = new API();
  const url = `/appointments/${appointmentID}`;
  const respond = await api.get(url);
  return respond.data as AppointmentOrShipment;
};

export const fetchMultipleAppointmentsDetails = async (appointmentIDs: string[]) => {
  return await Promise.all(appointmentIDs.map((id) => fetchAppointmentDetails(id)));
};

export const fetchAppointmentNotes = async (appointmentID: string) => {
  const api = new API();
  const url = `/appointments/${appointmentID}/notes`;
  const respond = await api.get(url);
  return respond.data.notes as AppointmentNote[];
};

export const fetchAppointmentTasks = async (appointmentID: string) => {
  const api = new API();
  const url = `/appointments/${appointmentID}/tasks`;
  const respond = await api.get(url);
  return respond.data as AppointmentTask[];
};

export interface UpdateBookingDateBody {
  appointmentIds: string[];
  date: string;
}

export const batchUpdateAppointments = async ({ appointmentIds, date }: UpdateBookingDateBody) => {
  const api = new API("logistics");
  const url = `/appointments/batch_update`;
  const body = {
    appointment_ids: appointmentIds,
    date,
  };
  const respond = await api.put(url, body);
  return respond.data as AppointmentOrShipment[];
};
