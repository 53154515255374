import React, { useState } from "react";

import GoogleMapReact from "google-map-react";

import { useRecoilValue } from "recoil";
import { clusterModeControl } from "recoil/_common/clusterModeControl";
import { bookingSelectedWarehouseIdState } from "recoil/booking/atoms";

import { useWarehouses } from "hooks/api/warehouses";
import { useActiveFlow } from "hooks/application/useActiveFlow";
import { useMapUISettings } from "hooks/application/useMapUISetting";

import { Address } from "lib/address";

import { useClusters } from "./hooks/useClusters";
import { useHiddenAppointmentsListener } from "./hooks/useHiddenAppointmentsListener";
import { useVisibleBookingMarkers } from "./hooks/useVisibleBookingMarkers";
import { useVisibleMarkers } from "./hooks/useVisibleMarkers";
import { AppointmentMarker } from "./Markers/AppointmentMarker";
import { ClusterMarker } from "./Markers/ClusterMarker";
import { IkeaMarker } from "./Markers/IkeaMarker";

import { Map, Maps } from "interface/map";

export const BookingMap: React.FC = () => {
  const [bounds, setBounds] = useState<number[]>([]);
  const [zoom, setZoom] = useState(10);

  const { isIkea } = useActiveFlow();

  const { warehouses: bookingIkeaWarehouseList } = useWarehouses();
  const bookingSelectedWarehouseId = useRecoilValue(bookingSelectedWarehouseIdState);

  const [mapRefs, setMapRefs] = useState<{ map: Map; maps: Maps } | null>(null);
  const mapUISettings = useMapUISettings();
  const visibleMarkers = useVisibleMarkers();
  const visibleBookingMarkers = useVisibleBookingMarkers();
  const isClusterMode = useRecoilValue(clusterModeControl);

  useHiddenAppointmentsListener();
  const { clusters } = useClusters({
    zoom,
    bounds,
    visibleMarkers,
  });

  const renderClusterModeMarkers = () => {
    if (!isClusterMode) return null;
    const map = mapRefs?.map;
    const supercluster = clusters.supercluster;
    return clusters.clusters.map((c) => {
      const { properties, geometry, id: clusterID } = c;
      const { cluster: isCluster } = properties;
      const [lng, lat] = geometry.coordinates;
      if (isCluster) {
        const { point_count } = properties;
        return (
          <ClusterMarker
            lng={lng}
            lat={lat}
            points={point_count}
            supercluster={supercluster}
            map={map}
            clusterID={clusterID}
          />
        );
      } else {
        const { appointmentIDs } = properties;
        return <AppointmentMarker lat={lat} lng={lng} key={lat} appointmentIDs={appointmentIDs || []} map={map} />;
      }
    });
  };

  const renderMarkers = () => {
    if (isClusterMode) return null;
    const map = mapRefs?.map;
    return visibleMarkers.map((marker) => {
      const { markerKey, appointmentIDs, lat, lng } = marker;
      return <AppointmentMarker lat={lat} lng={lng} key={markerKey} appointmentIDs={appointmentIDs} map={map} />;
    });
  };

  const renderBookingMarkers = () => {
    const map = mapRefs?.map;
    return visibleBookingMarkers.map((marker) => {
      const { markerKey, appointmentIDs, lat, lng } = marker;
      return (
        <AppointmentMarker
          lat={lat}
          lng={lng}
          key={markerKey}
          appointmentIDs={appointmentIDs}
          map={map}
          isBookingMarker={true}
        />
      );
    });
  };

  const renderIkeaWarehouseMarkers = () => {
    if (!isIkea) return null;
    let positions;
    if (!!bookingSelectedWarehouseId) {
      const address = bookingIkeaWarehouseList.find((a) => a?.id === bookingSelectedWarehouseId);
      positions = [Address.getCoordinates(address)];
    } else {
      positions = bookingIkeaWarehouseList.map((address) => Address.getCoordinates(address));
    }
    return positions.map(({ lat, lng }) => {
      return <IkeaMarker key={`${lat},${lng}`} lat={lat} lng={lng} />;
    });
  };

  return (
    <GoogleMapReact
      {...mapUISettings}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        setMapRefs({ map, maps });
      }}
      onChange={({ zoom, bounds }) => {
        setZoom(zoom);
        setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
      }}
    >
      {renderMarkers()}
      {renderBookingMarkers()}
      {renderClusterModeMarkers()}
      {renderIkeaWarehouseMarkers()}
    </GoogleMapReact>
  );
};
