import { useMutation } from "react-query";

import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { createRouteAutomationState as createRouteAutomationAtom } from "recoil/routes/atoms";
import { activeIkeaWarehouseAddressIDState } from "recoil/topFilterControls/atoms";

import { useAppointments } from "hooks/api/appointments";
import { useRouteAutomationJobs } from "hooks/api/useRouteAutomationJobs";
import { useVehicles } from "hooks/api/vehicles";

import { createRouteAutomationJob } from "api/routes";
import { warehouseLibrary } from "constants/routeAutomationWarehouseIds";

import { useCreateRouteState } from "../createRoute";

import { RouteAutomationJob } from "interface/routeAutomationJob";
import { Vehicle } from "interface/vehicle";

export const useCreateRouteAutomation = (
  onSuccess?: (data: RouteAutomationJob) => void,
  onError?: (error: string) => void
) => {
  const [createRouteAutomationState, setCreateRouteAutomationState] = useRecoilState(createRouteAutomationAtom);
  const activeIkeaWarehouseAddressID = useRecoilValue(activeIkeaWarehouseAddressIDState);
  const resetCreateRouteAutomationState = useResetRecoilState(createRouteAutomationAtom);
  const { getVehiclesAvailableToRoute } = useVehicles();
  const vehiclesAvailableToRoute = getVehiclesAvailableToRoute();
  const { appointmentsLibrary } = useAppointments();
  const { selectedAppointmentsIDs } = useCreateRouteState();
  const { getUnavailableVehicleIDs } = useRouteAutomationJobs();

  const { mutate: createRouteAutomationJobMutate, isLoading } = useMutation(createRouteAutomationJob, {
    onSuccess,
    onError,
  });

  const handleCreateRouteAutomationJob = (vehicles: Vehicle[]) => {
    return createRouteAutomationJobMutate({
      vehicles,
      appointments: createRouteAutomationState.appointments,
      warehouse: warehouseLibrary[activeIkeaWarehouseAddressID],
    });
  };

  const reset = () => {
    resetCreateRouteAutomationState();
  };

  const enableRouteAutomation = () => {
    const appointments = selectedAppointmentsIDs.map((appointmentID) => appointmentsLibrary[appointmentID]);

    setCreateRouteAutomationState((state) => ({
      ...state,
      enabled: true,
      vehicles: vehiclesAvailableToRoute.filter((vehicle) => areVehiclesAvailable([vehicle])),
      appointments,
      warehouse: warehouseLibrary[activeIkeaWarehouseAddressID],
    }));
  };

  const areVehiclesAvailable = (vehicles: Vehicle[]) => {
    const unavailableVehicleIDs = getUnavailableVehicleIDs();
    const isValid = vehicles.every((vehicle) => !unavailableVehicleIDs.includes(vehicle.id));
    return isValid;
  };

  return {
    isLoading,
    reset,
    enableRouteAutomation,
    handleCreateRouteAutomationJob,
    areVehiclesAvailable,
    ...createRouteAutomationState,
  };
};
