import { InputNumber } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  font-weight: 500;
  font-size: 14px;
`;

const InputNumberBase = styled(InputNumber)`
  &.ant-input-number-disabled {
    color: ${COLORS.GREY_DARK};
  }
`;

export const BookedAppointmentInput = styled(InputNumberBase)``;

export const MaxAppointmentInput = styled(InputNumberBase)`
  color: ${COLORS.PRIMARY_BLUE};

  .ant-input-number-handler-wrap {
    opacity: 1;
  }
`;

export const MaxLimitInput = styled(InputNumberBase)`
  width: 86px;
`;

export const TitleRow = styled.div`
  display: grid;
  grid-template-columns: 100px repeat(4, 1fr);
  gap: 10px;
  color: ${COLORS.GREY_DARK};
  margin: 8px 0;
`;

export const Timeslot = styled.div`
  margin: 24px 0;
  font-size: 16px;
  color: ${COLORS.CHARCOAL};
`;

export const BookedRow = styled.div`
  display: grid;
  grid-template-columns: 100px repeat(4, 1fr);
  gap: 10px;
  align-items: center;
  color: ${COLORS.GREY};
  margin: 10px 0;
`;
