import React, { PropsWithChildren } from "react";

import { ErrorBoundaryFallback } from "@secondcloset/web-components";

import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { ErrorBoundaryProps } from "@sentry/react/dist/errorboundary";

export const ErrorBoundary: React.FC<PropsWithChildren<ErrorBoundaryProps>> = (props) => (
  <SentryErrorBoundary fallback={(e) => <ErrorBoundaryFallback {...e} />} {...props}>
    {props.children}
  </SentryErrorBoundary>
);
