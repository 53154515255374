import { stringify } from "query-string";

import { API } from "lib/API";

export interface Permission {
  id: string;
  feature_id: string;
  email: string;
}

export interface Feature {
  id: string;
  app_name: "SIERRA" | "BVR";
  name: string;
  permissions: Permission[];
}

export const fetchFeatures = async (): Promise<Feature[]> => {
  const api = new API("bfapi");
  const query = { app_name: "BVR", name: "Devtool" };
  const url = `/features?${stringify(query)}`;
  const respond = await api.get(url);
  return respond.data as Feature[];
};
