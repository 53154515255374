import { useRecoilValue } from "recoil";
import { clusterModeControl } from "recoil/_common/clusterModeControl";

import { useCreateRouteState } from "hooks/application/createRoute";

import { useRenderMarkersInCreateRouteMode } from "./useRenderMarkersInCreateRouteMode";
import { useRenderMarkersInOverviewMode } from "./useRenderMarkersInOverviewMode";

import { Map } from "interface/map";

export const useRenderMarkers = (map: Map) => {
  const isClusterMode = useRecoilValue(clusterModeControl);
  const { enabled: isCreateRouteModeEnabled } = useCreateRouteState();
  const { renderMarkersInOverviewMode, renderSingleMarker } = useRenderMarkersInOverviewMode(map);
  const renderMarkersInCreateRouteMode = useRenderMarkersInCreateRouteMode(map);

  const renderMarkers = () => {
    if (isClusterMode) return null;
    return isCreateRouteModeEnabled ? renderMarkersInCreateRouteMode() : renderMarkersInOverviewMode();
  };

  return { renderMarkers, renderSingleMarker };
};
