import { useParams } from "react-router";
import { Link } from "react-router-dom";

import { notification, Button } from "antd";
import { startCase, upperCase } from "lodash";

import * as S from "./styles";

import { useVehicleDetails } from "hooks/api/vehicles";
import { useActiveDate } from "hooks/application/useActiveDate";

import { PageContainer } from "components/PageContainer";
import { PageLoader } from "components/PageLoader";

import { DetailsField } from "./DetailsField";

export const VehicleDetailsPage = () => {
  const { vehicleID } = useParams() as { vehicleID: string };
  const { activeDate } = useActiveDate();

  const { vehicle, isLoading } = useVehicleDetails(vehicleID, activeDate, {
    onError: (message: string) => notification.error({ message }),
  });

  if (isLoading) {
    return <PageLoader text="loading vehicle information..." />;
  }

  if (!vehicle) return null;

  return (
    <PageContainer withPadding>
      <S.Header>
        <S.Title>{vehicle.name}</S.Title>

        <Button style={{ marginLeft: "auto" }}>
          <Link to={`/vehicles/${vehicle.id}/edit`}>Edit Vehicle</Link>
        </Button>
      </S.Header>

      <S.VehicleDetails>
        <S.ImageContainer>{vehicle.photo ? <S.Image src={vehicle.photo.url} /> : <S.NoImageIcon />}</S.ImageContainer>

        <S.VehicleDetailsColumn>
          <DetailsField title="License Plate" value={vehicle.license_plate_number} />
          <DetailsField title="VIN" value={vehicle.vin} />
          <DetailsField title="Ownership Type" value={startCase(vehicle.ownership_type.name)} />
          <DetailsField title="Fuel Type" value={startCase(vehicle.fuel_type.name)} />
          <DetailsField title="Service Area" value={upperCase(vehicle.service_area.name)} />
          <DetailsField title="Vehicle Size" value={vehicle.vehicle_size.size} />
        </S.VehicleDetailsColumn>

        <S.VehicleDetailsColumn>
          <DetailsField title="Make" value={startCase(vehicle.make)} />
          <DetailsField title="Model" value={startCase(vehicle.model)} />
          <DetailsField title="Notes" value={vehicle.notes} layout="vertical" />
        </S.VehicleDetailsColumn>
      </S.VehicleDetails>
    </PageContainer>
  );
};
