import { useMemo } from "react";
import { useQuery } from "react-query";

import { keyBy, chain, sortBy } from "lodash";

import { getRouteAppointmentIDs } from "recoil/routes/helpers";

import { useActiveDate } from "hooks/application/useActiveDate";

import { fetchRouteIndex } from "api/routes";
import { Appointment as AppointmentLib } from "lib/appointment";

import { useAppointments } from "./appointments";

import { AppointmentOrShipment } from "interface/appointment";
import { Route } from "interface/route";

type RoutesLibrary = { [routeID: string]: Route };
type VehiclesRoutesMap = { [vehicleID: string]: string[] };
type AppointmentsRoutesMap = { [appointmentID: string]: string };

const ONE_MINUTE = 60 * 1000;

const useRoutes = () => {
  const { activeDate } = useActiveDate();
  const {
    isLoading,
    isFetched,
    error,
    data: routes,
  } = useQuery<Route[], string>(["routes", activeDate], () => fetchRouteIndex(activeDate), {
    staleTime: ONE_MINUTE,
  });

  const routesLibrary = useMemo(() => {
    if (!routes) return {};

    return keyBy(routes, (route) => route.id) as RoutesLibrary;
  }, [routes]);

  const routesVehicles = useMemo(() => {
    if (!routes) return {};

    return chain(routes)
      .groupBy((route) => route.vehicle_id)
      .mapValues((routes) => routes.map((route) => route.id))
      .value() as VehiclesRoutesMap;
  }, [routes]);

  const startingAppointmentIDs = useMemo(() => {
    if (!routes) return [];

    return routes.map((route) => {
      const sortedAppointments = sortBy(route.route_items, "sequence");
      return sortedAppointments[0].appointment_id;
    });
  }, [routes]);

  const endingAppointmentIDs = useMemo(() => {
    if (!routes) return [];

    return routes.map((route) => {
      const sortedAppointments = sortBy(route.route_items, "sequence");
      return sortedAppointments[sortedAppointments.length - 1].appointment_id;
    });
  }, [routes]);

  const appointmentIDsWithExistingRoutes = useMemo(() => {
    if (!routes) return [];

    return routes.flatMap((route) => route.route_items.map((routeItem) => routeItem.appointment_id));
  }, [routes]);

  return {
    isLoading,
    isFetched,
    error,
    routes,
    routesLibrary,
    routesVehicles,
    startingAppointmentIDs,
    endingAppointmentIDs,
    appointmentIDsWithExistingRoutes,
  };
};

const useRoutesWithAppointments = () => {
  const { routesLibrary } = useRoutes();
  const { appointmentsLibrary } = useAppointments();

  const routesWithAppointments = useMemo(() => {
    const getRouteAppointments = (routeID: string) => {
      const appointmentIDs = getRouteAppointmentIDs({ routeID, routesLibrary });
      const appointments = appointmentIDs.map((id) => appointmentsLibrary[id]).filter(Boolean);

      const hasReverseLogistics = appointments.find((a) => a?.job_type === "reverse_logistics");
      const result = hasReverseLogistics
        ? AppointmentLib.groupAppointmentsByReturnToSender(appointments)
        : AppointmentLib.groupAppointmentsByInventoryPickup(appointments);

      return result as AppointmentOrShipment[];
    };

    return Object.values(routesLibrary)
      .map((route) => ({
        ...route,
        appointments: getRouteAppointments(route.id),
      }))
      .filter(({ appointments }) => appointments.length > 0);
  }, [routesLibrary, appointmentsLibrary]);

  return routesWithAppointments;
};

const useAppointmentsRoutesMap = () => {
  const routesWithAppointments = useRoutesWithAppointments();

  const appointmentsRoutesMap = useMemo(() => {
    const result: AppointmentsRoutesMap = {};

    routesWithAppointments.forEach(({ id: routeID, appointments }) => {
      const appointmentsIDs = appointments.map((appointment) => appointment.id);
      appointmentsIDs.forEach((appointmentID) => {
        result[appointmentID] = routeID;
      });
    });

    return result;
  }, [routesWithAppointments]);

  return appointmentsRoutesMap;
};

export { useRoutes, useRoutesWithAppointments, useAppointmentsRoutesMap };
