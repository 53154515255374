import { useMemo } from "react";

import { useRecoilValue } from "recoil";
import { hiddenAppointmentMapState } from "recoil/appointments/atoms";

import { useAppointments } from "hooks/api/appointments";

import { Markers } from "lib/markers";

export interface VisibleMarker {
  markerKey: string;
  appointmentIDs: string[];
  lat: number;
  lng: number;
}

export const useVisibleMarkers = () => {
  const { appointmentsMarkers } = useAppointments();
  const hiddenAppointmentsMap = useRecoilValue(hiddenAppointmentMapState);

  const visibleMarkers = useMemo(() => {
    const noLocationMarkerKey = Markers.getNoLocationMarkerKey();
    return Object.entries(appointmentsMarkers).reduce((acc, cv) => {
      const [markerKey, appointmentIDs] = cv;
      if (markerKey === noLocationMarkerKey) return acc;
      const visibleAppointmentIDs = appointmentIDs.filter((id) => !hiddenAppointmentsMap[id]);
      if (visibleAppointmentIDs.length === 0) return acc;
      const { lat, lng } = JSON.parse(markerKey);
      acc.push({ markerKey, appointmentIDs: visibleAppointmentIDs, lat, lng });
      return acc;
    }, [] as VisibleMarker[]);
  }, [hiddenAppointmentsMap, appointmentsMarkers]);

  return visibleMarkers;
};
