import React, { useState } from "react";
import { useQuery } from "react-query";

import { Popover } from "antd";

import * as S from "./styles";

import { useAppointments } from "hooks/api/appointments";
import { useVehicles } from "hooks/api/vehicles";

import { fetchMultipleAppointmentsDetails } from "api/appointment";

import { RouteInformation } from "./RouteInformation";

import { AppointmentOrShipment } from "interface/appointment";
import { Route } from "interface/route";

const ONE_MINUTE = 60 * 1000;

interface RouteInformationPopoverProps {
  route: Route;
  appointments: AppointmentOrShipment[];
  children: React.ReactNode;
}

export const RouteInformationPopover: React.FC<RouteInformationPopoverProps> = ({ route, appointments, children }) => {
  const { vehiclesLibrary } = useVehicles();
  const { setAppointmentsDetails } = useAppointments();

  const [canShowRouteInformationPopover, setCanShowRouteInformationPopover] = useState(false);

  const { isLoading: isLoadingAppointmentsDetails } = useQuery(
    ["appointments", route.id],
    () => {
      return fetchMultipleAppointmentsDetails(appointments.map(({ id }) => id));
    },
    {
      onSuccess: (appointments) => {
        setAppointmentsDetails(appointments);
      },
      enabled: canShowRouteInformationPopover,
      staleTime: ONE_MINUTE,
    }
  );

  const handleVisibilityChange = (visible: boolean) => {
    if (visible) {
      setCanShowRouteInformationPopover(visible);
    }
  };

  const renderTitle = () => <S.PopoverTitle>Route Information</S.PopoverTitle>;

  const renderContent = () => {
    const routeVehicle = vehiclesLibrary[route.vehicle_id];
    return (
      <RouteInformation
        route={route}
        vehicle={routeVehicle}
        appointments={appointments}
        isLoadingItems={isLoadingAppointmentsDetails}
      />
    );
  };

  return (
    <Popover
      title={renderTitle()}
      content={renderContent()}
      trigger="click"
      placement="topRight"
      onVisibleChange={handleVisibilityChange}
    >
      {children}
    </Popover>
  );
};
