import React, { useEffect, useMemo } from "react";

import { ServiceLevel } from "@secondcloset/fulfillment-utils";
import { Fulfillment } from "@secondcloset/types";

import { TreeSelect } from "antd";

import * as S from "../styles";

import { useRecoilState } from "recoil";
import { activeServiceLevelsState } from "recoil/topFilterControls/atoms";

import { useActiveFlow } from "hooks/application/useActiveFlow";

import { getSelectPlaceHolder } from "../helpers";

import { AppointmentOrShipment } from "interface/appointment";

type ScServiceCode = Fulfillment.ScServiceCode;
type Organization = ServiceLevel.Organization;

interface ServiceLevelFilterProps {
  flowKey: Organization;
  appointments: AppointmentOrShipment[];
}

export const ServiceLevelFilter: React.FC<ServiceLevelFilterProps> = ({ flowKey, appointments }) => {
  const { isIkea } = useActiveFlow();
  const [activeServiceLevels, dispatchActiveServiceLevels] = useRecoilState(activeServiceLevelsState);

  const getOptionLabel = (label: string, key: string) => {
    const optionCount = appointments.filter((appt) => appt?.delivery_service_level === key).length;
    return label + ` (${optionCount})`;
  };
  const initialFilterOptions = ServiceLevel.getServiceLevelList(flowKey).map((sl) => {
    return {
      key: sl.key,
      value: sl.key,
      label: getOptionLabel(sl.label, sl.key),
    };
  });
  const initialSelectedOptions = initialFilterOptions.map((opt) => opt.key) as ScServiceCode[];

  // eslint-disable-next-line
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatchActiveServiceLevels(initialSelectedOptions);
  }, [flowKey]);

  const renderServiceTypeFilter = () => {
    const filterOptions = ServiceLevel.getServiceLevelList(flowKey).map((sl) => {
      return {
        key: sl.key,
        value: sl.key,
        label: getOptionLabel(sl.label, sl.key),
      };
    });

    return (
      <S.FilterContainer>
        <TreeSelect
          treeCheckable
          treeData={filterOptions}
          placeholder={"None Selected"}
          value={activeServiceLevels}
          onChange={dispatchActiveServiceLevels}
          showArrow
          style={{ width: 170 }}
          dropdownStyle={{ minWidth: isIkea ? 270 : 206 }}
          showSearch={false}
          maxTagCount={0}
          maxTagPlaceholder={() => (
            <S.SelectedText>
              {getSelectPlaceHolder([filterOptions.length], activeServiceLevels, "Service Levels", filterOptions, null)}
            </S.SelectedText>
          )}
        />
      </S.FilterContainer>
    );
  };

  return useMemo(renderServiceTypeFilter, [activeServiceLevels, appointments]);
};
