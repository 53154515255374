import { atom } from "recoil";

import { Route } from "interface/route";

export interface ActiveMarkerState {
  id: string;
  shouldCenter: boolean;
  selectedAppointmentId?: string;
}

export const activeMarkerState = atom<ActiveMarkerState>({
  key: "activeMarkerState",
  default: { id: "", shouldCenter: false, selectedAppointmentId: "" },
});

export const hoverAppointmentsState = atom<string[] | null>({
  key: "hoverAppointmentsState",
  default: null,
});

type RouteID = Route["id"];
export const hiddenRoutesIDsState = atom<RouteID[]>({
  key: "hiddenRoutesIDsState",
  default: [],
});

export const isUnassignedAppointmentsHiddenState = atom({
  key: "isUnassignedAppointmentsHiddenState",
  default: false,
});
