import { stringify } from "querystring";

import { API } from "lib/API";

import { Pagination } from "interface/pagination";
import { Vehicle, Statistics } from "interface/vehicle";

export interface VehiclesResponse {
  vehicles: Vehicle[];
  statistics: Statistics;
  pagination: Pagination;
}

const getURL = (query?: Record<string, string | number>) => {
  let queryString = "";
  if (query) {
    queryString = stringify(query);
  }

  return queryString ? `/vehicles?${queryString}` : "/vehicles";
};

export const fetchVehicleIndex = async (query?: Record<string, string | number>): Promise<VehiclesResponse> => {
  const api = new API();
  const url = getURL(query);

  const response = await api.get(url);
  return response.data as VehiclesResponse;
};
