import { atom } from "recoil";

import { Route, RouteUser } from "interface/route";

export enum SideBarViewType {
  "main" = "main",
  "multipleAppts" = "multipleAppts",
  "constructRoute" = "constructRoute",
}

export const sideBarActiveViewState = atom<SideBarViewType>({
  key: "sideBarActiveViewState",
  default: SideBarViewType.main,
});

export const constructingVehicleIDState = atom<string | null>({
  key: "constructingVehicleIDState",
  default: null,
});

export const constructingRouteIDState = atom<string | null>({
  key: "constructingRouteIDState",
  default: null,
});

export const constructingRouteNameState = atom<string | null>({
  key: "constructingRouteNameState",
  default: null,
});

export const constructingRouteUsersState = atom<RouteUser[]>({
  key: "constructingRouteUsersState",
  default: [],
});

export const constructingRouteAppointmentIDsState = atom<string[]>({
  key: "constructingRouteAppointmentIDsState",
  default: [],
});

export const isConstructingModeState = atom<boolean>({
  key: "isConstructingModeState",
  default: false,
});

export const isIkeaOutboundState = atom<boolean>({
  key: "isIkeaOutboundState",
  default: true,
});

export interface SidebarSearchState {
  searchKey: string;
}
export const sidebarSearchState = atom<SidebarSearchState>({
  key: "sidebarSearchState",
  default: {
    searchKey: "",
  },
});

interface AppointmentDetailsModalState {
  visible: boolean;
  appointmentID: string;
}
export const appointmentDetailsModalState = atom<AppointmentDetailsModalState>({
  key: "appointmentDetailsModalState",
  default: {
    visible: false,
    appointmentID: "",
  },
});

interface ActiveMarkerAppointmentsOverviewState {
  visible: boolean;
  appointmentsIDs: string[];
}
export const activeMarkerAppointmentsOverviewState = atom<ActiveMarkerAppointmentsOverviewState>({
  key: "activeMarkerAppointmentsOverviewState",
  default: {
    visible: false,
    appointmentsIDs: [],
  },
});

type RouteID = Route["id"];
interface ActiveRouteOverviewState {
  visible: boolean;
  routeID: string;
  previouslyHiddenRouteIDs: RouteID[];
}
export const activeRouteOverviewState = atom<ActiveRouteOverviewState>({
  key: "activeRouteOverviewState",
  default: {
    visible: false,
    routeID: "",
    previouslyHiddenRouteIDs: [],
  },
});
