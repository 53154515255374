import { without } from "lodash";
import { DataNode } from "rc-tree-select/lib/interface";

import { useRecoilState, useResetRecoilState } from "recoil";
import { activeOrganizationsState } from "recoil/topFilterControls/atoms";

import { ALL_ORGANIZATIONS_KEY } from "../../helpers";

export const useToggleOptions = () => {
  const [activeOrganizations, dispatchActiveOrganizations] = useRecoilState(activeOrganizationsState);
  const resetActiveOrganizations = useResetRecoilState(activeOrganizationsState);

  const onSelect = (selectedOrganization: string, filterOptions?: DataNode[]) => {
    if (!filterOptions) return;
    let selectedOrganizations = [...activeOrganizations, selectedOrganization];

    const areAllOrganizationsSelected =
      !selectedOrganizations.includes(ALL_ORGANIZATIONS_KEY) &&
      selectedOrganizations.length === filterOptions.length - 1;

    if (areAllOrganizationsSelected) selectedOrganizations = [...selectedOrganizations, ALL_ORGANIZATIONS_KEY];

    if (selectedOrganization === ALL_ORGANIZATIONS_KEY)
      selectedOrganizations = filterOptions.map((opt) => opt.value) as string[];

    return dispatchActiveOrganizations(selectedOrganizations);
  };

  const onDeselect = (deselectedOrganization: string, filterOptions?: DataNode[]) => {
    if (!filterOptions) return;
    if (deselectedOrganization === ALL_ORGANIZATIONS_KEY) return resetActiveOrganizations();

    const selectedOrganizations = without(activeOrganizations, deselectedOrganization, ALL_ORGANIZATIONS_KEY);
    return dispatchActiveOrganizations(selectedOrganizations);
  };

  return { onSelect, onDeselect };
};
