import { Common } from "@secondcloset/types";

import { API } from "lib/API";

type ServiceArea = Common.ServiceArea;
type Boundary = [{ latitude: number; longitude: number }];
type Address = { country: string; province: string };

export interface ServiceAreaResponse {
  id: number;
  facilities: ServiceArea[];
  name: ServiceArea;
  timezone: string;
  standard_boundary: Boundary;
  extended_boundary: Boundary;
  created_at: string;
  updated_at: string;
  address: Address;
  humanized_name: string;
}

export const fetchServiceAreas = async () => {
  const api = new API("logistics");
  const url = `/service_areas`;
  const response = await api.get(url);
  return response.data as ServiceAreaResponse[];
};
