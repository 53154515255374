import { useState } from "react";

import { random } from "lodash";

import * as S from "./styles";

export const AppointmentPreloader = () => {
  const [rowsWidths] = useState(() => {
    return [random(50, 80), random(40, 60), random(40, 60)];
  });

  return (
    <S.AppointmentPreloader>
      <S.MapIconPreloader />

      <S.AppointmentPreloaderRows>
        <S.AppointmentRowPreloader width={rowsWidths[0]} />
        <S.AppointmentRowPreloader width={rowsWidths[1]} />
        <S.AppointmentRowPreloader width={rowsWidths[2]} />
      </S.AppointmentPreloaderRows>
    </S.AppointmentPreloader>
  );
};
