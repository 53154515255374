import { AppointmentType } from "@secondcloset/types/src/fulfillment";

import { useSetRecoilState } from "recoil";
import { isIkeaOutboundState } from "recoil/sideBarControls/atoms";
import { activeAppointmentTypesState, selectedIkeaAppointmentsFilterValuesState } from "recoil/topFilterControls/atoms";

export const useIKEAInOutboundToggle = () => {
  const dispatchIkeaAppointmentFilterValues = useSetRecoilState(selectedIkeaAppointmentsFilterValuesState);
  const dispatchAppointmentTypes = useSetRecoilState(activeAppointmentTypesState);
  const dispatchIsIkeaOutbound = useSetRecoilState(isIkeaOutboundState);

  const handleIkeaInOutToggle = (ikeaAppointemntFilterValues: string[]) => {
    dispatchAppointmentTypes(ikeaAppointemntFilterValues as AppointmentType[]);
    dispatchIkeaAppointmentFilterValues(ikeaAppointemntFilterValues);
    const isOutbound = ikeaAppointemntFilterValues.includes("outbound");
    dispatchIsIkeaOutbound(isOutbound);
  };

  return handleIkeaInOutToggle;
};
