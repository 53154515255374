import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 16px;
`;

export const Text = styled.div`
  margin: 0 8px 0 0;
  color: ${COLORS.GREY};
`;

export const VersionText = styled.div`
  color: ${COLORS.GREY};
`;
