import React from "react";

import { AvailabilitySlot } from "@secondcloset/logistics-utils";
import { Logistics } from "@secondcloset/types";

import { uniq, sum } from "lodash";

import * as S from "./styles";

type AvailabilityTimeSlot = Logistics.AvailabilityTimeSlot;

interface CapacityByTimeSlotTableProps {
  allowEdit: boolean;
  onChangeTimeslotLimit: (timeslotAvailability: AvailabilityTimeSlot) => void;
  slotAvailabilitiesData: AvailabilityTimeSlot[];
}

export const CapacityByTimeSlotTable: React.FC<CapacityByTimeSlotTableProps> = ({
  allowEdit,
  onChangeTimeslotLimit,
  slotAvailabilitiesData,
}) => {
  const getTimeslotAvailabilities = (timeSlot: string) => {
    const timeSlotData = slotAvailabilitiesData.filter((slot) => slot.timeslot === timeSlot);

    const nonWhiteGloveAvailability = timeSlotData.find((slot) => slot.delivery_service_level === "non_white_glove");
    const whiteGloveAvailability = timeSlotData.find((slot) => slot.delivery_service_level === "white_glove");
    const consumerAvailability = timeSlotData.find((slot) => slot.customer_segment === "consumer");

    return { nonWhiteGloveAvailability, whiteGloveAvailability, consumerAvailability };
  };

  const timeSlots = uniq(slotAvailabilitiesData.map((slot) => slot.timeslot));

  const renderTableTitles = () => {
    return (
      <S.TitleRow>
        <div>Time Slot</div>
        <div>All other Service</div>
        <div>White Glove</div>
        <div>Consumer</div>
        <div>Max Limit</div>
      </S.TitleRow>
    );
  };

  const renderTimeslotLimitInput = (timeslotAvailability?: AvailabilityTimeSlot) => {
    return (
      <S.MaxAppointmentInput
        min={0}
        disabled={!allowEdit || !timeslotAvailability}
        value={timeslotAvailability?.maximum_appointments_number || 0}
        parser={(value) => {
          const result = parseInt(value || "0");
          return Number.isNaN(result) ? 0 : result;
        }}
        onChange={(value) => {
          if (!timeslotAvailability) return;

          onChangeTimeslotLimit({
            ...timeslotAvailability,
            maximum_appointments_number: value as number,
          });
        }}
      />
    );
  };

  const renderTimeslotAvailabilities = (
    timeslot: string,
    nonWhiteGloveAvailability?: AvailabilityTimeSlot,
    whiteGloveAvailability?: AvailabilityTimeSlot,
    consumerAvailability?: AvailabilityTimeSlot
  ) => {
    const totalMaxAppointmentsNumber = sum([
      nonWhiteGloveAvailability?.maximum_appointments_number ?? 0,
      whiteGloveAvailability?.maximum_appointments_number ?? 0,
      consumerAvailability?.maximum_appointments_number ?? 0,
    ]);

    return (
      <div key={timeslot}>
        <S.Timeslot>{AvailabilitySlot.getDisplaySlot(timeslot)}</S.Timeslot>

        <S.BookedRow>
          <div>Booked</div>

          <S.BookedAppointmentInput value={nonWhiteGloveAvailability?.booked_appointments_count || 0} disabled />
          <S.BookedAppointmentInput value={whiteGloveAvailability?.booked_appointments_count || 0} disabled />
          <S.BookedAppointmentInput value={consumerAvailability?.booked_appointments_count || 0} disabled />
        </S.BookedRow>

        <S.BookedRow>
          <div>Max Limit</div>

          {renderTimeslotLimitInput(nonWhiteGloveAvailability)}
          {renderTimeslotLimitInput(whiteGloveAvailability)}
          {renderTimeslotLimitInput(consumerAvailability)}

          <S.MaxLimitInput value={totalMaxAppointmentsNumber} disabled />
        </S.BookedRow>
      </div>
    );
  };

  return (
    <S.Container>
      {renderTableTitles()}

      {timeSlots.map((timeSlot) => {
        const { whiteGloveAvailability, nonWhiteGloveAvailability, consumerAvailability } =
          getTimeslotAvailabilities(timeSlot);

        return renderTimeslotAvailabilities(
          timeSlot,
          nonWhiteGloveAvailability,
          whiteGloveAvailability,
          consumerAvailability
        );
      })}
    </S.Container>
  );
};
