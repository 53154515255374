import { selector } from "recoil";

import { activeMarkerState } from "./atoms";

export const resetRouteMapControls = selector({
  key: "resetRouteMapControls",
  get: () => {
    return;
  },
  set: ({ reset }) => {
    reset(activeMarkerState);
  },
});
