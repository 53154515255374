import React from "react";

import { Fulfillment, Storage } from "@secondcloset/types";

import { Popconfirm, Table, Typography } from "antd";

import { COLORS } from "styles";

import { useAppointments } from "hooks/api/appointments";

import { RouteItem } from "interface/route";

type AppointmentStatus = Storage.AppointmentStatus | Fulfillment.ShippingMethodStatus | "signed";

interface RouteItemsTableProps {
  data: RouteItem[];
  isLoading: boolean;
  onDelete: (apptID: string) => void;
}
export const RouteItemsTable: React.FC<RouteItemsTableProps> = ({ isLoading, data, onDelete }) => {
  const { appointmentsLibrary } = useAppointments();

  const getAppointmentFromLibrary = (id: string) => {
    return appointmentsLibrary[id];
  };

  const renderAppointmentID = (apptID: string) => {
    const to = `${process.env.REACT_APP_SIERRA_DOMAIN}/appointments/${apptID}`;
    return (
      <Typography.Link href={to} target="_blank">
        {apptID.substring(apptID.length - 4, apptID.length)}
      </Typography.Link>
    );
  };

  const renderStatus = (apptID: string) => {
    const appointment = getAppointmentFromLibrary(apptID);
    const status = appointment?.status as AppointmentStatus;
    let color;
    switch (status) {
      case "completed":
        color = COLORS.BLUE;
        break;
      case "failed":
      case "incomplete":
      case "shipment_cancelled":
        color = COLORS.RED;
        break;
      case "on_the_way":
      case "started":
      case "arrived":
        color = COLORS.ORANGE;
        break;
      case "confirmed":
        color = COLORS.GRASS_GREEN;
        break;
      case "done":
      case "signed":
        color = COLORS.YELLOW;
        break;
      default:
        color = "black";
    }

    return <div style={{ color }}>{status}</div>;
  };

  const renderDeleteButton = (apptID: string) => {
    return (
      <Popconfirm
        title="Are you sure you want to delete this appointment?"
        onConfirm={() => onDelete(apptID)}
        okText="Yes"
        cancelText="No"
        placement="right"
      >
        <div style={{ cursor: "pointer", color: "red" }}>Delete</div>
      </Popconfirm>
    );
  };

  const renderAppointmentType = (apptID: string) => {
    const appointment = getAppointmentFromLibrary(apptID);
    return appointment?.job_type || "-";
  };

  return (
    <Table
      loading={isLoading}
      columns={[
        {
          title: "Appt. ID",
          dataIndex: "appointment_id",
          key: "appointment_id",
          render: renderAppointmentID,
        },
        {
          title: "Route Number",
          dataIndex: "route_number",
          key: "route_number",
        },
        {
          title: "Sequence",
          dataIndex: "sequence",
          key: "sequence",
        },
        {
          title: "Appt. Type",
          dataIndex: "appointment_id",
          key: "appointment_type",
          render: renderAppointmentType,
        },
        {
          title: "Status",
          dataIndex: "appointment_id",
          key: "status",
          render: renderStatus,
        },
        {
          title: "Delete",
          dataIndex: "appointment_id",
          key: "onDelete",
          render: renderDeleteButton,
        },
      ]}
      dataSource={data}
      pagination={{
        pageSize: 20,
        showTotal: (total) => total + " route items",
      }}
      size="small"
      rowKey="appointment_id"
    />
  );
};
