import React from "react";

import * as S from "./styles";

interface TabProps {
  active: boolean;
  onClick: () => void;
}

export const Tab: React.FC<TabProps> = ({ children, active, onClick }) => {
  return (
    <S.Container active={active} onClick={onClick}>
      {children}
    </S.Container>
  );
};
