import React from "react";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

import { Navbar } from "components/NavBar";

import { PrivateRoute } from "./PrivateRoute";
import { routes } from "./routes";

export const Router: React.FC = () => {
  const privateRoutes = routes
    .filter((r) => r.isPrivate)
    .map((route) => <PrivateRoute exact key={route.key} path={route.path} component={route.component} />);

  const publicRoutes = routes
    .filter((r) => !r.isPrivate)
    .map((route) => {
      const { key, path, component: Component } = route;
      return <Route exact key={key} path={path} render={(props) => <Component {...props} />} />;
    });

  return (
    <BrowserRouter>
      <Navbar />
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/routes" />} />
        {privateRoutes}
        {publicRoutes}
      </Switch>
    </BrowserRouter>
  );
};
