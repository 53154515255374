import React from "react";

import styled, { css } from "styled-components";

interface ContainerProps {
  layout?: "horizontal" | "vertical";
}
const Container = styled.div<ContainerProps>`
  display: flex;
  gap: 10px;
  font-size: 16px;

  > div {
    width: 50%;
  }

  ${(props) =>
    props.layout === "vertical" &&
    css`
      flex-direction: column;

      > div {
        width: 100%;
      }
    `}
`;

const Title = styled.div`
  font-weight: 600;
`;

interface DetailsFieldProps {
  layout?: "horizontal" | "vertical";
  title: string;
  value?: string;
}

export const DetailsField: React.FC<DetailsFieldProps> = ({ layout = "horizontal", title, value }) => (
  <Container layout={layout}>
    <Title>{title}</Title>
    <div>{value}</div>
  </Container>
);
