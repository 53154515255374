import { HiX, HiSearch, HiCheck } from "react-icons/hi";

import { Divider as AntdDivider, Modal as AntdModal, Input as AntdInput, Table as AntdTable } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

export const Modal = styled(AntdModal)`
  && {
    height: calc(100vh - 20px);

    .ant-modal-close-x {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .ant-modal-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: auto;
    }

    .ant-modal-body {
      flex-grow: 1;
      padding-bottom: 0;
    }
  }
`;

export const ModalStyle = {
  top: 20,
  bottom: 20,
};

export const ModalCloseButton = styled(HiX)`
  width: 20px;
  height: 20px;
  color: ${COLORS.PRIMARY_BLUE};
`;

export const TopBar = styled.div`
  padding-bottom: 14px;
  display: flex;
  justify-content: space-between;
`;

export const SearchInput = styled(AntdInput)`
  width: 256px;

  input {
    border-radius: 2px;
  }
  input:focus + .ant-input-suffix svg {
    color: ${COLORS.PRIMARY_BLUE};
  }
`;

export const SearchInputIcon = styled(HiSearch)`
  width: 20px;
  height: 20px;
  color: ${COLORS.GREY_DARK};
`;

export const VehiclesTable = styled(AntdTable)<{ hasDeselect?: boolean }>`
  .ant-table-tbody > tr {
    position: relative;
  }
  .ant-table-tbody > tr.selected {
    & > td {
      background: ${COLORS.ASH_BLUE};
    }
    ${({ hasDeselect }) =>
      hasDeselect &&
      `
        &:hover > td.action > svg {
          display: none;
        }
      `}
  }
  .ant-table-tbody > tr:not(.selected):hover > td {
    background: white;
  }
  .ant-table-tbody > tr:hover::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid ${COLORS.PRIMARY_BLUE};
    pointer-events: none;
  }
  .ant-table-tbody > tr:hover > td.action .action-button {
    display: inline-block;
  }

  .ant-table-tbody td.action {
    width: 154px;
    padding: 0;
    text-align: center;
  }
  .action-button {
    display: none;
  }
`;

export const CheckIcon = styled(HiCheck)`
  width: 24px;
  height: 24px;
  color: ${COLORS.PRIMARY_BLUE};
`;

export const ToggleContainer = styled.div`
  padding: 12px 20px;
`;

export const ToggleButton = styled.button<{ active?: boolean }>`
  padding: 0;
  background: 0;
  border: 0;
  color: ${COLORS.GREY_LIGHT};
  ${({ disabled }) => !disabled && `cursor: pointer;`}
  ${({ active }) => active && `color: ${COLORS.GREY_DARK}`};
`;

export const Divider = styled(AntdDivider)`
  background: ${COLORS.GREY_DARK};
`;
