import { Common } from "@secondcloset/types";

import { API } from "lib/API";

type Credential = Common.Credential;

type LoginProp = {
  email: string;
  password: string;
};

export const login = async ({ email, password }: LoginProp): Promise<Partial<Credential>> => {
  const api = new API();
  const url = "/tokens";
  const body = { user: { password, email } };
  const response = await api.post(url, body);
  return response.data;
};
