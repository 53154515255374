import { atom } from "recoil";

export interface HiddenAppointmentMapState {
  [appointmentID: string]: boolean;
}

export const hiddenAppointmentMapState = atom<HiddenAppointmentMapState>({
  key: "hiddenAppointmentMapState",
  default: {},
});
