import { useEffect } from "react";
import { Route, Redirect, useParams, useLocation } from "react-router-dom";

import { useActiveFlow } from "hooks/application/useActiveFlow";

import { DayView } from "./DayView";
import { MonthView } from "./MonthView";
import { Container as PageContainer } from "./PageLayout/Container";
import { View } from "./types";

export const CapacityUtilizationPage = () => {
  const { view } = useParams() as { view: View };
  const { setActiveFlowKey, WORK_FLOW_OPTIONS, isIkea } = useActiveFlow();

  useEffect(() => {
    if (isIkea) setActiveFlowKey(WORK_FLOW_OPTIONS.BOLT_LOGISTICS.key);
  }, [WORK_FLOW_OPTIONS.BOLT_LOGISTICS.key, isIkea, setActiveFlowKey]);

  const location = useLocation();

  if (!view) {
    return <Redirect to={{ ...location, pathname: "/capacity-utilization/day" }} />;
  }

  return (
    <PageContainer>
      <Route path="/capacity-utilization/day">
        <DayView />
      </Route>

      <Route path="/capacity-utilization/month">
        <MonthView />
      </Route>
    </PageContainer>
  );
};
