import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";

import { notification } from "antd";

import * as S from "./styles";

import { useRecoilValue } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";
import { CreateRouteView } from "recoil/routes/atoms";
import { activeIkeaWarehouseAddressIDState } from "recoil/topFilterControls/atoms";

import { useAppointmentsRoutesMap } from "hooks/api/routes";
import { useCreateRouteState } from "hooks/application/createRoute";
import { useCreateRouteAutomation } from "hooks/application/createRouteAutomation";
import { useActiveDate } from "hooks/application/useActiveDate";
import { useActiveFlow } from "hooks/application/useActiveFlow";
import { useActiveMarker } from "hooks/application/useActiveMarker";
import {
  useUnassignedAppointmentsMapVisibility,
  useAssignedAppointmentsMapVisibility,
} from "hooks/application/useRoutesMapVisibility";
import { useCollapseExpandedPanels } from "hooks/ui/useCollapseExpandedPanels";

import { SelectMultipleVehicleModal } from "components/SelectVehicleModal";
import { SelectAllToggle } from "pages/RoutesPage/SideBar/MainView/SelectAllToggle";

import {
  AssignedShipmentsList,
  useAppointmentsToRender as useAssignedAppointmentsToRender,
} from "../AssignedShipmentsList";
import { Collapse, CollapsePanel } from "../Collapse";
import { AssignedRouteHeader, UnassignedRouteHeader } from "../RouteHeader";
import {
  UnassignedShipmentsList,
  useAppointmentsToRender as useUnassignedAppointmentsToRender,
} from "../UnassignedShipmentsList";

import { SelectIkeaWarehouseModal } from "./SelectIkeaWarehouseModal";

import { Vehicle } from "interface/vehicle";

export const MainView = () => {
  const { isVisible: isUnassignedAppointmentsVisible, toggleVisibility: toggleUnassignedAppointmentsVisibility } =
    useUnassignedAppointmentsMapVisibility();
  const { isVisible: isAnyRouteVisible, toggleVisibility: toggleAssignedAppointmentsVisibility } =
    useAssignedAppointmentsMapVisibility();
  const queryClient = useQueryClient();

  const { isIkea: isIkeaFlow, activeFlowKey } = useActiveFlow();
  const { activeDate } = useActiveDate();
  const activeServiceArea = useRecoilValue(activeServiceAreaState);
  const activeIkeaWarehouseID = useRecoilValue(activeIkeaWarehouseAddressIDState);
  const [isSelectIkeaWarehouseModalVisible, setIsSelectIkeaWarehouseModalVisible] = useState(false);

  const {
    enabled: isCreateRouteStateEnabled,
    enable: enableCreateRouteState,
    setCurrentView: setCreateRouteStateView,
    resetState: resetCreateRouteState,
  } = useCreateRouteState();

  const handleRouteCreateSuccess = () => {
    queryClient.refetchQueries(["routeAutomationJobs"]);
    resetRouteAutomation();
    resetCreateRouteState();
    notification.success({
      message: "Success!",
      description: "Route automation job has been initiated",
    });
  };

  const handleRouteCreateError = (e: string) => {
    notification.error({
      message: "Save Route Error",
      description: e,
    });
  };

  const {
    enableRouteAutomation,
    reset: resetRouteAutomation,
    enabled: enabledCreateRouteAutomation,
    vehicles: createRouteAutomationVehicles,
    isLoading: loadingCreateRouteAutomation,
    handleCreateRouteAutomationJob,
  } = useCreateRouteAutomation(handleRouteCreateSuccess, handleRouteCreateError);

  useEffect(() => {
    resetCreateRouteState();
    resetRouteAutomation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFlowKey, activeDate, activeServiceArea]);
  const unassignedAppointmentsToRender = useUnassignedAppointmentsToRender();
  const { appointmentsToRender: assignedAppointmentsToRender } = useAssignedAppointmentsToRender();

  const handleCreateRouteButtonClick = () => {
    if (isIkeaFlow && activeIkeaWarehouseID === "") {
      setIsSelectIkeaWarehouseModalVisible(true);
      return;
    }

    enableCreateRouteState();
    setCreateRouteStateView(CreateRouteView.SelectAppointments);
  };

  const handleCancelRouteButtonClick = () => {
    resetCreateRouteState();
  };

  const handleSelectIkeaWarehouseModalClose = (ikeaWarehouseID?: string) => {
    const isIkeaWarehouseSelected = !!ikeaWarehouseID;
    if (isIkeaWarehouseSelected) {
      enableCreateRouteState();
      setCreateRouteStateView(CreateRouteView.SelectAppointments);
    }

    setIsSelectIkeaWarehouseModalVisible(false);
  };

  const enableRouteConstructionView = () => {
    enableCreateRouteState();
    setCreateRouteStateView(CreateRouteView.ConstructRoute);
  };

  const onCreateRouteAutomation = () => {
    enableRouteAutomation();
  };

  const onCloseRouteAutomationModal = () => {
    resetRouteAutomation();
  };

  const handleOnSave = async (vehicles: Vehicle[]) => {
    if (!vehicles.length) {
      return notification.error({
        message: "At least 1 vehicle is required for route automation",
      });
    }
    await handleCreateRouteAutomationJob(vehicles);
  };

  const getShouldRenderUnassignedAppointmentsSection = () => {
    if (isCreateRouteStateEnabled) return true;
    return unassignedAppointmentsToRender.length > 0;
  };

  const { expandedPanels, expandPanel, handleChangeExpandedPanels } = useCollapseExpandedPanels(["unassigned"]);

  useEffect(() => {
    if (isCreateRouteStateEnabled) {
      expandPanel("unassigned");
    }
  }, [isCreateRouteStateEnabled, expandPanel]);

  const { activeMarker } = useActiveMarker();
  const appointmentsRoutesMap = useAppointmentsRoutesMap();
  useEffect(() => {
    if (!activeMarker.selectedAppointmentId) return;

    const routeID = appointmentsRoutesMap[activeMarker.selectedAppointmentId];
    expandPanel(routeID ? "assigned" : "unassigned");
  }, [activeMarker.selectedAppointmentId, appointmentsRoutesMap, expandPanel]);

  return (
    <Collapse activeKey={expandedPanels} onChange={handleChangeExpandedPanels}>
      {getShouldRenderUnassignedAppointmentsSection() && (
        <CollapsePanel
          key="unassigned"
          header={
            <UnassignedRouteHeader
              label={
                isCreateRouteStateEnabled
                  ? "Selecting Shipments"
                  : `Unassigned (${unassignedAppointmentsToRender.length})`
              }
              visible={isUnassignedAppointmentsVisible}
              mode={isCreateRouteStateEnabled ? "create" : "view"}
              onToggleDisplay={toggleUnassignedAppointmentsVisibility}
              onCreate={handleCreateRouteButtonClick}
              onCancel={handleCancelRouteButtonClick}
            />
          }
          highlight={true}
        >
          {isCreateRouteStateEnabled && <SelectAllToggle />}
          {unassignedAppointmentsToRender.length > 0 ? (
            <UnassignedShipmentsList
              onConstruction={enableRouteConstructionView}
              onCreateRouteAutomation={onCreateRouteAutomation}
            />
          ) : (
            <S.NoAppointmentsLabel>No appointments</S.NoAppointmentsLabel>
          )}
        </CollapsePanel>
      )}

      {assignedAppointmentsToRender.length > 0 && (
        <CollapsePanel
          key="assigned"
          header={
            <AssignedRouteHeader
              label={`Assigned Shipments (${assignedAppointmentsToRender.length})`}
              visible={isAnyRouteVisible}
              onToggleDisplay={toggleAssignedAppointmentsVisibility}
            />
          }
          highlight={true}
        >
          <AssignedShipmentsList />
        </CollapsePanel>
      )}

      <SelectIkeaWarehouseModal
        visible={isSelectIkeaWarehouseModalVisible}
        onClose={handleSelectIkeaWarehouseModalClose}
      />

      <SelectMultipleVehicleModal
        visible={enabledCreateRouteAutomation}
        isLoading={loadingCreateRouteAutomation}
        selectedVehicleIDs={createRouteAutomationVehicles.map((vehicle) => vehicle.id)}
        onClose={onCloseRouteAutomationModal}
        onSave={handleOnSave}
      />
    </Collapse>
  );
};
