import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 25px;

  & > div {
    flex: 1;
  }
`;
