import styled from "styled-components";

import { COLORS } from "styles";
import { pulseAnimation } from "styles/animations";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  border: 1px solid ${COLORS.GREY_LIGHT};
`;

export const AppointmentPreloader = styled.div`
  display: flex;
  gap: 5px;
  padding: 10px;
  border-radius: 5px;
  background-color: rgb(229, 231, 235);
  ${pulseAnimation};
`;

export const MapIconPreloader = styled.div`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(156, 163, 175, 0.5);
`;

export const AppointmentPreloaderRows = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
`;

interface AppointmentRowPreloaderProps {
  width: number;
}
export const AppointmentRowPreloader = styled.div<AppointmentRowPreloaderProps>`
  width: ${(props) => props.width}%;
  height: 15px;
  border-radius: 3px;
  background-color: rgba(156, 163, 175, 0.5);
  ${pulseAnimation};
`;

export const RouteHeaderPreloader = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  background-color: rgba(209, 213, 219, 0.8);
`;

export const RouteHeaderTitle = styled.div`
  width: 100px;
  height: 20px;
  border-radius: 5px;
  background-color: rgba(156, 163, 175, 0.5);
`;

export const RouteHeaderButtons = styled.div`
  display: flex;
  margin-left: auto;
  gap: 5px;
`;

export const RouteHeaderButton = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: rgba(156, 163, 175, 0.5);
`;
