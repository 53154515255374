import { Common } from "@secondcloset/types";
type IOrganization = Common.Organization;

const IKEA_ORG_IDS = {
  staging: "1cc0c40a-43a2-11ea-94b5-df8fd7904ab8",
  preprod: "1cc0c40a-43a2-11ea-94b5-df8fd7904ab8",
  production: "4eb2d36c-4e70-11ea-8ee7-f39aa09266cc",
};

export class Organization {
  static isIKEA = (organization: IOrganization): boolean => {
    const orgID = organization?.id || "";
    return Organization.isIkeaOrgID(orgID);
  };

  static isIkeaOrgID = (organizationID: string): boolean => {
    return Object.values(IKEA_ORG_IDS).includes(organizationID);
  };

  static getIkeaOrgID = (): string => {
    const baseURL = process.env.REACT_APP_API;
    if (baseURL?.includes("staging")) {
      return IKEA_ORG_IDS.staging;
    } else if (baseURL?.includes("preprod")) {
      return IKEA_ORG_IDS.preprod;
    } else if (baseURL?.includes("secondcloset")) {
      return IKEA_ORG_IDS.production;
    } else return IKEA_ORG_IDS.staging;
  };
}
