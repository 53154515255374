import React, { useMemo } from "react";
import { HiOutlineAdjustments, HiOutlineHand } from "react-icons/hi";

import { ServiceArea } from "@secondcloset/types/src/_common";

import { Tooltip } from "antd";

import * as S from "./styles";

import { useRecoilValue } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";
import { isIkeaOutboundState } from "recoil/sideBarControls/atoms";
import { activeIkeaWarehouseAddressIDState } from "recoil/topFilterControls/atoms";

import { useRouteAutomationJobs } from "hooks/api/useRouteAutomationJobs";
import { useCreateRouteState } from "hooks/application/createRoute";

import { routeAutomationWarehouseIds } from "constants/routeAutomationWarehouseIds";

interface CreateRouteMenuProps {
  onCreateRouteManual: () => void;
  onCreateRouteAutomation: () => void;
  selectedCount: number;
}

const SERVICE_AREAS_WITH_ROUTE_AUTOMATION = ["yyz"] as ServiceArea[];

export const CreateRouteMenu: React.FC<CreateRouteMenuProps> = ({
  onCreateRouteManual,
  onCreateRouteAutomation,
  selectedCount,
}) => {
  const serviceArea = useRecoilValue(activeServiceAreaState);
  const activeIkeaWarehouseID = useRecoilValue(activeIkeaWarehouseAddressIDState);
  const { selectedAppointmentsIDs } = useCreateRouteState();
  const { appointmentIsInActiveJob } = useRouteAutomationJobs();
  const isIkeaOutbound = useRecoilValue(isIkeaOutboundState);

  const areAppointmentsValid = useMemo(
    () => selectedAppointmentsIDs.every((id) => !appointmentIsInActiveJob({ id })),
    [selectedAppointmentsIDs, appointmentIsInActiveJob]
  );

  const renderMenuItems = () => {
    const shouldShowRouteAutomationButton =
      isIkeaOutbound &&
      SERVICE_AREAS_WITH_ROUTE_AUTOMATION.includes(serviceArea as ServiceArea) &&
      routeAutomationWarehouseIds.includes(activeIkeaWarehouseID);
    return (
      <div>
        {shouldShowRouteAutomationButton ? (
          <S.MenuItem
            onClick={areAppointmentsValid ? onCreateRouteAutomation : undefined}
            disabled={!areAppointmentsValid}
          >
            <S.MenuItemIcon as={HiOutlineAdjustments} disabled={!areAppointmentsValid} />
            Route Automation
          </S.MenuItem>
        ) : null}

        <S.MenuItem onClick={areAppointmentsValid ? onCreateRouteManual : undefined} disabled={!areAppointmentsValid}>
          <S.MenuItemIcon as={HiOutlineHand} disabled={!areAppointmentsValid} />
          Create route manually
        </S.MenuItem>
      </div>
    );
  };

  return (
    <S.Container>
      <S.Title>{selectedCount} selected</S.Title>

      {areAppointmentsValid ? (
        renderMenuItems()
      ) : (
        <Tooltip
          title="Some of these appointments are currently being processed in another route automation job.
        Please remove the appointments or wait until the job has completed."
        >
          {renderMenuItems()}
        </Tooltip>
      )}
    </S.Container>
  );
};
