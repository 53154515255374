import styled from "styled-components";

export const PageContainer = styled.div`
  padding: 16px;
`;

export const BodyContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ImageUploadContainer = styled.div`
  flex: 1;
  padding-left: 16px;
`;
