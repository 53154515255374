import { useState, useEffect, useCallback } from "react";

import Fuse from "fuse.js";

export const useFuzzySearch = <T>(data: T[], options: Fuse.IFuseOptions<T>) => {
  const [fuse] = useState(() => new Fuse(data, options));

  useEffect(() => {
    fuse.setCollection(data);
  }, [fuse, data]);

  const search = useCallback(
    (searchExpression: string | Fuse.Expression) => {
      return searchExpression ? fuse.search(searchExpression).map((result) => result.item) : data;
    },
    [data, fuse]
  );

  return search;
};
