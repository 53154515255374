import { chain } from "lodash";
import isEmpty from "lodash/isEmpty";

import { Route } from "lib/route";
import { ConstructParams } from "pages/RoutesPage/SideBar/ConstructingView/types";

import { AppointmentOrShipment } from "interface/appointment";
import { Route as IRoute } from "interface/route";

export const acceptableStatusForRouting = ["confirmed", "scheduled", "ready"];

const getAppointmentsWithWrongStatus = (
  constructParams: ConstructParams,
  appointmentsLibrary: { [appointmentID: string]: AppointmentOrShipment }
): AppointmentOrShipment[] => {
  return chain(constructParams.appointmentIDs)
    .map((appointmentID) => {
      return appointmentsLibrary[appointmentID];
    })
    .filter((appointment) => {
      return appointment && !acceptableStatusForRouting.includes(appointment.status);
    })
    .value();
};

interface ValidateRouteErrors {
  errors: {
    no_appointments?: boolean;
    no_vehicle?: boolean;
  };
  appointment_with_wrong_status?: {
    appointments: AppointmentOrShipment[];
  };
}

export const validateRoute = (
  constructParams: ConstructParams,
  routesLibrary: { [index: string]: IRoute },
  appointmentsLibrary: { [appointmentID: string]: AppointmentOrShipment }
) => {
  const errors: ValidateRouteErrors = { errors: {} };
  const noAppointments = isEmpty(constructParams.appointmentIDs);
  const route = constructParams.routeID ? routesLibrary[constructParams.routeID] : undefined;

  if (!constructParams.vehicleID) errors.errors["no_vehicle"] = true;
  if (noAppointments) errors.errors["no_appointments"] = true;

  const hasRouteStarted = route ? Route.isRouteStarted(route) : false;
  const appointmentsWrongStatus = getAppointmentsWithWrongStatus(constructParams, appointmentsLibrary);

  if (!hasRouteStarted && appointmentsWrongStatus.length > 0) {
    errors["appointment_with_wrong_status"] = {
      appointments: appointmentsWrongStatus,
    };
  }

  return errors;
};
