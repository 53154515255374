import React, { FC, MutableRefObject, useEffect, useRef, useState } from "react";

import * as S from "./styles";

interface ScrollWrapperProps {
  children: JSX.Element | JSX.Element[];
}

export const ScrollWrapper: FC<ScrollWrapperProps & { ref: React.Ref<HTMLDivElement | null> }> = React.forwardRef(
  ({ children }, ref) => {
    // We initialize a local copy of the forwarded ref so we can access it in this component.
    // We set this ref when we pass the ref to the target node (see below).
    const scrollWrapperRef = useRef<HTMLDivElement | null>(null);
    const [enabledLeftOverlay, setEnabledLeftOverlay] = useState(false);
    const [enabledRightOverlay, setEnabledRightOverlay] = useState(true);
    // We use refs here to solve for not being able to access up-to-date state in the scroll event listener function.
    const showLeftOverlayRef = useRef(enabledLeftOverlay);
    const showRightOverlayRef = useRef(enabledRightOverlay);

    // We set both the state and refs here so we can rerender and have up-to-date state for the handleScroll function.
    const setStateAndRef = (ref: React.MutableRefObject<boolean>, data: boolean, setState: (data: boolean) => void) => {
      ref.current = data;
      setState(data);
    };

    // Trying to access state directly in this function doesn't work, so we use refs as an alternative data store.
    const handleScroll = () => {
      // If scrolled right at all, then show the left overlay.
      if (scrollWrapperRef?.current?.scrollLeft || -1 > 0) {
        if (!showLeftOverlayRef.current) {
          setStateAndRef(showLeftOverlayRef, true, setEnabledLeftOverlay);
        }
        // If scrolled all the way left, then hide the left overlay.
      } else if (showLeftOverlayRef.current) {
        setStateAndRef(showLeftOverlayRef, false, setEnabledLeftOverlay);
      }

      // If scrolled left at all, then show the right overlay.
      if (
        scrollWrapperRef?.current &&
        scrollWrapperRef.current.scrollWidth -
          scrollWrapperRef.current.scrollLeft -
          scrollWrapperRef.current.offsetWidth ===
          0
      ) {
        if (showRightOverlayRef.current) {
          setStateAndRef(showRightOverlayRef, false, setEnabledRightOverlay);
        }
        // If scrolled all the way right, then hide the right overlay.
      } else if (!showRightOverlayRef.current) {
        setStateAndRef(showRightOverlayRef, true, setEnabledRightOverlay);
      }
    };

    const onWheel = (e: WheelEvent) => {
      e.preventDefault();
      if (scrollWrapperRef?.current?.scrollLeft !== undefined) {
        scrollWrapperRef.current.scrollTo({
          top: 0,
          left: scrollWrapperRef.current.scrollLeft + e.deltaY,
        });
      }
    };

    useEffect(() => {
      // We set the event listener only after we've initialized our local ref with the node's ref.
      const node = scrollWrapperRef.current;
      if (node) {
        node.addEventListener("scroll", handleScroll);
        node.addEventListener("wheel", onWheel);
      }
      return () => node?.removeEventListener("scroll", handleScroll);
      // eslint-disable-next-line
    }, [ref]);

    return (
      <S.Wrapper
        // We initialize the local ref with the node's ref here
        ref={(node) => {
          scrollWrapperRef.current = node;
          // We do this type assertion because the ref object passed in is of type RefObject, of which the .current is read only.
          (ref as MutableRefObject<HTMLDivElement | null>).current = node;
          // The .current actually does change at runtime, but React handles this under the hood.
          // Generally, the readonly type makes sense for standard use cases.
        }}
      >
        <S.Overlay side="left" visible={enabledLeftOverlay} />
        {children}
        <S.Overlay side="right" visible={enabledRightOverlay} />
      </S.Wrapper>
    );
  }
);

ScrollWrapper.displayName = "ScrollWrapper";
