import React from "react";

import * as S from "./styles";

import { StatusTag } from "../StatusTag";
import { VehicleStatusDropdown } from "../VehicleStatusDropdown";

interface CreateEditVehicleHeaderProps {
  mode: "create" | "edit";
  status?: string;
  inUse?: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  onChangeVehicleStatus?: (status: string) => void;
}

export const CreateEditVehicleHeader: React.FC<CreateEditVehicleHeaderProps> = ({
  mode,
  status,
  inUse = false,
  onConfirm,
  onCancel,
  onChangeVehicleStatus,
}) => {
  return (
    <S.HeaderContainer>
      <S.TitleAndStatus>
        <S.Title>{mode === "create" ? "Create Vehicle" : "Edit Vehicle"}</S.Title>

        {mode === "edit" && status && <StatusTag status={status} inUse={inUse} />}
      </S.TitleAndStatus>
      <S.ButtonsContainer>
        {mode === "edit" && status && onChangeVehicleStatus && (
          <VehicleStatusDropdown currentStatusName={status} inUse={inUse} onChange={onChangeVehicleStatus} />
        )}
        <S.Button onClick={onCancel}>Cancel</S.Button>
        <S.Button onClick={onConfirm}>Confirm</S.Button>
      </S.ButtonsContainer>
    </S.HeaderContainer>
  );
};
