export const COLORS = {
  APPLE_BLUE: "#007AFF",
  ASH_BLUE: "#F0F2FF",
  BLACK: "#000000",
  BLUE_BOLD: "#0084F4",
  BLUE: "#4A90E2",
  BLUE_LIGHT: "#80C1F9",
  PRIMARY_BLUE: "#384DF6",
  PRIMARY_MID: "#B4BCFB",
  PRIMARY_LIGHT: "#F0F2FF",
  CHARCOAL: "#404041",
  PRIMARY_ORANGE: "#FF904E",
  ORANGE: "#F5A623",
  PRIMARY_GREEN: "#00B176",
  GREEN: "#37BC9B",
  GREEN_LIGHT: "#EFFFF1",
  LIME_GREEN: "#49DB86",
  GRASS_GREEN: "#6fcf97",
  GREY: "#9B9B9B",
  GREY_LIGHT: "#EEEEEE",
  GREY_LIGHTER: "#F5F5F5",
  GREY_MID: "#D5D5D5",
  GREY_DARK: "#7D7D7D",
  GREY_DARKER: "#262626",
  RED_DARK: "#FF3353",
  RED: "#FF3A52",
  RED_LIGHT: "#FFEEF1",
  YELLOW: "#FFD33D",
  YELLOW_LIGHT: "#FFECAD",
  WHITE: "#FFFFFF",
  TRANSPARENT: "transparent",
  TRANSPARENT_GREY: "rgba(0,0,0,0.25)",
  APP_BACKGROUND: "#FBFBFB",
  CARD_BACKGROUND: "#F6F6F9",
  PURPLE: "#BE52F2",
  PURPLE_LIGHT: "#DEA8F8",
};

export const MARKER_COLORS = {
  GREEN: "#00B571",
  GREEN_DARKER: "#00804F",
  BLUE: "#0084FC",
  BLUE_DARKER: "#0067C7",
  ORANGE: "#FF883A",
  ORANGE_DARKER: "#FF6905",
  DARK_GREY: "#404041",
  DARK_GREY_DARKER: "#262627",
  PURPLE: "#CC42FA",
  PURPLE_DARKER: "#BF10F9",
};
