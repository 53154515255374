import styled from "styled-components";

import { COLORS } from "styles";

interface WrapperProps {
  visible: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: row;
  bottom: 80px;
  padding: 8px 16px;
  width: fit-content;
  background-color: ${COLORS.WHITE};
  border-radius: 6px;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  right: ${({ visible }) => `${visible ? "16px" : "-640px"}`};
  transition: right 300ms ${({ visible }) => (visible ? "ease-in" : "ease-out")};
`;

export const TextWrapper = styled.div`
  margin: 0 0 0 8px;
`;
