import styled from "styled-components";

import { COLORS } from "styles";

export const SelectedRouteWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 20px 0;
`;

export const RouteID = styled.div`
  color: ${COLORS.APPLE_BLUE};
  margin-right: 10px;
`;
