import { LegendOrdinal } from "@visx/legend";
import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  position: relative;
`;

export const Legend = styled(LegendOrdinal)`
  position: absolute;
  gap: 20px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: ${COLORS.GREY_DARK};
  font-weight: 600;
`;
