import styled from "styled-components";

import { COLORS } from "styles";

interface ContainerProps {
  active: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 12px;
  color: ${(props) => (props.active ? COLORS.PRIMARY_BLUE : COLORS.GREY_DARK)};
  font-size: 16px;
  line-height: 24px;
  transition: color 0.2s;
  cursor: pointer;
  user-select: none;

  &::after {
    content: "";
    position: absolute;
    transition: background 0.2s;
    background: ${(props) => (props.active ? COLORS.PRIMARY_BLUE : "transparent")};
    height: 2px;
    right: 0;
    bottom: -4px;
    left: 0;
  }

  &:hover {
    &::after {
      background: ${(props) => (props.active ? COLORS.PRIMARY_BLUE : COLORS.GREY_MID)};
    }
  }
`;
