import { useQuery } from "react-query";

import { AppointmentType } from "@secondcloset/types/src/fulfillment";

import { notification } from "antd";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";
import {
  setBookingAppointmentIndex,
  setBookingAppointmentsMarkersIndex,
  setBookingPickupDeliveryPair,
} from "recoil/booking/selectors";

import { fetchAppointmentIndex } from "api/appointment";

export const useGetRangeOfDaysAppointments = () => {
  const activeServiceArea = useRecoilValue(activeServiceAreaState);
  const dispatchBookingAppointments = useSetRecoilState(setBookingAppointmentIndex);
  const dispatchBookingAppointmentsMarkers = useSetRecoilState(setBookingAppointmentsMarkersIndex);
  const dispatchBookingPickupDeliveryPair = useSetRecoilState(setBookingPickupDeliveryPair);

  const query = useQuery(
    ["fetchBookingAppointmentIndex", activeServiceArea],
    () =>
      fetchAppointmentIndex({
        isRangeOfDays: true,
        isIkea: true,
        serviceArea: activeServiceArea,
        job_types: ["inventory_pick_up", "cross_dock_delivery", "delivery"] as AppointmentType[],
      }),
    {
      onSuccess: (appointments) => {
        dispatchBookingAppointments(appointments);
        dispatchBookingAppointmentsMarkers(appointments);
        dispatchBookingPickupDeliveryPair(appointments);
      },
      onError: (error: string) => {
        notification.error({
          message: "Error",
          description: error,
        });
      },
    }
  );

  return query;
};
