import { Storage } from "@secondcloset/types";

import { COLORS } from "styles";

import { Maps, PolylineOptions } from "interface/map";

type AppointmentInterface = Storage.Appointment;

interface PolylinesOption {
  isUnder?: boolean;
  dashed?: boolean;
  arrow?: boolean;
}
export class Polylines {
  static getPolylineOptions = (_: string, maps: Maps, polylineOptions: PolylinesOption) => {
    const { isUnder, dashed: shouldRenderLineDashed = false, arrow: shouldRenderArrow = true } = polylineOptions;

    if (isUnder) {
      return {
        strokeColor: "#000",
        strokeOpacity: 0,
        strokeWeight: 30,
        zIndex: -2,
      };
    }

    const options: PolylineOptions = {
      strokeColor: COLORS.PURPLE,
      strokeOpacity: 1,
      strokeWeight: 2,
      zIndex: -1,
      clickable: false,
      icons: [],
    };

    if (shouldRenderArrow) {
      const routePathArrowSymbol = {
        path: maps.SymbolPath.FORWARD_OPEN_ARROW,
        strokeOpacity: 1,
        scale: 3,
      };
      options.icons.push({
        icon: routePathArrowSymbol,
        offset: "100%",
      });
    }

    if (shouldRenderLineDashed) {
      const routePathLineSymbol = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 4,
      };
      options.icons.push({
        icon: routePathLineSymbol,
        offset: "0",
        repeat: "20px",
      });
      options.strokeColor = COLORS.PURPLE_LIGHT;
      options.strokeOpacity = 0;
    }

    return options;
  };

  static getPolylinePath = (coordinates: AppointmentInterface["address"]["coordinates"][], maps: Maps) => {
    return coordinates.map(({ lat, lng }) => {
      return new maps.LatLng(lat, lng);
    });
  };
}
