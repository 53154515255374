import { useMemo } from "react";
import { useQuery, UseQueryOptions } from "react-query";

import { useRecoilValue } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";

import { fetchVehicleIndex } from "api/vehicles";
import { VehiclesResponse } from "api/vehicles/fetchVehicleIndex";

import { Vehicle } from "interface/vehicle";

type VehiclesLibrary = {
  [vehicleID: string]: Vehicle;
};

export const useVehicles = (
  query?: Record<string, string | number>,
  options?: UseQueryOptions<VehiclesResponse, string>
) => {
  const { isLoading, isFetched, error, data } = useQuery<VehiclesResponse, string>(
    ["vehicles", query],
    () => fetchVehicleIndex(query),
    options
  );

  const activeServiceArea = useRecoilValue(activeServiceAreaState) || "yyz";
  const getActiveServiceAreaVehicles = () => {
    return data?.vehicles?.filter((vehicle) => vehicle.service_area.name === activeServiceArea) ?? [];
  };

  const getVehiclesAvailableToRoute = () => {
    const activeServiceAreaVehicles = getActiveServiceAreaVehicles();
    return activeServiceAreaVehicles.filter((vehicle) => vehicle.status === "in_service");
  };

  const vehiclesLibrary = useMemo(() => {
    const result = {} as VehiclesLibrary;

    data?.vehicles?.forEach((vehicle) => {
      result[vehicle.id] = vehicle;
    });

    return result;
  }, [data?.vehicles]);

  return {
    isLoading,
    isFetched,
    error,
    vehicles: data?.vehicles,
    statistics: data?.statistics,
    pagination: data?.pagination,
    vehiclesLibrary,
    getActiveServiceAreaVehicles,
    getVehiclesAvailableToRoute,
  };
};
