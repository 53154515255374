import { stringify } from "querystring";

import { API } from "lib/API";

import { Vehicle } from "interface/vehicle";

const getURL = (vehicleID: string, query?: Record<string, string>) => {
  let queryString = "";
  if (query) {
    queryString = stringify(query);
  }

  const baseURL = `/vehicles/${vehicleID}`;

  return queryString ? `${baseURL}?${queryString}` : baseURL;
};

export const fetchVehicleDetails = async (vehicleID: string, query?: Record<string, string>): Promise<Vehicle> => {
  const api = new API();
  const url = getURL(vehicleID, query);

  const response = await api.get(url);
  return response.data as Vehicle;
};
