import { useQuery, UseQueryOptions } from "react-query";

import { fetchVehicleDetails } from "api/vehicles";

import { Vehicle } from "interface/vehicle";

export const useVehicleDetails = (vehicleID: string, date: string, options?: UseQueryOptions<Vehicle, string>) => {
  const query = {
    date,
  };

  const { data: vehicle, isLoading } = useQuery<Vehicle, string>(
    ["vehicleDetails", vehicleID, query],
    () => fetchVehicleDetails(vehicleID, query),
    options
  );

  return { isLoading, vehicle };
};
