import React, { useRef } from "react";

import * as S from "./styles";
import { COLORS } from "styles";

import { useRecoilValue } from "recoil";
import { bookingAppointmentsState } from "recoil/booking/atoms";

import { useAppointments } from "hooks/api/appointments";

import { Markers } from "lib/markers";

import { Map } from "interface/map";

interface AppointmentMarkerProps {
  lat: number;
  lng: number;
  appointmentIDs: string[];
  map: Map;
  isBookingMarker?: boolean;
}

export const AppointmentMarker: React.FC<AppointmentMarkerProps> = ({ appointmentIDs, isBookingMarker }) => {
  const markerRef = useRef(false);

  const { appointmentsLibrary } = useAppointments();
  const bookingAppointmentsLibrary = useRecoilValue(bookingAppointmentsState);

  const library = isBookingMarker ? bookingAppointmentsLibrary : appointmentsLibrary;

  const appointments = appointmentIDs.map((id) => library[id]).filter(Boolean);

  const icon = Markers.getBookingMarkerIcon(appointments, isBookingMarker);
  const label = Markers.getMarkerLabel(appointments, {
    isBookingMarker: isBookingMarker,
  });
  const labelTextColour = isBookingMarker ? COLORS.WHITE : COLORS.BLACK;

  return (
    <S.Container>
      <S.ImageWrap
        // onMouseDown and onMouseMove is to prevent weird UI upon dragging a marker
        onMouseDown={() => {
          markerRef.current = false;
        }}
        onMouseMove={() => {
          if (!markerRef.current) markerRef.current = true;
        }}
      >
        <S.Image src={icon} />
        <S.LabelWrap>
          <S.Label color={labelTextColour}>{label || ""}</S.Label>
        </S.LabelWrap>
      </S.ImageWrap>
    </S.Container>
  );
};
