import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const WeekDaysHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  color: ${COLORS.GREY_DARK};
`;

export const Day = styled.div``;

interface MonthViewProps {
  firstDay: number;
}
export const MonthView = styled.div<MonthViewProps>`
  display: grid;
  grid-template-columns: repeat(7, 1fr);

  & > ${Day}:first-child {
    grid-column-start: ${(props) => props.firstDay};
  }
`;
