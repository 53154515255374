import { orderBy, toLower } from "lodash";
import { DataNode } from "rc-tree-select/lib/interface";

import { useGetOrganizationNames } from "hooks/api/organizations";
import { useFuzzySearch } from "hooks/application/useFuzzySearch";

import { Appointment } from "lib/appointment";

import { ALL_ORGANIZATIONS_KEY, getAppointmentOrganizationID, NOT_SPECIFIED_ORGANIZATION_KEY } from "../../helpers";

import { AppointmentOrShipment } from "interface/appointment";

export const useGetFilterOptions = (organizationIDs: string[], appointments: AppointmentOrShipment[]) => {
  const activeAppointments = appointments.filter((appt) => !Appointment.isCancelled(appt));
  const organizationNames = useGetOrganizationNames(
    organizationIDs.filter((orgID) => orgID !== NOT_SPECIFIED_ORGANIZATION_KEY)
  );
  const searchOrganizations = useFuzzySearch(organizationNames, { keys: ["name", "id"] });

  const getOptionLabel = (label: string, key: string | null) => {
    const optionCount = activeAppointments.filter((appt) => getAppointmentOrganizationID(appt) === key).length;

    return label + ` (${optionCount})`;
  };
  const getFilterOptions = (searchKey: string) => {
    const filteredOganizations = searchOrganizations(searchKey);

    const mappedFilterOptions = filteredOganizations.map((org) => {
      return {
        key: org.id,
        value: org.id,
        label: getOptionLabel(org.name, org.id),
      } as DataNode;
    });

    const sortedOptions = orderBy(mappedFilterOptions, [(option) => toLower(option?.label?.toString())], ["asc"]);

    const allOrgsOption = {
      key: ALL_ORGANIZATIONS_KEY,
      value: ALL_ORGANIZATIONS_KEY,
      label: `All Organizations (${activeAppointments.length})`,
    };

    const noOrgAppointments = activeAppointments.filter((a) => !Appointment.isFulfillmentAppointment(a));

    const notSpecifiedOption = {
      key: NOT_SPECIFIED_ORGANIZATION_KEY,
      value: NOT_SPECIFIED_ORGANIZATION_KEY,
      label: `Not Specified (${noOrgAppointments.length})`,
    };

    if (!!searchKey) return sortedOptions;

    if (noOrgAppointments.length === 0) return [allOrgsOption, ...sortedOptions];

    return [allOrgsOption, ...sortedOptions, notSpecifiedOption];
  };

  return getFilterOptions;
};
