import { Divider as AntdDivider } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  padding: 12px 20px;
  border: 1px solid ${COLORS.GREY_MID};
  border-left: 0px;
  border-right: 0px;
  display: flexbox;
`;

export const ToggleButton = styled.button<{ active?: boolean }>`
  padding: 0;
  background: 0;
  border: 0;
  color: ${COLORS.GREY_LIGHT};
  ${({ disabled }) => !disabled && `cursor: pointer;`}
  ${({ active }) => active && `color: ${COLORS.GREY_DARK}`};
`;

export const Divider = styled(AntdDivider)`
  background: ${COLORS.GREY_DARK};
`;
