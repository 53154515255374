import { ServiceArea } from "@secondcloset/types/src/_common";

export enum JobStatus {
  WAITING = "waiting",
  ACTIVE = "active",
  COMPLETED = "completed",
  FAILED = "failed",
}

export interface RouteAutomationJob {
  appointment_ids: string[];
  completed_at: string | null;
  created_at: string;
  date: string;
  id: string;
  output: string | null;
  service_area: ServiceArea;
  status: string;
  updated_at: string;
  vehicle_ids: string[];
  user_id: string;
  version: string;
  queue?: {
    count?: number;
    state?: string;
  };
}
