import { useCallback } from "react";

import { includes } from "lodash";

import {
  useCreateRouteState,
  useCreateRouteView,
  useNotAddedToRouteUnassignedAppointments,
} from "hooks/application/createRoute";

export const useGetCanToggleAppointmentSelected = () => {
  const unassignedAppointments = useNotAddedToRouteUnassignedAppointments();
  const { isRemainingAppointmentsSelectorVisible } = useCreateRouteView();
  const { routeItems } = useCreateRouteState();

  const getCanToggleAppointmentSelected = useCallback(
    (appointmentID: string) => {
      if (!!routeItems?.length) {
        const routeAppointmentIDs = routeItems.map((a) => a.appointment_id);
        const isAppointmentOnRoute = includes(routeAppointmentIDs, appointmentID);

        return isRemainingAppointmentsSelectorVisible && !isAppointmentOnRoute;
      }
      const unassignedAppointmentIDs = unassignedAppointments.map((a) => a.id);
      const isAppointementUnAssigned = includes(unassignedAppointmentIDs, appointmentID);

      return isRemainingAppointmentsSelectorVisible && isAppointementUnAssigned;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isRemainingAppointmentsSelectorVisible, routeItems]
  );

  return getCanToggleAppointmentSelected;
};
