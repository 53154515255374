import { useRecoilState } from "recoil";
import { hoverAppointmentsState } from "recoil/routeMapControls/atoms";

export const useHoverAppointmentCard = () => {
  const [hoveredAppointments, setHoveredAppointments] = useRecoilState(hoverAppointmentsState);

  const hoverAppointmentCard = (appointmentID: string) => {
    setHoveredAppointments([appointmentID]);
  };

  const unhoverAppointmentCard = () => {
    setHoveredAppointments(null);
  };

  const getIsAppointmentHovered = (appointmentID: string) => {
    return hoveredAppointments?.includes(appointmentID);
  };

  return { getIsAppointmentHovered, hoverAppointmentCard, unhoverAppointmentCard };
};
