import styled from "styled-components";

import { COLORS } from "styles";

export const LogoutButton = styled.button.attrs({
  type: "button",
})`
  padding: 0;
  border: none;
  color: ${COLORS.GREY_DARK};
  background: none;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
`;
