import { Common } from "@secondcloset/types";

import { stringify } from "query-string";

import { API } from "lib/API";

type User = Common.User;

export const fetchUser = async (userID: string): Promise<User> => {
  const api = new API();
  const url = `/users/${userID}`;
  const respond = await api.get(url);
  return respond.data as User;
};

interface AdminUserLookupProps {
  q?: string;
  per_page?: string | number;
}

export const adminUserLookup = async (queryParams: AdminUserLookupProps = {}): Promise<User[]> => {
  const query = {
    role: "is_admin",
    per_page: "ALL",
    ...queryParams,
  };
  const api = new API();
  const url = `/users?${stringify(query)}`;
  const respond = await api.get(url);
  return respond.data as User[];
};
