import { Select } from "antd";

import * as S from "./styles";

import { View } from "../types";

interface ViewSelectorProps {
  value: View;
  onChange: (value: View) => void;
  className?: string;
}

export const ViewSelector: React.FC<ViewSelectorProps> = ({ value, onChange, className }) => (
  <S.Selector value={value} placeholder="Day" onChange={(value) => onChange(value as View)} className={className}>
    <Select.Option value="day">Day</Select.Option>
    <Select.Option value="month">Month</Select.Option>
  </S.Selector>
);
