import { useQuery } from "react-query";

import { notification } from "antd";

import { fetchAppointmentNotes } from "api/appointment";

import { AppointmentOrShipment } from "interface/appointment";

export const useNotes = (appointment?: AppointmentOrShipment | null) => {
  const {
    data: notes,
    isLoading: isNotesLoading,
    refetch: refetchNotes,
  } = useQuery(
    ["appointmentNotes", appointment?.id],
    () => {
      return appointment?.id ? fetchAppointmentNotes(appointment.id) : [];
    },
    {
      enabled: !!appointment && appointment.type === "storage",
      onError: (e: string) => notification.error({ message: e }),
    }
  );

  return { notes, isNotesLoading, refetchNotes };
};
