import Api, { APIDomain } from "@secondcloset/api-utils";

import * as Cookie from "./cookie";
import { reportAPI } from "./sentry";

interface HeaderTemplateProps {
  headers: {
    Accept: string;
    "Content-Type": string;
    Authorization?: string;
  };
}

export class API extends Api {
  constructor(domain?: APIDomain) {
    const getBaseURL = (domain?: APIDomain) => {
      switch (domain) {
        case "bfapi":
          return process.env.REACT_APP_BFAPI;
        case "silver":
          return process.env.REACT_APP_SILVER_API;
        default:
          return process.env.REACT_APP_API;
      }
    };

    super({
      baseURL: getBaseURL(domain) || "",
      domain,
      token: Cookie.getUserToken(),
    });
  }

  getHeaderTemplate(token: string) {
    const header: HeaderTemplateProps = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    if (token) header.headers.Authorization = "Bearer ".concat(token);
    return header;
  }

  get(url: string) {
    return super.get(url).catch((e) => {
      reportAPI(e);
      throw e;
    });
  }

  delete(url: string) {
    return super.delete(url).catch((e) => {
      reportAPI(e);
      throw e;
    });
  }

  put(url: string, body?: Record<string, unknown>) {
    return super.put(url, body).catch((e) => {
      reportAPI(e, body);
      throw e;
    });
  }

  post(url: string, body?: Record<string, unknown>) {
    return super.post(url, body).catch((error) => {
      reportAPI(error, body);
      throw error;
    });
  }
}
