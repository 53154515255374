import { includes } from "lodash";

import { useRecoilValue } from "recoil";
import { CreateRouteView, createRouteState as createRouteStateAtom } from "recoil/routes/atoms";

export const useCreateRouteView = () => {
  const createRouteState = useRecoilValue(createRouteStateAtom);

  const getShouldRenderMainView = () => {
    if (!createRouteState.enabled) return true;
    return createRouteState.currentView === CreateRouteView.SelectAppointments;
  };

  const getShouldRenderConstructionView = () => {
    if (!createRouteState.enabled) return false;
    const { currentView } = createRouteState;
    return (
      currentView === CreateRouteView.ConstructRoute ||
      currentView === CreateRouteView.ConstructRouteAddMoreAppointments
    );
  };

  const getIsOverlappingAppointmentsSelectorVisible = () => {
    return createRouteState.enabled && createRouteState.isOverlappingAppointmentsSelectorVisible;
  };

  const getIsRemainingAppointmentsSelectorVisible = () => {
    const isConstructRouteAddMoreAppointmentsView =
      createRouteState.currentView === CreateRouteView.ConstructRouteAddMoreAppointments;
    return createRouteState.enabled && isConstructRouteAddMoreAppointmentsView;
  };

  const shouldRenderMainView = getShouldRenderMainView();
  const shouldRenderConstructionView = getShouldRenderConstructionView();
  const isOverlappingAppointmentsSelectorVisible = getIsOverlappingAppointmentsSelectorVisible();
  const isRemainingAppointmentsSelectorVisible = getIsRemainingAppointmentsSelectorVisible();

  const getShouldRenderMapRoutes = () => {
    if (!createRouteState.enabled) return true;
    return (
      createRouteState.currentView === CreateRouteView.SelectAppointments ||
      createRouteState.currentView === CreateRouteView.ConstructRoute
    );
  };

  const getShouldRenderConstructionRoutes = () => {
    if (!createRouteState.enabled) return false;
    return (
      createRouteState.currentView === CreateRouteView.SelectAppointments ||
      createRouteState.currentView === CreateRouteView.ConstructRoute
    );
  };

  const getShouldAllowSelectingMapMarkers = () => {
    if (!createRouteState.enabled) return true;
    const allowedCreateRouteViewStates = [CreateRouteView.SelectAppointments, CreateRouteView.ConstructRoute];
    return includes(allowedCreateRouteViewStates, createRouteState.currentView);
  };

  const shouldRenderMapRoutes = getShouldRenderMapRoutes();
  const shouldRenderMapConstructionRoutes = getShouldRenderConstructionRoutes();
  const shouldAllowSelectingMapMarkers = getShouldAllowSelectingMapMarkers();

  return {
    shouldRenderMainView,
    shouldRenderConstructionView,
    isOverlappingAppointmentsSelectorVisible,
    isRemainingAppointmentsSelectorVisible,
    shouldRenderMapRoutes,
    shouldRenderMapConstructionRoutes,
    shouldAllowSelectingMapMarkers,
  };
};
