import { useMemo } from "react";
import { useQuery } from "react-query";

import { Organization } from "@secondcloset/fulfillment-utils";
import { Fulfillment } from "@secondcloset/types";

import { useRecoilState, useRecoilValue } from "recoil";
import {
  bookingAppointmentsState,
  bookingPickupDeliveryPairState,
  bookingSelectedWarehouseIdState,
} from "recoil/booking/atoms";
import * as BookingHelpers from "recoil/booking/helpers";

import { fetchWarehouses } from "api/warehouses";

type Location = Fulfillment.Location;
type Appointment = Fulfillment.Appointment;

type AppointmentsLibrary = {
  [key: string]: Appointment[];
};

export const useWarehouses = () => {
  const [selectedWarehouseID, setSelectedWarehouseID] = useRecoilState(bookingSelectedWarehouseIdState);

  const {
    isLoading,
    isFetched,
    error,
    data: warehouses = [],
  } = useQuery<Location[]>(
    "warehouses",
    () => {
      const organizationID = Organization.getIkeaOrgId(process.env.REACT_APP_NODE_ENV === "production");
      return fetchWarehouses({ organizationId: organizationID, customerName: "ikea warehouse" });
    },
    {
      onSuccess: (warehouses) => {
        if (warehouses.length > 0 && !selectedWarehouseID) {
          setSelectedWarehouseID(warehouses[0].id);
        }
      },
    }
  );

  const bookingAppointments = useRecoilValue(bookingAppointmentsState);
  const bookingPickupDeliveryPair = useRecoilValue(bookingPickupDeliveryPairState);
  const warehouseAppointmentsLibrary = useMemo(() => {
    const result: AppointmentsLibrary = {};

    warehouses.forEach((warehouse) => {
      const appointments = BookingHelpers.getFilteredAppointmentsByWarehouse(
        bookingAppointments,
        bookingPickupDeliveryPair,
        warehouse.id as string
      );

      result[warehouse.id] = appointments;
    });

    return result;
  }, [warehouses, bookingAppointments, bookingPickupDeliveryPair]);

  const warehouseFilteredAppointments = useMemo(
    () => warehouseAppointmentsLibrary[selectedWarehouseID],
    [warehouseAppointmentsLibrary, selectedWarehouseID]
  );

  return {
    isLoading,
    isFetched,
    error,
    warehouses,
    warehouseAppointmentsLibrary,
    warehouseFilteredAppointments,
  };
};
