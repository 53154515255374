import { useMutation, useQueryClient } from "react-query";

import { notification } from "antd";

import { createVehicle } from "api/vehicles";

export const useCreateVehicle = () => {
  const queryClient = useQueryClient();

  const { mutate: create, isLoading } = useMutation(createVehicle, {
    onSuccess: () => {
      queryClient.invalidateQueries("vehicles");
    },
    onError: (e: string) => {
      notification.error({
        message: "Create vehicle error",
        description: e,
      });
    },
  });

  return { create, isLoading };
};
