import { HiOutlinePhotograph } from "react-icons/hi";

import styled from "styled-components";

import { COLORS } from "styles";

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const Title = styled.div`
  font-size: 34px;
  font-weight: 600;
`;

export const VehicleDetails = styled.div`
  display: grid;
  grid-template-columns: 226px 1fr 1fr;
  gap: 52px;
  padding: 24px;
  border-radius: 2px;
  background-color: ${COLORS.GREY_LIGHTER};
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 226px;
  height: 226px;
  border-radius: 6px;
  background-color: ${COLORS.GREY_LIGHT};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const NoImageIcon = styled(HiOutlinePhotograph)`
  width: 90px;
  height: 90px;
  color: ${COLORS.GREY};
`;

export const VehicleDetailsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
