import styled from "styled-components";

import { VehiclesStatistics as VehiclesStatisticsBase } from "./VehiclesStatistics";

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
`;

export const PageTitle = styled.h1`
  margin: 0;
  font-size: 34px;
  font-weight: 600;
`;

export const VehiclesStatistics = styled(VehiclesStatisticsBase)`
  margin-bottom: 32px;
`;

export const VehicleTableWrap = styled.div`
  * {
    white-space: nowrap;
  }
`;

export const SearchAndFilterBar = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 24px;
`;
