import React from "react";

import { Select } from "antd";

import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { isConstructingModeState, sidebarSearchState } from "recoil/sideBarControls/atoms";
import { activeIkeaWarehouseAddressIDState } from "recoil/topFilterControls/atoms";

import { useActiveFlow } from "hooks/application/useActiveFlow";

import { useIsDisabledForCertainPages } from "./useIsDisabledForCertainPages";

interface Props {
  style?: React.CSSProperties;
}

export const WorkflowSelector: React.FC<Props> = ({ style }) => {
  const resetSidebarSearch = useResetRecoilState(sidebarSearchState);
  const dispatchActiveIkeaWarehouseAddressID = useSetRecoilState(activeIkeaWarehouseAddressIDState);
  const { activeFlowKey, setActiveFlowKey, WORK_FLOW_OPTIONS } = useActiveFlow();

  const isDisabledForCertainPages = useIsDisabledForCertainPages();
  const isConstructingMode = useRecoilValue(isConstructingModeState);

  const renderOptions = () => {
    return Object.values(WORK_FLOW_OPTIONS)
      .filter((o) => o.key !== activeFlowKey)
      .map((option) => {
        return (
          <Select.Option key={option.key} value={option.key}>
            {option.name}
          </Select.Option>
        );
      });
  };

  return (
    <Select
      disabled={isConstructingMode || isDisabledForCertainPages}
      style={{ ...style, width: 180 }}
      labelInValue={true}
      value={{
        key: activeFlowKey,
        value: activeFlowKey,
        label: activeFlowKey ? WORK_FLOW_OPTIONS[activeFlowKey].name : "-",
      }}
      onChange={({ key }) => {
        setActiveFlowKey(key);
        resetSidebarSearch();
        dispatchActiveIkeaWarehouseAddressID("");
      }}
    >
      {renderOptions()}
    </Select>
  );
};
