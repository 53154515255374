import * as S from "./styles";
import { LAYOUT } from "styles";

import { useActiveMarker } from "hooks/application/useActiveMarker";

import { Collapse } from "../Collapse";

import { useRenderAssignedAppointments } from "./useRenderAssignedAppointments";
import { useRenderUnassignedAppointments } from "./useRenderUnassignedAppointments";

export const ActiveMarkerAppointmentsOverview = () => {
  const { activeMarkerOverview, resetState: resetActiveMarkerState } = useActiveMarker();

  const renderUnassignedAppointments = useRenderUnassignedAppointments();
  const renderAssignedAppointments = useRenderAssignedAppointments();

  return (
    <S.Drawer
      visible={activeMarkerOverview.visible}
      onClose={resetActiveMarkerState}
      getContainer={false}
      width={LAYOUT.SIDEBAR_WIDTH}
      closeIcon={<S.CloseButtonIcon />}
    >
      <Collapse defaultActiveKey={["unassigned", "assigned"]}>
        {renderUnassignedAppointments()}
        {renderAssignedAppointments()}
      </Collapse>
    </S.Drawer>
  );
};
