import { Common, Fulfillment, Storage } from "@secondcloset/types";

import { cloneDeep, get } from "lodash";
import moment from "moment";

import { Address } from "./address";

import { AppointmentOrShipment } from "interface/appointment";

type AddressInterface = Common.BaseAddress;
type AppointmentStatus = Storage.AppointmentStatus | "in_progress" | "incompleted";
type ShipmentNote = Common.BaseNote;
type ShipmentItem = Fulfillment.ShipmentItem;
type AppointmentNote = Storage.AppointmentNote;
type AppointmentTask = Storage.AppointmentTask;

const colors = {
  red: "#da4453",
  green: "#8cc152",
  orange: "#f90",
  mint: "#37bc9b",
  yellow: "#f6bb42",
  skyBlue: "#3bafda",
  oceanBlue: "#4A89DC",
  purple: "#ac92ec",
  pink: "#ffc0cb",
  navy: "#001f3f",
  grassGreen: "#8cc152",
  boltBlue: "#2537C9",
};

export interface ItemDimension {
  length: UnitValue;
  width: UnitValue;
  height: UnitValue;
}

export interface UnitValue {
  unit: string;
  value: string;
}
export interface Item {
  name: string;
  quantity: number;
  id?: string;
  order_item_id?: string;
  description?: string;
  item_identifier?: string;
  item_identifier_type?: string;
  item_identifier_value?: string;
  shipment_actions?: string[];
  dimensions?: ItemDimension;
  weight?: UnitValue;
  weight_value?: string;
  weight_unit?: string;
  length_value?: string;
  length_unit?: string;
  height_value?: string;
  height_unit?: string;
  width_value?: string;
  width_unit?: string;
}

export type InventoryPickupAppointment = AppointmentOrShipment & {
  grouped_inventory_pickup_ids: string[];
};

export type ReturnToSenderAppointment = AppointmentOrShipment & {
  grouped_return_to_sender_ids: string[];
};

export class Appointment {
  static getAppointmentStatusColor = (appointment?: AppointmentOrShipment): string => {
    const status = (appointment?.status ?? "") as AppointmentStatus;
    switch (status) {
      case "scheduled":
      case "ready":
      case "confirmed":
        return colors.yellow;
      case "fulfilled":
      case "on_the_way":
      case "started":
      case "in_progress":
      case "active":
      case "done":
      case "staging":
      case "completed":
        return colors.green;
      case "incomplete":
      case "failed":
        return colors.red;
      default:
        return colors.yellow;
    }
  };

  static getAddress = (appointment?: AppointmentOrShipment): AddressInterface | undefined => {
    return (appointment as Storage.Appointment)?.address || (appointment as Fulfillment.Appointment)?.location;
  };

  static getOneLineAddress = (appointment: AppointmentOrShipment): AddressInterface["address"] | undefined => {
    return Appointment.getAddress(appointment)?.address;
  };

  static getPhoneNumber = (appointment?: AppointmentOrShipment): AddressInterface["phone_number"] => {
    const address = Appointment.getAddress(appointment);
    return address?.phone_number || (appointment as Fulfillment.Appointment)?.source?.customer?.phone_number || "-";
  };

  static getFormattedTimerange = (appointment: AppointmentOrShipment): string => {
    const { timerange } = appointment;
    const [startTime, endTime] = timerange.split("-");

    const startTimeFormatted = moment(startTime, "HH").format("hA");
    const endTimeFormatted = moment(endTime, "HH").format("hA");

    return `${startTimeFormatted} – ${endTimeFormatted}`;
  };

  static getCustomerName = (appointment?: AppointmentOrShipment): string => {
    if (!appointment) return "-";
    const isFulfillment = Appointment.isFulfillmentAppointment(appointment);
    if (!isFulfillment) {
      return `${appointment.user_firstname} ${appointment.user_lastname}`;
    }
    return appointment?.location?.contact_name || appointment?.source?.customer?.name || "-";
  };

  static getJobTypeColor = (appointment?: AppointmentOrShipment): string => {
    switch (appointment?.job_type) {
      case "pick_up":
      case "inventory_pick_up":
        return colors.orange;
      case "drop_off":
        return colors.skyBlue;
      case "immediate_pick_up":
        return colors.oceanBlue;
      case "return":
      case "reverse_logistics":
        return colors.purple;
      case "retrieve":
        return colors.yellow;
      case "immediate_retrieve":
        return colors.mint;
      case "delivery":
      case "cross_dock_delivery":
        return colors.navy;
      case "return_to_sender":
        return colors.grassGreen;
      default:
        return colors.pink;
    }
  };

  static getShipmentItems = (appointment?: AppointmentOrShipment): ShipmentItem[] => {
    const shipmentItems = (appointment as Fulfillment.Appointment)?.shipment?.shipment_items || [];
    return shipmentItems.map((item) => {
      const name = item?.description || "-";
      const quantity = 1;
      const dimensions = {
        length: {
          value: item.length_value,
          unit: item.length_unit,
        },
        width: {
          value: item.width_value,
          unit: item.width_unit,
        },
        height: {
          value: item.height_value,
          unit: item.height_unit,
        },
      };
      const weight = {
        value: item.weight_value,
        unit: item.weight_unit,
      };
      return { ...item, name, quantity, dimensions, weight };
    });
  };

  static getCompressedItems = (appointment?: AppointmentOrShipment | null): Item[] => {
    if (!appointment) return [];
    const items = (appointment as Storage.Appointment)?.items || Appointment.getShipmentItems(appointment);

    const itemsLibrary = items.reduce((acc, item) => {
      if (!acc[item.name]) acc[item.name] = cloneDeep(item);
      else acc[item.name].quantity += item.quantity;
      return acc;
    }, {} as { [name: string]: Item });

    return Object.keys(itemsLibrary).map((key: string) => itemsLibrary[key]);
  };

  static getPosition = (appointment?: AppointmentOrShipment): AddressInterface["coordinates"] => {
    const address = Appointment.getAddress(appointment);
    return Address.getCoordinates(address);
  };

  static isFulfillmentAppointment = (
    appointment?: AppointmentOrShipment | null
  ): appointment is Fulfillment.Appointment => {
    return appointment?.type === "fulfillment";
  };

  static getFulfillmentNotes = (appointment?: AppointmentOrShipment | null): string => {
    const isFulfillment = Appointment.isFulfillmentAppointment(appointment);
    if (isFulfillment) {
      return appointment?.source?.notes || "";
    } else return "";
  };

  static getAppointmentTaskTexts = (appointmentTasks?: AppointmentTask[]): string[] => {
    if (!Array.isArray(appointmentTasks)) return [];
    return appointmentTasks.map((i) => i.notes);
  };

  static getAppointmentNoteTexts = (appointmentNotes?: AppointmentNote[] | ShipmentNote[] | string): string[] => {
    if (!Array.isArray(appointmentNotes)) return [];
    return appointmentNotes.map((i) => i.text);
  };

  static isInventoryPickup = (appointment?: AppointmentOrShipment): boolean => {
    return appointment?.job_type === "inventory_pick_up";
  };

  static isReturnToSender = (appointment?: AppointmentOrShipment): boolean => {
    return appointment?.job_type === "return_to_sender";
  };

  static isReverseLogistics = (appointment?: AppointmentOrShipment): boolean => {
    return appointment?.job_type === "reverse_logistics";
  };

  static isCrossDockDelivery = (appointment?: AppointmentOrShipment): boolean => {
    return appointment?.job_type === "cross_dock_delivery";
  };

  static groupAppointmentsByInventoryPickup = (appointments: AppointmentOrShipment[]) => {
    const inventoryPickup = appointments.filter((a) => Appointment.isInventoryPickup(a));
    const nonInventoryPickup = appointments.filter((a) => !Appointment.isInventoryPickup(a));

    if (!inventoryPickup.length) return nonInventoryPickup;

    const mergedInventoryPickup = { ...inventoryPickup[0] } as InventoryPickupAppointment;
    mergedInventoryPickup.grouped_inventory_pickup_ids = inventoryPickup.map((a) => a.id);

    return [mergedInventoryPickup, ...nonInventoryPickup];
  };

  static groupAppointmentsByReturnToSender = (appointments: AppointmentOrShipment[]) => {
    const returnToSender = appointments.filter((a) => Appointment.isReturnToSender(a));
    const nonReturnToSender = appointments.filter((a) => !Appointment.isReturnToSender(a));

    if (!returnToSender.length) return nonReturnToSender;

    const mergedReturnToSender = { ...returnToSender[0] } as ReturnToSenderAppointment;
    mergedReturnToSender.grouped_return_to_sender_ids = returnToSender.map((a) => a.id);

    return [...nonReturnToSender, mergedReturnToSender];
  };

  static isMissingPackages = (isIkea: boolean, appointment: Fulfillment.Appointment) => {
    const isIkeaV2 = (appointment as Fulfillment.Appointment)?.source?.external_platform_version === 2;
    const packages = (appointment as Fulfillment.Appointment)?.shipment?.packages || [];
    const hasPackages = packages.length > 0 || get(appointment, "package_count", 0) > 0;
    return isIkea && isIkeaV2 && !hasPackages;
  };

  static getNowToDeadlineToBookDifference = (appointment: Fulfillment.Appointment) => {
    const now = moment();
    return moment(appointment.range_of_days_start_date).subtract(2, "days").set("hour", 19).diff(now, "minutes");
  };

  static getTimeLeftToBook = (appointment: Fulfillment.Appointment) => {
    const difference = Appointment.getNowToDeadlineToBookDifference(appointment);
    const formattedDifference = `${Math.floor(difference / 60)}h ${difference % 60}m`;
    const daysLeft = moment.duration(difference, "minutes").days();
    const timeLeft = `${daysLeft} Day${daysLeft === 1 ? "" : "s"} (${formattedDifference})`;
    return { difference, daysLeft, timeLeft };
  };

  static isCancelled = (appointment: AppointmentOrShipment) => {
    return appointment.status === "cancelled";
  };
}
