import { atom } from "recoil";

import { AppointmentOrShipment } from "interface/appointment";
import { RouteItem, RouteUser } from "interface/route";
import { Vehicle } from "interface/vehicle";

export enum CreateRouteView {
  SelectAppointments,
  ConstructRoute,
  ConstructRouteAddMoreAppointments,
}

export interface CreateRouteState {
  enabled: boolean;
  currentView: CreateRouteView | null;
  selectedAppointmentsIDs: string[];
  isOverlappingAppointmentsSelectorVisible: boolean;
  overlappingAppointmentsIDs: string[];
  routeID?: string;
  routeItems?: RouteItem[];
  routeName?: string;
  vehicleID?: string;
  users?: RouteUser[];
}

export const createRouteState = atom<CreateRouteState>({
  key: "createRouteState",
  default: {
    enabled: false,
    currentView: null,
    selectedAppointmentsIDs: [],
    isOverlappingAppointmentsSelectorVisible: false,
    overlappingAppointmentsIDs: [],
  },
});

export type RouteAutomationWarehouse = "queensway" | "argentia";
export interface CreateRouteAutomationState {
  enabled: boolean;
  warehouse: RouteAutomationWarehouse;
  appointments: AppointmentOrShipment[];
  vehicles: Vehicle[];
}

export const createRouteAutomationState = atom<CreateRouteAutomationState>({
  key: "createRouteAutomationState",
  default: {
    enabled: false,
    warehouse: "queensway",
    appointments: [],
    vehicles: [],
  },
});
