import React, { useState } from "react";
import { useMutation, useQueryClient } from "react-query";

import { Select, Modal, notification } from "antd";
import { startCase } from "lodash";

import * as S from "./styles";

import { useRoutes } from "hooks/api/routes";

import { updateRouteStatus } from "api/routes";

interface SelectedRouteProps {
  activeRouteIDs: string[];
}

export const SelectedRoute: React.FC<SelectedRouteProps> = ({ activeRouteIDs }) => {
  const queryClient = useQueryClient();

  const { routesLibrary } = useRoutes();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const { mutate, isLoading } = useMutation(updateRouteStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries("routes");
      notification.success({
        message: `route status is now ${newStatus}`,
      });
    },
    onError: (e: string) => {
      notification.error({ message: e });
    },
  });

  if (activeRouteIDs?.length > 1) return null;

  const routeID = activeRouteIDs[0];
  const route = routesLibrary[routeID];

  const renderStatusToggle = () => {
    const options = ["in_progress", "ready"];
    return (
      <Select
        style={{ width: "150px" }}
        value={startCase(route?.route_status || "")}
        onSelect={(v: string) => {
          setNewStatus(v);
          setIsModalVisible(true);
        }}
        loading={isLoading}
      >
        {options.map((o) => (
          <Select.Option key={o} value={o}>
            {startCase(o)}
          </Select.Option>
        ))}
      </Select>
    );
  };

  const renderModal = () => {
    return (
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => {
          setIsModalVisible(false);
          mutate({ routeID, status: newStatus });
        }}
      >
        Are you sure to set route status to <b>{startCase(newStatus)}</b>?
      </Modal>
    );
  };

  return (
    <S.SelectedRouteWrapper>
      <S.RouteID>Route ID: {routeID}</S.RouteID>
      {renderStatusToggle()}
      {renderModal()}
    </S.SelectedRouteWrapper>
  );
};
