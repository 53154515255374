import { HiSearch } from "react-icons/hi";

import { Input as AntdInput } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

export const SearchInput = styled(AntdInput)`
  width: 256px;

  input {
    border-radius: 2px;
  }

  input:focus + .ant-input-suffix svg {
    color: ${COLORS.PRIMARY_BLUE};
  }
`;

export const SearchInputIcon = styled(HiSearch)`
  width: 20px;
  height: 20px;
  color: ${COLORS.GREY_DARK};
`;
