import { useMemo } from "react";

import * as S from "./styles";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { activeMarkerAppointmentsOverviewState, appointmentDetailsModalState } from "recoil/sideBarControls/atoms";

import { useAssignedAppointments } from "hooks/api/appointments";
import { useAppointmentsRoutesMap, useRoutesWithAppointments } from "hooks/api/routes";
import { useActiveMarker } from "hooks/application/useActiveMarker";

import { useHoverAppointmentCard, useClickAppointmentCard } from "../AppointmentCard";
import { Collapse, CollapsePanel, RouteCollapsePanel } from "../Collapse";
import { OverviewRouteHeader } from "../RouteHeader";

export const useRenderAssignedAppointments = () => {
  const setAppointmentDetailsModal = useSetRecoilState(appointmentDetailsModalState);

  const assignedAppointments = useAssignedAppointments();
  const { appointmentsIDs: activeMarkerAppointmentsIDs } = useRecoilValue(activeMarkerAppointmentsOverviewState);

  const activeMarkerAssignedAppointments = useMemo(
    () => assignedAppointments.filter((appointment) => activeMarkerAppointmentsIDs.includes(appointment.id)),
    [activeMarkerAppointmentsIDs, assignedAppointments]
  );

  const appointmentsRoutesMap = useAppointmentsRoutesMap();
  const routesWithAppointments = useRoutesWithAppointments();

  const activeMarkerRoutesWithAppointments = useMemo(() => {
    const routesIDs = activeMarkerAppointmentsIDs.map((appointmentID) => {
      return appointmentsRoutesMap[appointmentID];
    });
    return routesWithAppointments.filter(({ id }) => routesIDs.includes(id));
  }, [activeMarkerAppointmentsIDs, appointmentsRoutesMap, routesWithAppointments]);

  const { getIsAppointmentActive } = useActiveMarker();
  const { getIsAppointmentHovered, hoverAppointmentCard, unhoverAppointmentCard } = useHoverAppointmentCard();
  const handleAppointmentCardClick = useClickAppointmentCard();

  const renderAssignedAppointments = () => {
    if (activeMarkerAssignedAppointments.length === 0) return null;

    const expandedRoutesIDs = activeMarkerRoutesWithAppointments.map((route) => route.id);

    return (
      <CollapsePanel key="assigned" header={<S.CollapseHeader>Assigned Shipments</S.CollapseHeader>} highlight={true}>
        <Collapse defaultActiveKey={expandedRoutesIDs}>
          {activeMarkerRoutesWithAppointments.map((route) => (
            <RouteCollapsePanel
              key={route.id}
              header={<OverviewRouteHeader route={route} appointments={route.appointments} />}
              highlight={true}
            >
              {route.appointments.map((appointment) => (
                <S.AppointmentCard
                  key={appointment.id}
                  appointment={appointment}
                  selected={getIsAppointmentActive(appointment.id)}
                  hovered={getIsAppointmentHovered(appointment.id)}
                  onShowDetails={(appointmentID) => setAppointmentDetailsModal({ visible: true, appointmentID })}
                  onClick={handleAppointmentCardClick}
                  onMouseEnterCard={hoverAppointmentCard}
                  onMouseLeaveCard={unhoverAppointmentCard}
                />
              ))}
            </RouteCollapsePanel>
          ))}
        </Collapse>
      </CollapsePanel>
    );
  };

  return renderAssignedAppointments;
};
