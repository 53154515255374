import React from "react";

import * as S from "./styles";

import { usePluralRules } from "hooks/application/usePluralRules";

interface AppointmentsCountProps {
  count: number;
  hiddenCount?: number;
}

export const AppointmentsCount: React.FC<AppointmentsCountProps> = ({ count, hiddenCount = 0 }) => {
  const pluralRules = usePluralRules();
  const pluralRule = pluralRules.select(count);

  return (
    <S.Container>
      {count} {pluralRule === "other" ? "appointments" : "appointment"}
      {hiddenCount > 0 && <S.HiddenAppointmentsCount>{hiddenCount} hidden</S.HiddenAppointmentsCount>}
    </S.Container>
  );
};
