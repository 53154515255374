import { stringify } from "query-string";

import { API } from "lib/API";

export interface FetchWarehousesQuery {
  organizationId?: string;
  customerName?: string;
}

export const fetchWarehouses = async (query: FetchWarehousesQuery) => {
  const api = new API("fulfillment");
  const { organizationId, customerName } = query;
  const queryParams = {
    organization_id: organizationId,
    customer_name: customerName,
    per_page: 10000,
  };
  const url = `/customer_addresses/search?${stringify(queryParams)}`;
  const response = await api.get(url);
  return response.data;
};
