import { useMutation, useQueryClient } from "react-query";

import { notification } from "antd";

import { updateVehicle } from "api/vehicles";

export const useUpdateVehicle = () => {
  const queryClient = useQueryClient();

  const { mutate: update, isLoading } = useMutation(updateVehicle, {
    onSuccess: () => {
      queryClient.invalidateQueries("vehicles");
    },
    onError: (e: string) =>
      notification.error({
        message: "Edit vehicle error",
        description: e,
      }),
  });

  return { update, isLoading };
};
