import moment from "moment";

import { useActiveDate } from "hooks/application/useActiveDate";

export const useGetActiveMonthAndYear = () => {
  const { activeDate } = useActiveDate();
  const activeDateInstance = moment(activeDate);

  return {
    monthName: activeDateInstance.format("MMMM"),
    monthNumber: activeDateInstance.month(),
    year: activeDateInstance.year(),
  };
};
