import { MARKER_COLORS } from "styles";

export const getColorByTimeslot = (timeslot: string) => {
  switch (timeslot) {
    case "10-13":
    case "09-13":
      return MARKER_COLORS.GREEN;
    case "13-16":
    case "13-17":
      return MARKER_COLORS.BLUE;
    case "16-19":
    case "17-21":
      return MARKER_COLORS.ORANGE;
    case "09-21":
      return MARKER_COLORS.DARK_GREY;
    default:
      return MARKER_COLORS.PURPLE;
  }
};

export const getHoverColor = (color: string) => {
  switch (color) {
    case MARKER_COLORS.GREEN:
      return MARKER_COLORS.GREEN_DARKER;
    case MARKER_COLORS.BLUE:
      return MARKER_COLORS.BLUE_DARKER;
    case MARKER_COLORS.ORANGE:
      return MARKER_COLORS.ORANGE_DARKER;
    case MARKER_COLORS.DARK_GREY:
      return MARKER_COLORS.DARK_GREY_DARKER;
    case MARKER_COLORS.PURPLE:
      return MARKER_COLORS.PURPLE_DARKER;
    default:
      return color;
  }
};
