import styled from "styled-components";

import { COLORS } from "styles";

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 11px 16px 16px;
  border-left: 1px solid ${COLORS.GREY_LIGHT};

  .ant-input-search-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
