import { Common } from "@secondcloset/types";

import { isEmpty } from "lodash";

import markerIconIkea from "assets/markers/ikea.png";
import markerIconBlue from "assets/markers/svg/blue.svg";
import markerIconGrey from "assets/markers/svg/grey.svg";

import { Address } from "./address";
import { Appointment } from "./appointment";

import { AppointmentOrShipment } from "interface/appointment";

type IAddress = Common.BaseAddress;

export class Markers {
  static getBookingMarkerIcon = (
    appointments: AppointmentOrShipment[],
    isBookingMarker?: boolean
  ): string | undefined => {
    if (isEmpty(appointments)) return;

    return isBookingMarker ? markerIconBlue : markerIconGrey;
  };

  static getIkeaMarkerIcon = () => {
    return markerIconIkea;
  };

  static getMarkerLabel = (
    appointments: AppointmentOrShipment[],
    options: {
      isStart?: boolean;
      isEnd?: boolean;
      isBookingMarker?: boolean;
    }
  ): string | undefined => {
    const { isStart, isEnd, isBookingMarker } = options;

    if (isBookingMarker) return "RD";
    if (isEmpty(appointments)) return undefined;

    let text = "";
    if (isStart) text = "start";
    else if (isEnd) text = "end";
    else text = appointments[0].timerange;

    return text;
  };

  static getMarkerLabelInCreateRouteMode = (appointments: AppointmentOrShipment[]) => {
    return appointments[0]?.timerange;
  };

  static getMarkerKeyFromAppointment = (appointment: AppointmentOrShipment): string => {
    const position = Appointment.getPosition(appointment);
    return JSON.stringify(position);
  };

  static getMarkerKeyFromAddress = (address: IAddress): string => {
    const position = Address.getCoordinates(address);
    return JSON.stringify(position);
  };

  static getNoLocationMarkerKey = (): string => JSON.stringify({ lat: 0, lng: 0 });
}
