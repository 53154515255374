import React from "react";

import styled from "styled-components";

import iconOutlined from "assets/markers/svg/multipleOutlined.svg";
import iconSolid from "assets/markers/svg/multipleSolid.svg";

interface ContainerProps {
  icon: string;
}
const Container = styled.div<ContainerProps>`
  width: 40px;
  height: 31px;
  background-image: url(${(props) => props.icon});
  background-size: cover;
`;

interface MultiIconProps {
  type: "solid" | "outlined";
}

export const MultiIcon: React.FC<MultiIconProps> = ({ type }) => (
  <Container icon={type === "solid" ? iconSolid : iconOutlined} />
);
