import { HiDownload } from "react-icons/hi";

import { Button as AntdButton } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

export const Button = styled(AntdButton)`
  border: 1px solid ${COLORS.GREY_DARK};
  color: ${COLORS.CHARCOAL};
`;

export const Icon = styled(HiDownload)`
  margin-right: 3px;
  vertical-align: middle;
`;
