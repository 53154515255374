import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  position: relative;
  background: ${COLORS.WHITE};
  display: flex;
`;

interface ExpandedSectionProps {
  expanded: boolean;
}

export const ExpandedSection = styled.div<ExpandedSectionProps>`
  display: flex;
  border-left: 1px solid ${COLORS.GREY_LIGHT};
  overflow: auto;
  transition: width 0.2s ease;
  width: ${(props) => (props.expanded ? "220px" : "0px")};
`;

export const NoteTaskContent = styled.div`
  padding: 10px;
  width: 100%;
  word-break: break-word;
`;

export const InfoSection = styled.div`
  padding-bottom: 10px;
  label {
    font-size: 13px;
  }
  .text {
    color: ${COLORS.GREY};
    font-size: 12px;
    padding: 5px;
  }
`;

export const ExpandedButton = styled.div`
  width: 40px;
  height: 180px;
  background: ${COLORS.BLUE};
  cursor: pointer;

  .text {
    color: white;
    margin: 40px 0 0 10px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
  }

  &:hover {
    opacity: 0.9;
  }
`;
