import React from "react";

import * as S from "./styles";

import { ItemDimension, UnitValue } from "lib/appointment";

import { Details } from "./types";

interface ProductTableProps {
  details: Details;
}

const formatDimensions = (dimensions?: ItemDimension) => {
  if (!dimensions) return "N/A";

  const length = `${dimensions.length.value} ${dimensions.length.unit}`;
  const width = `${dimensions.width.value} ${dimensions.width.unit}`;
  const height = `${dimensions.height.value} ${dimensions.height.unit}`;

  return `${length} x ${width} x ${height}`;
};

const formatWeight = (weight?: UnitValue) => {
  if (!weight) return "N/A";

  return `${weight.value} ${weight.unit}`;
};

export const ProductTable: React.FC<ProductTableProps> = ({ details }) => {
  const columns = [
    {
      title: "Product name",
      dataIndex: "name",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
    },
    {
      title: "Dims",
      dataIndex: "dims",
    },
    {
      title: "Weight",
      dataIndex: "weight",
    },
  ];

  if (!details.items) return null;

  const data = details.items.map((item) => ({
    name: item.name,
    quantity: item.quantity,
    dims: formatDimensions(item.dimensions),
    weight: formatWeight(item.weight),
  }));

  const totalQuantity = details.items.reduce((acc, item) => {
    return acc + item.quantity;
  }, 0);

  const totalWeight = details.items.reduce((acc, item) => {
    return acc + parseFloat(item.weight?.value || "0");
  }, 0);

  const renderTableTitle = () => (
    <>
      <S.ProductsTableHeader>
        <S.ProductsTableHeaderTitle>Product</S.ProductsTableHeaderTitle>
        <S.ProductsTableHeaderSummary>
          (Total Qty {totalQuantity}, Total weight {totalWeight} lbs)
        </S.ProductsTableHeaderSummary>
      </S.ProductsTableHeader>

      <S.ProductsTableHeaderDivider />
    </>
  );

  return (
    <S.ProductsTable
      columns={columns}
      dataSource={data}
      pagination={false}
      showHeader={false}
      title={renderTableTitle}
    />
  );
};
