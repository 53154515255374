import { useEffect } from "react";

import { useActiveMarker } from "hooks/application/useActiveMarker";

const FOCUS_TIMEOUT = 500;

export const useFocusActiveMarkerAppointment = () => {
  const { activeMarker } = useActiveMarker();

  useEffect(() => {
    if (!activeMarker.selectedAppointmentId) return;

    const timeoutID = setTimeout(() => {
      if (!activeMarker.selectedAppointmentId) return;

      const appointmentCard = document.querySelector(`[data-appointment-card=a-${activeMarker.selectedAppointmentId}]`);
      appointmentCard?.scrollIntoView({ block: "center", behavior: "smooth" });
    }, FOCUS_TIMEOUT);

    return () => clearTimeout(timeoutID);
  }, [activeMarker.selectedAppointmentId]);
};
