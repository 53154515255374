import { useQuery } from "react-query";

import { uniq } from "lodash";

import { fetchOrganizationNames } from "api/organizationNames";

const DEFAULT_ORG_NAME = {
  name: "",
  id: "",
};

export const useGetOrganizationNames = (organizationIDs: string[]) => {
  const orgIDs = uniq(organizationIDs.sort());

  const { data: organizationNames } = useQuery(["organizationNames", orgIDs], () => fetchOrganizationNames(orgIDs), {
    enabled: !!organizationIDs.length,
  });

  return organizationNames ?? [DEFAULT_ORG_NAME];
};
