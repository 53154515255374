import React from "react";
import { Route, Redirect, useLocation, RouteComponentProps } from "react-router-dom";

import PropTypes from "prop-types";

import { useRecoilValue } from "recoil";
import { userState } from "recoil/_common/user";

interface Props {
  component: React.FC<RouteComponentProps>;
  exact?: boolean;
  path?: string;
}

export const PrivateRoute: React.FC<Props> = (props) => {
  const user = useRecoilValue(userState);
  const location = useLocation();
  const { component: Component, ...rest } = props;
  const isAuthenticated = !!user;

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              search: location.search,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};
