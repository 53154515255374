import styled from "styled-components";

export const Container = styled.div`
  .ant-select-selection-item {
    background-color: transparent;
    border: none;
  }
`;

export const PlaceholderText = styled.span`
  cursor: pointer;
`;
