import { useRecoilValue } from "recoil";
import { activeMarkerState } from "recoil/routeMapControls/atoms";

import { useSidebarSearch } from "hooks/application/useSidebarSearch";

import { AppointmentMarker } from "../../../Markers";
import { useVisibleMarkers, VisibleMarker } from "../../useVisibleMarkers";
import { useHoverMarker } from "../useHoverMarker";

import { useClickMarkerInOverviewMode } from "./useClickMarkerInOverviewMode";

import { Map } from "interface/map";

export const useRenderMarkersInOverviewMode = (map: Map) => {
  const visibleMarkers = useVisibleMarkers();

  const activeMarker = useRecoilValue(activeMarkerState);
  const { hoverMarkers, unhoverMarker, getIsMarkerHovered } = useHoverMarker();

  const { getIsMarkerSearched } = useSidebarSearch();

  const handleMarkerClick = useClickMarkerInOverviewMode();

  const renderSingleMarker = (marker: VisibleMarker) => {
    const { markerKey, appointmentIDs, lat, lng } = marker;

    return (
      <AppointmentMarker
        lat={lat}
        lng={lng}
        key={markerKey}
        markerKey={markerKey}
        appointmentIDs={appointmentIDs}
        map={map}
        isActive={activeMarker.id === markerKey}
        isHovered={getIsMarkerHovered(appointmentIDs)}
        shouldBounce={getIsMarkerSearched(appointmentIDs)}
        onClick={handleMarkerClick}
        onMouseEnter={hoverMarkers}
        onMouseLeave={unhoverMarker}
      />
    );
  };

  const renderMarkersInOverviewMode = () => visibleMarkers.map(renderSingleMarker);

  return { renderSingleMarker, renderMarkersInOverviewMode };
};
