import React, { useRef } from "react";

import * as S from "./styles";
import { COLORS } from "styles";

import { useAppointments } from "hooks/api/appointments";
import { useRoutes } from "hooks/api/routes";

import { Markers as MarkersLib } from "lib/markers";

import { Label } from "./Label";
import { MultiIcon } from "./MultiIcon";
import { SingleIcon } from "./SingleIcon";
import { getColorByTimeslot, getHoverColor } from "./utils";

import { Map } from "interface/map";

interface AppointmentMarkerProps {
  lat: number;
  lng: number;
  markerKey: string;
  appointmentIDs: string[];
  map: Map;
  isActive?: boolean;
  isHovered?: boolean;
  shouldBounce?: boolean;
  onClick?: (appointmentIDs: string[], markerKey: string) => void;
  onMouseEnter?: (appointmentIDs: string[]) => void;
  onMouseLeave?: () => void;
}

export const AppointmentMarker: React.FC<AppointmentMarkerProps> = React.memo(
  ({
    appointmentIDs,
    markerKey,
    isActive = false,
    isHovered = false,
    shouldBounce = false,
    onClick,
    onMouseEnter,
    onMouseLeave,
  }) => {
    const clickTime = useRef(0);
    const allowClick = useRef(false);
    const { startingAppointmentIDs, endingAppointmentIDs, appointmentIDsWithExistingRoutes } = useRoutes();
    const { appointmentsLibrary } = useAppointments();

    const appointments = appointmentIDs.map((id) => appointmentsLibrary[id]).filter(Boolean);
    const isMultipleAppointments = appointments.length > 1;

    const isStart = appointments.some((appt) => startingAppointmentIDs.includes(appt.id));
    const isEnd = appointments.some((appt) => endingAppointmentIDs.includes(appt.id));
    const isOnARoute = appointments.some((appt) => appointmentIDsWithExistingRoutes.includes(appt.id));
    const isAllOnARoute = appointments.every((appt) => appointmentIDsWithExistingRoutes.includes(appt.id));

    const handleMarkerClick = () => {
      if (!allowClick) return;
      onClick?.(appointmentIDs, markerKey);
    };

    const getColor = () => {
      const color = getColorByTimeslot(appointments[0]?.timerange);
      return isHovered ? getHoverColor(color) : color;
    };
    const color = getColor();

    const getLabelText = () => {
      return isMultipleAppointments ? "OLA" : MarkersLib.getMarkerLabel(appointments, { isStart, isEnd });
    };

    const getLabelColor = () => {
      if (isMultipleAppointments) {
        return isAllOnARoute ? COLORS.WHITE : COLORS.CHARCOAL;
      }
      return isOnARoute ? COLORS.WHITE : color;
    };

    const labelText = getLabelText();
    const labelColor = getLabelColor();

    return (
      <S.Container
        scale={isActive || isHovered}
        bounce={shouldBounce}
        onClick={handleMarkerClick}
        onMouseDown={() => {
          clickTime.current = Date.now();
        }}
        onMouseUp={() => {
          allowClick.current = Date.now() - clickTime.current < 100;
        }}
        onMouseEnter={() => onMouseEnter?.(appointmentIDs)}
        onMouseLeave={onMouseLeave}
        data-testid={`appointmentMarker[${markerKey}]`}
      >
        {isActive && (
          <S.PulseAnimationWrapper>
            <S.PulseAnimation color={color} />
          </S.PulseAnimationWrapper>
        )}
        {isMultipleAppointments ? (
          <MultiIcon type={isAllOnARoute ? "solid" : "outlined"} />
        ) : (
          <SingleIcon type={isOnARoute ? "solid" : "outlined"} color={color} />
        )}

        <Label color={labelColor} markerType={isMultipleAppointments ? "overlapping" : "single"}>
          {labelText}
        </Label>
        {isMultipleAppointments && <S.AppointmentsCountIndicator>{appointments.length}</S.AppointmentsCountIndicator>}
      </S.Container>
    );
  }
);

AppointmentMarker.displayName = "AppointmentMarker";
