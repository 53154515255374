import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

interface OverlayProps {
  side: "left" | "right";
  visible?: boolean;
}

export const Overlay = styled.div<OverlayProps>`
  position: absolute;
  display: ${({ visible }) => (!visible ? "none" : "")};
  right: ${({ side }) => (side === "right" ? "56px" : "")};
  z-index: 2;
  margin: ${({ side }) => (side === "left" ? "0 0 0 -1px" : "")};
  width: 56px;
  height: 32px;
  opacity: 0.8;
  pointer-events: none;
  background: ${({ side }) => `linear-gradient(
    to ${side === "left" ? "right" : "left"},
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 0) 100%
  )`};
`;
