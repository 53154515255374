import { Storage, Fulfillment } from "@secondcloset/types";

import { DataNode } from "rc-tree-select/lib/interface";

import { Appointment } from "lib/appointment";

import { AppointmentOrShipment } from "interface/appointment";

type AppointmentType = Storage.AppointmentType | Fulfillment.AppointmentType;
type OptionTypes = Storage.Appointment | Storage.AppointmentSlot | Fulfillment.ScServiceCode | AppointmentType;

type AllOptionType =
  | {
      key: OptionTypes;
      value: OptionTypes;
      label: string;
    }
  | DataNode;

type SimpleModeAllOptionType = {
  id: string;
  pId: string | null;
  value: string;
  title: string;
};

export const getSelectPlaceHolder = (
  numOfAllOptions: number[],
  selectedOptionKeys: OptionTypes[] | string[],
  filterName: string,
  allOptions?: AllOptionType[] | null,
  simpleAllOptions?: SimpleModeAllOptionType[] | null
) => {
  const numberOfSelectedOptions = selectedOptionKeys.length;
  let label = `${numberOfSelectedOptions} Selected`;
  if (!!allOptions) {
    if (numberOfSelectedOptions === 1) {
      const selectedOption = allOptions.filter((o) => o.key === selectedOptionKeys[0]);
      label = `${selectedOption.map((o) => o.label)}`;
    }
  }
  if (!!simpleAllOptions) {
    if (numberOfSelectedOptions === 1) {
      const selectedOption = simpleAllOptions.filter((o) => o.id === selectedOptionKeys[0]);
      label = `${selectedOption.map((o) => o.title)}`;
    }
  }
  if (numOfAllOptions.includes(numberOfSelectedOptions)) label = `All ${filterName}`;

  return label;
};

export const NOT_SPECIFIED_ORGANIZATION_KEY = "not_specified";
export const ALL_ORGANIZATIONS_KEY = "all_organizations";

export const getAppointmentOrganizationID = (appointment: AppointmentOrShipment) => {
  if (Appointment.isFulfillmentAppointment(appointment)) {
    const orgID = appointment.source?.customer?.organization_id;
    return orgID ?? NOT_SPECIFIED_ORGANIZATION_KEY;
  }

  return NOT_SPECIFIED_ORGANIZATION_KEY;
};
