import { useEffect } from "react";
import { useQueryClient } from "react-query";

import * as S from "./styles";

import { useRecoilState, useRecoilValue } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";
import { appointmentDetailsModalState } from "recoil/sideBarControls/atoms";

import { useAppointments } from "hooks/api/appointments";
import { useRoutes } from "hooks/api/routes";
import { useCreateRouteView } from "hooks/application/createRoute";
import { useActiveDate } from "hooks/application/useActiveDate";

import { AppointmentDetailsModal } from "components/AppointmentDetailsModal";

import { ActiveMarkerAppointmentsOverview } from "./ActiveMarkerAppointmentsOverview";
import { ActiveRouteOverview } from "./ActiveRouteOverview";
import { ConstructingView } from "./ConstructingView";
import { MainView } from "./MainView";
import { OverlappingAppointmentsSelector } from "./OverlappingAppointmentsSelector";
import { Preloader } from "./Preloader";
import { Search } from "./Search";
import { useCompletedRouteAutomationJobEffects } from "./useCompletedRouteAutomationJobEffects";
import { useFocusActiveMarkerAppointment } from "./useFocusActiveMarkerAppointment";

export const SideBar: React.FC = () => {
  const { isLoading: isRoutesLoading } = useRoutes();
  const { isLoading: isAppointmentsLoading } = useAppointments();
  const { shouldRenderMainView, shouldRenderConstructionView } = useCreateRouteView();
  const [appointmentDetailsModal, setAppointmentDetailsModal] = useRecoilState(appointmentDetailsModalState);
  const queryClient = useQueryClient();
  const { activeDate } = useActiveDate();
  const activeServiceArea = useRecoilValue(activeServiceAreaState);
  useFocusActiveMarkerAppointment();

  useCompletedRouteAutomationJobEffects();
  useEffect(() => {
    queryClient.invalidateQueries("routeAutomationJobs");
  }, [activeDate, activeServiceArea, queryClient]);

  return (
    <S.Container>
      <S.InnerContainer isSideBarShowing={true}>
        <Search showCSV />

        {isRoutesLoading || isAppointmentsLoading ? (
          <Preloader />
        ) : (
          <>
            {shouldRenderMainView && <MainView />}
            {shouldRenderConstructionView && <ConstructingView />}

            <OverlappingAppointmentsSelector />
            <ActiveRouteOverview />
            <ActiveMarkerAppointmentsOverview />
          </>
        )}
      </S.InnerContainer>

      <AppointmentDetailsModal
        visible={appointmentDetailsModal.visible}
        appointmentID={appointmentDetailsModal.appointmentID}
        onClose={() => setAppointmentDetailsModal({ visible: false, appointmentID: "" })}
      />
    </S.Container>
  );
};
