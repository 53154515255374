import { useMemo, useState, useEffect } from "react";

import { chain } from "lodash";

import { useSetRecoilState } from "recoil";
import { CreateRouteView, createRouteState as createRouteStateAtom } from "recoil/routes/atoms";

import { useAppointments } from "hooks/api/appointments";
import { useCreateRouteState } from "hooks/application/createRoute";

import { groupAppointments } from "utilities/routes/groupAppointment";

import { AppointmentOrShipment } from "interface/appointment";

export const useSelectedAppointments = () => {
  const { appointmentsLibrary } = useAppointments();

  const setCreateRouteState = useSetRecoilState(createRouteStateAtom);
  const { currentView, setRouteDetails, selectedAppointmentsIDs, toggleAppointmentSelected } = useCreateRouteState();

  const isVisible = currentView === CreateRouteView.ConstructRouteAddMoreAppointments;

  const [initialSelectedAppointmentsIDs, setInitialSelectedAppointmentsIDs] = useState<string[]>([]);
  useEffect(() => {
    if (isVisible) {
      setInitialSelectedAppointmentsIDs(selectedAppointmentsIDs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const appointmentsSelectionMap = useMemo(
    () =>
      chain(selectedAppointmentsIDs)
        .keyBy((appointmentID) => appointmentID)
        .mapValues(() => true)
        .value(),
    [selectedAppointmentsIDs]
  );

  const getIsAppointmentSelected = (appointment: AppointmentOrShipment) => appointmentsSelectionMap[appointment.id];

  const confirmSelection = () => {
    selectedAppointmentsIDs.forEach((appointmentID) => {
      delete appointmentsSelectionMap[appointmentID];
    });

    let createRouteNewSelectedAppointmentIDs = [...selectedAppointmentsIDs, ...Object.keys(appointmentsSelectionMap)];
    createRouteNewSelectedAppointmentIDs = groupAppointments(
      createRouteNewSelectedAppointmentIDs,
      appointmentsLibrary
    ).map((a) => a.id);

    setRouteDetails({
      selectedAppointmentsIDs: createRouteNewSelectedAppointmentIDs,
    });
  };

  const cancelSelection = () => {
    setCreateRouteState((state) => ({
      ...state,
      selectedAppointmentsIDs: initialSelectedAppointmentsIDs,
    }));
  };

  return {
    getIsAppointmentSelected,
    toggleAppointmentSelected,
    confirmSelection,
    cancelSelection,
  };
};
