import { Menu } from "antd";
import styled, { css } from "styled-components";

import { COLORS } from "styles";

import { AppointmentCard as AppointmentCardBase, AppointmentHoverShadow } from "../AppointmentCard";

interface AppointmentCardProps {
  hovered?: boolean;
  selected?: boolean;
  allowSelection: boolean;
}
export const AppointmentCard = styled(AppointmentCardBase)<AppointmentCardProps>`
  cursor: pointer;
  overflow: hidden;

  .ant-card-body {
    ${(props) => props.allowSelection && "cursor: pointer"};
  }

  ${(props) => props.hovered && AppointmentHoverShadow};

  ${(props) =>
    props.selected &&
    css`
      background-color: ${COLORS.PRIMARY_LIGHT};
    `}
`;

export const MenuItemTitle = styled(Menu.Item)`
  &:hover {
    cursor: initial;
  }
`;

export const CreateRouteMenuContainerStyle = (x: number, y: number) => {
  const width = 220;

  return {
    width: `${width}px`,
    left: x + width > window.innerWidth ? `${window.innerWidth - width}px` : `${x}px`,
    top: `${y}px`,
    position: "fixed",
    zIndex: 1,
  } as React.CSSProperties;
};
