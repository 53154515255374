import React from "react";

import { useVehicleStatuses } from "hooks/api/vehicles";

import { Tabs } from "components/Tabs";

interface VehicleStatusSelectorProps {
  value: string;
  onChange: (status: string) => void;
}

export const VehicleStatusSelector: React.FC<VehicleStatusSelectorProps> = ({ value, onChange }) => {
  const options = useVehicleStatuses();
  if (!options) return null;
  return (
    <Tabs
      tabs={options.map((option) => ({ key: option.name, title: option.to_show }))}
      selectedTab={value}
      onChange={(tab) => onChange(tab.key as string)}
    />
  );
};
