import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";

import { useRecoilValue } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";

import { fetchRouteAutomationJobs } from "api/routeAutomationJobs";

import { useActiveDate } from "../application/useActiveDate";

import { JobStatus, RouteAutomationJob } from "interface/routeAutomationJob";

const THIRTY_SECONDS = 30 * 1000;

export const useRouteAutomationJobs = () => {
  const { activeDate } = useActiveDate();
  const serviceArea = useRecoilValue(activeServiceAreaState);

  const {
    isLoading,
    isFetched,
    error,
    data: routeAutomationJobs,
    refetch: refetchRouteAutomationJobs,
  } = useQuery<RouteAutomationJob[]>(
    ["routeAutomationJobs", activeDate, serviceArea],
    () => {
      return fetchRouteAutomationJobs({ date: activeDate, serviceArea });
    },
    {
      initialData: [],
      enabled: !!activeDate && !!serviceArea && !!process.env.REACT_APP_SILVER_API,
      refetchInterval: THIRTY_SECONDS,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  const activeRouteAutomationJobs = useMemo(() => {
    return routeAutomationJobs?.filter((job) => job.status === JobStatus.ACTIVE);
  }, [routeAutomationJobs]);

  const routeAutomationAppointmentLibrary = useMemo(() => {
    if (!routeAutomationJobs || !routeAutomationJobs.length) return {};
    const appointmentIDsJobs = routeAutomationJobs.reduce((acc, job) => {
      job.appointment_ids.forEach((id) => {
        if (!acc[id]) acc[id] = [job];
        else acc[id].push(job);
      });
      return acc;
    }, {} as { [appointmentID: string]: RouteAutomationJob[] });
    return appointmentIDsJobs;
  }, [routeAutomationJobs]);

  const getUnavailableVehicleIDs = () => {
    if (!routeAutomationJobs) return [];
    return routeAutomationJobs.reduce((acc, job) => {
      if (job.status === JobStatus.ACTIVE) {
        return [...acc, ...job.vehicle_ids];
      }
      return acc;
    }, [] as string[]);
  };

  const appointmentIsInActiveJob = useCallback(
    (appointment: { id: string }) =>
      routeAutomationAppointmentLibrary[appointment.id] &&
      !!routeAutomationAppointmentLibrary[appointment.id]?.find((job) => job.status === JobStatus.ACTIVE),
    [routeAutomationAppointmentLibrary]
  );

  return {
    isLoading,
    isFetched,
    error,
    routeAutomationJobs,
    activeRouteAutomationJobs,
    routeAutomationAppointmentLibrary,
    getUnavailableVehicleIDs,
    appointmentIsInActiveJob,
    refetchRouteAutomationJobs,
  };
};
