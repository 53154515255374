import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  margin-right: 16px;
`;

export const PopOverContainer = styled.div`
  max-width: 200px;
`;
