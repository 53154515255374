import { Drawer as AntdDrawer } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

import { AppointmentCard as AppointmentCardBase, AppointmentHoverShadow } from "../../AppointmentCard";

export const Drawer = styled(AntdDrawer)`
  position: absolute;

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

export const Title = styled.div`
  color: ${COLORS.PRIMARY_BLUE};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  user-select: none;
`;

export const AppointmentsList = styled.div`
  height: 100%;
  overflow: auto;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: auto;
  padding: 17px;
`;

interface AppointmentCardProps {
  hovered?: boolean;
}
export const AppointmentCard = styled(AppointmentCardBase)<AppointmentCardProps>`
  overflow: hidden;
  cursor: pointer;

  ${(props) => props.hovered && AppointmentHoverShadow};
`;
