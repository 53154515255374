import * as S from "./styles";

import { AssignDateButton } from "./AssignDateButton";

export const Header = () => (
  <S.HeaderWrapper>
    <S.HeaderText>Range of Days Appointments</S.HeaderText>
    <AssignDateButton />
  </S.HeaderWrapper>
);
