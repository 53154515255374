import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const Image = styled.img`
  width: 100%;
  object-fit: contain;
`;
