import { HiX, HiChevronDown } from "react-icons/hi";

import { Modal as AntdModal, Select } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

export const Modal = styled(AntdModal)`
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ModalCloseIcon = styled(HiX)`
  width: 20px;
  height: 20px;
  color: ${COLORS.CHARCOAL};
`;

export const WarehouseSelect = styled(Select)`
  width: 100%;

  .ant-select-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const WarehouseSelectArrowIcon = styled(HiChevronDown)`
  flex-shrink: 0;
  width: 20px;
  height: 20px;
`;
