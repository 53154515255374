import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-top: 0;
  color: ${COLORS.GREY_DARKER};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`;

export const HiddenAppointmentsCount = styled.span`
  margin-left: 10px;
  color: ${COLORS.GREY_DARK};
`;
