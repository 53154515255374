import React, { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";

import { Fulfillment } from "@secondcloset/types";

import { Drawer, Divider, Row, Col, notification } from "antd";
import { DrawerProps } from "antd/lib/drawer";
import Search from "antd/lib/input/Search";

import { useSetRecoilState } from "recoil";
import { resetRouteMapControls } from "recoil/routeMapControls/selectors";
import { resetSideBarControls } from "recoil/sideBarControls/selectors";

import { useAppointments } from "hooks/api/appointments";
import { useRoutes } from "hooks/api/routes";
import { useActiveDate } from "hooks/application/useActiveDate";

import { createOrUpdateRoute, deleteRoute } from "api/routes";

import { filterRoutesByUser } from "../utils/filterRoutesByUser";

import { RouteItemsTable } from "./RouteItemsTable";
import { RouteNumberFilter } from "./RouteNumberFilter";
import { SelectedRoute } from "./SelectedRoute";

import { Route, RouteItem } from "interface/route";

export const DevToolDrawer: React.FC<DrawerProps> = (p) => {
  const queryClient = useQueryClient();

  const [searchedUser, setSearchedUser] = useState("");
  const [activeRouteIDs, setActiveRouteIDs] = useState<string[]>([]);
  const [routes, setRoutes] = useState<Route[]>([]);
  const [routeItems, setRouteItems] = useState<RouteItem[]>([]);
  const { isFetched: isRoutesFetched, routesLibrary } = useRoutes();
  const { isFetched: isAppointmentsFetched, appointmentsLibrary } = useAppointments();
  const isLoaded = isRoutesFetched && isAppointmentsFetched;
  const { activeDate } = useActiveDate();
  const dispatchResetSideBarControls = useSetRecoilState(resetSideBarControls);
  const dispatchResetRouteMapControls = useSetRecoilState(resetRouteMapControls);
  const { mutate: constructRoute, isLoading: isConstructRouteLoading } = useMutation(createOrUpdateRoute, {
    onSuccess: () => {
      updateStates();
      notification.success({
        message: "Success!",
        description: "Route has been updated",
      });
    },
  });
  const { mutate: onDeleteRoute, isLoading: isDeleteRouteLoading } = useMutation(deleteRoute, {
    onSuccess: () => {
      updateStates();
      notification.success({
        message: "Success!",
        description: "Route has been deleted",
      });
    },
  });

  useEffect(() => {
    setRoutes(Object.values(routesLibrary));

    // when routes library change and there's already a user searched
    if (searchedUser) handleSearchRouteByUser(searchedUser);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [routesLibrary]);

  useEffect(() => {
    const allActiveRoutes = routes.map((r) => r.id);
    setActiveRouteIDs(allActiveRoutes);
  }, [searchedUser, routes]);

  useEffect(() => {
    if (!isLoaded) return;
    const filteredRouteItems = routes
      .filter((route) => activeRouteIDs.includes(route.id))
      .flatMap((r) =>
        r.route_items.map((i) => {
          const appointment = appointmentsLibrary[i.appointment_id];
          return {
            ...i,
            route_number: r.route_number,
            shipment_number: (appointment as Fulfillment.Appointment)?.shipment?.shipment_number,
          };
        })
      );
    setRouteItems(filteredRouteItems);
  }, [routes, activeRouteIDs, appointmentsLibrary]);

  const handleSearchRouteByUser = (user: string) => {
    setSearchedUser(user);
    setRoutes(filterRoutesByUser(Object.values(routesLibrary), user));
  };

  const getRouteByAppointmentID = (appointmentId: string): Route | undefined => {
    return routes.find((route: Route) => {
      return route?.route_items.find((routeItem: RouteItem) => routeItem.appointment_id === appointmentId);
    });
  };

  const removeAppointmentInRoute = (route: Route, appointmentId: string) => {
    const routeItems = route.route_items;
    const index = routeItems.findIndex((routeItem: RouteItem) => routeItem.appointment_id === appointmentId);
    routeItems.splice(index, 1);
  };

  const updateStates = () => {
    dispatchResetSideBarControls();
    dispatchResetRouteMapControls();
    queryClient.invalidateQueries("routes");
  };

  const deleteEmptyRoute = (route: Route) => {
    onDeleteRoute(route.id);
  };

  const updateRoute = (route: Route) => {
    const routeAppointmentIDs = route.route_items.map((routeItem) => routeItem.appointment_id);
    const params = {
      date: activeDate,
      number: +route.route_number,
      appointmentIDs: routeAppointmentIDs,
      users: route.route_users,
      vehicleID: route.vehicle_id || "",
      routeID: route.id || "",
    };

    constructRoute(params);
  };

  const onDelete = (apptID: string) => {
    const route = getRouteByAppointmentID(apptID);
    if (!route) {
      return console.error("Route not found in dev tool");
    }
    removeAppointmentInRoute(route, apptID);
    if (route.route_items?.length > 0) updateRoute(route);
    else deleteEmptyRoute(route);
  };

  return (
    <Drawer title="BVR Developer tooling" width="60vw" {...p}>
      <SelectedRoute activeRouteIDs={activeRouteIDs} />
      <Row align="middle" gutter={16}>
        <Col sm={24} md={10}>
          <RouteNumberFilter data={routes} value={activeRouteIDs} onChange={setActiveRouteIDs} isLoading={!isLoaded} />
        </Col>
        <Col sm={24} md={14}>
          <Search
            placeholder="Search routes by username"
            onSearch={handleSearchRouteByUser}
            onChange={({ target }) => {
              if (!target.value) handleSearchRouteByUser("");
            }}
            allowClear
          />
        </Col>
      </Row>
      <Divider orientation="left">{searchedUser ? `${searchedUser}'s` : "All"} routes</Divider>
      <RouteItemsTable
        data={routeItems}
        isLoading={!isLoaded || isConstructRouteLoading || isDeleteRouteLoading}
        onDelete={onDelete}
      />
    </Drawer>
  );
};
