import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  width: 265px;
  height: 265px;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
`;

export const UploadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 2px dashed ${COLORS.GREY_MID};
  background-color: ${COLORS.GREY_LIGHTER};
  cursor: pointer;

  &:hover {
    border-color: ${COLORS.PRIMARY_BLUE};
  }
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  font-size: 24px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    background-color: black;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover:before {
    opacity: 0.5;
  }
`;

export const ActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  z-index: 2;
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 0;
  border-radius: 5px;
  background: transparent;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }

  ${Container}:hover & {
    opacity: 1;
  }
`;

export const ImagePreview = styled.img`
  width: 100%;
`;
