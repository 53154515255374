import { Drawer as AntdDrawer } from "antd";
import styled from "styled-components";

import { COLORS } from "styles";

import { AppointmentCard as AppointmentCardBase, AppointmentHoverShadow } from "../AppointmentCard";

export const Drawer = styled(AntdDrawer)`
  position: absolute;

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

export const Header = styled.div`
  padding: 11px 17px;
`;

export const Title = styled.h5`
  margin: 0;
  color: ${COLORS.PRIMARY_BLUE};
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

export const Subtitle = styled.p`
  margin: 0;
  margin-top: 8px;
  color: ${COLORS.GREY_DARK};
  font-size: 14px;
  line-height: 20px;
`;

export const AppointmentCard = styled(AppointmentCardBase)`
  overflow: hidden;
  cursor: pointer;

  &:hover {
    ${AppointmentHoverShadow};
  }
`;

export const Appointments = styled.div`
  overflow: auto;
  flex-basis: 100%;
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 17px;
`;
