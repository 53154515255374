import { useMemo } from "react";

import { useRoutesWithAppointments } from "hooks/api/routes";
import { useSidebarSearch } from "hooks/application/useSidebarSearch";

export const useAppointmentsToRender = () => {
  const routesWithAppointments = useRoutesWithAppointments();
  const { isSearchEnabled, getIsAppointmentSearched } = useSidebarSearch();

  const routesWithAppointmentsToRender = useMemo(() => {
    if (!isSearchEnabled) return routesWithAppointments;

    const result = [] as typeof routesWithAppointments;

    routesWithAppointments.forEach((routeWithAppointments) => {
      const searchedRouteAppointments = routeWithAppointments.appointments.filter(getIsAppointmentSearched);
      if (searchedRouteAppointments.length === 0) return;

      result.push({
        ...routeWithAppointments,
        appointments: searchedRouteAppointments,
      });
    });

    return result;
  }, [isSearchEnabled, routesWithAppointments, getIsAppointmentSearched]);

  const appointmentsToRender = useMemo(() => {
    return routesWithAppointmentsToRender.flatMap((route) => route.appointments);
  }, [routesWithAppointmentsToRender]);

  return {
    routesWithAppointmentsToRender,
    appointmentsToRender,
  };
};
