import React from "react";

import { Button } from "antd";

import * as S from "./styles";

import { useRecoilValue } from "recoil";
import { createRouteState } from "recoil/routes/atoms";

interface UnassignedRouteHeaderProps {
  label: string;
  visible: boolean;
  mode: "view" | "create";
  onToggleDisplay: (visible: boolean) => void;
  onCreate: () => void;
  onCancel: () => void;
}

export const UnassignedRouteHeader: React.FC<UnassignedRouteHeaderProps> = ({
  label,
  visible,
  mode,
  onToggleDisplay,
  onCreate,
  onCancel,
}) => {
  const { enabled: isCreateRouteStateEnabled } = useRecoilValue(createRouteState);

  const renderVisibilityButton = () => {
    if (isCreateRouteStateEnabled) return false;

    if (visible) {
      return (
        <S.IconButton onClick={() => onToggleDisplay(false)}>
          <S.VisibilityIcon visible />
        </S.IconButton>
      );
    }

    return (
      <S.IconButton onClick={() => onToggleDisplay(true)}>
        <S.VisibilityIcon />
      </S.IconButton>
    );
  };

  return (
    <S.MainRow>
      <S.LabelColumn>{label}</S.LabelColumn>

      <S.ActionColumn onClick={(e) => e.stopPropagation()}>
        {renderVisibilityButton()}

        {mode === "view" && (
          <Button type="primary" onClick={() => onCreate()}>
            Create
          </Button>
        )}
        {mode === "create" && (
          <Button type="ghost" onClick={() => onCancel()}>
            Cancel
          </Button>
        )}
      </S.ActionColumn>
    </S.MainRow>
  );
};
