import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useLocation, Redirect } from "react-router";

import { notification, Spin } from "antd";

import * as S from "./styles";

import { useRecoilState } from "recoil";
import { userState } from "recoil/_common/user";

import { login } from "api/login";
import { Logo } from "components/Logo";
import { PageContainer } from "components/PageContainer";
import * as Cookie from "lib/cookie";
import * as User from "lib/user";

import { AnimatedBackground } from "./AnimatedBackground";
import { LoginForm } from "./LoginForm";

export const LoginPage: React.FC = () => {
  const [error, setError] = useState("");
  const [user, setUser] = useRecoilState(userState);
  const { mutate: onLogin, isLoading } = useMutation(login, {
    onSuccess: (credential) => {
      const user = credential.user;
      const token = credential.token;
      const isAdmin = User.isAdmin(user);
      if (!isAdmin) {
        return notification.error({
          message: "Error",
          description: "Access denied",
        });
      }
      Cookie.setCredential({ user, token }, true);
      setUser(user);
    },
    onError: (error: string) => {
      setError(error);
    },
  });
  const location = useLocation();

  useEffect(() => {
    const userInCookie = Cookie.getCredential()?.user;
    if (userInCookie) setUser(userInCookie);
  }, [setUser]);

  if (user) {
    type TStateFrom = { from: { pathname: string } };
    const { from } = (location.state ?? {
      from: { pathname: "/" },
    }) as TStateFrom;
    return <Redirect to={from} />;
  }

  return (
    <Spin tip="logging in..." spinning={isLoading}>
      <PageContainer>
        <S.Container>
          <S.BackgroundContainer>
            <AnimatedBackground />
          </S.BackgroundContainer>
          <S.AppName>Route Planner</S.AppName>
          <S.LogoWrap>
            <Logo />
          </S.LogoWrap>
          <h1>LOGIN</h1>
          <LoginForm
            onFormSubmit={({ email, password }) => {
              onLogin({ email, password });
            }}
            error={error}
            isLoading={isLoading}
          />
        </S.Container>
      </PageContainer>
    </Spin>
  );
};
