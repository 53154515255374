import React, { useRef } from "react";

import { Switch } from "antd";

import { useRecoilState } from "recoil";
import { clusterModeControl } from "recoil/_common/clusterModeControl";

import * as Cookie from "lib/cookie";
import { SwitchLabel, SwitchWrapper } from "pages/RoutesPage/styles";

export const ClusterModeToggle: React.FC = () => {
  const [isClusterModeActive, dispatchClusterModeActive] = useRecoilState(clusterModeControl);
  const clusterModeToggleRef = useRef<HTMLButtonElement | null>(null);

  return (
    <SwitchWrapper isChecked={isClusterModeActive} onClick={() => clusterModeToggleRef?.current?.click()}>
      <SwitchLabel>Cluster Mode</SwitchLabel>
      <Switch
        ref={clusterModeToggleRef}
        checked={isClusterModeActive}
        onChange={(isActive: boolean) => {
          dispatchClusterModeActive(isActive);
          const appSettings = Cookie.getAppSettings();
          const newAppSettings = appSettings ? { ...appSettings, clusterMode: isActive } : { clusterMode: isActive };
          Cookie.setAppSettings(newAppSettings);
        }}
      />
    </SwitchWrapper>
  );
};
