import { Button } from "antd";

import * as S from "./styles";
import { LAYOUT } from "styles";

import { useSetRecoilState } from "recoil";
import { appointmentDetailsModalState } from "recoil/sideBarControls/atoms";

import { useCreateRouteView } from "hooks/application/createRoute";

import { useOverlappingAppointments } from "./hooks/useOverlappingAppointments";
import { useUpdateSelectedAppointments } from "./hooks/useUpdateSelectedAppointments";

export const OverlappingAppointmentsSelector = () => {
  const {
    appointments,
    appointmentsSelectionMap,
    getIsAppointmentSelected,
    toggleAppointmentSelected,
    resetOverlappingAppointmentsState,
  } = useOverlappingAppointments();
  const updateSelectedAppointments = useUpdateSelectedAppointments();
  const { isOverlappingAppointmentsSelectorVisible } = useCreateRouteView();
  const setAppointmentDetailsModal = useSetRecoilState(appointmentDetailsModalState);

  const handleCancel = () => {
    resetOverlappingAppointmentsState();
  };

  const handleConfirm = () => {
    updateSelectedAppointments(appointmentsSelectionMap);
    resetOverlappingAppointmentsState();
  };

  return (
    <S.Drawer
      visible={isOverlappingAppointmentsSelectorVisible}
      closable={false}
      getContainer={false}
      width={LAYOUT.SIDEBAR_WIDTH}
    >
      <S.Header>
        <S.Title>Overlapping Shipments</S.Title>
        <S.Subtitle>Following appointments have same location. Select appointment you wish to route.</S.Subtitle>
      </S.Header>

      <S.Appointments>
        {appointments.map((appointment) => (
          <S.AppointmentCard
            key={appointment.id}
            appointment={appointment}
            selected={getIsAppointmentSelected(appointment)}
            onClick={toggleAppointmentSelected}
            onShowDetails={(appointmentID) => setAppointmentDetailsModal({ visible: true, appointmentID })}
          />
        ))}
      </S.Appointments>

      <S.ActionButtons>
        <Button onClick={handleCancel}>Cancel</Button>

        <Button type="primary" onClick={handleConfirm}>
          Confirm
        </Button>
      </S.ActionButtons>
    </S.Drawer>
  );
};
