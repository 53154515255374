import React, { useEffect, useState } from "react";

import GoogleMapReact from "google-map-react";

import { useRecoilValue } from "recoil";
import { clusterModeControl } from "recoil/_common/clusterModeControl";
import { getRouteAppointmentIDs } from "recoil/routes/helpers";
import { activeRouteOverviewState, activeMarkerAppointmentsOverviewState } from "recoil/sideBarControls/atoms";
import { activeIkeaWarehouseAddressIDState } from "recoil/topFilterControls/atoms";

import { useAppointments } from "hooks/api/appointments";
import { useRoutes } from "hooks/api/routes";
import { useIkeaWarehouseAddressList } from "hooks/api/warehouses";
import { useCreateRouteState, useCreateRouteView } from "hooks/application/createRoute";
import { useActiveFlow } from "hooks/application/useActiveFlow";
import { useActiveMarker } from "hooks/application/useActiveMarker";
import { useActiveRouteOverview } from "hooks/application/useActiveRouteOverview";
import { useMapUISettings } from "hooks/application/useMapUISetting";
import { useRoutesMapVisibility } from "hooks/application/useRoutesMapVisibility";

import { Address } from "lib/address";
import { groupAppointments } from "utilities/routes/groupAppointment";

import { useClusters } from "./Hooks/useClusters";
import { useDrawPolyline } from "./Hooks/useDrawPolyline";
import { useHiddenAppointmentsListener } from "./Hooks/useHiddenAppointmentsListener";
import { useRenderMarkers } from "./Hooks/useRenderMarkers";
import { useVisibleMarkers } from "./Hooks/useVisibleMarkers";
import { ClusterMarker } from "./Markers/ClusterMarker";
import { IkeaMarker } from "./Markers/IkeaMarker";

import { Map, Maps } from "interface/map";

export const RoutesMap: React.FC = () => {
  const { routesLibrary } = useRoutes();
  const { appointmentsLibrary } = useAppointments();
  const activeIkeaWarehouseAddressID = useRecoilValue(activeIkeaWarehouseAddressIDState);
  const ikeaWarehouseAddressList = useIkeaWarehouseAddressList();
  const { isIkea } = useActiveFlow();
  const isClusterMode = useRecoilValue(clusterModeControl);
  const { activeMarker, resetState: resetActiveMarker } = useActiveMarker();
  const { getIsRouteVisible } = useRoutesMapVisibility();
  const mapUISettings = useMapUISettings();
  const [mapRefs, setMapRefs] = useState<{ map: Map; maps: Maps } | null>(null);
  const [bounds, setBounds] = useState<number[]>([]);
  const [zoom, setZoom] = useState(10);

  const visibleMarkers = useVisibleMarkers();
  const { renderMarkers, renderSingleMarker } = useRenderMarkers(mapRefs?.map);
  const drawPolyline = useDrawPolyline();
  const { closeRouteOverview } = useActiveRouteOverview();
  const { visible: isRouteOverviewVisible } = useRecoilValue(activeRouteOverviewState);
  const { visible: isAppointmentOverviewVisible } = useRecoilValue(activeMarkerAppointmentsOverviewState);

  const { shouldRenderMapRoutes, shouldRenderMapConstructionRoutes } = useCreateRouteView();
  const { selectedAppointmentsIDs } = useCreateRouteState();

  useHiddenAppointmentsListener();
  const { clusters, clusterMap, getClusterTotalAppointmentsCount } = useClusters({
    zoom,
    bounds,
    visibleMarkers,
  });

  useEffect(() => {
    if (!shouldRenderMapRoutes) return;

    const drawRoutesPolylines = () => {
      return Object.keys(routesLibrary)
        .filter(getIsRouteVisible)
        .map((routeID) => {
          const { map, maps } = mapRefs || {};
          const appointmentIDs = getRouteAppointmentIDs({ routeID, routesLibrary });
          return drawPolyline({ map, maps, appointmentIDs, routeID, clusterMap });
        });
    };

    const drawConstructingRoutePolylines = () => {
      const { map, maps } = mapRefs || {};

      const appointmentIDs = isIkea
        ? groupAppointments(selectedAppointmentsIDs, appointmentsLibrary).map((a) => a.id)
        : selectedAppointmentsIDs;

      return drawPolyline({
        map,
        maps,
        appointmentIDs,
        routeID: "new-route",
        clusterMap,
        dashed: true,
      });
    };

    if (mapRefs) {
      if (shouldRenderMapConstructionRoutes) {
        const { destroyPolylines } = drawConstructingRoutePolylines();
        return () => destroyPolylines();
      } else {
        const routesPolylines = drawRoutesPolylines();
        return () => routesPolylines.forEach(({ destroyPolylines }) => destroyPolylines());
      }
    }
  }, [
    appointmentsLibrary,
    clusterMap,
    drawPolyline,
    getIsRouteVisible,
    isIkea,
    mapRefs,
    routesLibrary,
    selectedAppointmentsIDs,
    shouldRenderMapConstructionRoutes,
    shouldRenderMapRoutes,
  ]);

  const handleMapClick = () => {
    if (!!activeMarker.id) resetActiveMarker();
    if (isRouteOverviewVisible && !isAppointmentOverviewVisible) closeRouteOverview();
  };

  const renderClusterModeMarkers = () => {
    if (!isClusterMode) return null;

    const map = mapRefs?.map;
    const supercluster = clusters.supercluster;

    return clusters.clusters.map((c) => {
      const { properties, geometry, id: clusterID } = c;
      const { cluster: isCluster } = properties;
      const [lng, lat] = geometry.coordinates;

      if (isCluster) {
        const pointsCount = getClusterTotalAppointmentsCount(clusterID);

        return (
          <ClusterMarker
            lng={lng}
            lat={lat}
            points={pointsCount}
            supercluster={supercluster}
            map={map}
            clusterID={clusterID}
          />
        );
      } else {
        const { markerKey, appointmentIDs } = properties;
        return renderSingleMarker({ markerKey, appointmentIDs, lat, lng });
      }
    });
  };

  const renderIkeaWarehouseMarkers = () => {
    if (!isIkea) return null;
    let positions;
    if (!!activeIkeaWarehouseAddressID) {
      const address = ikeaWarehouseAddressList.find((a) => a?.id === activeIkeaWarehouseAddressID);
      positions = [Address.getCoordinates(address)];
    } else {
      positions = ikeaWarehouseAddressList.map((address) => Address.getCoordinates(address));
    }
    return positions.map(({ lat, lng }) => {
      return <IkeaMarker key={`${lat}-${lng}`} lat={lat} lng={lng} />;
    });
  };

  useEffect(() => {
    if (!!activeMarker?.id && activeMarker.shouldCenter) {
      const { lat, lng } = JSON.parse(activeMarker.id);
      mapRefs?.map?.panTo({ lat, lng });
    }
  }, [activeMarker, mapRefs]);

  return (
    <GoogleMapReact
      {...mapUISettings}
      onClick={handleMapClick}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => {
        setMapRefs({ map, maps });
      }}
      onChange={({ zoom, bounds }) => {
        setZoom(zoom);
        setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
      }}
    >
      {renderMarkers()}
      {renderIkeaWarehouseMarkers()}
      {renderClusterModeMarkers()}
    </GoogleMapReact>
  );
};
