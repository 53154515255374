import styled from "styled-components";

import { LAYOUT } from "styles";

import { PageContainerProps } from "./PageContainer";

const sitePadding = LAYOUT.SITE_PADDING;

const getHeight = (props: PageContainerProps): string => {
  const headerHeight = props?.withHeader ? LAYOUT.HEADER_HEIGHT : "0px";
  const footerHeight = props?.withFooter ? LAYOUT.FOOTER_HEIGHT : "0px";
  return `calc(100vh - ${headerHeight} - ${footerHeight})`;
};

const getPadding = (props: PageContainerProps): string | null => {
  return props?.withPadding ? `${sitePadding} ${sitePadding}` : null;
};

export const Container = styled.div<PageContainerProps>`
  width: 100vw;
  height: ${(props) => getHeight(props)};
  padding: ${(props) => getPadding(props)};
`;

export const PageTitle = styled.div`
  font-size: 24px;
`;
