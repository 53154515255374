import React from "react";

import * as S from "./styles";
import { COLORS } from "styles";

import { useAppointments } from "hooks/api/appointments";

import { Markers as MarkersLib } from "lib/markers";

import { Label } from "./Label";
import { MultiIcon } from "./MultiIcon";
import { SingleIcon } from "./SingleIcon";
import { getColorByTimeslot } from "./utils";

import { AppointmentOrShipment } from "interface/appointment";
import { Map } from "interface/map";

interface AppointmentMarkerInCreateRouteModeProps {
  lat: number;
  lng: number;
  markerKey: string;
  appointmentIDs: string[];
  map: Map;
  selected?: boolean;
  isHovered?: boolean;
  isActive?: boolean;
  onClick?: (appointments: AppointmentOrShipment[], markerKey: string) => void;
  onMouseEnter?: (appointmentIDs: string[]) => void;
  onMouseLeave?: () => void;
}

export const AppointmentMarkerInCreateRouteMode: React.FC<AppointmentMarkerInCreateRouteModeProps> = React.memo(
  ({ appointmentIDs, selected, isHovered, isActive, markerKey, onClick, onMouseEnter, onMouseLeave }) => {
    const { appointmentsLibrary } = useAppointments();

    const appointments = appointmentIDs.map((id) => appointmentsLibrary[id]);
    const isMultipleAppointments = appointments.length > 1;

    const color = getColorByTimeslot(appointments[0]?.timerange);

    const getLabelText = () => {
      return isMultipleAppointments ? "OLA" : MarkersLib.getMarkerLabelInCreateRouteMode(appointments);
    };

    const getLabelColor = () => {
      if (isMultipleAppointments) {
        return selected ? COLORS.WHITE : COLORS.CHARCOAL;
      }
      return selected ? COLORS.WHITE : color;
    };

    const labelText = getLabelText();
    const labelColor = getLabelColor();

    return (
      <S.Container
        scale={isActive || isHovered}
        onClick={() => onClick?.(appointments, markerKey)}
        onMouseEnter={() => onMouseEnter?.(appointmentIDs)}
        onMouseLeave={onMouseLeave}
      >
        {isActive && (
          <S.PulseAnimationWrapper>
            <S.PulseAnimation color={color} />
          </S.PulseAnimationWrapper>
        )}
        {isMultipleAppointments ? (
          <MultiIcon type={selected ? "solid" : "outlined"} />
        ) : (
          <SingleIcon type={selected ? "solid" : "outlined"} color={color} />
        )}

        <Label color={labelColor} markerType={isMultipleAppointments ? "overlapping" : "single"}>
          {labelText}
        </Label>

        {isMultipleAppointments && <S.AppointmentsCountIndicator>{appointments.length}</S.AppointmentsCountIndicator>}
      </S.Container>
    );
  }
);

AppointmentMarkerInCreateRouteMode.displayName = "AppointmentMarkerInCreateRouteMode";
