import { useAppointments } from "hooks/api/appointments";

import { useActiveFlow } from "../useActiveFlow";

import { AppointmentOrShipment } from "interface/appointment";

export const useInventoryOnHandAppointment = () => {
  const { isIkea: isIkeaFlow } = useActiveFlow();
  const { inventoryOnHandAppointmentIDMap } = useAppointments();

  const getIsIkeaInventoryOnHandAppointment = (appointment: AppointmentOrShipment) => {
    if (isIkeaFlow) {
      return Boolean(inventoryOnHandAppointmentIDMap[appointment.id]);
    }
    return false;
  };

  return { getIsIkeaInventoryOnHandAppointment };
};
