import { useRecoilValue } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";

const YVR_TIMESLOTS = ["09-13", "13-17", "17-21"];
const DEFAULT_TIMESLOTS = ["10-13", "13-16", "16-19"];

export const useActiveServiceAreaTimeslots = () => {
  const activeServiceArea = useRecoilValue(activeServiceAreaState);

  if (activeServiceArea === "yvr") {
    return YVR_TIMESLOTS;
  }

  return DEFAULT_TIMESLOTS;
};
