import { TreeSelect } from "antd";
import { TreeSelectProps as IKEAAppointmentFilterProps } from "rc-tree-select";
import styled from "styled-components";

import { COLORS } from "styles";

export const IKEAAppointmentFilter = styled(TreeSelect)<IKEAAppointmentFilterProps>`
  .ant-select-selection-item {
    background-color: ${(props) => (props.disabled ? COLORS.GREY_LIGHTER : COLORS.WHITE)};
  }
`;
