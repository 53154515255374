import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .ant-table-wrapper {
    width: 100%;

    .ant-table-cell {
      max-width: 100px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;

export const ExternalOrderNumber = styled.a`
  white-space: nowrap;
`;
