import React from "react";

import { Modal, Button, Tag, Typography, Table } from "antd";
import startCase from "lodash/startCase";

import * as S from "./styles";

import { Appointment } from "lib/appointment";

import { AppointmentOrShipment } from "interface/appointment";

interface WarningModalProps {
  visible: boolean;
  appointments: AppointmentOrShipment[];
  setVisible: (visible: boolean) => unknown;
  onConfirm: () => unknown;
}

export const WarningModal: React.FC<WarningModalProps> = ({ visible, appointments, setVisible, onConfirm }) => {
  const renderWarningMessage = () => {
    return (
      <S.WarningTextContainer>
        <S.WarningText>
          The following appointments in your route are not in the correct status. They should be Confirmed, Ready or
          Scheduled.
        </S.WarningText>
        <br />
        <S.WarningText>(This can cause &quot;invalid signal&quot; errors for drivers on the road)</S.WarningText>
      </S.WarningTextContainer>
    );
  };

  const renderSierraLink = (apptID: string) => {
    const to = `${process.env.REACT_APP_SIERRA_DOMAIN}/appointments/${apptID}`;
    return (
      <Typography.Link href={to} target="_blank">
        <S.ViewDetailsLink>View Details</S.ViewDetailsLink>
      </Typography.Link>
    );
  };

  const getDataSource = () => {
    return appointments.map((appointment) => {
      const name = Appointment.getCustomerName(appointment);
      return {
        key: appointment.id,
        name: name,
        jobType: appointment,
        status: appointment,
        link: appointment.id,
      };
    });
  };

  const renderJobType = (appointment: AppointmentOrShipment) => {
    const jobType = appointment.job_type;
    if (!jobType) return null;

    const jobTypeColor = Appointment.getJobTypeColor(appointment);

    return <Tag color={jobTypeColor}>{startCase(jobType)}</Tag>;
  };

  const renderAppointmentStatus = (appointment: AppointmentOrShipment) => {
    const status = appointment.status;
    const statusColor = Appointment.getAppointmentStatusColor(appointment);
    return <Tag color={statusColor}>{startCase(status)}</Tag>;
  };

  const columns = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Appt. Type",
      dataIndex: "jobType",
      key: "jobType",
      render: renderJobType,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderAppointmentStatus,
    },
    {
      title: "",
      dataIndex: "link",
      key: "link",
      render: renderSierraLink,
    },
  ];
  return (
    <Modal
      visible={visible}
      onCancel={() => setVisible(false)}
      title="Warning"
      width={700}
      footer={[
        <Button key="cancel" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="done"
          type="primary"
          onClick={() => {
            onConfirm();
            setVisible(false);
          }}
        >
          Create Anyway
        </Button>,
      ]}
    >
      {renderWarningMessage()}
      <Table dataSource={getDataSource()} columns={columns} pagination={false} />
    </Modal>
  );
};
