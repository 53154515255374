import { useMemo } from "react";

import { uniq } from "lodash";

import { useRecoilState, useResetRecoilState } from "recoil";
import { CreateRouteView, createRouteState as createRouteStateAtom } from "recoil/routes/atoms";
import { sidebarSearchState } from "recoil/sideBarControls/atoms";

import { useAppointments } from "hooks/api/appointments";

import { addRemoveAppointmentRouteID } from "utilities/routes/addRemoveAppointmentRouteId";

import { AppointmentOrShipment } from "interface/appointment";
import { RouteUser, RouteItem } from "interface/route";

export interface SetRouteDetailsProps {
  routeID?: string;
  routeName?: string;
  routeItems?: RouteItem[];
  vehicleID?: string;
  users?: RouteUser[];
  selectedAppointmentsIDs?: string[];
}

export const useCreateRouteState = () => {
  const { appointmentsLibrary, appointmentsState } = useAppointments();

  const [createRouteState, setCreateRouteState] = useRecoilState(createRouteStateAtom);
  const resetSidebarSearch = useResetRecoilState(sidebarSearchState);
  const resetCreateRouteState = useResetRecoilState(createRouteStateAtom);

  const isCreateRouteView = createRouteState.currentView === CreateRouteView.ConstructRoute;

  const enable = () => {
    setCreateRouteState((state) => ({ ...state, enabled: true }));
  };

  const setCurrentView = (view: CreateRouteView) => {
    setCreateRouteState((state) => ({ ...state, currentView: view }));
    resetSidebarSearch();
  };

  const setRouteDetails = (routeDetails: SetRouteDetailsProps) => {
    setCreateRouteState((state) => ({
      ...state,
      ...routeDetails,
    }));
  };

  const toggleAppointmentSelected = (appointmentID: string) => {
    const { selectedAppointmentsIDs } = createRouteState;

    const newSelectedAppointmentsIDs = addRemoveAppointmentRouteID(
      appointmentID,
      selectedAppointmentsIDs,
      appointmentsState,
      isCreateRouteView
    );

    setCreateRouteState((state) => ({
      ...state,
      selectedAppointmentsIDs: newSelectedAppointmentsIDs,
    }));
  };

  const setSelectedAppointments = (appointmentIDs: string[]) => {
    const newSelectedAppointmentsIDs = uniq(
      appointmentIDs.flatMap((appointmentID) =>
        addRemoveAppointmentRouteID(appointmentID, [], appointmentsState, isCreateRouteView)
      )
    );
    setCreateRouteState((state) => ({
      ...state,
      selectedAppointmentsIDs: newSelectedAppointmentsIDs,
    }));
  };

  const createRouteAppointments = useMemo(() => {
    const { selectedAppointmentsIDs } = createRouteState;
    return selectedAppointmentsIDs.map((appointmentID) => appointmentsLibrary[appointmentID]).filter(Boolean);
  }, [appointmentsLibrary, createRouteState]);

  const overlappingAppointments = useMemo(
    () => createRouteState.overlappingAppointmentsIDs.map((id) => appointmentsLibrary[id]),
    [appointmentsLibrary, createRouteState.overlappingAppointmentsIDs]
  );

  const setOverlappingAppointments = (appointments: AppointmentOrShipment[]) => {
    setCreateRouteState((state) => ({
      ...state,
      isOverlappingAppointmentsSelectorVisible: true,
      overlappingAppointmentsIDs: appointments.map(({ id }) => id),
    }));
  };

  const resetOverlappingAppointmentsState = () => {
    setCreateRouteState((state) => ({
      ...state,
      isOverlappingAppointmentsSelectorVisible: false,
      overlappingAppointmentsIDs: [],
    }));
  };

  return {
    ...createRouteState,
    enable,
    setCurrentView,
    setRouteDetails,
    createRouteAppointments,
    toggleAppointmentSelected,
    overlappingAppointments,
    setOverlappingAppointments,
    setSelectedAppointments,
    resetOverlappingAppointmentsState,
    resetState: resetCreateRouteState,
  };
};
