import { useEffect } from "react";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { useHistory } from "react-router";

import { message, Popover } from "antd";
import moment from "moment";

import * as S from "./styles";

import { useAvailabilities } from "hooks/api/availabilities";
import { useActiveServiceAreaTimeslots } from "hooks/application/capacityUtilization";
import { useActiveDate } from "hooks/application/useActiveDate";

import { PageLoader } from "components/PageLoader";

import { Content as PageContent } from "../PageLayout/Content";
import { Header as PageHeader, Date as PageHeaderDate, Controls as PageHeaderControls } from "../PageLayout/Header";
import { ViewSelector } from "../ViewSelector";

import { Calendar } from "./Calendar";
import { CalendarDay } from "./CalendarDay";
import { Legend } from "./Legend";
import { TimeslotSelector } from "./TimeslotSelector";
import { useGetActiveMonthAndYear } from "./useGetActiveMonthAndYear";
import { useSelectedTimeslots } from "./useSelectedTimeslots";

export const MonthView = () => {
  const history = useHistory();
  const { activeDate } = useActiveDate();
  const { monthNumber, year } = useGetActiveMonthAndYear();
  const timeslots = useActiveServiceAreaTimeslots();
  const { selectedTimeslots, updateSelectedTimeslots } = useSelectedTimeslots();

  const dateFrom = moment({ year, month: monthNumber }).startOf("month").format("YYYY-MM-DD");
  const dateTo = moment({ year, month: monthNumber }).endOf("month").format("YYYY-MM-DD");
  const { availabilities, isLoading, isRefetching } = useAvailabilities(dateFrom, dateTo);

  useEffect(() => {
    if (isRefetching) {
      const hide = message.loading("Refetching availabilities", 20);
      return () => hide();
    }
  }, [isRefetching]);

  if (!availabilities || isLoading) return <PageLoader size="large" />;

  const handleDayClick = (date: string) => {
    history.push({
      pathname: "/capacity-utilization/day",
      search: `?date=${date}`,
    });
  };

  const renderDay = (day: number) => {
    const date = moment({ year, month: monthNumber, day }).format("YYYY-MM-DD");
    const dayIndex = availabilities?.findIndex((a) => a.date === date);

    return (
      <CalendarDay
        availability={availabilities[dayIndex]}
        date={date}
        timeslots={selectedTimeslots}
        onClick={handleDayClick}
      />
    );
  };

  return (
    <>
      <PageHeader>
        <PageHeaderDate>{moment(activeDate).format("MMMM, YYYY")}</PageHeaderDate>

        <PageHeaderControls>
          <TimeslotSelector
            timeslots={timeslots}
            selectedTimeslots={selectedTimeslots}
            onChange={updateSelectedTimeslots}
          />

          <ViewSelector
            value="month"
            onChange={(value) => {
              history.push(`/capacity-utilization/${value}?date=${activeDate}`);
            }}
          />

          <Popover trigger="click" placement="bottomLeft" content={<Legend />}>
            <S.IconButton>
              <S.Icon as={HiOutlineInformationCircle} />
            </S.IconButton>
          </Popover>
        </PageHeaderControls>
      </PageHeader>

      <PageContent>
        <Calendar month={monthNumber} year={year} render={renderDay} />
      </PageContent>
    </>
  );
};
