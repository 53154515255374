import { useQuery } from "react-query";

import { ServiceLevel } from "@secondcloset/fulfillment-utils";
import { ServiceLevelInterface } from "@secondcloset/fulfillment-utils/dist/lib/ServiceLevel";
import { Fulfillment, Storage } from "@secondcloset/types";

import { startCase } from "lodash";

import { useAppointments } from "hooks/api/appointments";
import { useActiveFlow } from "hooks/application/useActiveFlow";

import { fetchAppointmentDetails, fetchAppointmentNotes, fetchAppointmentTasks } from "api/appointment";
import { Appointment } from "lib/appointment";

import { Details } from "./types";

import { AppointmentOrShipment } from "interface/appointment";

type AppointmentNote = Storage.AppointmentNote;
type AppointmentTask = Storage.AppointmentTask;

const ONE_MINUTE = 60 * 1000;

const getServiceType = (appointment: AppointmentOrShipment) => {
  const serviceLevelLabel =
    ServiceLevel.getServiceLevelList().find(
      (sl: ServiceLevelInterface) => sl.key === appointment.delivery_service_level
    )?.label || "-";

  const jobType = startCase(appointment.job_type);
  return `${jobType} (${serviceLevelLabel})`;
};

const getSierraLink = (appointment: AppointmentOrShipment) => {
  return `${process.env.REACT_APP_SIERRA_DOMAIN}/appointments/${appointment.id}`;
};

export const useAppointmentDetails = (appointmentID: string) => {
  const { isIkea: isIkeaFlow } = useActiveFlow();
  const { inventoryOnHandAppointmentIDMap } = useAppointments();

  const getInventoryLocation = (appointment: AppointmentOrShipment) => {
    if (isIkeaFlow) {
      if (appointment.job_type === "delivery" || appointment.job_type === "cross_dock_delivery")
        return inventoryOnHandAppointmentIDMap[appointment.id] ? "On Hand" : "Pick Up";
    }

    if (appointment.job_type === "delivery") return "On Hand";
  };

  const formatFulfillmentAppointmentDetails = (appt: Fulfillment.Appointment): Details => {
    const inventoryLocation = getInventoryLocation(appt);

    return {
      orderId: appt.shipment.external_order_id ?? undefined,
      isFulfillment: true,
      externalOrderNumber: appt.shipment.external_order?.external_order_number,
      organizationName: appt.shipment.organization?.name,
      customer: {
        name: Appointment.getCustomerName(appt) || "N/A",
        phone: Appointment.getPhoneNumber(appt) || "N/A",
        address: Appointment.getOneLineAddress(appt) || "N/A",
      },
      internalNote: appt.source.internal_notes ?? undefined,
      shipmentNote: Appointment.getAppointmentNoteTexts(appt.shipment.notes).join(". "),
      marketplaceNote: appt.source.notes || "",
      appointmentServiceType: getServiceType(appt),
      appointmentSubType: startCase(appt.job_subtype),
      timeslot: appt.formatted_timeslot ?? undefined,
      inventoryLocation,
      sierraLink: getSierraLink(appt),
      items: Appointment.getCompressedItems(appt),
    };
  };

  const formatStorageAppointmentDetails = (appt: Storage.Appointment): Details => {
    const inventoryLocation = getInventoryLocation(appt);

    return {
      apptId: appt.id,
      apptNumber: appt.number,
      isFulfillment: false,
      organizationName: "",
      customer: {
        name: Appointment.getCustomerName(appt) || "N/A",
        phone: Appointment.getPhoneNumber(appt) || "N/A",
        address: Appointment.getOneLineAddress(appt) || "N/A",
      },
      storageNotes: Appointment.getAppointmentNoteTexts(appointmentNotes).join("\n"),
      storageTasks: Appointment.getAppointmentTaskTexts(appointmentTasks).join("\n"),
      appointmentServiceType: getServiceType(appt),
      appointmentSubType: startCase(appt.job_subtype),
      timeslot: appt.formatted_timeslot ?? undefined,
      inventoryLocation,
      sierraLink: getSierraLink(appt),
      items: Appointment.getCompressedItems(appt),
    };
  };

  const getFormattedAppointmentDetails = (appointmentDetails: AppointmentOrShipment) => {
    return appointmentDetails.type === "fulfillment"
      ? formatFulfillmentAppointmentDetails(appointmentDetails as Fulfillment.Appointment)
      : formatStorageAppointmentDetails(appointmentDetails as Storage.Appointment);
  };

  const { isLoading: isLoadingAppointmentDetails, data: appointmentDetails } = useQuery<AppointmentOrShipment>(
    ["appointments", appointmentID],
    () => fetchAppointmentDetails(appointmentID),
    {
      enabled: !!appointmentID,
      staleTime: ONE_MINUTE,
    }
  );

  const shouldFetchStorageNotesAndTask =
    !!appointmentID && !!appointmentDetails && appointmentDetails.type !== "fulfillment";

  const { isLoading: isLoadingAppointmentNotes, data: appointmentNotes } = useQuery<AppointmentNote[]>(
    ["appointmentsNotes", appointmentID],
    () => fetchAppointmentNotes(appointmentID),
    {
      enabled: shouldFetchStorageNotesAndTask,
      staleTime: ONE_MINUTE,
    }
  );

  const { isLoading: isLoadingAppointmentTasks, data: appointmentTasks } = useQuery<AppointmentTask[]>(
    ["appointmentsTasks", appointmentID],
    () => fetchAppointmentTasks(appointmentID),
    {
      enabled: shouldFetchStorageNotesAndTask,
      staleTime: ONE_MINUTE,
    }
  );

  const isLoading = isLoadingAppointmentDetails || isLoadingAppointmentNotes || isLoadingAppointmentTasks;

  return {
    appointmentDetails,
    isLoading,
    getFormattedAppointmentDetails,
  };
};
