import styled from "styled-components";

import { COLORS } from "styles";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

interface ButtonWrapperProps {
  active?: boolean;
  disabled?: boolean;
}

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  width: fit-content;

  &:not(:last-child) {
    margin: 0 8px 0 0;
  }

  .ant-btn {
    border-radius: 6px;
    color: ${({ active }) => (active ? "#40a9ff" : "#d9d9d9")};
    border-color: ${({ active }) => (active ? "#40a9ff" : "")};

    &:hover {
      color: ${({ disabled }) => (disabled ? "" : "#40a9ff")};
    }
  }
`;

interface IconButtonProps {
  disabled?: boolean;
}

export const IconButton = styled.div<IconButtonProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 8px;
  color: ${({ disabled }) => (disabled ? COLORS.GREY_MID : "initial")};

  &:first-child {
    margin: 0 8px 0 0;
  }

  &:last-child {
    margin: 0 0 0 8px;
  }

  span {
    font-size: 18px;
  }
`;

interface OverlayProps {
  side: "left" | "right";
  visible?: boolean;
}

export const Overlay = styled.div<OverlayProps>`
  position: absolute;
  display: ${({ visible }) => (!visible ? "none" : "")};
  right: ${({ side }) => (side === "right" ? "56px" : "")};
  z-index: 2;
  margin: ${({ side }) => (side === "left" ? "0 0 0 -1px" : "")};
  width: 56px;
  height: 32px;
  opacity: 0.8;
  pointer-events: none;
  background: ${({ side }) => `linear-gradient(
    to ${side === "left" ? "right" : "left"},
    rgba(255, 255, 255, 1) 40%,
    rgba(255, 255, 255, 0) 100%
  )`};
`;
