import { selector } from "recoil";

import {
  constructingRouteAppointmentIDsState,
  constructingRouteIDState,
  constructingRouteNameState,
  constructingRouteUsersState,
  constructingVehicleIDState,
  isConstructingModeState,
  sideBarActiveViewState,
  SideBarViewType,
} from "./atoms";

export const resetSideBarControls = selector({
  key: "resetSideBarControls",
  get: () => {
    return;
  },
  set: ({ set }) => {
    set(sideBarActiveViewState, SideBarViewType.main);
    set(constructingVehicleIDState, null);
    set(constructingRouteIDState, null);
    set(constructingRouteNameState, null);
    set(constructingRouteUsersState, []);
    set(constructingRouteAppointmentIDsState, []);
    set(isConstructingModeState, false);
  },
});
