import React from "react";

import * as S from "./styles";

interface AssignedRouteHeaderProps {
  label: string;
  visible: boolean;
  onToggleDisplay: (visible: boolean) => void;
}

export const AssignedRouteHeader: React.FC<AssignedRouteHeaderProps> = ({ label, visible, onToggleDisplay }) => (
  <S.MainRow>
    <S.LabelColumn>{label}</S.LabelColumn>
    <S.ActionColumn onClick={(e) => e.stopPropagation()}>
      <S.ActionColumnElements>
        {visible ? (
          <S.IconButton onClick={() => onToggleDisplay(false)}>
            <S.VisibilityIcon visible />
          </S.IconButton>
        ) : (
          <S.IconButton onClick={() => onToggleDisplay(true)}>
            <S.VisibilityIcon />
          </S.IconButton>
        )}
      </S.ActionColumnElements>
    </S.ActionColumn>
  </S.MainRow>
);
