import { HiX } from "react-icons/hi";

import { Drawer as AntdDrawer } from "antd";
import styled, { css } from "styled-components";

import { COLORS } from "styles";

import { AppointmentCard as AppointmentCardBase, AppointmentHoverShadow } from "../AppointmentCard";

export const Drawer = styled(AntdDrawer)`
  position: absolute;

  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

interface AppointmentCardProps {
  hovered?: boolean;
  selected?: boolean;
}
export const AppointmentCard = styled(AppointmentCardBase)<AppointmentCardProps>`
  overflow: hidden;

  ${(props) => props.hovered && AppointmentHoverShadow};
  &:hover {
    ${AppointmentHoverShadow};
  }

  ${(props) =>
    props.selected &&
    css`
      background-color: ${COLORS.PRIMARY_LIGHT};
    `}
`;

export const CollapseHeader = styled.div`
  user-select: none;
`;

export const CloseButtonIcon = styled(HiX)`
  width: 20px;
  height: 20px;
  margin-top: -4px;
  color: ${COLORS.CHARCOAL};
`;
