import React from "react";

import styled from "styled-components";

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div`
  font-size: 34px;
  font-weight: 600;
`;

export const Date = styled.div`
  align-self: flex-end;
  margin-left: 16px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: 500;
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;
`;

export const Header: React.FC = ({ children }) => {
  return (
    <Container>
      <Title>Capacity Utilization</Title>
      {children}
    </Container>
  );
};
