import { RouteAutomationWarehouse } from "recoil/routes/atoms";

const staging1WarehouseIds = [
  "9d3079fe-3299-11ec-8844-17d5a9d9ccb5", // Staging1 Argentia
  "9e085694-3299-11ec-8844-43d005770257", // Staging1 Queensway
];
const staging4WarehouseIds = [
  "50e6d76a-43ff-11ec-832f-27bf401af004", // Staging4 Queensway
  "531c67ca-43ff-11ec-832f-6b952ddd3987", // Staging4 Argentia
];

const staging4CloneWarehouseIds = [
  "953cc5ea-2e07-11ec-bae1-d354262a45f2", // Staging4 clone Queensway
  "97169832-2e07-11ec-bae1-6b93ff4c805b", // Staging4 clone Argentia
];

const preProdWarehouseIds = [
  "cc1ea4f2-4691-11ea-8932-570834928b75", // Pre-Prod Queensway
];

const productionWarehouseIds = [
  "42a3a2ac-7523-11ea-8eda-3f7f95052489", // Production Queensway
  "6248b1f0-77df-11eb-b7f1-77131402e397", // Production Argentia
];

export const warehouseLibrary: { [key: string]: RouteAutomationWarehouse } = {
  "42a3a2ac-7523-11ea-8eda-3f7f95052489": "queensway", // Production
  "6248b1f0-77df-11eb-b7f1-77131402e397": "argentia", // Production
  "cc1ea4f2-4691-11ea-8932-570834928b75": "queensway", // Pre-Prod
  "9e085694-3299-11ec-8844-43d005770257": "queensway", // Staging1
  "9d3079fe-3299-11ec-8844-17d5a9d9ccb5": "argentia", // Staging1
  "953cc5ea-2e07-11ec-bae1-d354262a45f2": "queensway", // Staging4 clone
  "97169832-2e07-11ec-bae1-6b93ff4c805b": "argentia", // Staging4 clone
  "50e6d76a-43ff-11ec-832f-27bf401af004": "queensway", // Staging4
  "531c67ca-43ff-11ec-832f-6b952ddd3987": "argentia", // Staging4
};

export const routeAutomationWarehouseIds = [
  ...staging1WarehouseIds,
  ...staging4WarehouseIds,
  ...staging4CloneWarehouseIds,
  ...preProdWarehouseIds,
  ...productionWarehouseIds,
];
