import * as Sentry from "@sentry/react";
import { ProfilerProps } from "@sentry/react/dist/profiler";
import { Integrations } from "@sentry/tracing";
import { CaptureContext } from "@sentry/types/esm/scope";

import { version } from "../../../package.json";
import { isLocalhost } from "../../serviceWorker";

const isStaging = process.env.REACT_APP_API?.includes("staging");
const IGNORE_ERRORS = ["Network Error", "You need to authenticate.", "Invalid username or password"];

// sentry configuration
export const sentryInit = (): void => {
  if (!isLocalhost)
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: version,
      integrations: [new Integrations.BrowserTracing()],
      environment: isStaging ? "staging" : "production",
      tracesSampleRate: isStaging ? 1 : 0.25,
      normalizeDepth: isStaging ? 3 : 6,
    });
};

export const withProfiler = (component: React.FC, options?: ProfilerProps): React.FC => {
  if (isStaging) return component;
  return Sentry.withProfiler(component, options); // profiling only in production
};

export const report = (error: Error | string, context?: CaptureContext): string => {
  if (typeof error === "string") {
    return Sentry.captureMessage(error, {
      level: Sentry.Severity.Error,
      ...context,
    });
  } else {
    return Sentry.captureException(error, context);
  }
};

export const reportAPI = (error: Error | string, payload: unknown = {}) => {
  if (typeof error === "string" && IGNORE_ERRORS.includes(error)) {
    return;
  }

  report(error, { extra: { payload } });
};

export class APIError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "APIError";
  }
}
