import { Common } from "@secondcloset/types";

import { RouteAutomationWarehouse } from "recoil/routes/atoms";

import { API } from "lib/API";

import { AppointmentOrShipment } from "interface/appointment";
import { Route, RouteUser } from "interface/route";
import { RouteAutomationJob } from "interface/routeAutomationJob";
import { Vehicle } from "interface/vehicle";

type ServiceArea = Common.ServiceArea;

export const fetchRouteIndex = async (date: string): Promise<Route[]> => {
  const api = new API("bvr");
  const url = `/routes?date=${date}`;
  const respond = await api.get(url);
  return respond.data.routes as Route[];
};

export interface CreateOrUpdateRouteParams {
  date: string;
  appointmentIDs: string[];
  users: RouteUser[];
  vehicleID: string;
  routeID?: string;
  routeName?: string;
}

export const createOrUpdateRoute = async (params: CreateOrUpdateRouteParams): Promise<Route> => {
  const { date, appointmentIDs, users, vehicleID, routeID, routeName } = params;
  const route_items = appointmentIDs.map((id, index) => ({
    appointment_id: id,
    sequence: index + 1,
  }));
  const route_users = users.map((user) => ({
    user_id: user.user_id,
    assigned_role: user.assigned_role,
  }));
  const route_name = routeName;
  const route = {
    route_date: date,
    route_items,
    route_users,
    route_name,
    vehicle_id: vehicleID,
  };
  const api = new API();
  const isCreate = !routeID;
  const url = isCreate ? "/routes" : `/routes/${routeID}`;
  const apiType = isCreate ? "post" : "put";
  const respond = await api[apiType](url, { route });
  return respond.data.route as Route;
};

export const deleteRoute = async (routeID: string) => {
  const api = new API();
  const url = `/routes/${routeID}`;
  return api.delete(url);
};

export interface DownloadRouteCsvOption {
  date: string;
  organizationID?: string | null;
  excludedOrganizationId?: string | null;
  serviceArea: ServiceArea;
}

export const downloadRouteCsv = async (option: DownloadRouteCsvOption): Promise<string> => {
  const api = new API();
  const url = "/routes/manifest";
  const body = {
    date: option.date,
    organization_id: option.organizationID,
    excluded_organization_id: option.excludedOrganizationId,
    service_area: option.serviceArea,
  };
  const respond = await api.post(url, body);
  return respond.data as string;
};

interface UpdateRouteStatusParam {
  routeID: string;
  status: string;
}

export const updateRouteStatus = async (param: UpdateRouteStatusParam): Promise<Route> => {
  const { routeID, status } = param;
  const api = new API();
  const url = `/routes/${routeID}/update_status`;
  const respond = await api.put(url, { route_status: status });
  return respond.data as Route;
};

interface CreateRouteAutomationJobParams {
  warehouse: RouteAutomationWarehouse;
  vehicles: Vehicle[];
  appointments: AppointmentOrShipment[];
}

export const createRouteAutomationJob = async (params: CreateRouteAutomationJobParams): Promise<RouteAutomationJob> => {
  const api = new API("silver");
  const url = `/routing/create-job`;
  const response = await api.post(url, {
    ...params,
    speed: 15,
  });

  return response.data as RouteAutomationJob;
};
