import styled from "styled-components";

import { COLORS } from "styles";

export const NoAppointmentsLabel = styled.div`
  display: flex;
  justify-content: center;
  padding: 6px;
  color: ${COLORS.GREY};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
`;
