import React from "react";

import { Select, Tooltip } from "antd";

import { COLORS } from "styles";

import { useVehicleStatuses } from "hooks/api/vehicles";

import { Status } from "interface/vehicle";

interface VehicleStatusDropdownProps {
  currentStatusName: string;
  inUse: boolean;
  onChange: (status: string) => void;
}

export const VehicleStatusDropdown: React.FC<VehicleStatusDropdownProps> = ({ currentStatusName, inUse, onChange }) => {
  const statuses = useVehicleStatuses() ?? [];

  const getOptionLabel = (option: Status) => {
    const status = option.name;
    let color;
    switch (status) {
      case "deactivated":
        color = COLORS.RED;
        break;
      case "in_service":
        color = COLORS.PRIMARY_GREEN;
        break;
      case "temporarily_out_of_service":
      default:
        color = COLORS.BLACK;
    }
    return <div style={{ color }}>{option.to_show}</div>;
  };

  const options = statuses?.map((status) => {
    const backgroundColor = currentStatusName === status.name ? COLORS.ASH_BLUE : COLORS.WHITE;
    return (
      <Select.Option value={status.name} key={status.name} style={{ backgroundColor }}>
        {getOptionLabel(status)}
      </Select.Option>
    );
  });

  const inUsePrompt =
    "This vehicle is assigned to one of today’s routes. Please remove it from the route before changing the status";

  const renderSelect = () => {
    return (
      <Select
        value="Actions"
        style={{ width: 89 }}
        disabled={inUse}
        dropdownMatchSelectWidth={false}
        onChange={(value) => onChange(value as string)}
        data-testid="VehicleStatusDropdownSelect"
      >
        {options}
      </Select>
    );
  };

  return (
    <>
      {inUse ? (
        <Tooltip title={inUsePrompt} placement="bottom">
          {renderSelect()}
        </Tooltip>
      ) : (
        renderSelect()
      )}
    </>
  );
};
