import { useMemo } from "react";

import { useUnassignedAppointments } from "hooks/api/appointments";
import { useSidebarSearch } from "hooks/application/useSidebarSearch";

export const useAppointmentsToRender = () => {
  const unassignedAppointments = useUnassignedAppointments();
  const { isSearchEnabled, getIsAppointmentSearched } = useSidebarSearch();

  const appointmentsToRender = useMemo(() => {
    return isSearchEnabled ? unassignedAppointments.filter(getIsAppointmentSearched) : unassignedAppointments;
  }, [unassignedAppointments, isSearchEnabled, getIsAppointmentSearched]);

  return appointmentsToRender;
};
