import { difference } from "lodash";

import { useRecoilValue } from "recoil";
import { activeMarkerState } from "recoil/routeMapControls/atoms";

import { useCreateRouteState, useCreateRouteView } from "hooks/application/createRoute";
import { useSidebarSearch } from "hooks/application/useSidebarSearch";

import { AppointmentMarkerInCreateRouteMode } from "../../../Markers";
import {
  useVisibleEditingRouteAppointmentsMarkers,
  useVisibleUnassignedAppointmentsMarkers,
  VisibleMarker,
} from "../../useVisibleMarkers";
import { useHoverMarker } from "../useHoverMarker";

import { useClickMakerInCreateRouteMode } from "./useClickMarkerInCreateRouteMode";

import { Map } from "interface/map";

export const useRenderMarkersInCreateRouteMode = (map: Map) => {
  const visibleUnassignedAppointmentsMarkers = useVisibleUnassignedAppointmentsMarkers();
  const visibleEditingRouteAppointmentsMarkers = useVisibleEditingRouteAppointmentsMarkers();

  const activeMarker = useRecoilValue(activeMarkerState);
  const { hoverMarkers, unhoverMarker, getIsMarkerHovered } = useHoverMarker();
  const handleMarkerInCreateRouteModeClick = useClickMakerInCreateRouteMode();

  const { isSearchEnabled, getIsMarkerSearched } = useSidebarSearch();
  const { shouldRenderConstructionView } = useCreateRouteView();
  const { selectedAppointmentsIDs } = useCreateRouteState();

  const mergeDuplicateMarkers = (markers: VisibleMarker[]) => {
    const markersMap = markers.reduce((result, marker) => {
      const processedMarker = result[marker.markerKey];

      if (processedMarker) {
        processedMarker.appointmentIDs = [...processedMarker.appointmentIDs, ...marker.appointmentIDs];
      } else {
        result[marker.markerKey] = { ...marker };
      }

      return result;
    }, {} as { [markerKey: string]: VisibleMarker });

    return Object.values(markersMap);
  };

  const getShouldRenderMarkerInCreateRouteMode = (appointmentIDs: string[]) => {
    if (!isSearchEnabled) return true;
    if (shouldRenderConstructionView) return true;

    return getIsMarkerSearched(appointmentIDs);
  };

  const renderMarkersInCreateRouteMode = () => {
    const markers = mergeDuplicateMarkers([
      ...visibleUnassignedAppointmentsMarkers,
      ...visibleEditingRouteAppointmentsMarkers,
    ]);

    return markers.map((marker) => {
      const { markerKey, appointmentIDs, lat, lng } = marker;

      const isAllAppointmentsSelected = difference(appointmentIDs, selectedAppointmentsIDs).length === 0;

      const shouldRender = getShouldRenderMarkerInCreateRouteMode(appointmentIDs);
      if (!shouldRender) return null;

      return (
        <AppointmentMarkerInCreateRouteMode
          lat={lat}
          lng={lng}
          key={markerKey}
          markerKey={markerKey}
          appointmentIDs={appointmentIDs}
          map={map}
          selected={isAllAppointmentsSelected}
          isActive={activeMarker.id === markerKey}
          isHovered={getIsMarkerHovered(appointmentIDs)}
          onClick={handleMarkerInCreateRouteModeClick}
          onMouseEnter={hoverMarkers}
          onMouseLeave={unhoverMarker}
        />
      );
    });
  };

  return renderMarkersInCreateRouteMode;
};
