import React, { useMemo } from "react";
import { useQuery } from "react-query";

import { Empty, Spin } from "antd";
import { isEmpty } from "lodash";

import * as S from "./styles";

import { useAppointments } from "hooks/api/appointments";

import { fetchAppointmentDetails } from "api/appointment";
import { Appointment } from "lib/appointment";

import { AppointmentOrShipment } from "interface/appointment";

interface ItemsTableProps {
  appointment?: AppointmentOrShipment | null;
  infoBoxVisible: boolean;
}

export const ItemsTable: React.FC<ItemsTableProps> = ({ appointment, infoBoxVisible }) => {
  const { setAppointmentsDetails } = useAppointments();
  const { isLoading } = useQuery(
    ["appointmentDetails", appointment?.id],
    () => {
      return appointment?.id ? fetchAppointmentDetails(appointment.id) : null;
    },
    {
      enabled: !!appointment?.id && infoBoxVisible,
      onSuccess: (appointment) => {
        if (!!appointment) setAppointmentsDetails([appointment]);
      },
    }
  );

  const compressedItems = useMemo(() => Appointment.getCompressedItems(appointment), [appointment]);

  if (isLoading) {
    return (
      <S.NoItems>
        <Spin tip="Loading..." />
      </S.NoItems>
    );
  }

  if (isEmpty(compressedItems)) {
    return (
      <S.NoItems>
        <Empty description="No Items" image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </S.NoItems>
    );
  }

  const itemRows = compressedItems.map((item) => {
    const { weight_value, weight_unit, length_value, length_unit, height_value, height_unit, width_value, width_unit } =
      item;
    const getUnitSymbol = (unit: string) => (unit === "in" ? `"` : unit);
    const length = `${parseInt(length_value || "")}${getUnitSymbol(length_unit || "")}`;
    const width = `${parseInt(width_value || "")}${getUnitSymbol(width_unit || "")}`;
    const height = `${parseInt(height_value || "")}${getUnitSymbol(height_unit || "")}`;
    const dimensions = `${length} x ${width} x ${height}`;
    const weight = `${parseInt(weight_value || "")} ${getUnitSymbol(weight_unit || "")}`;

    return (
      <S.Row key={item.name}>
        <S.Column flex={4}>{item.name}</S.Column>
        <S.Column flex={1}>{item.quantity}</S.Column>
        <S.Column flex={2}>{length_value && height_value && width_value ? dimensions : "N/A"}</S.Column>
        <S.Column flex={1}>{weight_value ? weight : "N/A"}</S.Column>
      </S.Row>
    );
  });

  return (
    <S.Container>
      <table>
        <S.TableBody>
          <S.Row>
            <S.Column flex={4}>Product</S.Column>
            <S.Column flex={1}>Qty</S.Column>
            <S.Column flex={2}>Dimensions</S.Column>
            <S.Column flex={1}>Weight</S.Column>
          </S.Row>
          {itemRows}
        </S.TableBody>
      </table>
    </S.Container>
  );
};
