import { useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useMutation, useQueryClient } from "react-query";

import { notification } from "antd";
import { isEmpty, uniq } from "lodash";

import * as S from "./styles";

import { useResetRecoilState, useSetRecoilState } from "recoil";
import { resetRouteMapControls } from "recoil/routeMapControls/selectors";
import { createRouteState, CreateRouteView } from "recoil/routes/atoms";
import { appointmentDetailsModalState } from "recoil/sideBarControls/atoms";

import { useAppointments } from "hooks/api/appointments";
import { useRoutes } from "hooks/api/routes";
import { SetRouteDetailsProps, useCreateRouteState } from "hooks/application/createRoute";
import { useActiveDate } from "hooks/application/useActiveDate";
import { useActiveMarker } from "hooks/application/useActiveMarker";
import { useSidebarSearch } from "hooks/application/useSidebarSearch";

import { createOrUpdateRoute, CreateOrUpdateRouteParams, deleteRoute } from "api/routes";
import { SelectVehicleModal } from "components/SelectVehicleModal";
import { Appointment } from "lib/appointment";
import {
  addMissingInventoryPickupOrReturnToSender,
  addRemoveAppointmentRouteID,
} from "utilities/routes/addRemoveAppointmentRouteId";
import { groupAppointments } from "utilities/routes/groupAppointment";
import { sortIKEAAppointmentIDs } from "utilities/routes/sortIKEAAppointmentIDs";
import { validateRoute } from "utilities/routes/validateRoute";

import { useClickAppointmentCard, useHoverAppointmentCard } from "../AppointmentCard";
import { ConstructionRouteHeader } from "../RouteHeader/ConstructionRouteHeader";
import { routeAppointmentCount } from "../utils";

import { AppointmentsCount } from "./AppointmentsCount";
import { Footer } from "./Footer/Footer";
import { RemainingAppointmentsSelector } from "./RemainingAppointmentsSelector";
import { SelectFieldOpsModal } from "./SelectFieldOpsModal";
import { ConstructParams } from "./types";
import { WarningModal } from "./WarningModal";

import { AppointmentOrShipment } from "interface/appointment";
import { RouteUser } from "interface/route";

export const ConstructingView = () => {
  const queryClient = useQueryClient();

  const { activeDate } = useActiveDate();
  const [constructParams, setConstructParams] = useState<ConstructParams>({
    date: activeDate,
    appointmentIDs: [],
    users: [],
  });
  const [enabledVehicleModal, setEnabledVehicleModal] = useState(false);
  const [enabledFieldOpsModal, setEnabledFieldOpsModal] = useState(false);
  const [enabledWarningModal, setEnabledWarningModal] = useState(false);
  const [wrongStatusAppointments, setWrongStatusAppointments] = useState<AppointmentOrShipment[]>([]);
  const resetCreateRouteState = useResetRecoilState(createRouteState);
  const dispatchResetRouteMapControls = useSetRecoilState(resetRouteMapControls);
  const { routesLibrary } = useRoutes();
  const {
    appointmentsState,
    appointmentsLibrary,
    inventoryPickupCrossDockDeliveryPairs,
    reverseLogisticsReturnToSenderPairs,
  } = useAppointments();
  const setAppointmentDetailsModal = useSetRecoilState(appointmentDetailsModalState);
  const { getIsAppointmentHovered, hoverAppointmentCard, unhoverAppointmentCard } = useHoverAppointmentCard();
  const { getIsAppointmentActive } = useActiveMarker();
  const handleAppointmentCardClick = useClickAppointmentCard();

  const {
    setCurrentView: setCreateRouteStateView,
    setRouteDetails,
    routeID,
    routeItems,
    routeName,
    vehicleID,
    users,
    selectedAppointmentsIDs,
  } = useCreateRouteState();
  const { isSearchEnabled, getIsAppointmentSearched } = useSidebarSearch();

  useEffect(() => {
    const appointmentIDs = selectedAppointmentsIDs || [];
    const sortedAppointmentIDs = groupAppointments(appointmentIDs, appointmentsLibrary).map((a) => a.id);

    setConstructParams((prevConstructParams) => ({
      ...prevConstructParams,
      routeID,
      vehicleID,
      users: users || [],
      appointmentIDs: sortedAppointmentIDs,
      routeName,
    }));

    setRouteDetails({ selectedAppointmentsIDs: sortedAppointmentIDs });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setConstructParams((prevConstructParams) => ({
      ...prevConstructParams,
      appointmentIDs: selectedAppointmentsIDs ?? [],
    }));
  }, [selectedAppointmentsIDs]);

  const { mutate: constructRoute, isLoading: loadingConstructRoute } = useMutation(createOrUpdateRoute, {
    onSuccess: () => {
      queryClient.invalidateQueries("routes");
      resetCreateRouteState();
      dispatchResetRouteMapControls();
      notification.success({
        message: "Success",
        description: "Route has been saved",
      });
    },
    onError: (e: string) => {
      notification.error({
        message: "Save Route Error",
        description: e,
      });
    },
  });

  const { mutate: onDeleteRoute, isLoading: loadingDeleteRoute } = useMutation(deleteRoute, {
    onSuccess: () => {
      queryClient.invalidateQueries("routes");
      resetCreateRouteState();
      dispatchResetRouteMapControls();
      notification.success({
        message: "Success",
        description: "Route has been deleted",
        duration: 1.5,
      });
    },
    onError: (e: string) => {
      notification.error({
        message: "Delete Route Error",
        description: e,
      });
    },
  });

  const onDragEnd = (result: DropResult) => {
    const appointmentID = result.draggableId;
    if (!result.destination) return;

    const newRouteAppointmentIDs = constructParams.appointmentIDs.filter((id) => id !== appointmentID) || [];

    let newIndex = result.destination.index;
    if (newIndex < 0) newIndex = 0;
    newRouteAppointmentIDs.splice(newIndex, 0, appointmentID);
    const sortedAppointments = groupAppointments(newRouteAppointmentIDs, appointmentsLibrary);

    // This is required to remove the jittery animation if we solely rely on createRouteState.selectedAppointmentsIDs
    setConstructParams({ ...constructParams, appointmentIDs: sortedAppointments.map((a) => a.id) });
    setRouteDetails({ selectedAppointmentsIDs: sortedAppointments.map((a) => a.id) });
  };

  const handleCancel = () => {
    resetCreateRouteState();
    setCreateRouteStateView(CreateRouteView.SelectAppointments);
  };

  const onSelectVehicle = (vehicleID: string) => {
    setConstructParams((prevConstructParams) => ({ ...prevConstructParams, vehicleID }));
    setEnabledVehicleModal(false);
  };

  const onSelectRouteUsers = (routeUsers: RouteUser[]) => {
    setConstructParams((prevConstructParams) => ({ ...prevConstructParams, users: routeUsers }));
  };

  const onInputRouteName = (newRouteName: string) => {
    setConstructParams((prevConstructParams) => ({ ...prevConstructParams, routeName: newRouteName }));
  };

  const handleDeleteAppointment = (appointmentID: string) => {
    const newAppointmentIDs = addRemoveAppointmentRouteID(
      appointmentID,
      constructParams.appointmentIDs,
      appointmentsState
    );

    const sortedAppointmentIDs = groupAppointments(newAppointmentIDs, appointmentsLibrary).map((a) => a.id);
    const routeDetailsPatch = {
      selectedAppointmentsIDs: sortedAppointmentIDs,
    } as SetRouteDetailsProps;

    const isEditingExistingRoute = routeItems;
    if (isEditingExistingRoute) {
      routeDetailsPatch.routeItems = routeItems.filter(({ appointment_id }) =>
        newAppointmentIDs.includes(appointment_id)
      );
    }

    setRouteDetails(routeDetailsPatch);
  };

  const appointments = useMemo(() => {
    return constructParams.appointmentIDs.map((id) => appointmentsLibrary[id]);
  }, [appointmentsLibrary, constructParams.appointmentIDs]);

  const appointmentsToRender = useMemo(() => {
    return isSearchEnabled ? appointments.filter(getIsAppointmentSearched) : appointments;
  }, [isSearchEnabled, appointments, getIsAppointmentSearched]);

  const renderAppointmentCard = (appointment: AppointmentOrShipment, index: number) => {
    return (
      <Draggable key={appointment.id} draggableId={appointment.id} index={index}>
        {(provided) => (
          <S.DraggableAppointmentContainer
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <S.AppointmentCard
              appointment={appointment}
              selected={getIsAppointmentActive(appointment.id)}
              dragIcon={true}
              onDelete={handleDeleteAppointment}
              onShowDetails={(appointmentID) => setAppointmentDetailsModal({ visible: true, appointmentID })}
              isConstructing={true}
              hovered={getIsAppointmentHovered(appointment.id)}
              onMouseEnterCard={hoverAppointmentCard}
              onMouseLeaveCard={unhoverAppointmentCard}
              onClick={handleAppointmentCardClick}
            />
          </S.DraggableAppointmentContainer>
        )}
      </Draggable>
    );
  };

  const routeErrors = useMemo(() => {
    return validateRoute(constructParams, routesLibrary, appointmentsLibrary);
  }, [appointmentsLibrary, constructParams, routesLibrary]);

  const onSave = () => {
    let appointmentIDs = uniq([
      ...constructParams.appointmentIDs,
      ...addMissingInventoryPickupOrReturnToSender(
        constructParams.appointmentIDs,
        appointmentsLibrary,
        inventoryPickupCrossDockDeliveryPairs,
        Appointment.isInventoryPickup
      ),
      ...addMissingInventoryPickupOrReturnToSender(
        constructParams.appointmentIDs,
        appointmentsLibrary,
        reverseLogisticsReturnToSenderPairs,
        Appointment.isInventoryPickup
      ),
    ]);

    appointmentIDs = sortIKEAAppointmentIDs(appointmentIDs, appointmentsState);

    constructRoute({ ...constructParams, appointmentIDs } as CreateOrUpdateRouteParams);
  };

  const onConfirmClick = () => {
    if (routeErrors.errors.no_appointments) {
      notification.error({
        message: "Submit Error!",
        description: "Route cannot be empty, you may want to delete the route",
      });
    } else if (routeErrors.appointment_with_wrong_status) {
      setEnabledWarningModal(true);
      setWrongStatusAppointments(routeErrors.appointment_with_wrong_status.appointments);
    }

    if (!isEmpty(routeErrors.errors) || routeErrors.appointment_with_wrong_status) return;
    onSave();
  };

  const handleDeleteRoute = () => {
    if (!constructParams.routeID) return;
    onDeleteRoute(constructParams.routeID);
  };

  const appointmentsCount = useMemo(() => routeAppointmentCount(appointments), [appointments]);

  return (
    <S.MainContainer>
      <S.Header>
        <ConstructionRouteHeader
          onClickTruck={() => setEnabledVehicleModal(true)}
          onClickDriver={() => setEnabledFieldOpsModal(true)}
          onDeleteRoute={() => handleDeleteRoute()}
          onInputRouteName={onInputRouteName}
          routeID={routeID}
          prevRouteName={routeName}
          hasVehicle={!!constructParams.vehicleID}
          hasFieldOps={!!constructParams.users.length}
          canDelete={!!constructParams.routeID}
        />
      </S.Header>

      <AppointmentsCount count={appointmentsCount} hiddenCount={appointmentsCount - appointmentsToRender.length} />

      <S.AppointmentsList>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="route-list-droppable">
            {(provided) => (
              <S.DraggableListContainer {...provided.droppableProps} ref={provided.innerRef}>
                {appointmentsToRender.map((appointment, index) => renderAppointmentCard(appointment, index))}
              </S.DraggableListContainer>
            )}
          </Droppable>
        </DragDropContext>
      </S.AppointmentsList>

      <Footer
        onSave={onConfirmClick}
        isValid={isEmpty(routeErrors.errors)}
        isLoading={loadingConstructRoute || loadingDeleteRoute}
        isEdit={!!constructParams.routeID}
        onCancel={handleCancel}
        onAddMoreAppointmentsRequest={() => {
          setCreateRouteStateView(CreateRouteView.ConstructRouteAddMoreAppointments);
        }}
      />

      <SelectFieldOpsModal
        visible={enabledFieldOpsModal}
        onClose={() => setEnabledFieldOpsModal(false)}
        onSave={onSelectRouteUsers}
        routeUsers={constructParams.users}
      />

      <SelectVehicleModal
        visible={enabledVehicleModal}
        onSave={onSelectVehicle}
        selectedVehicleID={constructParams.vehicleID}
        onClose={() => setEnabledVehicleModal(false)}
      />

      <WarningModal
        visible={enabledWarningModal}
        appointments={wrongStatusAppointments}
        setVisible={setEnabledWarningModal}
        onConfirm={() => onSave()}
      />

      <RemainingAppointmentsSelector />
    </S.MainContainer>
  );
};
