import React, { useEffect, useState } from "react";

import { AppointmentSlot } from "@secondcloset/types/src/storage";

import { TreeSelect } from "antd";

import * as S from "../styles";

import { useSetRecoilState } from "recoil";
import { activeTimeSlotsState } from "recoil/topFilterControls/atoms";

import { useAppointments } from "hooks/api/appointments";
import { useActiveFlow } from "hooks/application/useActiveFlow";

import { getSelectPlaceHolder } from "../helpers";

import { AppointmentOrShipment } from "interface/appointment";

export const SlotFilter: React.FC<{ appointments: AppointmentOrShipment[] }> = ({ appointments }) => {
  const { isIkea } = useActiveFlow();
  const { appointmentTimeslots } = useAppointments();
  const dispatchActiveTimeSlots = useSetRecoilState(activeTimeSlotsState);
  const [activeTimeSlots, setActiveTimeSlots] = useState(appointmentTimeslots);

  useEffect(() => {
    setActiveTimeSlots(appointmentTimeslots);
  }, [isIkea, appointmentTimeslots]);

  const getOptionLabel = (key: string) => {
    const optionCount = appointments.filter((appt) => appt?.timerange === key).length;
    return key + ` (${optionCount})`;
  };

  const filterOptions = appointmentTimeslots.map((s) => {
    return {
      key: s,
      value: s,
      label: getOptionLabel(s),
    };
  });

  const handleSelectionChange = (value: AppointmentSlot[]) => {
    setActiveTimeSlots(value);
    dispatchActiveTimeSlots(value);
  };

  return (
    <S.FilterContainer>
      <TreeSelect
        treeCheckable
        treeData={filterOptions}
        placeholder={"None Selected"}
        value={activeTimeSlots}
        onChange={handleSelectionChange}
        showArrow
        style={{ width: 144 }}
        dropdownStyle={{ minWidth: 144 }}
        showSearch={false}
        maxTagCount={0}
        maxTagPlaceholder={() => (
          <S.SelectedText>
            {getSelectPlaceHolder([filterOptions.length], activeTimeSlots, "Timeslots", filterOptions, null)}
          </S.SelectedText>
        )}
      />
    </S.FilterContainer>
  );
};
