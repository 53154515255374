import styled from "styled-components";

import { COLORS } from "styles";

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  border: $border;
  align-items: center;
  padding-right: 16px;

  .label {
    padding: 0 8px 0 0;
    font-size: 14px;
    color: ${COLORS.GREY};
  }

  .ant-select-selection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 184px;
  }
`;
