import { useCallback, useMemo } from "react";

import isEmpty from "lodash/isEmpty";
import keyBy from "lodash/keyBy";

import { useRecoilState } from "recoil";
import { sidebarSearchState } from "recoil/sideBarControls/atoms";

import { useAppointments } from "hooks/api/appointments";
import { useAppointmentsRoutesMap, useRoutes } from "hooks/api/routes";

import { useFuzzySearch } from "./useFuzzySearch";

import { AppointmentOrShipment } from "interface/appointment";

interface AppointmentWithSearchAnnotation {
  appointment: AppointmentOrShipment;
  externalOrderNumber?: string;
  externalOrderNumberParts?: string[];
  customerName?: string;
  routeNumber?: string;
  routeName?: string;
  routeUsersNames?: string[];
}

export const useSidebarSearch = () => {
  const [searchState, setSearchState] = useRecoilState(sidebarSearchState);
  const appointmentRoutes = useAppointmentsRoutesMap();
  const isSearchEnabled = !isEmpty(searchState.searchKey);

  const { routesLibrary } = useRoutes();
  const { appointmentsLibrary } = useAppointments();

  const appointmentsWithSearchInfo = useMemo(() => {
    const appointments = Object.values(appointmentsLibrary);

    const result = appointments.map((appointment) => {
      const routeID = appointmentRoutes[appointment.id];
      const route = routesLibrary[routeID];

      const result = { appointment } as AppointmentWithSearchAnnotation;

      if ("source" in appointment) {
        result.externalOrderNumber = appointment.source.external_order_number;
        result.externalOrderNumberParts = appointment.source.external_order_number?.split("___");
        result.customerName = appointment.source.customer?.name;
      }
      if (route) {
        result.routeNumber = route.route_number;
        result.routeName = route.route_name;
        result.routeUsersNames = route.route_users.map(({ first_name, last_name }) => `${first_name} ${last_name}`);
      }

      return result;
    });

    return result;
  }, [routesLibrary, appointmentsLibrary, appointmentRoutes]);

  const searchAppointments = useFuzzySearch(appointmentsWithSearchInfo, {
    keys: [
      "externalOrderNumber",
      "externalOrderNumberParts",
      "routeNumber",
      "routeName",
      "customerName",
      "routeUsersNames",
    ],
    useExtendedSearch: true,
    shouldSort: false,
    threshold: 0.3,
  });

  const searchedAppointmentsMap = useMemo(() => {
    if (!searchState.searchKey) return {};

    const searchedAppointments = searchAppointments({
      $or: [
        { externalOrderNumber: `=${searchState.searchKey}` },
        { externalOrderNumberParts: `=${searchState.searchKey}` },
        { routeNumber: `=${searchState.searchKey}` },
        { routeName: searchState.searchKey },
        { customerName: searchState.searchKey },
        { routeUsersNames: searchState.searchKey },
      ],
    }).map(({ appointment }) => appointment);

    return keyBy(searchedAppointments, (appointment) => appointment.id);
  }, [searchState.searchKey, searchAppointments]);

  const getIsAppointmentSearched = useCallback(
    (appointment: { id: string }) => searchedAppointmentsMap[appointment.id],
    [searchedAppointmentsMap]
  );

  const getIsMarkerSearched = (appointmentIDs: string[]) => {
    if (!isSearchEnabled) return false;
    return appointmentIDs.some((appointmentID) => getIsAppointmentSearched({ id: appointmentID }));
  };

  const setSearchKey = (searchKey: string) => {
    setSearchState((state) => ({ ...state, searchKey }));
  };

  return {
    ...searchState,
    isSearchEnabled,
    setSearchKey,
    getIsAppointmentSearched,
    searchedAppointmentsMap,
    getIsMarkerSearched,
  };
};
