export const useLocalStorage = () => {
  const getValue = (key: string) => {
    return localStorage.getItem(key);
  };

  const setValue = (key: string, value: string) => {
    return localStorage.setItem(key, value);
  };

  return { getValue, setValue };
};
