import { useQuery, useQueryClient, UseQueryOptions } from "react-query";
import { useRouteMatch } from "react-router";

import { useRecoilValue } from "recoil";
import { activeServiceAreaState } from "recoil/_common/activeServiceArea";
import { bookingSelectedDateState, bookingSelectedWarehouseIdState } from "recoil/booking/atoms";
import { activeIkeaWarehouseAddressIDState } from "recoil/topFilterControls/atoms";

import { useCreateRouteView } from "hooks/application/createRoute";
import { useActiveDate } from "hooks/application/useActiveDate";
import { useActiveFlow } from "hooks/application/useActiveFlow";

import { AppointmentIndexQuery, fetchAppointmentIndex, fetchInventoryOnHandAppointmentIndex } from "api/appointment";

import { setupAppointments } from "./setupAppointments";

import { AppointmentOrShipment } from "interface/appointment";
import { AppointmentsState } from "interface/appointmentsState";

const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = 60 * ONE_MINUTE;

const DefaultAppointmentsState = {
  appointmentsLibrary: {},
  appointmentsMarkers: {},
  inventoryPickupCrossDockDeliveryPairs: {},
  reverseLogisticsReturnToSenderPairs: {},
  inventoryOnHandAppointmentIDMap: {},
  appointmentTimeslots: [],
};

const fetchAppointments = async (query: AppointmentIndexQuery) => {
  const fetchAppointmentsPromises = [fetchAppointmentIndex(query)];

  if (query.isIkea) {
    fetchAppointmentsPromises.push(
      fetchInventoryOnHandAppointmentIndex({
        date: query.date,
        serviceArea: query.serviceArea,
      })
    );
  }

  const [appointments, inventoryOnHandAppointments = []] = await Promise.all(fetchAppointmentsPromises);
  return [...appointments, ...inventoryOnHandAppointments];
};

const useAppointmentsBase = (query: AppointmentIndexQuery, options?: UseQueryOptions<AppointmentsState, string>) => {
  const {
    isLoading,
    isFetched,
    error,
    data: appointmentsState = DefaultAppointmentsState,
  } = useQuery<AppointmentsState, string>(
    ["appointments", query],
    async () => {
      const appointments = await fetchAppointments(query);
      return setupAppointments(appointments);
    },
    options
  );

  return { isLoading, isFetched, error, appointmentsState, ...appointmentsState };
};

const useRoutesPageQueryAndOptions = () => {
  const { activeDate } = useActiveDate();
  const activeServiceArea = useRecoilValue(activeServiceAreaState);
  const { isIkea } = useActiveFlow();
  const ikeaWarehouseAddressID = useRecoilValue(activeIkeaWarehouseAddressIDState);

  const query = {
    date: activeDate,
    serviceArea: activeServiceArea,
    isIkea,
    ikeaWarehouseAddressID,
  };

  const isQueryEnabled = !!activeServiceArea;
  const { shouldRenderConstructionView: isConstructionModeEnabled } = useCreateRouteView();
  const options = {
    enabled: isQueryEnabled,
    staleTime: ONE_MINUTE,
    refetchInterval: isConstructionModeEnabled ? false : ONE_HOUR,
  };

  const isRoutesPagePath = useRouteMatch("/routes");
  if (isRoutesPagePath) {
    return { query, options };
  }
};

const useBookingPageQueryAndOptions = () => {
  const activeServiceArea = useRecoilValue(activeServiceAreaState);
  const { isIkea } = useActiveFlow();
  const bookingSelectedDate = useRecoilValue(bookingSelectedDateState);
  const bookingSelectedWarehouseID = useRecoilValue(bookingSelectedWarehouseIdState);

  const query = {
    date: bookingSelectedDate ?? "",
    serviceArea: activeServiceArea,
    isIkea: true,
    ikeaWarehouseAddressID: bookingSelectedWarehouseID,
  };

  const isQueryEnabled = !!(activeServiceArea && bookingSelectedDate && isIkea);
  const options = {
    enabled: isQueryEnabled,
    staleTime: ONE_MINUTE,
  };

  const isBookingPagePath = useRouteMatch("/booking");
  if (isBookingPagePath) {
    return { query, options };
  }
};

export const useAppointments = () => {
  const routesPageQueryAndOptions = useRoutesPageQueryAndOptions();
  const bookingPageQueryAndOptions = useBookingPageQueryAndOptions();

  const { query, options } = [routesPageQueryAndOptions, bookingPageQueryAndOptions].find((query) => query) ?? {
    query: {},
    options: { enabled: false },
  };

  const queryClient = useQueryClient();

  const setAppointmentsDetails = (appointments: AppointmentOrShipment[]) => {
    queryClient.setQueryData<AppointmentsState | undefined>(["appointments", query], (appointmentsState) => {
      if (!appointmentsState) {
        return DefaultAppointmentsState;
      }
      const appointmentsLibrary = { ...appointmentsState.appointmentsLibrary };
      appointments.forEach((appointment) => {
        appointmentsLibrary[appointment.id] = appointment;
      });
      return {
        ...appointmentsState,
        appointmentsLibrary,
      };
    });
  };

  return {
    ...useAppointmentsBase(query, options),
    setAppointmentsDetails,
  };
};
