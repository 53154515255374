import React from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";

import queryString from "query-string";

import * as S from "./styles";

import { useActiveFlow } from "hooks/application/useActiveFlow";

import { routes } from "router/routes";

import { DatePicker } from "./DatePicker";
import { NavigationTabs, TabNavigationItem } from "./NavigationTabs";
import { ServiceAreaSelector } from "./ServiceAreaSelector";
import { WarehouseFilter } from "./WarehouseFilter";
import { WorkflowSelector } from "./WorkflowSelector";

export const Navbar: React.FC = () => {
  const { isIkea: isIkeaFlow } = useActiveFlow();

  const location = useLocation();
  const history = useHistory();

  const isPathEqual = (routeDefinitionPath: string, currentURL: string) => {
    const routeDefinitionPathWords = routeDefinitionPath.split("/");
    const currentURLWords = currentURL.split("/");

    const idIndex = routeDefinitionPathWords.findIndex((w) => w.includes(":"));
    if (idIndex < 0) {
      return routeDefinitionPath === currentURL;
    }

    routeDefinitionPathWords.splice(idIndex, 1);
    currentURLWords.splice(idIndex, 1);
    return routeDefinitionPathWords.join("/") === currentURLWords.join("/");
  };

  const showNavbar = () => {
    const currentPath = location.pathname;
    const foundPath = routes.find((r) => isPathEqual(r.path, currentPath));
    if (foundPath) return foundPath.showNavBar;
    return false;
  };

  if (!showNavbar()) return null;

  const navigationTabs = routes
    .filter((route) => route.isTab)
    .map(({ key, name, path, url }) => ({ key, name, path, url }));

  const handleTabClick = (tabItem: TabNavigationItem) => {
    const targetRoute = routes.find((route) => route.key === tabItem.key);
    if (!targetRoute) return;

    const query = queryString.parse(location.search);
    const navigateURL = tabItem.url ?? tabItem.path;

    const currentTab = navigationTabs.find(({ path }) => matchPath(location.pathname, { path }));

    if (currentTab && targetRoute.persistDateFrom.includes(currentTab.key) && query?.date) {
      history.push(`${navigateURL}?date=${query.date}`);
    } else {
      history.push(navigateURL);
    }
  };

  return (
    <S.Container>
      <WorkflowSelector />
      <ServiceAreaSelector />
      <DatePicker />
      {isIkeaFlow && <WarehouseFilter />}

      <S.TabsContainer>
        <NavigationTabs items={navigationTabs} onTabClick={handleTabClick} />
      </S.TabsContainer>
    </S.Container>
  );
};
